import {
  alpha,
  buttonClasses,
  Color,
  ComponentsProps,
  ComponentsOverrides,
  SimplePaletteColorOptions,
  Theme,
} from '@mui/material'
import { OverridesStyleRules } from '@mui/material/styles/overrides'
import { ComponentOptions } from '../types'

interface ButtonStyles {
  defaultProps: ComponentsProps['MuiButton']
  styleOverrides: {
    outlinedError?: OverridesStyleRules
  } & ComponentsOverrides<Theme>['MuiButton']
}

export const MuiOSHButton = ({ palette, spacing }: ComponentOptions): ButtonStyles => {
  const primary = palette.primary as SimplePaletteColorOptions
  const error = palette.error as SimplePaletteColorOptions
  const grey = palette.grey as Color

  return {
    defaultProps: {
      variant: 'outlined',
      color: 'secondary',
      size: 'large',
    },
    styleOverrides: {
      root: {
        textTransform: 'none',
        fontWeight: 500,
        '&>svg': {
          marginRight: spacing(1),
        },
        '&:focus, &:active': {
          boxShadow: `0 0 0 2px ${alpha(primary.light as string, 0.35)}`,
        },
      },
      outlined: {
        border: '1px solid',
        backgroundColor: 'transparent',
        color: palette.text?.secondary,
        boxShadow: 'none',
        '&:hover': {
          backgroundColor: grey[200],
          borderColor: grey[400],
          boxShadow: 'none',
        },
        '&:focus, &:active': {
          outlineOffset: 3,
          outlineColor: grey[300],
        },
        '&:active': {
          outlineColor: grey[500],
        },
        [`&.${buttonClasses.disabled}`]: {
          backgroundColor: grey[100],
          borderColor: grey[400],
          color: grey[400],
        },
      },
      outlinedSecondary: {
        color: grey[500],
        borderColor: grey[500],
      },
      outlinedPrimary: {
        borderColor: primary.main,
        backgroundColor: primary.main,
        color: primary.contrastText,
        '&:hover': {
          backgroundColor: primary.dark,
        },
      },
      outlinedError: {
        borderColor: error.main,
        color: error.main,
        '&:hover': {
          backgroundColor: alpha(error.light as string, 0.34),
        },
        '&:focus, &:active': {
          boxShadow: `0 0 0 2px ${alpha(error.light as string, 0.35)}`,
        },
      },
      sizeLarge: {
        padding: spacing(0.5, 1.5),
        fontSize: '1rem',
      },
      sizeMedium: {
        padding: spacing(0.25, 1.25),
        fontSize: '1rem',
      },
      sizeSmall: {
        padding: spacing(0.25, 1.25),
        fontSize: '.85rem',
      },
    },
  }
}
