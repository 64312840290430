import { MissingIdTokenError } from '../../lib/errors'
import fetch from '../../api/fetch'
import { dispatchBasicActions } from '../redux-helpers'
import { actions } from './actionCreators'
import { selectToken, selectRedirect, selectUsersOrg, selectIsImpersonating } from './selectors'
import { modals } from '../context/selectors'
import { hideModal } from '../context/actions'
import { queryUsers } from '../users/actions'
import { pathTo } from '../navigator'
import { useHistory } from '../../react/routing/useHistory'

export const activities = {
  createAccount: actions.createAccount,
}

export function createAccount({ firstName, lastName, email, password, organization }) {
  const { push } = useHistory()

  return dispatchBasicActions(actions.createAccount, async dispatch => {
    const { orgId, user, loggedInAs } = await fetch.post('/account', {
      firstName,
      lastName,
      email,
      password,
      organization,
    })
    dispatch(actions.whoami.complete({ user, loggedInAs }))
    push(pathTo.customerShoppingCartCheckout(orgId))
  })
}

export function logout() {
  return (dispatch, getState) => {
    if (selectIsImpersonating(getState())) {
      dispatch(clearImpersonatedUser())
    }

    dispatch(actions.logout())
    window._logout()
  }
}

export const getUser = () => async (dispatch, getState) => {
  await dispatch(whoami())
  const redirect = selectRedirect(getState())
  if (redirect) {
    handleRedirect(dispatch, getState, redirect)
  }
}

const handleRedirect = (dispatch, getState, redirect) => {
  const { push } = useHistory()
  const orgId = selectUsersOrg(getState())
  let redirectPath = redirect
  if (orgId && redirect.startsWith('/eventBot')) {
    redirectPath = `/customerClassic/orgs/${orgId}${redirect}`
  }

  push(redirectPath)
  dispatch(actions.clearRedirect())
}

const whoami = () =>
  dispatchBasicActions(actions.whoami, {
    payload: async () => {
      const token = selectToken()
      if (!token) {
        throw new MissingIdTokenError()
      }
      const result = await fetch.get('/auth/whoami')
      if (!result) {
        throw new Error('unable to find out who you are')
      }

      return result
        ? {
            token,
            ...result,
          }
        : result
    },
    fail: dispatch => {
      dispatch(logout())
    },
    failPayload: () => ({}),
  })

export const impersonateUser = userId =>
  dispatchBasicActions(actions.impersonation, async dispatch => {
    await fetch.post('/auth/impersonate', { userId })
    await dispatch(whoami())
    dispatch(hideModal(modals.IMPERSONATION))
    dispatch(queryUsers(''))
  })

export const clearImpersonatedUser = () =>
  dispatchBasicActions(actions.impersonation, async dispatch => {
    await fetch.post('/auth/clearImpersonation')
    await dispatch(whoami())
  })
