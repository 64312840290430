import { createActions } from 'redux-actions'
import { basicActions, gridSortActions, modalActions, uploadProgressActions } from '../redux-helpers'

const actionCreators = createActions({
  events: {
    addEventImage: basicActions(),
    channels: basicActions(),
    create: basicActions(),
    decommissionBot: basicActions(),
    delete: basicActions(),
    featureFlagDefinitions: basicActions(),
    getShortenedUrl: basicActions(),
    gridSort: gridSortActions(),
    linksModal: modalActions(),
    load: basicActions(),
    loadAvailableEvents: payload => payload,
    loadLiveEvents: basicActions(),
    loadTrafficController: payload => payload,
    loadAiAgentPoolInfo: payload => payload,
    mergeFields: basicActions(),
    phoneNumberBuyModal: modalActions(),
    phoneSidModal: modalActions(),
    removeEventImages: basicActions(),
    resetSmoochWebIntegrations: basicActions(),
    saveTrafficController: basicActions(),
    scriptModal: modalActions(),
    scriptTemplates: basicActions(),
    selectFile: file => ({ file }),
    smoochApps: basicActions(),
    timeFilter: payload => payload,
    update: basicActions(),
    updateEventImageDescription: basicActions(),
    updateEventLinks: basicActions(),
    uploadMediaProgress: uploadProgressActions(),
  },
})

export const actions = actionCreators.events
