import { useGet } from '~brix/connectors/fetch/useGet'
import { useHistory } from 'react-router'
import { config } from '~config'

export const useExecuteLink = () => {
  const { get } = useGet()
  const { push } = useHistory()

  const executeLink = async (url, handleLocalUrl, openInHouse = false) => {
    if (isLocalhost(url)) return handleLocalUrl(url)

    if (isChatly(url)) {
      const key = getChatlyKey(url)
      const { type, targetUrl } = await get(`/chatly/checkTarget/${key}`)

      if (openInHouse && targetUrl) {
        const path = targetUrl.replace(config.firebase.exconPlaceHost, '')

        push(path)
      }

      if (type === 'local') {
        return handleLocalUrl(url)
      }
    }

    // NOTE: setTimeout is a hack to make these links work on iOS Safari.
    // Safari blocks calls to window.open in async threads. setTimeout puts the function call back
    // into synchronous processing.
    setTimeout(() => {
      window.open(url, '_blank')
    })
  }

  return {
    executeLink,
  }
}

const isLocalhost = url => {
  return /\/localhost/.test(url)
}

const isChatly = url => {
  return /r[t]*\.42chat\.com/.test(url)
}

const getChatlyKey = url => {
  const match = /http(s?):\/\/r[t]*\.42chat\.com\//.exec(url)
  if (match) {
    return url.replace(match[0], '')
  }
}
