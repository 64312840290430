export const languageCodes = {
  en: 'en',
  es: 'es',
  ru: 'ru',
  bn: 'bn',
}

export const languageLabels = {
  [languageCodes.en]: 'English',
  [languageCodes.es]: 'Español - Spanish',
  [languageCodes.ru]: 'Русский - Russian',
  [languageCodes.bn]: 'বাংলা - Bengali',
}
