import { Components, SimplePaletteColorOptions, Theme } from '@mui/material'
import { ComponentOptions } from './types'

export const MuiDialogTitle = ({ palette }: ComponentOptions): Components<Theme>['MuiDialogTitle'] => {
  const secondary = palette.secondary as SimplePaletteColorOptions

  return {
    defaultProps: {},
    styleOverrides: {
      root: {
        backgroundColor: secondary.main,
        color: secondary.contrastText,
      },
    },
  }
}
