import { Map, fromJS } from 'immutable'
import useSWR from 'swr'
import { useGet } from '../fetch/useGet'

export const useExconSettings = (exconId, settings = Map()) => {
  const { get } = useGet({ initialFetching: true })

  const { data } = useSWR(
    `/excon/settings/${exconId}`,
    async url => {
      if (exconId === '42') return settings
      const result = await get(url)
      return fromJS(result)
    },
    { revalidateOnFocus: false },
  )

  return { settings: data, isFetching: data === undefined }
}
