import React from 'react'
import PropTypes from 'prop-types'

const Conditional = ({ children, conditions }) => {
  const keys = Object.keys(conditions)

  const renderConditions = () => {
    const all = keys.reduce((descendants, key) => {
      if (conditions[key] && children[key]) {
        if (React.isValidElement(children[key])) {
          descendants.push(React.cloneElement(children[key], { key }))
        } else {
          descendants.push(React.cloneElement(children[key](), { key }))
        }
      }
      return descendants
    }, [])
    if (all.length === 0) {
      return children.default || null
    }
    return all
  }
  return renderConditions()
}

Conditional.propTypes = {
  conditions: PropTypes.object.isRequired,
  children: PropTypes.object.isRequired,
}

export default Conditional
