import React, { useEffect } from 'react'
import { Flex } from 'mui-blox'
import { Button, Typography } from '@mui/material'
import { BlockQuote, CenteredPage, useSetExconTitle } from '~layout'
import { useRequestSecureLink } from './hooks/useRequestSecureLink'
import { FormControlCheckbox, iconUsages, WaitingForData } from '~controls'
import { useRequestTokenPlatforms } from './hooks/useRequestTokenPlatforms'
import { NEW_LINK_DELIVERY_PLATFORMS } from '~constants'
import { useForm } from 'react-hook-form'
import { FieldController } from '~form'
import { makeStyles } from '@mui/styles'
import { isValue } from '~core/validation/isValue'
import { Scrollable } from '../../page'

export type PlatformFormData = {
  [NEW_LINK_DELIVERY_PLATFORMS.email]?: boolean
  [NEW_LINK_DELIVERY_PLATFORMS.sms]?: boolean
  [NEW_LINK_DELIVERY_PLATFORMS.web]?: boolean
}

const GenericMessage = ({ supportedPlatforms = {} }) => {
  const classes = useStyles()
  const { requestLink, requested, errorOcurred } = useRequestSecureLink()
  const { control, handleSubmit, watch } = useForm<PlatformFormData>({ defaultValues: supportedPlatforms })
  const handleRequestNewLinkClick = handleSubmit(value => requestLink(value))
  const supportedKeys = Object.keys(supportedPlatforms)
  const fieldValues = watch()
  const canSubmit = Object.values(fieldValues).reduce((acc, v) => {
    if (v) acc = v

    return acc
  }, false)

  let description =
    'Request a new link by clicking the button below. You will receive a new link via your selected platform shortly.'

  let color: 'info' | 'warning' | 'success' | undefined = 'info'
  let usage: string = iconUsages.information

  if (requested) {
    description = 'A new secure link has been sent.  Please check your selected platform.'
    color = 'success'
    usage = iconUsages.check
  }

  if (errorOcurred || !isValue(supportedPlatforms)) {
    description =
      'We are having trouble verifying your identity. Please return to the \
      conversation where you were first issued your link, and ask your question again. For example, \
      depending on what you were looking for you might text "screening," "schedule," or "upload."'
    color = 'warning'
    usage = iconUsages.alert
  }

  return (
    <>
      <BlockQuote color={color} iconUsage={usage}>
        <Typography variant='subtitle1'>{description}</Typography>
      </BlockQuote>
      {errorOcurred ? (
        <Typography variant='subtitle2' gutterBottom>
          {'If you continue to have problems, please send an email to '}
          <a href='mailto:support@42chat.com'>support@42chat.com</a>.
        </Typography>
      ) : (
        <form>
          <Flex flexDirection='column' mt={2}>
            <Typography variant='subtitle2' gutterBottom>
              Select the delivery method(s) for your new link:
            </Typography>
            {supportedKeys.map((k: 'email' | 'sms' | 'web') => {
              if (!supportedPlatforms[k]) return null

              const fieldLabels = {
                [NEW_LINK_DELIVERY_PLATFORMS.email]: 'Email',
                [NEW_LINK_DELIVERY_PLATFORMS.sms]: 'Text',
                [NEW_LINK_DELIVERY_PLATFORMS.web]: 'Web Message',
              }

              return (
                <FieldController control={control} name={k} key={k}>
                  {({ field }) => {
                    return (
                      <FormControlCheckbox
                        {...field}
                        label={fieldLabels[k]}
                        disabled={supportedKeys.length === 1}
                        fullWidth
                        className={classes.checkbox}
                      />
                    )
                  }}
                </FieldController>
              )
            })}
            <Button
              disabled={requested || !canSubmit}
              variant='contained'
              color='primary'
              onClick={handleRequestNewLinkClick}
            >
              {requested ? 'Requested' : 'Request a new secure link'}
            </Button>
          </Flex>
        </form>
      )}
    </>
  )
}

const Unauthorized = () => {
  const { setHideTitleBar } = useSetExconTitle()
  const { supportedPlatforms, isGettingPlatforms } = useRequestTokenPlatforms()

  useEffect(() => {
    setHideTitleBar && setHideTitleBar(true)
  }, [setHideTitleBar])

  return (
    <CenteredPage pageTitle='Device Security' p={2}>
      <WaitingForData isWaiting={isGettingPlatforms}>
        <Scrollable>
          <Flex flexColumn justifyContent='center' p={1}>
            <Flex flex='0.6 1 60%' minHeight='0%'>
              <Flex flexColumn>
                <Typography variant='h1' component='h2' color='textSecondary' gutterBottom>
                  For your security,
                </Typography>
                <Typography variant='subtitle2' gutterBottom>
                  We need to send you a new link
                </Typography>
                <GenericMessage supportedPlatforms={supportedPlatforms} />
              </Flex>
            </Flex>
          </Flex>
        </Scrollable>
      </WaitingForData>
    </CenteredPage>
  )
}

export default Unauthorized

const useStyles = makeStyles(() => ({
  checkbox: {
    marginTop: 0,
    paddingBottom: 0,
  },
}))
