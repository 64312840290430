import React from 'react'
import { Suspense, lazyRetry } from '../../../../routing'

const Routes = lazyRetry(() =>
  import(
    /* webpackChunkName: "speakers" */
    './Routes'
  ),
)

const Root = props => {
  return (
    <Suspense>
      <Routes {...props} />
    </Suspense>
  )
}

export default Root
