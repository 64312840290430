import { useEffect } from 'react'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import { parseQuery, combine } from '../../../utils'
import { buildQuery } from '../../../../core/util/buildQuery'
import { useQueryParams } from '../../routing'
import { useGet } from '~brix/connectors/fetch'

export const useRedirectLink = () => {
  const { get, isGetting } = useGet({ initialLoading: true })

  const { exconId } = useParams()
  const { screeningId } = useQueryParams()

  const { replace } = useHistory()
  const { search } = useLocation()

  const { to, key, ...rest } = parseQuery(search)

  useEffect(() => {
    const load = async () => {
      const result = await get(`/excon/link/key/${key}`)

      if (result.count < 2) {
        let redirectUrl = buildQuery(combine('/excon', result.id || exconId, to), rest)
        // If a health screening id is provided in ConnectedStatus power up, go stratight to HSB status screen
        if (screeningId) {
          redirectUrl = buildQuery(`/excon/${result.id}/${to}/status/${screeningId}`, rest)
        }

        replace(redirectUrl)
      } else if (rest.originId) {
        const { originId, ...params } = rest
        replace(buildQuery(combine('/excon', originId, to), params))
      }
    }

    load()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return isGetting
}
