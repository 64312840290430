import { Components, Theme, SimplePaletteColorOptions } from '@mui/material'
import { ComponentOptions } from './types'

export const MuiCircularProgress = ({ palette }: ComponentOptions): Components<Theme>['MuiCircularProgress'] => {
  const tertiary = palette.tertiary as SimplePaletteColorOptions

  return {
    defaultProps: {
      color: 'inherit',
    },
    styleOverrides: {
      root: {
        color: tertiary.light,
      },
    },
  }
}
