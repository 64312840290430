import { createActions } from 'redux-actions'
import { basicActions, simpleAction } from '../redux-helpers'

const actionCreators = createActions({
  support: {
    loadEventInfo: simpleAction,
    loadConversation: simpleAction,
    loadIdks: simpleAction,
    loadIdksForEvent: simpleAction,
    loadSupportRequests: simpleAction,
    loadSupportRequestsByEvent: simpleAction,
    saveListScroll: simpleAction,
    sendMessage: basicActions(),
    setBotActiveState: basicActions(),
    setConversationLogEntry: simpleAction,
    setIdkResolved: basicActions(),
    setIdkValidity: basicActions(),
    setIdkEscalated: basicActions(),
    setIdk: basicActions(),
    updateBotActiveState: simpleAction,
    updateSupportRequest: basicActions(),
    loadUserConversations: simpleAction,
    loadUserConversationsByEvent: simpleAction,
    incrementUserConversationCount: simpleAction,
    incrementSystemUserConversationCount: simpleAction,
  },
})

export const actions = actionCreators.support
