import { createActions } from 'redux-actions'
import { basicActions } from '../redux-helpers'

const actionCreators = createActions({
  customerNotifications: {
    loadNotifications: basicActions(),
  },
})

export const actions = actionCreators.customerNotifications
