import styled from 'styled-components'

export const MainContainer = styled.div<{ borderColor?: string }>`
  border: solid 2px ${({ borderColor }) => borderColor || 'transparent'};
  border-radius: 5px;
  background-color: #ffff;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
  padding: 25px;
  padding-top: 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: auto;
  word-wrap: break-word;
  min-width: 25ch;
  max-width: 300px;
`

export const Avatar = styled.img`
  width: inherit;
  height: inherit;
  border-radius: inherit;
  object-fit: fill;
`

export const ActionButton = styled.button<{ size: number; moveTop?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  border-radius: 50%;
  padding: 0;
  margin: 0;
  margin-top: -${({ size, moveTop }) => (moveTop ? size / 2 : 0)}px;
  border: none;
  background-color: transparent;
  cursor: pointer;
`
