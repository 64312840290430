const Responses = function (restClient) {
  this.restClient = restClient
}

Responses.prototype.getAll = function (orgId, eventId) {
  return this.restClient.get(`/orgs/${orgId}/events/${eventId}/intents`)
}

Responses.prototype.syncIntents = function (orgId, eventId) {
  return this.restClient.get(`/orgs/${orgId}/events/${eventId}/intents/sync`)
}

Responses.prototype.exportIntents = function (orgId, eventId) {
  return this.restClient.get(`/orgs/${orgId}/events/${eventId}/intents/export`)
}

export default Responses
