import { CONVERSATION_SOURCE_TYPES, MESSAGE_EVENT_TYPES, MESSAGE_DIRECTIONS } from '~constants'

export const getBubbleSource = log => {
  let bubbleSource

  if (!log) return null

  if (log.direction === MESSAGE_DIRECTIONS.incoming) {
    bubbleSource = CONVERSATION_SOURCE_TYPES.user
  } else if (
    log.direction === MESSAGE_DIRECTIONS.outgoing &&
    log.messageEventType === MESSAGE_EVENT_TYPES.supportResponse
  ) {
    bubbleSource = CONVERSATION_SOURCE_TYPES.support
  } else if (log.direction === MESSAGE_DIRECTIONS.outgoing) {
    bubbleSource = CONVERSATION_SOURCE_TYPES.bot
  }

  return bubbleSource
}
