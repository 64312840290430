import React from 'react'

const DEFAULT_START_COLOR = '#6ba843'
const DEFAULT_STOP_COLOR = '#88bf43'

interface WaveProps {
  startColor?: string
  stopColor?: string
}

export const Wave: React.FC<WaveProps> = ({ startColor = DEFAULT_START_COLOR, stopColor = DEFAULT_STOP_COLOR }) => {
  return (
    <svg
      width='100%'
      height='100%'
      id='svg'
      viewBox='0 0 1440 390'
      xmlns='http://www.w3.org/2000/svg'
      className='transition duration-300 ease-in-out delay-150'
    >
      <defs>
        <linearGradient id='gradient' x1='0%' y1='50%' x2='100%' y2='50%'>
          <stop offset='5%' stopColor={stopColor}></stop>
          <stop offset='95%' stopColor={startColor}></stop>
        </linearGradient>
      </defs>
      <path
        d='M 0,400 C 0,400 0,200 0,200 C 122.78571428571428,165.17857142857144 245.57142857142856,130.35714285714286 376,136 C 506.42857142857144,141.64285714285714 644.4999999999999,187.75 776,221 C 907.5000000000001,254.25 1032.4285714285716,274.64285714285717 1142,269 C 1251.5714285714284,263.35714285714283 1345.7857142857142,231.67857142857142 1440,200 C 1440,200 1440,400 1440,400 Z'
        stroke='none'
        strokeWidth='0'
        fill={stopColor}
        fillOpacity='1'
        className='transition-all duration-300 ease-in-out delay-150 path-0'
        transform='rotate(-180 720 200)'
      ></path>
    </svg>
  )
}
