import { createActions } from 'redux-actions'
import { basicActions } from '../redux-helpers'

const actionCreators = createActions({
  botTesting: {
    postMessage: basicActions(),
    updatePhoneNumber: payload => payload,
    receiveMessage: payload => payload,
    getAvailableNumbers: basicActions(),
    selectCountry: payload => payload,
    selectBotPhoneNumber: payload => payload,
  },
})

export const actions = actionCreators.botTesting
