import { useCallback } from 'react'
import { useToast } from '~hooks/useToast'

export type PushErrorMessageToast = (message: string, code?: number) => void

export const useApiMessageToast = () => {
  const { push } = useToast()

  const pushError: PushErrorMessageToast = useCallback(
    (message, code) => {
      switch (code) {
        case 401:
          push('You are not authorized to access that', 'error')
          break
        case 403:
          push('You do not have sufficient privileges for this', 'error')
          break
        case 404:
          push('Oops! We are having trouble finding that', 'error')
          break
        default:
          push(message || 'Oops! It looks like a slight malfunction occurred.', 'error')
          break
      }
    },
    [push],
  )

  return { pushError }
}
