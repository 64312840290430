import { useGet } from '~brix/connectors/fetch/useGet'
import useSWR from 'swr'
import { buildQuery } from '~core/util/buildQuery'
import { useCallback } from 'react'

const baseUrl = 'customer2/users'

export const useUsers = () => {
  const { get, isGetting } = useGet<any[]>()

  const { data: initialOptions } = useSWR(
    baseUrl,
    async url => {
      const result = await get(url)

      return result || []
    },
    { revalidateOnFocus: false, revalidateIfStale: false },
  )

  const getData = useCallback(
    async (searchTerm, onLoad) => {
      const url = buildQuery(baseUrl, {
        search: searchTerm,
      })
      const result = await get(url)

      onLoad(result.map(r => r))
    },
    [get],
  )

  return {
    getData,
    isGetting,
    initialOptions: initialOptions ?? [],
  } as const
}
