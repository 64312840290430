import { handleActions, combineActions } from 'redux-actions'
import { Map, fromJS } from 'immutable'
import { actions } from './actionCreators'
import paths from './paths'

import { arrayToMap, basicCompleteReducer, basicFailReducer, basicStartReducer } from '../redux-helpers-immutable'

export default handleActions(
  {
    [combineActions(actions.loadEvents.start)]: basicStartReducer,

    [combineActions(actions.loadEvents.fail)]: basicFailReducer,

    [actions.loadEvents.complete]: (state, { payload: { events, orgId } }) =>
      basicCompleteReducer(state).setIn(paths.events(orgId), arrayToMap(events)),

    [actions.setFilterValue]: (state, { payload: { filter } }) => state.setIn(paths.filter(), filter),

    [actions.saveBotSurveyResults]: (state, { payload: { results } }) => {
      const resultsMap = fromJS(results)
      return state.setIn(paths.botSurveyResults(), resultsMap)
    },

    [actions.provisionUpdate]: (state, { payload: { orgId, surveyResultsId, step } }) =>
      state.setIn(paths.provisionMessages(orgId, surveyResultsId), step),
  },
  Map(),
)
