export const componentTypes = {
  addon: 'addon',
  branding: 'branding',
  channel: 'channel',
  conversationExtension: 'conversationExtension',
  connector: 'connector',
  event: 'event',
  integration: 'integration',
  nlp: 'nlp',
  notification: 'notification',
  sales: 'sales',
  standard: 'standard',
}

export const componentKeys = {
  addons: {
    attendeeCount: 'addons.attendeeCount',
    botName: 'addons.botName',
    botMenu: 'addons.botMenu',
    botRegistration: 'addons.botRegistration',
    calendarPowerUp: 'addons.calendarPowerUp',
    connectedStatusPowerUp: 'addons.connectedStatusPowerUp',
    integration: 'addons.integration',
    inventoryPowerUp: 'addons.inventoryPowerUp',
    participantFieldsPowerUp: 'addons.participantFieldsPowerUp',
  },
  nlp: {
    dialogFlow: 'nlp.DialogFlow',
    dialogFlowJokes: 'nlp.DialogFlow.jokes',
  },
  channels: {
    facebookMessenger: 'channels.facebookMessenger',
    messenger: 'channels.messenger',
    smoochMessenger: 'channels.smoochMessenger',
    telnyx: 'channels.telnyx',
    twilio: 'channels.twilio',
    whatsApp: 'channels.whatsApp',
    zipwhip: 'channels.zipwhip',
  },
  branding: 'branding',
  conversationExtension: 'conversationExtension',
  event: 'event',
  notifications: {
    fromBot: 'notification',
    fromConversation: 'notifications.fromConversation',
  },
  sales: {
    quote: 'sales.quote',
    delivery: 'sales.delivery',
  },
  standard: {
    answers: 'standard.answers',
  },
  integration: {
    integration: 'integration.integration',
    registrationSystem: 'integration.registrationSystem',
  },
  connector: {
    meetings: 'connector.meetings',
    exhibitors: 'connector.exhibitors',
    participants: 'connector.participants',
    scheduleItems: 'connector.scheduleItems',
    sessions: 'connector.sessions',
    speakers: 'connector.speakers',
    sponsors: 'connector.sponsors',
  },
}

export const componentFormComponents = {
  answers: 'answers',
  botMenuPowerUp: 'botMenuPowerUp',
  branding: 'branding',
  conversationExtension: 'conversationExtension',
  customerDelivery: 'customerDelivery',
  dialogFlow: 'dialogFlow',
  event: 'event',
  facebookMessenger: 'facebookMessenger',
  integration: 'integration',
  connector: 'connector',
  notification: 'notification',
  notificationFromConversation: 'notificationFromConversation',
  participantFieldsPowerUp: 'participantFieldsPowerUp',
  calendarPowerUp: 'calendarPowerUp',
  connectedStatusPowerUp: 'connectedStatusPowerUp',
  inventoryPowerUp: 'inventoryPowerUp',
  product: 'product',
  whatsAppChannel: 'whatsAppChannel',
  twilioChannel: 'twilioChannel',
  telnyxChannel: 'telnyxChannel',
}

export const componentStatus = {
  red: 'red',
  yellow: 'yellow',
  green: 'green',
}

export const deliveryStatus = {
  prepping: 'prepping', // botCreated, awaiting customer form completion.
  waitingPayment: 'waitingPayment', // questions answered. awaiting customer payment.
  conversationBuild: 'conversationBuild', // payment complete. awaiting conversation team completion.
  customerApproval: 'customerApproval', // conversation team finished with bot. awaiting customer approvals.
  live: 'live', // customer approvals complete. bot channels visible to customer
  custom: 'custom',
}

export const deliveryStatusLabels = {
  [deliveryStatus.prepping]: 'Customer Questionnaire',
  [deliveryStatus.waitingPayment]: 'Customer Payment',
  [deliveryStatus.conversationBuild]: 'Conversation Review',
  [deliveryStatus.customerApproval]: 'Customer Approval',
  [deliveryStatus.live]: 'Bot Live',
}

export const deliveryStepStates = {
  pending: 'pending',
  inProgress: 'inProgress',
  completed: 'completed',
}
