export const extractMMS = messageText => {
  const regexp = /(!\[.*?])(\(.*?\))/g
  const Reggie = /(\((.*?)\))/

  let formattedMessage = messageText

  const rawImages = messageText.match(regexp)
  let image
  if (rawImages !== null) {
    for (const rawImage of rawImages) {
      const innies = rawImage.match(Reggie)
      if (innies !== null) {
        image = innies[2]
        formattedMessage = formattedMessage.replace(rawImage, ``)
      }
    }
  }
  return { messageText: formattedMessage, image }
}
