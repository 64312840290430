import React from 'react'
import { StyledEngineProvider } from '@mui/material'
import { StylesProvider as ClassicStylesProvider } from '@mui/styles'

export const StylesProvider = ({ children }) => {
  return (
    <ClassicStylesProvider injectFirst>
      <StyledEngineProvider injectFirst>{children}</StyledEngineProvider>
    </ClassicStylesProvider>
  )
}
