import { Components, Theme } from '@mui/material'
import { ComponentOptions } from './types'

export const MuiSelect = ({ palette }: ComponentOptions): Components<Theme>['MuiSelect'] => {
  return {
    defaultProps: {},
    styleOverrides: {
      select: {
        '&:focus': {
          backgroundColor: 'inherit',
        },
      },
      icon: {
        color: palette.text?.secondary,
      },
    },
  }
}
