import React from 'react'
import { MainContainer, Avatar, TopContainer, ActionButtonContainer, CloseButton } from './variantC.style'
import { AvatarProps } from '../types'
import { Flex } from 'mui-blox'
import { Typography } from '@mui/material'
import { SendIcon } from './assets'
import { Wave } from './assets/Wave'
import { Button, SemanticIcon, iconUsages } from '~controls'

export const AvatarVariantC: React.FC<AvatarProps> = ({
  imageUrl,
  text,
  onClick,
  textColor,
  borderColor,
  backgroundColor,
  secondaryColor,
  title,
  subtitle,
  setIsClosed,
  isClosed,
  position,
  isMessageActive,
  buttonSize,
  id,
  ariaLabel,
}) => {
  const stopColor = secondaryColor || backgroundColor
  const isLeft = position === 'left'

  if (isClosed || !isMessageActive) {
    return (
      <Button
        id={id}
        aria-label={ariaLabel}
        onClick={onClick}
        style={{ backgroundColor: 'transparent', border: 'none', margin: 0, padding: 0 }}
      >
        <Avatar size={buttonSize} src={imageUrl} color={borderColor} />
      </Button>
    )
  }

  return (
    <MainContainer isLeft={isLeft}>
      <TopContainer startColor={backgroundColor} stopColor={stopColor}>
        <Flex flexFull alignItems={isLeft ? 'flex-start' : 'flex-end'}>
          <CloseButton onClick={() => setIsClosed(true)}>
            <SemanticIcon usage={iconUsages.close} color='white' style={{ width: 'inherit', height: 'inherit' }} />
          </CloseButton>
        </Flex>
        <Flex justifyContent='space-between' alignItems='center' flexDirection='row'>
          <Flex flexDirection='column' alignItems='flex-start'>
            {subtitle && (
              <Typography color='white' fontSize={15}>
                {subtitle}
              </Typography>
            )}
            {title && (
              <Typography color='white' fontSize={32} lineHeight={1}>
                {title}
              </Typography>
            )}
          </Flex>

          <Flex>
            <Avatar size={buttonSize} src={imageUrl} color={borderColor} />
          </Flex>
        </Flex>
      </TopContainer>
      <Flex flexFull width='100%'>
        <Wave startColor={backgroundColor} stopColor={stopColor} />
      </Flex>
      <Flex style={{ padding: 28, paddingTop: 0 }} flexColumn>
        <span style={{ color: textColor }}>{text}</span>
        <Flex width='100%' justifyContent={isLeft ? 'flex-start' : 'flex-end'} marginTop='45px'>
          <ActionButtonContainer onClick={onClick} color={backgroundColor} id={id} aria-label={ariaLabel}>
            <img src={SendIcon} width={30} height={36} />
          </ActionButtonContainer>
        </Flex>
      </Flex>
    </MainContainer>
  )
}

export default AvatarVariantC
