import {
  alpha,
  darken,
  Color,
  ComponentsProps,
  ComponentsOverrides,
  SimplePaletteColorOptions,
  Theme,
  iconButtonClasses,
} from '@mui/material'
import { OverridesStyleRules } from '@mui/material/styles/overrides'
import { ComponentOptions } from './types'

interface IconButtonStyles {
  defaultProps: ComponentsProps['MuiIconButton']
  styleOverrides: {
    colorError?: OverridesStyleRules
  } & ComponentsOverrides<Theme>['MuiIconButton']
}

export const MuiIconButton = ({ hue, palette }: ComponentOptions): IconButtonStyles => {
  const primary = palette.primary as SimplePaletteColorOptions
  const secondary = palette.secondary as SimplePaletteColorOptions
  const error = palette.error as SimplePaletteColorOptions
  const grey = palette.grey as Color

  return {
    defaultProps: {
      size: 'large',
      color: 'secondary',
    },
    styleOverrides: {
      root: {
        border: '1px solid',
        borderRadius: 4,
        transition: 'all 0.25s ease',
        transitionProperty: 'border-color, color, background-color',
        '&:focus, &:active': {
          boxShadow: `0 0 0 2px ${alpha(primary.light as string, 0.35)}`,
        },
        '&:hover': {
          backgroundColor: alpha(secondary.light as string, hue === 'light' ? 0.1 : 0.3),
          transition: 'all 0.25s ease',
          transitionProperty: 'border-color, color, background-color',
        },
        '&.Mui-disabled': {
          backgroundColor: hue === 'light' ? grey[100] : grey[800],
          borderColor: grey[400],
          color: grey[400],
        },
      },
      colorInherit: {
        borderWidth: 0,
        color: 'inherit',
        fill: 'inherit',
        [`&.${iconButtonClasses.disabled}`]: {
          backgroundColor: 'transparent',
        },
        '&:focus': {
          boxShadow: 'unset',
          // boxShadow: `0 0 0 2px ${alpha(secondary.light as string, 0.2)}`,
        },
        '&:active': {
          boxShadow: 'unset',
          // boxShadow: `0 0 0 2px ${alpha(secondary.light as string, 0.2)}`,
        },
      },
      colorSecondary: {
        borderColor: alpha(secondary.light as string, 0.65),
        color: secondary.light,
        fill: secondary.light,
        '&:focus, &:active': {
          boxShadow: `0 0 0 2px ${alpha(secondary.light as string, 0.2)}`,
        },
        '&:hover': {
          color: darken(secondary.light as string, 0.1),
          borderColor: alpha(secondary.light as string, 0.7),
        },
      },
      colorPrimary: {
        borderColor: primary.main,
        color: primary.main,
        fill: primary.main,
      },
      colorError: {
        borderColor: error.main,
        color: error.main,
        fill: error.main,
        '&:focus, &:active': {
          boxShadow: `0 0 0 2px ${alpha(error.light as string, 0.2)}`,
        },
      },
      sizeLarge: {
        padding: 8,
        '& svg': {
          width: 26,
          height: 26,
        },
      },
      sizeMedium: {
        padding: 6,
        '& svg': {
          width: 24,
          height: 24,
        },
      },
      sizeSmall: {
        padding: 4,
        '& svg': {
          width: 22,
          height: 22,
        },
      },
    },
  }
}
