import { handleActions, combineActions } from 'redux-actions'
import { Map } from 'immutable'
import { actions } from './actionCreators'
import paths from './paths'

import { arrayToMap, basicCompleteReducer, basicFailReducer, basicStartReducer } from '../redux-helpers-immutable'

export default handleActions(
  {
    [combineActions(actions.loadResponses.start)]: basicStartReducer,

    [combineActions(actions.loadResponses.fail)]: basicFailReducer,

    [actions.loadResponses.complete]: (state, { payload: { responses, orgId, eventId } }) =>
      basicCompleteReducer(state).setIn(paths.responses(orgId, eventId), arrayToMap(responses)),

    //  [actions.setFilterValue]: (state, { payload: { filter } }) =>
    //    state.setIn(paths.filter(), filter),
  },
  Map(),
)
