import { completeParams } from '../../navigator'
import { getParamFromCurrentLocation } from '../../../utils'
import { selectOrgId, selectEventId } from '../../context/selectors'

export const isSystemSupport = () => {
  return !Boolean(selectOrgId()) && !Boolean(selectEventId())
}

export const getOrgId = selectOrgId
export const getEventId = selectEventId

export const makeSupportRoute = (state, path, ...rest) => {
  const orgId = selectOrgId(state)
  const eventId = selectEventId(state)

  const eventParams = Boolean(orgId) && Boolean(eventId) ? [orgId, eventId] : []
  const params = [...eventParams, ...rest]

  return completeParams(path, ...params)
}

export const getFilter = () => {
  return getParamFromCurrentLocation('support')
}

export const routeCreators = (state, currentPath) => {
  if (!currentPath) throw new Error('path is required')
  return {
    itemRoute: id => makeSupportRoute(state, currentPath, getFilter(state), id),
    filterRoute: filter => makeSupportRoute(state, currentPath, filter),
  }
}
