import React, { useState } from 'react'
import { PageTitleContext } from './hooks/useSetPageTitle'

const PageTitleProvider = ({ children }) => {
  const [pageTitle, _setPageTitle] = useState('')
  const [pageSubtitle, _setPageSubtitle] = useState('')
  const [windowTitle, _setWindowTitle] = useState('')
  const [tools, _setTools] = useState(null)

  const setPageTitle = (title, subTitle, windowTitle) => {
    _setPageTitle(title)
    _setPageSubtitle(subTitle)
    _setWindowTitle(windowTitle)
  }

  const setTools = _ => _setTools(_)

  return (
    <PageTitleContext.Provider value={{ pageTitle, pageSubtitle, windowTitle, setPageTitle, setTools, tools }}>
      {children}
    </PageTitleContext.Provider>
  )
}

export default PageTitleProvider
