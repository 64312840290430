let history

const setHistory = hist => {
  history = hist
}

function checkHistory() {
  if (!history) {
    console.error('History is not set')
    // throw new Error('History is not set.  setHistory must be given the router history at the app level')
  }
}

export const useHistory = () => {
  const push = (path, state) => {
    checkHistory()
    history.push(path, state)
  }

  const replace = (path, state) => {
    checkHistory()
    history.replace(path, state)
  }

  const goBack = () => {
    checkHistory()
    history.goBack()
  }

  return {
    goBack,
    push,
    replace,
    setHistory,
  }
}
