import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Typography } from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import { Flex } from 'mui-blox'
import { combine } from '../../utils/combine'
import { ExconFrame, useSetExconTitle } from '~layout'

const Home = ({ match: { url }, classes }) => {
  const isLocal = location.hostname === 'localhost'

  const { setPageTitle } = useSetExconTitle()
  useEffect(() => {
    setPageTitle('Home')
  }, [setPageTitle])

  return (
    <ExconFrame pageTitle='Home'>
      <Flex justifyContent='center'>
        <Typography variant='h6'>Conversation extension home</Typography>
      </Flex>
      {isLocal && (
        <Flex flexDirection='column' className={classes.content}>
          <Typography>
            <Link to={combine(url, 'exhibitorSearch/5bf2b3711316c40013cff688')}>Exhibitor Search</Link>
          </Typography>
          <Typography>
            <Link to={combine(url, 'feedback')}>Feedback</Link>
          </Typography>
          <Typography>
            <Link to={combine(url, 'itinerary')}>Itinerary</Link>
          </Typography>
          <Typography>
            <Link to={combine(url, 'meetings')}>Meetings</Link>
          </Typography>
          <Typography>
            <Link to={combine(url, 'notifications')}>Notifications</Link>
          </Typography>
          <Typography>
            <Link to={combine(url, 'osh')}>OSH</Link>
          </Typography>
          <Typography>
            <Link to={combine(url, 'profile')}>Profile</Link>
          </Typography>
          <Typography>
            <Link to={combine(url, 'polly')}>Polly</Link>
          </Typography>
          <Typography>
            <Link to={combine(url, 'speakerSearch/admin')}>Speaker Search (admin)</Link>
          </Typography>
          <Typography>
            <Link to={combine(url, 'speakerSearch/user/speaker')}>Speaker Search (user)</Link>
          </Typography>
          <Typography>
            <Link to={combine(url, 'content')}>Content</Link>
          </Typography>
          <Typography>
            <Link to={combine(url, 'quotes')}>Quotes</Link>
          </Typography>
        </Flex>
      )}
    </ExconFrame>
  )
}

Home.propTypes = {
  match: PropTypes.object.isRequired,
}

const styles = ({ spacing }) => ({
  content: {
    margin: spacing(1),
    marginTop: spacing(4),
  },
})

export default withStyles(styles)(Home)
