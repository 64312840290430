import { createActions } from 'redux-actions'
import {
  basicActions,
  simpleAction,
  basicActionsWithMeta,
  gridSortActions,
  modalActions,
  uploadProgressActions,
} from '../redux-helpers'

const actionCreators = createActions({
  participants: {
    create: basicActions(),
    delete: basicActionsWithMeta(),
    gridSort: gridSortActions(),
    load: basicActionsWithMeta(),
    loadParticipant: simpleAction,
    modal: modalActions(),
    update: basicActionsWithMeta(),
    import: basicActions(),
    uploadProgress: uploadProgressActions(),
    incrementPageIndex: simpleAction,
    resetPageIndex: simpleAction,
    filterableFields: simpleAction,
    customFields: simpleAction,
  },
})
export const actions = actionCreators.participants
export const branch = 'participants'
