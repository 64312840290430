import React from 'react'
import PropTypes from 'prop-types'
import { useTheme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { getContrastRatio } from '@mui/material/styles'
import classNames from 'classnames'
import { logoOnWhite, logoOnDark, logoMonochrome } from '../../../images'

const LogoLarge = ({ className = undefined, hide, extraLarge, backgroundColor, monochrome }) => {
  const classes = useStyles()
  const { palette } = useTheme()

  const background = backgroundColor || palette.secondary.main

  return (
    <img
      src={monochrome ? logoMonochrome : getContrastRatio(background, '#fff') > 3 ? logoOnDark : logoOnWhite}
      alt='logo'
      className={classNames(classes.logo, { [classes.logoVisible]: !hide, [classes.logoXl]: extraLarge }, className)}
    />
  )
}

LogoLarge.propTypes = {
  hide: PropTypes.bool,
  backgroundColor: PropTypes.string,
  monochrome: PropTypes.bool,
  extraLarge: PropTypes.bool,
}

LogoLarge.defaultProps = {
  hide: false,
  backgroundColor: undefined,
  monochrome: false,
  extraLarge: false,
}

const useStyles = makeStyles(({ transitions, mixins: { combine } }) => ({
  logo: {
    opacity: 0,
    transform: 'translate(-100px)',
    height: 48,
    margin: combine(2, 14),
  },
  logoXl: {
    height: 66,
  },
  logoVisible: {
    paddingTop: '5px',
    opacity: 100,
    transform: 'translate(0)',
    transition: transitions.create(['opacity', 'transform'], {
      duration: transitions.duration.shorter,
    }),
  },
}))

export default LogoLarge
