export const extractButtons = messageText => {
  if (messageText.startsWith('You can text:')) {
    const buttons = messageText
      .replace('You can text:', '')
      .replace(', or', ', ')
      .replace(' or ', ', ')
      .split(',')
      .map(button => button.trim())
    return {
      messageText: '',
      buttons,
    }
  }

  return {
    messageText,
    buttons: [],
  }
}
