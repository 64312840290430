import { createContext, useContext } from 'react'

interface ContextProps {
  pageTitle: string
  pageSubtitle: string
  windowTitle: string
  setPageTitle: (...args: any) => void
  setTools?: (...args: any) => void
  tools?: JSX.Element | null
  showBackButton?: boolean
  hideTitleBar?: boolean
  setHideTitleBar?: (boolean) => void
  setShowBackButton?: (boolean) => void
}

export const ExconTitleContext = createContext({
  pageTitle: '',
  pageSubtitle: '',
  windowTitle: '',
  setPageTitle: Function,
  showBackButton: false,
  hideTitleBar: false,
} as ContextProps)

export const useSetExconTitle = () => useContext(ExconTitleContext)
