import { combineActions, handleActions } from 'redux-actions'
import { saveAs } from 'file-saver'
import { actions, branchName } from './actionCreators'
import sharedActions from '../shared/actions'
import { adjustColumnReducer, reduceAndSaveToLocalStorage } from '../shared/reducers'
import { deserializeStateFromLocalStorage } from '../storageHelpers'
import { getNow } from '../../../core/util/clock'

import {
  basicState,
  basicCompleteReducer,
  basicFailReducer,
  basicLoadCompleteReducer,
  basicStartReducer,
  filterClearReducer,
  filterSearchReducer,
  filterState,
  gridSortState,
  gridPinReducer,
  modalState,
} from '../redux-helpers'

const storagePath = branchName

const initialState = {
  ...basicState(),
  byId: {},
  byEvent: {},
  byOrg: {},
  filter: filterState(),
  gridSort: gridSortState(),
  columnWidths: {},
  trainingModal: modalState(),
  ...deserializeStateFromLocalStorage(storagePath),
}

function normalizeDfIntent(dfIntent) {
  let totalHitCount = 0
  if (dfIntent.hitCounts != null) {
    if (dfIntent.hitCounts.preEvent != null) {
      totalHitCount += dfIntent.hitCounts.preEvent
    }
    if (dfIntent.hitCounts.event != null) {
      totalHitCount += dfIntent.hitCounts.event
    }
    if (dfIntent.hitCounts.postEvent != null) {
      totalHitCount += dfIntent.hitCounts.postEvent
    }
  }

  if (dfIntent.meta && dfIntent.meta.modifiedAt) {
    dfIntent.localModifiedDate = dfIntent.meta.modifiedAt
  }

  return {
    ...dfIntent,
    totalHitCount,
  }
}

export default handleActions(
  {
    // xxx.start
    [combineActions(actions.load.start, actions.syncIntents.start, actions.runTraining.start)]: basicStartReducer,

    // xxx.fail
    [combineActions(actions.load.fail, actions.syncIntents.fail, actions.runTraining.fail)]: basicFailReducer,

    // complete
    [actions.syncIntents.complete]: (state, action) => {
      return {
        ...basicCompleteReducer(state, action),
      }
    },

    [actions.exportIntents.complete]: (state, action) => {
      const fileName = `IntentsExport-${getNow().toFormat('yyyy-LL-dd')}.csv`
      let blob = new Blob([action.payload], { type: 'text/csv;charset=utf-8' })
      saveAs(blob, fileName)
      return {
        ...basicCompleteReducer(state, action),
      }
    },

    // load
    [actions.load.complete]: (state, action) => {
      const { eventId, orgId } = action.meta
      const { dfIntents } = action.payload

      let byId = { ...state.byId }
      ;(state.byEvent[eventId] || []).forEach(id => delete byId[id])
      byId = dfIntents.reduce((acc, current) => {
        acc[current.id] = normalizeDfIntent(current)
        return acc
      }, byId)

      let byEvent = dfIntents.reduce((acc, current) => {
        if (!acc[current.eventId]) {
          acc[current.eventId] = []
        }
        acc[current.eventId] = acc[current.eventId].concat(current.id)
        return acc
      }, {})

      let byOrg = [...(state.byOrg[orgId] || [])]
      dfIntents.forEach(n => {
        if (!byOrg.includes(n.id)) {
          byOrg = byOrg.concat(n.id)
        }
      })

      return {
        ...basicLoadCompleteReducer(state, action),
        byId,
        byEvent: {
          ...state.byEvent,
          ...byEvent,
        },
        byOrg: {
          ...state.byOrg,
          [orgId]: byOrg,
        },
      }
    },
    [actions.removeExtension]: (state, { payload: { id } }) => {
      const dfIntent = state.byId[id]
      delete dfIntent.conversationExtension

      return state
    },
    [actions.addExtension]: (state, { payload: { id, extension } }) => {
      const dfIntent = state.byId[id]
      dfIntent.conversationExtension = extension

      return state
    },

    // filter
    [actions.filter.clear]: filterClearReducer,
    [actions.filter.search]: filterSearchReducer(
      i => i.intentName,
      i => i.action,
      i => i.platforms,
      i => i.responses.map(r => r.response),
    ),

    // gridSort
    [actions.gridSort.sort]: (state, action) => {
      return {
        ...state,
        gridSort: action.payload,
      }
    },

    // pinColumn
    [sharedActions.pinColumn]: (state, { payload }) => {
      return reduceAndSaveToLocalStorage(state, payload, 'pinnedColumns', storagePath, gridPinReducer)
    },

    [sharedActions.adjustColumn]: (state, { payload }) => {
      return reduceAndSaveToLocalStorage(state, payload, 'columnWidths', storagePath, adjustColumnReducer)
    },

    [actions.toggleSelection]: (state, { payload }) => {
      const ids = Object.keys(state.byId)

      const currentlySelectedIds = ids
        .map(id => state.byId[id])
        .filter(intent => {
          return intent.isSelected === true
        })
        .map(i => i.id)
      const total = ids.length

      if (payload === 'all') {
        let newById = {}
        if (currentlySelectedIds.length < total) {
          for (const id of ids) {
            newById[id] = { ...state.byId[id], isSelected: true }
          }
        } else {
          for (const id of ids) {
            newById[id] = { ...state.byId[id], isSelected: false }
          }
        }
        return {
          ...state,
          byId: newById,
        }
      }
      return {
        ...state,
        byId: {
          ...state.byId,
          [payload]: {
            ...state.byId[payload],
            isSelected: !state.byId[payload].isSelected,
          },
        },
      }
    },

    [actions.trainingModal.show]: state => {
      return {
        ...state,
        trainingModal: {
          visible: true,
        },
      }
    },

    [actions.trainingModal.hide]: state => {
      return {
        ...state,
        trainingModal: {
          visible: false,
        },
      }
    },

    [actions.runTraining.complete]: (state, action) => {
      return {
        ...basicCompleteReducer(state, action),
      }
    },
  },
  initialState,
)
