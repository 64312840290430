export default {
  notifications: (orgId, eventId) => ['org', orgId, 'event', eventId, 'notifications'],
  notification: (orgId, eventId, notificationId) => [
    'org',
    orgId,
    'event',
    eventId,
    'notifications',
    notificationId,
  ],
}
