import styled from 'styled-components'

export const MainContainer = styled.div<{ left?: boolean }>`
  display: flex;
  gap: 1rem;
  flex-direction: ${({ left }) => (left ? 'row-reverse' : 'row')};
`

export const CloseButton = styled.div`
  height: 0;
  width: 0;
  border-radius: 50%;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: all 0.3s;
  cursor: pointer;
`

export const BubbleBody = styled.div<{ borderColor?: string }>`
  position: relative;
  z-index: 2;
  border: solid 2px ${({ borderColor }) => borderColor || 'transparent'};
  background-color: #6ba843;
  border-radius: 10px;
  padding: 10px;
  width: auto;
  word-wrap: break-word;
  min-width: 25ch;
  max-width: 300px;
  &:hover ${CloseButton} {
    opacity: 1;
    height: 16px;
    width: 16px;
  }
`

export const BubbleTail = styled.div<{ left?: boolean; borderColor?: string }>`
  z-index: 3;
  position: absolute;
  margin-top: -10px;
  margin-left: -5px;
  height: 0;
  width: 0;
  border-top: solid 25px ${({ borderColor }) => borderColor || 'transparent'};
  border-bottom: solid 10px transparent;
  border-right: solid 5px transparent;
  border-left: solid 5px transparent;
  transform: ${({ left }) => (left ? 'rotate(30deg)' : 'rotate(-30deg)')};
`
export const BubbleTailBorder = styled.div<{ left?: boolean; borderColor?: string }>`
  z-index: 1;
  position: absolute;
  margin-top: -10px;
  margin-left: ${({ left }) => (left ? -9 : -6)}px;
  height: 0;
  width: 0;
  border-top: solid 29px ${({ borderColor }) => borderColor || 'transparent'};
  border-bottom: solid 19px transparent;
  border-right: solid ${({ left }) => (left ? 10 : 5)}px transparent;
  border-left: solid ${({ left }) => (left ? 5 : 10)}px transparent;
  transform: ${({ left }) => (left ? 'rotate(26deg)' : 'rotate(-26deg)')};
`

export const Avatar = styled.img`
  width: inherit;
  height: inherit;
  border-radius: inherit;
  object-fit: fill;
`

export const ActionButton = styled.button<{ size: number }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  border-radius: 50%;
  padding: 0;
  margin: 0;
  border: none;
  background-color: transparent;
  cursor: pointer;
`
