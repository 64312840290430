import React from 'react'
import { DateTime } from 'luxon'
import { Typography, TypographyVariant, TypographyProps } from '@mui/material'
import { DATETIME } from '../../../constants'
import { typify, TYPES } from '../../../../core/util/data/typify'

type FormatFn = (dt: DateTime) => string
type ComponentProps<C extends React.ElementType = 'span'> = Omit<
  TypographyProps<C, { component?: C }>,
  'children'
> & {
  children?: string | DateTime | unknown
  format?: string | FormatFn
  showLocal?: boolean
  timezone?: string
  variant?: TypographyVariant
  formatter?: (value: string) => string
}

const Datography = ({
  children,
  format = DATETIME.dateTime,
  showLocal = false,
  timezone = 'utc',
  variant,
  formatter,
  ...other
}: ComponentProps) => {
  const Invalid = () => (
    <Typography variant={variant} {...other}>
      Invalid Date
    </Typography>
  )

  let dateTime: DateTime | null = null

  switch (typify(children)) {
    case TYPES.string:
      dateTime = DateTime.fromISO(children as string, { zone: timezone })
      break
    case TYPES.datetime:
      dateTime = children as DateTime
      break
  }

  if (!dateTime) {
    console.warn('Datography: child should be string or Luxon DateTime')

    return <Invalid />
  }
  if (!dateTime.isValid) {
    console.warn('Datography: child is invalid DateTime')

    return <Invalid />
  }

  if (showLocal) {
    dateTime = dateTime.toLocal()
  }

  let formatted = 'Invalid Date'

  switch (typify(format)) {
    case TYPES.string:
      formatted = dateTime.toFormat(format as string)
      break
    case TYPES.function:
      formatted = (format as FormatFn)(dateTime)
      break
  }

  if (formatter) {
    formatted = formatter(formatted)
  }

  return (
    <Typography variant={variant} {...other}>
      {formatted}
    </Typography>
  )
}

export default Datography
