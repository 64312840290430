import React from 'react'
import {
  Controller,
  ControllerProps,
  ControllerRenderProps,
  FieldValues,
  FieldPath,
  ControllerFieldState,
  UseFormStateReturn,
  PathValue,
  Path,
} from 'react-hook-form'
import { PartialBy } from '~models/util'
import { handleChange } from './values/handleChange'
import { getErrorProps } from './getErrorProps'

type FieldElement = React.ReactElement<
  Record<string, unknown> & {
    label?: string
  }
>

type ComponentProps<
  FV extends Record<string, any> = FieldValues,
  TName extends FieldPath<FV> = FieldPath<FV>,
> = PartialBy<ControllerProps<FV, TName>, 'render'> & {
  required?: boolean
  rules?: ControllerProps<FV, TName>['rules'] & { setValueAs?: (value: any) => any }
  defaultValue?: PathValue<FV, Path<FV>>
  errorMessages?: Record<string, string>
  children: ({
    field,
    fieldState,
    formState,
  }: {
    field: ControllerRenderProps<FV, TName> & { error?: boolean; helperText?: string }
    fieldState: ControllerFieldState
    formState: UseFormStateReturn<FV>
  }) => FieldElement
}

const FieldController = <FV extends Record<string, any> = FieldValues, TName extends FieldPath<FV> = FieldPath<FV>>({
  children,
  control,
  name,
  required,
  defaultValue = '' as any,
  errorMessages,
  rules = {},
  ...other
}: ComponentProps<FV, TName>): React.ReactElement<any, any> => {
  if (!children && !other.render) {
    console.warn('child[0] must be a valid function, or a render prop must be passed in')

    return <div />
  }

  const { setValueAs, ...otherRules } = rules
  const resolvedRules = { ...otherRules, required: required ?? otherRules.required ?? false }

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      rules={resolvedRules}
      {...other}
      render={({ field, fieldState, formState }) => {
        const onChange = handleChange(field.onChange, field.value, setValueAs)

        const errorProps = getErrorProps(name, formState.errors, errorMessages)

        const fieldProps = {
          ...field,
          ...errorProps,
          value: field.value ?? defaultValue,
          onChange,
        }

        const el: FieldElement = children({
          field: fieldProps,
          fieldState,
          formState,
        })

        if (!el || !React.isValidElement(el)) {
          console.warn('child[0] must return a valid element')

          return <div>Invalid Element</div>
        }

        return el
      }}
    />
  )
}

export default FieldController
