import { createActions } from 'redux-actions'
import { basicActions } from '../redux-helpers'

const actionCreators = createActions({
  auth: {
    authenticate: basicActions(),
    bootstrap: basicActions(),
    logout: () => undefined,
    whoami: basicActions(),
    impersonation: basicActions(),
    setAuth: auth => ({ auth }),
    saveRedirect: redirect => ({ redirect }),
    clearRedirect: () => ({}),
    createAccount: basicActions(),
  },
})

export const actions = actionCreators.auth
