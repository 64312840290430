import React, { FC, useState, useCallback } from 'react'
import { PropsWithChildren } from 'react'
import { Socket } from 'socket.io-client'
import { socketNamespaces } from '~constants'
import { SocketSpacesContext } from './hooks/useSocketSpace'

export const SocketSpacesProvider: FC<PropsWithChildren<Record<never, never>>> = ({ children }) => {
  const [spaces, setSpaces] = useState<Partial<Record<socketNamespaces, Socket>>>({})

  const setSocket = useCallback(
    (socket: Socket, namespace: socketNamespaces) => {
      setSpaces(current => {
        return { ...current, [namespace]: socket }
      })
    },
    [setSpaces],
  )

  return <SocketSpacesContext.Provider value={{ spaces, setSocket }}>{children}</SocketSpacesContext.Provider>
}
