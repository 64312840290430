import React from 'react'
import PropTypes from 'prop-types'
import { DataContext, useDataContext } from './useDataContext'

const DataProvider = ({ dataKey, value, children }) => {
  const context = useDataContext()
  context[dataKey] = value

  return <DataContext.Provider value={context}>{children}</DataContext.Provider>
}

DataProvider.propTypes = {
  dataKey: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
}

export default DataProvider
