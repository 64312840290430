import React from 'react'
import { Flex } from 'mui-blox'
import { makeStyles } from '@mui/styles'
import classNames from 'classnames'
import { useContentWidth } from './hooks/useContentWidth'

const ContentFrame = ({ children, className, ...rest }) => {
  const classes = useStyles()
  const { width } = useContentWidth()

  return (
    <Flex
      flexDirection='column'
      width='100%'
      alignItems='center'
      {...rest}
      className={classNames(classes.contentFrame, className)}
      role='main'
    >
      <Flex flexColumn width='100%' maxWidth={width}>
        {children}
      </Flex>
    </Flex>
  )
}

const useStyles = makeStyles(({ layout, transitions, breakpoints }) => {
  const transition = transitions.create('padding', {
    easing: transitions.easing.sharp,
    duration: transitions.duration.short,
  })

  return {
    contentFrame: {
      paddingLeft: layout.sideBarLarge,
      [breakpoints.down('md')]: {
        paddingLeft: layout.sidebar,
        transition,
      },
      [breakpoints.down('sm')]: {
        paddingLeft: 0,
        transition,
      },
      transition,
    },
  }
})

export default ContentFrame
