import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { InputBase } from '@mui/material'
import { IconButton } from '../_controls'
import { keyboardCodes } from '../../utils'
import SendIcon from 'mdi-react/SendIcon'
import { makeStyles } from '@mui/styles'
import { Flex } from 'mui-blox'

const SendMessage = ({ initialText = '', onSendMessage, placeholder, ...rest }) => {
  const classes = useStyles()

  const [text, setText] = useState(initialText)

  const handleSendMessage = () => {
    onSendMessage(text)
    setText('')
  }

  const handleKeyDown = event => {
    if (event.keyCode === keyboardCodes.Enter) {
      if (event.ctrlKey) {
        setText(text + '\n')
        return
      }

      event.preventDefault()
      handleSendMessage()
    }
  }

  const onFocusDummyButton = () => {
    const e = document.getElementById('avatar-button')

    e?.focus()
  }

  return (
    <Flex alignItems={'center'}>
      <InputBase
        tabIndex={-1}
        className={classes.input}
        multiline
        maxRows={3}
        placeholder={placeholder}
        value={text}
        onKeyDown={handleKeyDown}
        onChange={({ target }) => setText(target.value)}
        style={{ paddingLeft: '8px' }}
        inputProps={{
          'aria-describedby': 'conversation-intro',
          autoFocus: true,
        }}
        {...rest}
      />

      <IconButton
        id='send-message-button'
        size='small'
        onClick={handleSendMessage}
        className={classes.send}
        aria-label='Send message'
      >
        <SendIcon className={classes.sendIcon} id='send-message-button' />
      </IconButton>
      <button
        aria-hidden={true}
        style={{ width: 0, height: 0, border: 'none', outline: 'none', opacity: '0', position: 'absolute' }}
        onFocus={onFocusDummyButton}
      />
    </Flex>
  )
}

SendMessage.propTypes = {
  onSendMessage: PropTypes.func.isRequired,
  initialText: PropTypes.string,
  placeholder: PropTypes.string,
}
SendMessage.defaultProps = {
  placeholder: 'Enter text here...',
}

const useStyles = makeStyles(({ palette, spacing, mixins: { alpha, border } }) => ({
  input: {
    background: palette.background.paper,
    border: `1px solid ${palette.grey[200]}`,
    borderRadius: spacing(0.5),
    minHeight: 50,
  },
  root: {
    borderTop: border(1, alpha(palette.secondary.light, 0.3)),
    borderBottom: border(1, alpha(palette.secondary.light, 0.3)),
    backgroundColor: palette.background.default,
    padding: spacing(1),
  },
  send: {
    background: palette.secondary.main,
    borderRadius: '50%',
    marginLeft: spacing(1),
    height: 32,
    '&:hover': {
      background: palette.secondary.dark,
    },
  },
  sendIcon: {
    color: palette.secondary.contrastText,
  },
}))

export default SendMessage
