export const featureFlags = {
  allowTwilioMessagesToNonUSNumbers: 'allowTwilioMessagesToNonUSNumbers',
  googleTranslate: 'googleTranslate',
  participantBasedSupport: 'participantBasedSupport',
  serveLocalResponses: 'serveLocalResponses',
  usesLegacyBubbleSeparator: 'usesLegacyBubbleSeparator',
  webMessenger: 'webMessenger',
  nlpResponseLogging: 'nlpResponseLogging',
  smoochMessageLogging: 'smoochMessageLogging',
  selfRegisteredParticipants: 'selfRegisteredParticipants',
  pushToDialogFlow: 'pushToDialogFlow',
  attendeeConnections: 'attendeeConnections',
}

export const featureFlagCategories = [
  {
    category: 'General',
    key: featureFlags.allowTwilioMessagesToNonUSNumbers,
    default: false,
    name: 'Allow Twilio Messages to non US Numbers',
    description: '',
  },
  {
    category: 'General',
    key: featureFlags.googleTranslate,
    default: false,
    name: 'Google Translate',
    description: '',
  },
  {
    category: 'General',
    key: featureFlags.participantBasedSupport,
    default: false,
    name: 'Enable Participant Based Support',
    description: '',
  },
  {
    category: 'General',
    key: featureFlags.serveLocalResponses,
    default: false,
    name: 'Serve Local Responses',
    description: '',
    deprecated: true,
  },
  {
    category: 'General',
    key: featureFlags.usesLegacyBubbleSeparator,
    default: false,
    name: 'Use Legacy Bubble Separator',
    description: 'false means that we use \\$ instead of \\n',
    deprecated: true,
  },
  {
    category: 'General',
    key: featureFlags.webMessenger,
    default: false,
    name: 'Web Messenger',
    description: '',
  },
  {
    category: 'General',
    key: featureFlags.pushToDialogFlow,
    default: false,
    name: 'Push to Dialog Flow',
    description: 'Intents and Entities are edited locally.',
  },
  {
    category: 'Logging',
    key: featureFlags.nlpResponseLogging,
    default: false,
    name: 'NLP Logging',
    description: '',
  },
  {
    category: 'Logging',
    key: featureFlags.smoochMessageLogging,
    default: false,
    name: 'Smooch Logging',
    description: '',
  },
  {
    category: 'Participants',
    key: featureFlags.selfRegisteredParticipants,
    default: true,
    name: 'Self Register Participants',
    description: '',
  },
  {
    category: 'Participants',
    key: featureFlags.attendeeConnections,
    default: false,
    name: 'Allow Attendee Connections',
    description: 'Attendees can connect and share information',
  },
]
