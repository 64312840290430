import React from 'react'
import ClassNames from 'classnames'
import { Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { ValueOf } from '~models/util'

import Account from 'mdi-react/AccountOutlineIcon'
import AccountCog from 'mdi-react/AccountCogOutlineIcon'
import AccountGroup from 'mdi-react/AccountGroupOutlineIcon'
import AccountMultipleRemoveOutlineIcon from 'mdi-react/AccountMultipleRemoveOutlineIcon'
import AccountNetwork from 'mdi-react/AccountNetworkIcon'
import AccountPlusOutline from 'mdi-react/AccountPlusOutlineIcon'
import AccountQuestion from 'mdi-react/AccountQuestionOutlineIcon'
import AccountTie from 'mdi-react/AccountTieOutlineIcon'
import AccountVoice from 'mdi-react/AccountTieVoiceIcon'
import Add from 'mdi-react/PlusIcon'
import AddCircle from 'mdi-react/PlusCircleOutlineIcon'
import AddFromLibrary from 'mdi-react/BookPlusIcon'
import Exclamation from 'mdi-react/ExclamationBoldIcon'
import Alert from 'mdi-react/AlertCircleOutlineIcon'
import Alpha from 'mdi-react/AlphabeticalIcon'
import Answers from 'mdi-react/ClipboardCheckOutlineIcon'
import Approved from 'mdi-react/ThumbUpIcon'
import ApprovedNot from 'mdi-react/ThumbUpOutlineIcon'
import ArrowLeft from 'mdi-react/ArrowLeftIcon'
import ArrowRight from 'mdi-react/ArrowRightIcon'
import ArrowUpCircleOutline from 'mdi-react/ArrowUpCircleOutlineIcon'
import ArrowUpDown from 'mdi-react/ArrowUpDownIcon'
import Asterisk from 'mdi-react/AsteriskIcon'
import BillingExclusions from 'mdi-react/CurrencyUsdOffIcon'
import BlankCircle from 'mdi-react/CircleOutlineIcon'
import BlankSquare from 'mdi-react/SquareOutlineIcon'
import BlankTriangle from 'mdi-react/TriangleOutlineIcon'
import Bot from 'mdi-react/RobotIcon'
import Bug from 'mdi-react/BugOutlineIcon'
import Calendar from 'mdi-react/CalendarIcon'
import CalendarRepeat from 'mdi-react/CalendarRefreshIcon'
import CalendarTime from 'mdi-react/CalendarClockIcon'
import Cancel from 'mdi-react/CancelIcon'
import ChartBar from 'mdi-react/ChartBarIcon'
import ChartLine from 'mdi-react/ChartLineIcon'
import Checkmark from 'mdi-react/CheckIcon'
import Check from 'mdi-react/CheckCircleOutlineIcon'
import CheckAll from 'mdi-react/CheckboxMultipleMarkedCircleIcon'
import CheckboxBlankCircleOutline from 'mdi-react/CheckboxBlankCircleOutlineIcon'
import CheckboxCircle from 'mdi-react/CheckboxMarkedCircleOutlineIcon'
import Checklist from 'mdi-react/FormatListChecksIcon'
import CheckNone from 'mdi-react/CheckboxMultipleBlankCircleOutlineIcon'
import ChevronDoubleLeft from 'mdi-react/ChevronDoubleLeftIcon'
import ClockAlert from 'mdi-react/ClockAlertOutlineIcon'
import ClockIcon from 'mdi-react/ClockOutlineIcon'
import Clone from 'mdi-react/SheepIcon'
import Close from 'mdi-react/CloseIcon'
import CommentPlusOutlineIcon from 'mdi-react/CommentPlusOutlineIcon'
import Computer from 'mdi-react/LaptopIcon'
import Controls from 'mdi-react/TuneIcon'
import ConversationDesignIcon from 'mdi-react/WechatIcon'
import CustomerIcon from 'mdi-react/FaceIcon'
import Decommissioned from 'mdi-react/PowerPlugOutlineIcon'
import DeleteIcon from 'mdi-react/DeleteIcon'
import Document from 'mdi-react/FileDocumentOutlineIcon'
import DollarIcon from 'mdi-react/CurrencyUsdIcon'
import Domain from 'mdi-react/DomainIcon'
import DownloadIcon from 'mdi-react/CloudDownloadOutlineIcon'
import DragVariant from 'mdi-react/DragVariantIcon'
import DragVertical from 'mdi-react/DragVerticalIcon'
import EditProfile from 'mdi-react/AccountEditOutlineIcon'
import Ellipsis from 'mdi-react/DotsHorizontalIcon'
import Email from 'mdi-react/EmailOutlineIcon'
import EmoticonHappyIcon from 'mdi-react/EmoticonHappyIcon'
import EndDate from 'mdi-react/CalendarEndIcon'
import Entity from 'mdi-react/ArrowDecisionAutoOutlineIcon'
import ExpandDown from 'mdi-react/ExpandMoreIcon'
import ExpandLeft from 'mdi-react/ChevronLeftIcon'
import ExpandRight from 'mdi-react/ChevronRightIcon'
import ExpandUp from 'mdi-react/ChevronUpIcon'
import ExternalLinkIcon from 'mdi-react/OpenInNewIcon'
import Facebook from 'mdi-react/FacebookIcon'
import FileTable from 'mdi-react/FileTableIcon'
import Filter from 'mdi-react/FilterIcon'
import FilterOutline from 'mdi-react/FilterOutlineIcon'
import FilterRemove from 'mdi-react/FilterRemoveIcon'
import Flag from 'mdi-react/FlagVariantIcon'
import FlagOutline from 'mdi-react/FlagVariantOutlineIcon'
import Form from 'mdi-react/FormatListChecksIcon'
import FormTextboxIcon from 'mdi-react/FormTextboxIcon'
import ForumIcon from 'mdi-react/ForumIcon'
import GenerateIcon from 'mdi-react/TransferDownIcon'
import GoogleSpreadsheetIcon from 'mdi-react/GoogleSpreadsheetIcon'
import Help from 'mdi-react/HelpCircleOutlineIcon'
import HistoryIcon from 'mdi-react/HistoryIcon'
import Home from 'mdi-react/HomeOutlineIcon'
import Identifier from 'mdi-react/IdentifierIcon'
import IDKIcon from 'mdi-react/CommentQuestionIcon'
import ImageOutlineIcon from 'mdi-react/ImageOutlineIcon'
import Information from 'mdi-react/InformationOutlineIcon'
import InstagramIcon from 'mdi-react/InstagramIcon'
import Language from 'mdi-react/EarthIcon'
import LinkIcon from 'mdi-react/LinkIcon'
import ListIcon from 'mdi-react/FormatListBulletedIcon'
import LocationIcon from 'mdi-react/CompassOutlineIcon'
import Lock from 'mdi-react/LockIcon'
import LockOpen from 'mdi-react/LockOpenIcon'
import Menu from 'mdi-react/MenuIcon'
import MessageAlert from 'mdi-react/MessageAlertOutlineIcon'
import MessageImage from 'mdi-react/MessageImageOutlineIcon'
import MessageText from 'mdi-react/MessageTextOutlineIcon'
import MessageTextFilled from 'mdi-react/MessageTextIcon'
import Messenger from 'mdi-react/ForumOutlineIcon'
import NoEntry from 'mdi-react/MinusCircleOutlineIcon'
import Notification from 'mdi-react/BellOutlineIcon'
import NumberedList from 'mdi-react/FormatListNumberedIcon'
import OutOfScope from 'mdi-react/WeatherNightIcon'
import ParticipantCanceled from 'mdi-react/AccountCancelOutlineIcon'
import ParticipantChecked from 'mdi-react/AccountCheckOutlineIcon'
import ParticipantDetails from 'mdi-react/AccountDetailsOutlineIcon'
import ParticipantField from 'mdi-react/CodeTagsIcon'
import ParticipantFieldChecked from 'mdi-react/CodeTagsCheckIcon'
import PasteIcon from 'mdi-react/ContentPasteIcon'
import PDFIcon from 'mdi-react/FilePdfOutlineIcon'
import PencilIcon from 'mdi-react/PencilIcon'
import Phone from 'mdi-react/PhoneIcon'
import PowerOff from 'mdi-react/PowerOffIcon'
import PowerOn from 'mdi-react/PowerIcon'
import Print from 'mdi-react/PrinterIcon'
import ProductIcon from 'mdi-react/CashRegisterIcon'
import ProgressQuestion from 'mdi-react/ProgressQuestionIcon'
import Refresh from 'mdi-react/RefreshIcon'
import ResendEmail from 'mdi-react/EmailAlertOutlineIcon'
import Restore from 'mdi-react/ReplayIcon'
import Reviewed from 'mdi-react/ThumbUpOutlineIcon'
import ReviewedNot from 'mdi-react/ThumbDownOutlineIcon'
import SaveIcon from 'mdi-react/ContentSaveIcon'
import SearchIcon from 'mdi-react/MagnifyIcon'
import SearchTagIcon from 'mdi-react/TagFacesIcon'
import Send from 'mdi-react/SendIcon'
import ShapeOutline from 'mdi-react/ShapeOutlineIcon'
import Share from 'mdi-react/ExportVariantIcon'
import Shield from 'mdi-react/ShieldIcon'
import Signal from 'mdi-react/BroadcastIcon'
import SignalOff from 'mdi-react/BroadcastOffIcon'
import SortArrowAscending from 'mdi-react/MenuUpIcon'
import SortArrowDescending from 'mdi-react/MenuDownIcon'
import SortAscendingIcon from 'mdi-react/SortReverseVariantIcon'
import SortDescendingIcon from 'mdi-react/SortVariantIcon'
import SortIcon from 'mdi-react/SortIcon'
import StageFour from 'mdi-react/ReorderVerticalIcon'
import StageOne from 'mdi-react/MinusIcon'
import StageTwo from 'mdi-react/EqualIcon'
import StairsDown from 'mdi-react/StairsDownIcon'
import StairsUp from 'mdi-react/StairsUpIcon'
import Star from 'mdi-react/StarIcon'
import StarOutline from 'mdi-react/StarOutlineIcon'
import StartDate from 'mdi-react/CalendarStartIcon'
import SupportIcon from 'mdi-react/AccountNetworkOutlineIcon'
import TestTubeIcon from 'mdi-react/TestTubeIcon'
import TextToSpeech from 'mdi-react/TextToSpeechIcon'
import Thermometer from 'mdi-react/ThermometerIcon'
import TimerSandIcon from 'mdi-react/TimerSandIcon'
import TrainTo from 'mdi-react/ArrowExpandRightIcon'
import TwitterIcon from 'mdi-react/TwitterIcon'
import UpdateIcon from 'mdi-react/UpdateIcon'
import UploadIcon from 'mdi-react/UploadIcon'
import UserUtteranceOff from 'mdi-react/AccountOffOutlineIcon'
import UserUtteranceOn from 'mdi-react/AccountVoiceIcon'
import ValidationOff from 'mdi-react/FlashOffIcon'
import ValidationOn from 'mdi-react/FlashOnIcon'
import VariableIcon from 'mdi-react/ABCIcon'
import Visibility from 'mdi-react/EyeOutlineIcon'
import VisibilityOff from 'mdi-react/EyeOffOutlineIcon'
import VolumeOff from 'mdi-react/VolumeOffIcon'
import VolumeHigh from 'mdi-react/VolumeHighIcon'
import WhatsAppIcon from 'mdi-react/WhatsappIcon'
import XMLIcon from 'mdi-react/XmlIcon'

// The Purpose of this stuff is to be able to use icons based on their usage.
// If we need an icon to indicate 'locked' - it should be the same across the app.
// By connecting icons up to their usage, we don't need to worry about which icon is
// being used for what, we just need to identify the usage, and hook it up.

export const iconUsages = {
  add: 'add',
  addCircle: 'addCircle',
  addFromLibrary: 'addFromLibrary',
  alert: 'alert',
  alphabetical: 'alphabetical',
  answers: 'answers',
  approved: 'approved',
  approvedNot: 'approvedNot',
  arrowLeft: 'arrowLeft',
  arrowRight: 'arrowRight',
  arrowUpDown: 'arrowUpDown',
  billingExclusions: 'billingExclusions',
  bot: 'bot',
  bug: 'bug',
  busy: 'busy',
  calendar: 'calendar',
  calendarRepeat: 'calendarRepeat',
  calendarTime: 'calendarTime',
  cancel: 'cancel',
  chart: 'chart',
  check: 'check',
  checkAll: 'checkAll',
  checked: 'checked',
  checkedNot: 'checkedNot',
  checklist: 'checklist',
  checkmark: 'checkmark',
  checkNone: 'checkNone',
  circle: 'circle',
  clock: 'clock',
  clockAlert: 'clockAlert',
  clone: 'clone',
  close: 'close',
  computer: 'computer',
  conversationDesign: 'conversationDesign',
  customer: 'customer',
  dataTableReport: 'dataTableReport',
  dateTime: 'dateTime',
  decommissioned: 'decommissioned',
  deEscalate: 'deEscalate',
  delete: 'delete',
  document: 'document',
  dollar: 'dollar',
  download: 'download',
  dragVariant: 'dragVariant',
  dragVertical: 'dragVertical',
  edit: 'edit',
  editProfile: 'editProfile',
  ellipsis: 'ellipsis',
  email: 'email',
  endDate: 'endDate',
  entity: 'entity',
  error: 'error',
  escalate: 'escalate',
  exclamation: 'exclamation',
  expandDown: 'expandDown',
  expandLeft: 'expandLeft',
  expandRight: 'expandRight',
  expandUp: 'expandUp',
  externalLink: 'externalLink',
  facebook: 'facebook',
  falsePositives: 'falsePositives',
  favorite: 'favorite',
  favoriteFilled: 'favoriteFilled',
  filter: 'filter',
  filterFilled: 'filterFilled',
  filterRemove: 'filterRemove',
  flag: 'flag',
  flagFilled: 'flagFilled',
  form: 'form',
  generate: 'generate',
  googleSpreadsheet: 'googleSpreadsheet',
  help: 'help',
  history: 'history',
  home: 'home',
  id: 'id',
  idks: 'idks',
  image: 'image',
  information: 'information',
  instagram: 'instagram',
  intents: 'intents',
  language: 'language',
  link: 'link',
  list: 'list',
  location: 'location',
  locked: 'locked',
  manager: 'manager',
  markdown: 'markdown',
  menu: 'menu',
  messageAlert: 'messageAlert',
  messageImage: 'messageImage',
  messageStats: 'messageStats',
  messageText: 'messageText',
  messageTextFilled: 'messageTextFilled',
  messenger: 'messenger',
  navigateBreadcrumb: 'navigateBreadcrumb',
  nlp: 'nlp',
  none: 'none',
  notifications: 'notifications',
  numberedList: 'numberedList',
  org: 'org',
  outOfScope: 'outOfScope',
  participant: 'participant',
  participantAdd: 'participantAdd',
  participantCanceled: 'participantCanceled',
  participantChecked: 'participantChecked',
  participantDetails: 'participantDetails',
  participantField: 'participantField',
  participantFieldChecked: 'participantFieldChecked',
  participantGroup: 'participantGroup',
  participantQuestion: 'participantQuestion',
  participantSettings: 'participantSettings',
  participantsDelete: 'participantsDelete',
  paste: 'paste',
  pdf: 'pdf',
  pending: 'pending',
  phone: 'phone',
  powerOff: 'powerOff',
  powerOn: 'powerOn',
  print: 'print',
  products: 'products',
  push: 'push',
  questionnaire: 'questionnaire',
  questionOrganization: 'questionOrganization',
  refresh: 'refresh',
  registration: 'registration',
  required: 'required',
  resendEmail: 'resendEmail',
  restore: 'restore',
  restricted: 'restricted',
  reviewed: 'reviewed',
  reviewedNot: 'reviewedNot',
  save: 'save',
  search: 'search',
  searchTags: 'searchTags',
  send: 'send',
  settings: 'settings',
  share: 'share',
  shield: 'shield',
  signal: 'signal',
  signalOff: 'signalOff',
  silence: 'silence',
  silenceOff: 'silenceOff',
  smile: 'smile',
  sortArrowAscending: 'sortArrowAscending',
  sortArrowDescending: 'sortArrowDescending',
  sortAscending: 'sortAscending',
  sortDescending: 'sortDescending',
  square: 'square',
  stageFour: 'stageFour',
  stageOne: 'stageOne',
  stageThree: 'stageThree',
  stageTwo: 'stageTwo',
  star: 'star',
  starFilled: 'starFilled',
  startDate: 'startDate',
  success: 'success',
  support: 'support',
  test: 'test',
  thermometer: 'thermometer',
  todoOff: 'todoOff',
  trainingPhrase: 'trainingPhrase',
  trainTo: 'trainTo',
  triangle: 'triangle',
  twitter: 'twitter',
  unknown: 'unknown',
  unlocked: 'unlocked',
  upload: 'upload',
  userUtteranceOff: 'userUtteranceOff',
  userUtteranceOn: 'userUtteranceOn',
  validationOff: 'validationOff',
  validationOn: 'validationOn',
  variable: 'variable',
  visibility: 'visibility',
  visibilityOff: 'visibilityOff',
  whatsApp: 'whatsApp',
} as const

const iconMap = {
  [iconUsages.add]: Add,
  [iconUsages.addCircle]: AddCircle,
  [iconUsages.addFromLibrary]: AddFromLibrary,
  [iconUsages.alert]: Alert,
  [iconUsages.alphabetical]: Alpha,
  [iconUsages.answers]: Answers,
  [iconUsages.approved]: Approved,
  [iconUsages.approvedNot]: ApprovedNot,
  [iconUsages.arrowLeft]: ArrowLeft,
  [iconUsages.arrowRight]: ArrowRight,
  [iconUsages.arrowUpDown]: ArrowUpDown,
  [iconUsages.billingExclusions]: BillingExclusions,
  [iconUsages.bot]: Bot,
  [iconUsages.bug]: Bug,
  [iconUsages.busy]: TimerSandIcon,
  [iconUsages.calendar]: Calendar,
  [iconUsages.calendarRepeat]: CalendarRepeat,
  [iconUsages.calendarTime]: CalendarTime,
  [iconUsages.cancel]: Cancel,
  [iconUsages.chart]: ChartBar,
  [iconUsages.check]: Check,
  [iconUsages.checkAll]: CheckAll,
  [iconUsages.checked]: CheckboxCircle,
  [iconUsages.checkmark]: Checkmark,
  [iconUsages.checkedNot]: CheckboxBlankCircleOutline,
  [iconUsages.checklist]: Checklist,
  [iconUsages.checkNone]: CheckNone,
  [iconUsages.circle]: BlankCircle,
  [iconUsages.clock]: ClockIcon,
  [iconUsages.clockAlert]: ClockAlert,
  [iconUsages.clone]: Clone,
  [iconUsages.close]: Close,
  [iconUsages.computer]: Computer,
  [iconUsages.conversationDesign]: ConversationDesignIcon,
  [iconUsages.customer]: CustomerIcon,
  [iconUsages.dataTableReport]: FileTable,
  [iconUsages.decommissioned]: Decommissioned,
  [iconUsages.deEscalate]: StairsDown,
  [iconUsages.delete]: DeleteIcon,
  [iconUsages.document]: Document,
  [iconUsages.dollar]: DollarIcon,
  [iconUsages.download]: DownloadIcon,
  [iconUsages.dragVariant]: DragVariant,
  [iconUsages.dragVertical]: DragVertical,
  [iconUsages.edit]: PencilIcon,
  [iconUsages.editProfile]: EditProfile,
  [iconUsages.ellipsis]: Ellipsis,
  [iconUsages.email]: Email,
  [iconUsages.endDate]: EndDate,
  [iconUsages.entity]: Entity,
  [iconUsages.error]: NoEntry,
  [iconUsages.escalate]: StairsUp,
  [iconUsages.exclamation]: Exclamation,
  [iconUsages.expandDown]: ExpandDown,
  [iconUsages.expandLeft]: ExpandLeft,
  [iconUsages.expandRight]: ExpandRight,
  [iconUsages.expandUp]: ExpandUp,
  [iconUsages.externalLink]: ExternalLinkIcon,
  [iconUsages.facebook]: Facebook,
  [iconUsages.falsePositives]: CommentPlusOutlineIcon,
  [iconUsages.filter]: FilterOutline,
  [iconUsages.filterFilled]: Filter,
  [iconUsages.filterRemove]: FilterRemove,
  [iconUsages.flag]: FlagOutline,
  [iconUsages.flagFilled]: Flag,
  [iconUsages.form]: Form,
  [iconUsages.generate]: GenerateIcon,
  [iconUsages.googleSpreadsheet]: GoogleSpreadsheetIcon,
  [iconUsages.help]: Help,
  [iconUsages.history]: HistoryIcon,
  [iconUsages.home]: Home,
  [iconUsages.id]: Identifier,
  [iconUsages.idks]: IDKIcon,
  [iconUsages.image]: ImageOutlineIcon,
  [iconUsages.information]: Information,
  [iconUsages.instagram]: InstagramIcon,
  [iconUsages.intents]: ForumIcon,
  [iconUsages.language]: Language,
  [iconUsages.link]: LinkIcon,
  [iconUsages.list]: ListIcon,
  [iconUsages.location]: LocationIcon,
  [iconUsages.locked]: Lock,
  [iconUsages.manager]: AccountTie,
  [iconUsages.markdown]: XMLIcon,
  [iconUsages.menu]: Menu,
  [iconUsages.messageAlert]: MessageAlert,
  [iconUsages.messageImage]: MessageImage,
  [iconUsages.messageStats]: ChartLine,
  [iconUsages.messageText]: MessageText,
  [iconUsages.messageTextFilled]: MessageTextFilled,
  [iconUsages.messenger]: Messenger,
  [iconUsages.navigateBreadcrumb]: ChevronDoubleLeft,
  [iconUsages.nlp]: AccountVoice,
  [iconUsages.none]: ProgressQuestion,
  [iconUsages.notifications]: Notification,
  [iconUsages.numberedList]: NumberedList,
  [iconUsages.org]: Domain,
  [iconUsages.outOfScope]: OutOfScope,
  [iconUsages.participant]: Account,
  [iconUsages.participantAdd]: AccountPlusOutline,
  [iconUsages.participantCanceled]: ParticipantCanceled,
  [iconUsages.participantChecked]: ParticipantChecked,
  [iconUsages.participantDetails]: ParticipantDetails,
  [iconUsages.participantField]: ParticipantField,
  [iconUsages.participantFieldChecked]: ParticipantFieldChecked,
  [iconUsages.participantGroup]: AccountGroup,
  [iconUsages.participantQuestion]: AccountQuestion,
  [iconUsages.participantSettings]: AccountCog,
  [iconUsages.participantsDelete]: AccountMultipleRemoveOutlineIcon,
  [iconUsages.paste]: PasteIcon,
  [iconUsages.pdf]: PDFIcon,
  [iconUsages.pending]: UpdateIcon,
  [iconUsages.phone]: Phone,
  [iconUsages.powerOff]: PowerOff,
  [iconUsages.powerOn]: PowerOn,
  [iconUsages.print]: Print,
  [iconUsages.products]: ProductIcon,
  [iconUsages.push]: ArrowUpCircleOutline,
  [iconUsages.questionnaire]: FormTextboxIcon,
  [iconUsages.questionOrganization]: SortIcon,
  [iconUsages.refresh]: Refresh,
  [iconUsages.registration]: AccountNetwork,
  [iconUsages.required]: Asterisk,
  [iconUsages.resendEmail]: ResendEmail,
  [iconUsages.restore]: Restore,
  [iconUsages.reviewed]: Reviewed,
  [iconUsages.reviewedNot]: ReviewedNot,
  [iconUsages.save]: SaveIcon,
  [iconUsages.search]: SearchIcon,
  [iconUsages.searchTags]: SearchTagIcon,
  [iconUsages.send]: Send,
  [iconUsages.settings]: Controls,
  [iconUsages.share]: Share,
  [iconUsages.shield]: Shield,
  [iconUsages.signal]: Signal,
  [iconUsages.signalOff]: SignalOff,
  [iconUsages.silence]: VolumeOff,
  [iconUsages.silenceOff]: VolumeHigh,
  [iconUsages.smile]: EmoticonHappyIcon,
  [iconUsages.sortArrowAscending]: SortArrowAscending,
  [iconUsages.sortArrowDescending]: SortArrowDescending,
  [iconUsages.sortAscending]: SortAscendingIcon,
  [iconUsages.sortDescending]: SortDescendingIcon,
  [iconUsages.square]: BlankSquare,
  [iconUsages.stageFour]: StageFour,
  [iconUsages.stageOne]: StageOne,
  [iconUsages.stageThree]: Menu,
  [iconUsages.stageTwo]: StageTwo,
  [iconUsages.star]: StarOutline,
  [iconUsages.starFilled]: Star,
  [iconUsages.startDate]: StartDate,
  [iconUsages.support]: SupportIcon,
  [iconUsages.test]: TestTubeIcon,
  [iconUsages.thermometer]: Thermometer,
  [iconUsages.todoOff]: ShapeOutline,
  [iconUsages.trainingPhrase]: TextToSpeech,
  [iconUsages.trainTo]: TrainTo,
  [iconUsages.triangle]: BlankTriangle,
  [iconUsages.twitter]: TwitterIcon,
  [iconUsages.unknown]: Help,
  [iconUsages.unlocked]: LockOpen,
  [iconUsages.upload]: UploadIcon,
  [iconUsages.userUtteranceOff]: UserUtteranceOff,
  [iconUsages.userUtteranceOn]: UserUtteranceOn,
  [iconUsages.validationOff]: ValidationOff,
  [iconUsages.validationOn]: ValidationOn,
  [iconUsages.variable]: VariableIcon,
  [iconUsages.visibility]: Visibility,
  [iconUsages.visibilityOff]: VisibilityOff,
  [iconUsages.whatsApp]: WhatsAppIcon,
}

export type IconUsage = ValueOf<typeof iconUsages>

export type ComponentProps = {
  usage: string
  color?: string
  className?: string
  style?: React.CSSProperties
}

export const SemanticIcon = ({ usage, className = undefined, color = '', ...rest }: ComponentProps): JSX.Element => {
  const classes = useStyles()
  const Icon = iconMap[usage]

  if (!Icon) {
    return <Typography>Unknown Icon</Typography>
  }

  return (
    <Icon {...rest} className={ClassNames(classes.base, classes[usage], classes[`color_${color}`], className)} />
  )
}

const useStyles = makeStyles(({ palette, mixins: { alpha } }) => ({
  base: {
    color: 'inherit',
  },
  color_white: {
    color: 'white',
    fill: 'white',
  },
  color_inherit: {
    color: 'inherit',
  },
  color_primary: {
    color: palette.primary.main,
  },
  color_secondary: {
    color: palette.secondary.main,
  },
  color_secondaryLight: {
    color: palette.secondary.light,
  },
  color_tertiary: {
    color: (palette as any).tertiary.main,
  },
  color_warning: {
    color: palette.warning.main,
  },
  color_error: {
    color: palette.error.main,
  },
  color_success: {
    color: palette.success.main,
  },
  color_textSecondary: {
    color: palette.text.secondary,
  },
  color_info: {
    color: palette.info.main,
  },
  color_disabled: {
    color: alpha(palette.action.disabled, 0.18),
  },
  [iconUsages.generate]: {
    transform: 'rotate(180deg)',
  },
  [iconUsages.stageOne]: {
    transform: 'rotate(90deg)',
  },
  [iconUsages.stageTwo]: {
    transform: 'rotate(90deg)',
  },
  [iconUsages.stageThree]: {
    transform: 'rotate(90deg)',
  },
  [iconUsages.signalOff]: {
    transform: 'rotate(-90deg)',
  },
}))
