class Interceptor {
  constructor() {
    this.setInterceptor = this.setInterceptor.bind(this)
    this.onIntercept = null
  }

  onIntercept: ((error: any) => void) | null

  setInterceptor = (interceptor: (error: any) => void) => {
    this.onIntercept = interceptor
  }

  intercept = (error: any) => {
    this.onIntercept && this.onIntercept(error)
  }
}

export const interceptor = new Interceptor()
