import deepmerge from 'deepmerge'
import { basic } from './basic'
import { customer } from './customer'
import { osh } from './osh'

export const getTypography = (type, palette, { rem }) => {
  const typographies = { basic, osh, customer, dark: basic }

  const getTypography =
    typographies[type] ||
    function () {
      return {}
    }

  return {
    fontFamily: '"Lato", Arial, Helvetica, sans-serif',
    ...deepmerge(basic(palette, rem), getTypography(palette, rem)),
  }
}
