import React from 'react'
import { Suspense, lazyRetry } from '../../../routing'

const GlossaryPage = lazyRetry(() =>
  import(
    /* webpackChunkName: "glossary" */
    './GlossaryPage'
  ),
)

const Root = props => (
  <Suspense>
    <GlossaryPage {...props} />
  </Suspense>
)

export default Root
