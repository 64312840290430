import { combineUrl } from '../../../core/util/string/combineUrl'
import { sciensio as config } from '../../config'
import { fetchOnce, clearPromiseForUrl } from './fetchOnce'
import { getBearerToken } from './getBearerToken'
import { getParticipantBearerToken } from './getParticipantBearerToken'

export const requestWithFile = (url, fileData, jsonData, onProgress) => {
  const bearer = getBearerToken()
  const participantBearer = getParticipantBearerToken()

  return fetchOnce(url, 'post', () => {
    const promise = new Promise((resolve, reject) => {
      const name = fileData.name
      const xhr = new XMLHttpRequest()
      const formData = new FormData()

      formData.append('file', fileData, name)
      if (jsonData) formData.append('json', JSON.stringify(jsonData))

      const handleProgressEvent = event => {
        if (event.lengthComputable) {
          onProgress({ name, progress: +(event.loaded / event.total).toFixed(2) })
        }
      }

      const handleLoadEvent = () => {
        onProgress({ name, progress: 1 })
      }

      const handleErrorEvent = () => {
        onProgress({ name, progress: 0 })
        reject({ status: -1, message: xhr.statusText })
      }

      xhr.withCredentials = true

      xhr.upload.addEventListener('progress', handleProgressEvent)
      xhr.upload.addEventListener('load', handleLoadEvent)
      xhr.upload.addEventListener('error', handleErrorEvent)

      xhr.open('post', combineUrl(config.apiUrl, 'api', url))
      xhr.setRequestHeader('Authorization', bearer)
      xhr.setRequestHeader('ParticipantAuth', participantBearer)

      xhr.onload = () => {
        if (xhr.status >= 200 && xhr.status < 300) {
          clearPromiseForUrl(url, 'post')
          resolve(JSON.parse(xhr.response))
        } else {
          reject({
            status: xhr.status,
            message: xhr.statusText,
          })
          clearPromiseForUrl(url, 'post')
        }
      }

      xhr.send(formData)
    })

    return promise
  })
}
