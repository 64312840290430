import { useState, useCallback } from 'react'
import { socketNamespaces } from '~constants'
import { sciensio } from '../../config'
import { useSocketAction } from '../socketv4'

export const useVersionCheck = () => {
  const [newVersion, setNewVersion] = useState(sciensio.buildVersion)

  useSocketAction({ namespace: socketNamespaces.app }, 'version', ({ version }) => {
    console.log('v4 socket', version)
    setNewVersion(version)
  })

  const refresh = useCallback(() => {
    location.reload()
  })

  return {
    changed: newVersion !== sciensio.buildVersion,
    currentVersion: sciensio.buildVersion,
    newVersion,
    refresh,
  }
}
