import React from 'react'
import { Suspense, lazyRetry } from '../../../routing'

const ItineraryRoutes = lazyRetry(() =>
  import(
    /* webpackChunkName: "itinerary" */
    './ItineraryRoutes'
  ),
)

const Root = props => (
  <Suspense>
    <ItineraryRoutes {...props} />
  </Suspense>
)

export default Root
