const tollFreeCodes = ['800', '888', '877', '866', '855', '844', '833']

interface ParsePhoneNumberResult {
  rawInput: string
  phoneNumber: string | null
  match?: string[] | null
  countryCode?: string
  areaCode?: string
  prefix?: string
  lineNumber?: string
  isTollFree?: boolean
}
interface ParsePhoneNumberOptions {
  defaultCountryCode?: string
  ignoreCountryCode?: boolean
}

type FuncType = (
  number: string | number | null | undefined,
  options?: ParsePhoneNumberOptions,
) => ParsePhoneNumberResult

const badFaithNumbers = ['0000000000', '1111111111', '1234567890', '1234567899', '1112223333', '1234567891']

export const parsePhoneNumber: FuncType = (n, options = {}) => {
  const { defaultCountryCode, ignoreCountryCode } = options

  let result = {
    rawInput: String(n),
    phoneNumber: null,
  } as ParsePhoneNumberResult

  if (!n) {
    return result
  }

  result.match = result.rawInput
    // .replace(/\D+/, '')
    .match(/^[\s\D]*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?[\s\D]*$/)

  if (!result.match || badFaithNumbers.includes(result.match[2] + result.match[3] + result.match[4])) {
    return result
  }

  let [, countryCode] = result.match
  const [, , areaCode, prefix, lineNumber] = result.match

  if (!countryCode && !ignoreCountryCode) {
    if (!defaultCountryCode) {
      return result
    } else {
      countryCode = defaultCountryCode.match(/^\+/) ? defaultCountryCode : `+${defaultCountryCode}`
    }
  } else if (!countryCode && ignoreCountryCode) {
    if (!defaultCountryCode) {
      countryCode = ''
    } else {
      countryCode = defaultCountryCode.match(/^\+/) ? defaultCountryCode : `+${defaultCountryCode}`
    }
  } else if (countryCode) {
    countryCode = countryCode.match(/^\+/) ? countryCode : `+${countryCode}`
  }

  return {
    ...result,
    phoneNumber: countryCode + areaCode + prefix + lineNumber,
    countryCode,
    areaCode,
    prefix,
    lineNumber,
    isTollFree: tollFreeCodes.includes(areaCode),
  }
}
