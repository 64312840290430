import React from 'react'
import { Suspense, lazyRetry } from '../../../../routing'

const Routes = lazyRetry(() =>
  import(
    /* webpackChunkName: "calendar_view" */
    './Routes'
  ),
)

const Root = props => (
  <Suspense>
    <Routes {...props} />
  </Suspense>
)

export default Root
