import { createActions } from 'redux-actions'
import { basicActions, gridSortActions, modalActions } from '../redux-helpers'

const actionCreators = createActions({
  orgs: {
    create: basicActions(),
    delete: basicActions(),
    gridSort: gridSortActions(),
    load: basicActions(),
    modal: modalActions(),
    seriesModal: modalActions(),
    update: basicActions(),
    filterText: x => x,
  },
})
export const actions = actionCreators.orgs
