import { Components, Theme } from '@mui/material'
import { ComponentOptions } from './types'

export const MuiInputBase = ({ typography }: ComponentOptions): Components<Theme>['MuiInputBase'] => {
  return {
    defaultProps: {},
    styleOverrides: {
      root: {
        fontSize: typography.body1?.fontSize,
      },
    },
  }
}
