import React, { FC } from 'react'
import { ExconFrame } from '~layout'
import { Suspense, lazyRetry } from '../../../../routing'

const Routes = lazyRetry(
  () =>
    import(
      /* webpackChunkName: "voting_admin" */
      './Routes'
    ),
)

const Root: FC = props => (
  <Suspense>
    <Routes {...props} RootComponent={ExconFrame} />
  </Suspense>
)

export default Root
