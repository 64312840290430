import { useState, useEffect } from 'react'

export const useDebounce = (value, onDebounce, delay = 1000) => {
  const [debounceValue, setDebounceValue] = useState(value)

  useEffect(() => {
    if (value !== debounceValue) {
      const timer = setTimeout(() => {
        setDebounceValue(value)
        if (onDebounce) onDebounce(value)
      }, delay)

      return () => {
        clearTimeout(timer)
      }
    }
  }, [value, delay])

  return debounceValue
}
