import React from 'react'
import { RecoilRoot } from 'recoil'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { I18nextProvider } from 'react-i18next'
import { CssBaseline } from '@mui/material'
import i18n from './language/i18n'
import { ThemeProvider } from './style'
import QuotesRoot from './react/surveys/quote/Root'
import ShoppingCartRoot from './shoppingCart/Root'
import ExConRoot from './react/exCon/Root'
import OrderRoot from './react/customer2/OrderRoot'
import SiteRoot from './siteRoot'
import FacebookRoot from './react/auth/facebook/Root'
import Invitation from './react/auth/Invitation'
import { MessengerClient } from './react/messenger/MessengerClient'
import { Logo42Chat } from '~controls'
import { MessengerCustomerMock } from './react/messenger/MessengerCustomerMock'

import '../../node_modules/react-virtualized/styles.css'
import { AuthScopeErrorHandler } from './react/authScope/AuthScopeErrorHandler'
import { EmbeddedMessengerConversation } from './react/messenger/EmbeddedMessengerConversation'
import { CustomFormPageMock } from './react/custom-forms/CustomFormPageMock'

const AppRoot = () => {
  return (
    <RecoilRoot>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <CssBaseline />
        <BrowserRouter>
          <I18nextProvider i18n={i18n}>
            <ThemeProvider>
              <Logo42Chat>
                <AuthScopeErrorHandler>
                  <Switch>
                    <Redirect from='/eventBot/qualify' to='/quotes' />
                    <Route path='/excon' component={ExConRoot} />
                    <Route path='/bots' component={OrderRoot} />
                    <ShoppingCartRoot path='/eventBot/:slug?' />
                    <Route path='/messenger-test' component={MessengerCustomerMock} />
                    <Route path='/custom-forms-test' component={CustomFormPageMock} />
                    <Route path='/messenger' component={MessengerClient} />
                    <Route path='/embedded-messenger' component={EmbeddedMessengerConversation} />
                    <Route path='/invite' component={Invitation} />
                    <Route path='/facebook' component={FacebookRoot} />
                    <Route path='/quotes' component={QuotesRoot} />
                    <Route path='/' component={SiteRoot} />
                    <Redirect to='/' />
                  </Switch>
                </AuthScopeErrorHandler>
              </Logo42Chat>
            </ThemeProvider>
          </I18nextProvider>
        </BrowserRouter>
      </LocalizationProvider>
    </RecoilRoot>
  )
}

export default AppRoot
