import { Map, fromJS } from 'immutable'
import { handleActions, combineActions } from 'redux-actions'
import {
  arrayToMap,
  basicStartReducer,
  basicCompleteReducer,
  basicFailReducer,
  gridPinReducer,
  gridSortReducer,
  columnAdjustmentReducer,
  branchReduce,
} from '../redux-helpers-immutable'

import { actions, branch } from './actionCreators'
import sharedActions from '../shared/actions'
import paths from './paths'

export default handleActions(
  {
    [combineActions(actions.loadResults.start, actions.save.start)]: basicStartReducer,

    [combineActions(actions.loadResults.fail, actions.save.fail)]: basicFailReducer,

    [actions.loadResults.complete]: (state, { payload: { trainingId, results } }) =>
      basicCompleteReducer(state).setIn(paths.results(trainingId), arrayToMap(results)),

    [actions.save.complete]: (state, { payload: { trainingId, result } }) =>
      basicCompleteReducer(state).setIn(paths.result(trainingId, result.id), fromJS(result)),

    [sharedActions.pinColumn]: (state, action) => branchReduce(state, action, branch, gridPinReducer),

    [sharedActions.gridSort]: (state, action) => branchReduce(state, action, branch, gridSortReducer),

    [sharedActions.adjustColumn]: (state, action) => branchReduce(state, action, branch, columnAdjustmentReducer),
  },
  Map(),
)
