import React from 'react'
import { Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import classNames from 'classnames'
import { Flex } from 'mui-blox'
import { SemanticIcon } from '~controls'

type ComponentProps = {
  children: React.ReactElement | React.ReactFragment | string
  iconUsage?: string
  color?: 'info' | 'warning' | 'error' | 'success'
}

const BlockQuote = ({ iconUsage, children, color = 'info' }: ComponentProps): JSX.Element => {
  const classes = useStyles()

  let descendants = children

  if (typeof children === 'string') {
    descendants = <Typography>{children}</Typography>
  }

  return (
    <blockquote className={classNames(classes.main, classes[`color_${color}`])}>
      <Flex>
        {iconUsage && (
          <Flex mr={2}>
            <SemanticIcon usage={iconUsage} color={color} />
          </Flex>
        )}
        {descendants}
      </Flex>
    </blockquote>
  )
}

const useStyles = makeStyles(({ palette, spacing, mixins: { alpha } }) =>
  createStyles({
    main: {
      margin: spacing(3, 0),
      padding: spacing(2.5, 3),
      borderLeft: `5px solid ${palette.info.main}`,
      backgroundColor: alpha(palette.info.light, 0.2),
    },
    color_success: {
      borderLeft: `5px solid ${palette.success.main}`,
      backgroundColor: alpha(palette.success.light, 0.2),
    },
    color_warning: {
      borderLeft: `5px solid ${palette.warning.main}`,
      backgroundColor: alpha(palette.warning.light, 0.2),
    },
    color_info: {
      borderLeft: `5px solid ${palette.info.main}`,
      backgroundColor: alpha(palette.info.light, 0.2),
    },
    color_error: {
      borderLeft: `5px solid ${palette.error.main}`,
      backgroundColor: alpha(palette.error.light, 0.2),
    },
  }),
)

export default BlockQuote
