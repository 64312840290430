import React, { useState } from 'react'
import { Drawer, IconButton } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { Flex } from 'mui-blox'
import LogoLarge from '../logo/LogoLarge'
import { useBreakpoint } from '../hooks/useBreakpoint'
import { SemanticIcon, iconUsages } from '../../_controls'
import TopFrame from './TopFrame'

const LeftBar = ({ children, className }) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)

  const isXs = useBreakpoint('sm').down
  const isOpen = !isXs || open

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  return (
    <>
      <Flex
        justifyContent='center'
        alignItems='center'
        className={classNames(classes.openPanel)}
        sx={isOpen ? { display: 'none' } : {}}
      >
        <IconButton onClick={handleOpen} color='inherit' size='large'>
          <SemanticIcon usage={iconUsages.expandRight} />
        </IconButton>
      </Flex>
      <Drawer
        open={isOpen}
        variant={isXs ? 'temporary' : 'permanent'}
        onClose={handleClose}
        elevation={0}
        PaperProps={{
          classes: {
            root: classes.root,
          },
        }}
        className={classNames(classes.drawer, className)}
      >
        <Flex flexColumn width='100%'>
          <TopFrame>
            <Flex pl={1.5}>
              <LogoLarge monochrome extraLarge={!isXs} />
            </Flex>
            {isXs && (
              <Flex>
                <IconButton onClick={handleClose} color='inherit' size='large'>
                  <SemanticIcon usage={iconUsages.expandLeft} />
                </IconButton>
              </Flex>
            )}
          </TopFrame>
          <Flex flex='0.33 1 auto'> </Flex>
          <Flex flexColumn justifyContent='center' width='100%'>
            {children}
          </Flex>
        </Flex>
      </Drawer>
    </>
  )
}

LeftBar.propTypes = {
  // Add PropTypes for props passed from parent components here.
}

const useStyles = makeStyles(({ layout, palette, zIndex, breakpoints, transitions }) => {
  const transition = transitions.create('width', {
    easing: transitions.easing.sharp,
    duration: transitions.duration.short,
  })
  return {
    drawer: {
      zIndex: zIndex.appBar,
    },
    root: {
      width: layout.sideBarLarge,
      backgroundColor: palette.primary.main,
      color: palette.common.white,
      overflow: 'hidden',
      border: 'unset',
      transition,
      [breakpoints.down('sm')]: {
        width: layout.sidebar,
        transition,
      },
    },
    openPanel: {
      position: 'fixed',
      backgroundColor: palette.primary.main,
      color: palette.common.white,
      height: layout.topBar,
      width: layout.topBar,
      zIndex: zIndex.appBar + 1,
    },
  }
})

export default LeftBar
