export const SORT_DIRECTIONS = {
  asc: 'ascending',
  desc: 'descending',
} as const

export const SORT_BY = {
  nlpUnreviewed: 'nlpUnreviewed',
  nlpEscalated: 'nlpEscalated',
  idksUnreviewed: 'idksUnreviewed',
  idksEscalated: 'idksEscalated',
} as const
