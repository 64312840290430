import React, { FC } from 'react'
import PhoneFrame from './PhoneFrame'
import MessengerConversation from '../conversation/MessengerConversation'
import { useConversationId } from '../hooks/useConversationId'

export interface MessengerFramedProps {
  eventId: string
  frame?: string
  color?: string
  MessengerStyle?: Record<string, unknown>
}

export const MessengerFramed: FC<MessengerFramedProps> = ({ frame, color, eventId, MessengerStyle }) => {
  const { conversationId } = useConversationId(eventId)

  return (
    <PhoneFrame frame={frame} color={color} style={MessengerStyle}>
      {conversationId && <MessengerConversation conversationId={conversationId} ready />}
    </PhoneFrame>
  )
}
