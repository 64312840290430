import React from 'react'
import PropTypes from 'prop-types'
import { Flex } from 'mui-blox'
import { useHistory } from 'react-router'
import { Typography, Button } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { SemanticIcon, iconUsages } from '../SemanticIcon'
import Robot from '../../../images/Robot.png'

const ErrorDisplay = ({ getMessage, getShowButtons, error }) => {
  const classes = useStyles()
  const { goBack } = useHistory()

  const handleRefresh = () => {
    location.reload()
  }
  return (
    <Flex flexColumn p={2} alignItems='center'>
      <Flex flex={0.1} />
      <Flex flexColumn maxWidth={600} flex={0.9}>
        <img src={Robot} className={classes.image} />
        <Typography variant='h3' align='center' className={classes.message}>
          {getMessage(error) || 'Oops! Something went terribly wrong.'}
        </Typography>
        {getShowButtons(error) && (
          <Flex className={classes.buttonContainer} flexWrap='wrap' marginTop={2}>
            <Button onClick={goBack}>
              <SemanticIcon usage={iconUsages.expandLeft} />
              Go Back to previous page
            </Button>
            <Button onClick={handleRefresh}>
              <SemanticIcon usage={iconUsages.restore} />
              Try to refresh
            </Button>
          </Flex>
        )}
      </Flex>
    </Flex>
  )
}

ErrorDisplay.protoTypes = {
  error: PropTypes.object.isRequired,
  getMessage: PropTypes.func,
  getShowButtons: PropTypes.func,
}

ErrorDisplay.defaultProps = {
  getMessage: () => null,
  getShowButtons: () => true,
}

const useStyles = makeStyles(({ spacing, breakpoints }) => ({
  image: {
    alignSelf: 'flex-end',
    maxHeight: 240,
  },
  buttonContainer: {
    justifyContent: 'space-between',
    '& button': {
      marginTop: spacing(0.5),
    },
    [breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
  message: {
    marginTop: spacing(1),
    marginBottom: spacing(1),
  },
}))

export default ErrorDisplay
