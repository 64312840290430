import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSetPageTitle } from './hooks/useSetPageTitle'
import { useSetContentWidth } from './frames/hooks/useSetContentWidth'

const PageTitle = ({ title, subTitle, tools, contentWidth, children }) => {
  const { setPageTitle, setTools } = useSetPageTitle()
  const setWidth = useSetContentWidth()

  useEffect(() => {
    if (title) setPageTitle(title, subTitle)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [title, subTitle])

  useEffect(() => {
    if (tools) setTools(tools)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tools])

  useEffect(() => {
    if (contentWidth) setWidth(contentWidth)
  }, [contentWidth, setWidth])

  return children
}

PageTitle.propTypes = {
  subTitle: PropTypes.string,
  title: PropTypes.string,
  tools: PropTypes.node,
  contentWidth: PropTypes.oneOf([800, 960, '100%']),
}

PageTitle.defaultProps = {
  subTitle: undefined,
  title: undefined,
  tools: undefined,
  contentWidht: undefined,
}

export default PageTitle
