import { Map, fromJS } from 'immutable'
import paths from '../paths'

export const setIdk = (state, { payload: { idk } }) => {
  let nextState = trySetInSystem(state, idk)
  nextState = trySetInEvent(nextState, idk)
  return nextState
}

const updateSystemCounts = (state, idk) => {
  const previous = state.getIn(paths.systemIdk(idk.id), Map())
  const hasPrev = previous.size !== 0

  const { idkFilters } = require('../selectors/idks')
  let all = state.getIn(paths.systemIdkCount(idkFilters.idksAll.apiFilter), 0)
  let unresolved = state.getIn(paths.systemIdkCount(idkFilters.idksUnresolved.apiFilter), 0)
  let unhandled = state.getIn(paths.systemIdkCount(idkFilters.idksUnhandled.apiFilter), 0)

  if (hasPrev) {
    if (!previous.get('validity') && idk.validity) {
      unhandled--
    }
    if (!previous.get('isResolved') && idk.isResolved) {
      unresolved--
    } else if (previous.get('isResolved') && !idk.isResolved) {
      unresolved++
    }
  } else {
    if (!idk.validity) {
      unhandled++
    }
    if (!idk.isResolved) {
      unresolved++
    }
    all++
  }

  return state
    .setIn(paths.systemIdkCount(idkFilters.idksAll.apiFilter), all)
    .setIn(paths.systemIdkCount(idkFilters.idksUnresolved.apiFilter), unresolved)
    .setIn(paths.systemIdkCount(idkFilters.idksUnhandled.apiFilter), unhandled)
}

const updateEventCounts = (state, idk) => {
  const { orgId, eventId } = idk
  const previous = state.getIn(paths.idk(orgId, eventId, idk.id), Map())
  const hasPrev = previous.size !== 0

  const { idkFilters } = require('../selectors/idks')
  let all = state.getIn(paths.idkCount(orgId, eventId, idkFilters.idksAll.apiFilter), 0)
  let unresolved = state.getIn(paths.idkCount(orgId, eventId, idkFilters.idksUnresolved.apiFilter), 0)
  let unhandled = state.getIn(paths.idkCount(orgId, eventId, idkFilters.idksUnhandled.apiFilter), 0)

  if (hasPrev) {
    if (!previous.get('validity') && idk.validity) {
      unhandled--
    }
    if (!previous.get('isResolved') && idk.isResolved) {
      unresolved--
    } else if (previous.get('isResolved') && !idk.isResolved) {
      unresolved++
    }
  } else {
    if (!idk.validity) {
      unhandled++
    }
    if (!idk.isResolved) {
      unresolved++
    }
    all++
  }

  return state
    .setIn(paths.idkCount(orgId, eventId, idkFilters.idksAll.apiFilter), all)
    .setIn(paths.idkCount(orgId, eventId, idkFilters.idksUnresolved.apiFilter), unresolved)
    .setIn(paths.idkCount(orgId, eventId, idkFilters.idksUnhandled.apiFilter), unhandled)
}

const trySetInSystem = (state, idk) => {
  if (state.getIn(paths.systemIdks())) {
    return updateSystemCounts(state, idk).setIn(paths.systemIdk(idk.id), fromJS(idk))
  }
  return state
}

const trySetInEvent = (state, idk) => {
  if (state.getIn(paths.idks(idk.orgId, idk.eventId))) {
    return updateEventCounts(state, idk).setIn(paths.idk(idk.orgId, idk.eventId, idk.id), fromJS(idk))
  }
  return state
}
