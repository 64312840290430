import { Components, Theme } from '@mui/material'

export const MuiPaper = (): Components<Theme>['MuiPaper'] => {
  return {
    defaultProps: { elevation: 0 },
    styleOverrides: {
      root: {
        elevation1: {
          boxShadow:
            '0px 1px 2px 1px rgba(0,0,0,0.08), 0px 1px 1px 0px rgba(0,0,0,0.04), 0px 1px 1px -1px rgba(0,0,0,0.04)',
        },
      },
    },
  }
}
