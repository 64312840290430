import { createContext, useContext, useEffect } from 'react'

interface LogoContext {
  showLogo: (visible: boolean) => void
}

export const Logo42ChatContext = createContext<Partial<LogoContext>>({})

export const use42ChatLogo = (): void => {
  const { showLogo } = useContext(Logo42ChatContext) as LogoContext

  useEffect(() => {
    let isUnMounted = false

    if (!isUnMounted) {
      showLogo(true)
    }

    return () => {
      isUnMounted = true
      showLogo(false)
    }
  }, [showLogo])
}
