import { useMemo } from 'react'
import { useTheme as useMuiTheme } from '@mui/material'
import { getTheme } from '../../../style/theme'
import { calculateSwatches } from './calculateSwatches'
import { calculateBackgrounds } from './calculateBackgrounds'
import { calculateHeader } from './calculateHeader'

export const useTheme = (
  themeName,
  primary,
  secondary,
  tertiary,
  background,
  headerBackground,
  popupMessageBackgroundColor,
  popupMessageBorderColor,
  popupMessageTextColor,
  popupMessageSecondaryColor,
) => {
  const currentTheme = useMuiTheme()

  const primarySwatch = useMemo(() => {
    return calculateSwatches(primary, currentTheme)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [primary])

  const secondarySwatch = useMemo(() => {
    return calculateSwatches(secondary, currentTheme)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [secondary])

  const tertiarySwatch = useMemo(() => {
    return calculateSwatches(tertiary, currentTheme)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tertiary])

  const backgroundSwatch = useMemo(() => {
    return calculateBackgrounds(background, currentTheme)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [background])

  const headerSwatch = useMemo(() => {
    return calculateHeader(headerBackground, currentTheme)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [headerBackground])

  const popupMessageBackgroundColorSwatch = useMemo(() => {
    return calculateHeader(popupMessageBackgroundColor, currentTheme)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [popupMessageBackgroundColor])

  const popupMessageSecondaryColorSwatch = useMemo(() => {
    return calculateHeader(popupMessageSecondaryColor, currentTheme)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [popupMessageSecondaryColor])

  const popupMessageBorderColorSwatch = useMemo(() => {
    return calculateHeader(popupMessageBorderColor, currentTheme)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [popupMessageBorderColor])

  const popupMessageTextColorSwatch = useMemo(() => {
    return calculateHeader(popupMessageTextColor, currentTheme)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [popupMessageTextColor])

  const theme = useMemo(() => {
    const text = backgroundSwatch.color
      ? {
          text: {
            primary: backgroundSwatch.color,
            secondary: currentTheme.mixins.emphasize(backgroundSwatch.color, 0.3),
            disabled: currentTheme.mixins.emphasize(backgroundSwatch.color, 0.5),
            hint: currentTheme.mixins.emphasize(backgroundSwatch.color, 0.5),
          },
        }
      : {}

    const background = backgroundSwatch.default
      ? {
          background: {
            default: backgroundSwatch.default,
            paper: backgroundSwatch.paper,
          },
          divider: currentTheme.mixins.emphasize(backgroundSwatch.default, 0.15),
        }
      : {}

    const primary =
      primarySwatch.backgrounds.length > 0
        ? {
            primary: paletteValueFromSwatch(primarySwatch),
          }
        : {}

    const secondary =
      secondarySwatch.backgrounds.length > 0
        ? {
            secondary: paletteValueFromSwatch(secondarySwatch),
          }
        : {}

    const tertiary =
      tertiarySwatch.backgrounds.length > 0
        ? {
            tertiary: paletteValueFromSwatch(tertiarySwatch),
          }
        : {}

    const header = headerSwatch.default
      ? { header: { main: headerSwatch.default, contrastText: headerSwatch.color } }
      : {}

    const popupMessageBackgroundColor =
      popupMessageBackgroundColorSwatch.backgrounds.length > 0
        ? {
            popupMessageBackgroundColor: paletteValueFromSwatch(popupMessageBackgroundColorSwatch),
          }
        : {}

    const popupMessageSecondaryColor =
      popupMessageSecondaryColorSwatch.backgrounds.length > 0
        ? {
            popupMessageSecondaryColor: paletteValueFromSwatch(popupMessageSecondaryColorSwatch),
          }
        : {}

    const popupMessageBorderColor =
      popupMessageBorderColorSwatch.backgrounds.length > 0
        ? {
            popupMessageBorderColor: paletteValueFromSwatch(popupMessageBorderColorSwatch),
          }
        : {}

    const popupMessageTextColor =
      popupMessageTextColorSwatch.backgrounds.length > 0
        ? {
            popupMessageTextColor: paletteValueFromSwatch(popupMessageTextColorSwatch),
          }
        : {}

    return getTheme(themeName, {
      ...text,
      ...background,
      ...primary,
      ...secondary,
      ...tertiary,
      ...header,
      ...popupMessageBackgroundColor,
      ...popupMessageBorderColor,
      ...popupMessageTextColor,
      ...popupMessageSecondaryColor,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [primarySwatch, secondarySwatch, tertiarySwatch, backgroundSwatch, headerSwatch, themeName])

  return {
    theme,
    primarySwatch,
    secondarySwatch,
    tertiarySwatch,
    backgroundSwatch,
    headerBackgroundSwatch: headerSwatch,
    popupMessageBackgroundColorSwatch,
    popupMessageBorderColorSwatch,
    popupMessageTextColorSwatch,
    popupMessageSecondaryColorSwatch,
  }
}

const paletteValueFromSwatch = swatch => {
  return {
    lighter: swatch.backgrounds[0],
    light: swatch.backgrounds[1] || 300,
    main: swatch.backgrounds[2] || 500,
    dark: swatch.backgrounds[3] || 700,
    contrastText: swatch.color,
  }
}
