import { Theme } from '@mui/material/styles'
import { rgbaToHex } from './rgbaToHex'

const notSetValue = '#F1F1F1'

export const calculateHeader = (value: string, { palette }: Theme) => {
  const cleaned = (value ?? '').replace('#', '').trim() !== '' ? value : notSetValue
  const defaultColor = rgbaToHex(cleaned)

  return {
    default: defaultColor,
    backgrounds: [defaultColor],
    color: rgbaToHex(palette.getContrastText(defaultColor)),
  } as const
}
