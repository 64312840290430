const ResponseGroups = function (restClient) {
  this.restClient = restClient
}

ResponseGroups.prototype.get = function (orgId, eventId, intentId) {
  return this.restClient.get(`/orgs/${orgId}/events/${eventId}/intents/${intentId}/responseGroups`)
}

ResponseGroups.prototype.update = function (orgId, eventId, intentId, responseGroups) {
  return this.restClient.post(`/orgs/${orgId}/events/${eventId}/intents/${intentId}/responseGroups`, responseGroups)
}

export default ResponseGroups
