export const extractLink = messageText => {
  const result = {
    messageText,
    links: [],
  }
  if (!messageText) return result

  const regexp = /%\[[^\]]+\]\([^\s]+\)/g
  const linkRegexp = /(%\[)([^\]]+)(\]\()([^)]+)(\))/

  const links = messageText.match(regexp)
  if (links !== null) {
    result.messageText = messageText
    for (let link of links) {
      result.messageText = result.messageText.replace(link, '')
      const matches = link.match(linkRegexp)
      result.links.push({ text: matches[2], url: matches[4] })
    }
    result.messageText = result.messageText.trim()
  }

  return result
}
