import React, { FC } from 'react'
import MessengerConversation from '../conversation/MessengerConversation'
import { useConversationId } from '../hooks/useConversationId'
import { Flex } from 'mui-blox'

export interface MessengerFramedProps {
  eventId: string
}

export const NoFrameMessenger: FC<MessengerFramedProps> = ({ eventId }) => {
  const { conversationId } = useConversationId(eventId)

  return (
    <Flex sx={{ height: '100%' }}>
      {conversationId && <MessengerConversation conversationId={conversationId} ready hideFrame />}
    </Flex>
  )
}
