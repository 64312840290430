type Options = {
  leading?: boolean
  trailing?: boolean
}

// eslint-disable-next-line @typescript-eslint/ban-types
export const throttle = <F extends Function>(fn: F, ms = 500, options: Options = { trailing: true }) => {
  let context: any | null = null
  let argies: any[] | null = null
  let result: any | null = null
  let timeoutId: ReturnType<typeof setTimeout> | null = null
  let previous = 0

  let later = function () {
    previous = options.leading === false ? 0 : Date.now()
    timeoutId = null
    result = fn.apply(context, argies)
    if (!timeoutId) context = argies = null
  }

  return <any>function (this: any, ...args: any[]) {
    let now = Date.now()

    if (!previous && options.leading === false) previous = now

    let remaining = ms - (now - previous)

    context = this
    argies = args

    if (remaining <= 0 || remaining > ms) {
      if (timeoutId) {
        clearTimeout(timeoutId)
        timeoutId = null
      }

      previous = now
      result = fn.apply(context, argies)

      if (!timeoutId) context = argies = null
    } else if (!timeoutId && options.trailing !== false) {
      timeoutId = setTimeout(later, remaining)
    }

    return result
  }
}
