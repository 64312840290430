import { useEffect, useState } from 'react'
import { generateNanoId } from '../../../../core/util/generateNanoId'
import { useQueryParams } from '../../routing/hooks/useQueryParams'
import { ConversationIdArgs } from '../types'

const CONVERSATION_KEY = 'conversation_id'

export const useConversationId = (eventId: string): ConversationIdArgs => {
  const { conversationId: queryId } = useQueryParams()
  const [conversationId, setConversationId] = useState<string | null>(localStorage.getItem(getKey(eventId)))

  useEffect(() => {
    if (queryId && queryId !== conversationId) {
      if (isInvalidConversationId(queryId))
        throw Error(`Invalid conversationId ${queryId}. It must be alphanumeric.`)
      localStorage.setItem(getKey(eventId), queryId)
      setConversationId(queryId)
    } else if (
      !conversationId ||
      conversationId === 'undefined' ||
      (conversationId && isInvalidConversationId(conversationId))
    ) {
      const generated = generateNanoId(12, 'abcdefghijklmnopqrstufwxyz0123456789')

      localStorage.setItem(getKey(eventId), generated)
      setConversationId(generated)
    }
  }, [queryId, conversationId, eventId])

  const resetId = () => {
    localStorage.removeItem(getKey(eventId))
    setConversationId(null)
  }

  return {
    conversationId,
    resetId,
  } as ConversationIdArgs
}

const getKey = eventId => {
  return `${CONVERSATION_KEY}-${eventId}`
}

const isInvalidConversationId = id => /\W/.test(id)
