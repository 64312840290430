import { Components, Theme } from '@mui/material'

export const MuiFormHelperText = (): Components<Theme>['MuiFormHelperText'] => {
  return {
    defaultProps: {},
    styleOverrides: {
      contained: {
        position: 'absolute',
        bottom: -3,
        left: -1,
        margin: 0,
        backgroundColor: 'inherit',
        padding: '0 2px',
        fontSize: '.75rem',
        lineHeight: '.75rem',
      },
    },
  }
}
