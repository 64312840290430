export const METADATA_OBJECT_TYPES = {
  participant: 'participant',
  speaker: 'speaker',
  session: 'session',
  exhibitor: 'exhibitor',
  clientInventory: 'clientInventory',
}

export const METADATA_TYPES = {
  note: 'note',
  followup: 'followup',
  flagged: 'flagged',
  favorite: 'favorite',
  data: 'data',
  connection: 'connection',
}

export const METADATA_SHARE_TYPES = {
  participantType: 'participantType',
}
