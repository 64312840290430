import { useAuthToken } from './useAuthToken'
import { getTokenFromStorage } from './getTokenFromStorage'

// TODO:participantAuth Remove after 1 week
const locationKey = 'participantAuth'
export const useParticipantAuthToken = queryString => {
  return useAuthToken(queryString, locationKey)
}

export const getParticipantAuthToken = () => {
  return getTokenFromStorage(locationKey)
}
