import { useCallback } from 'react'
import { useSetRecoilState } from 'recoil'
import { generateNanoId } from '~core/util/generateNanoId'
import { toastNotification, ToastNotificationType } from './state/toastNotification'

export type { ToastNotificationType }

interface ReturnProps {
  push: (message: string, type?: ToastNotificationType) => void
}

export const useToast = (): ReturnProps => {
  const setValue = useSetRecoilState(toastNotification)

  const push = useCallback(
    (message: string, type?: ToastNotificationType): void => {
      setValue(state => [...state, { message, type, id: generateNanoId(4) }])
    },
    [setValue],
  )

  return {
    push,
  }
}
