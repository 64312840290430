import React from 'react'
import { MainContainer, Avatar, ActionButton } from './variantA.style'
import { AvatarProps } from '../types'

export const AvatarVariantA: React.FC<AvatarProps> = ({
  imageUrl,
  text,
  textColor,
  backgroundColor,
  onClick,
  isMessageActive,
  borderColor,
  buttonSize,
  id,
  ariaLabel,
}) => {
  if (!isMessageActive) {
    return (
      <ActionButton size={buttonSize} onClick={onClick} id={id} aria-label={ariaLabel}>
        <Avatar src={imageUrl} />
      </ActionButton>
    )
  }

  return (
    <div>
      <div style={{ height: buttonSize / 2 }} />
      <MainContainer style={{ backgroundColor }} borderColor={borderColor}>
        <ActionButton size={buttonSize} onClick={onClick} moveTop id={id} aria-label={ariaLabel}>
          <Avatar src={imageUrl} />
        </ActionButton>

        <span style={{ marginTop: 5, color: textColor }}>{text}</span>
      </MainContainer>
    </div>
  )
}

export default AvatarVariantA
