const modal = ['modal']
const mediaUploadModal = ['mediaUploadModal']

export default {
  _root: () => 'context',
  notification: () => ['notification'],
  mediaUploadModal: () => mediaUploadModal,
  mediaUploadModalPaths: {
    visible: () => mediaUploadModal.concat(['visible']),
    meta: () => mediaUploadModal.concat(['meta']),
  },
  themePreview: () => ['themePreview'],
  sidebarToggled: () => ['sidebarToggled'],
  modal: () => modal,
  modalPaths: {
    modalType: () => modal.concat(['modalType']),
    meta: () => modal.concat(['meta']),
  },
  cardLocation: () => ['cardLocation'],
  eventId: () => ['eventId'],
  activityStatus: activity => ['activities', activity],
}
