import React from 'react'

const retry = (importFunc, retriesLeft = 4, interval = 1000) => {
  return new Promise((resolve, reject) => {
    importFunc()
      .then(resolve)
      .catch(error => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            reject(error)
            return
          }

          retry(importFunc, retriesLeft - 1, interval).then(resolve, reject)
        }, interval)
      })
  })
}

export function lazyRetry(importFunc) {
  return React.lazy(() => retry(importFunc))
}
