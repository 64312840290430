import { messageBubbleTypes } from '../../../constants'
import { extractButtons } from './extractButtons'
import { extractLink } from './extractLink'
import { extractMMS } from './extractMMS'

export const extractMessage = messageText => {
  const lines = messageText.split(/[\n↵]/)

  return lines.reduce((acc, line) => {
    const linkResult = extractLink(line)
    if (linkResult.links.length > 0) {
      acc.push({ text: linkResult.messageText, type: messageBubbleTypes.text })
      linkResult.links.forEach(link => {
        acc.push({ type: messageBubbleTypes.link, ...link })
      })
      return acc
    }

    let result = extractButtons(line)
    if (result.buttons.length > 0) {
      acc.push({ type: messageBubbleTypes.postBack, buttons: result.buttons })
      return acc
    }
    result = extractMMS(line)
    if (result.image) {
      acc.push({ type: messageBubbleTypes.image, image: result.image })
      return acc
    }

    acc.push({ text: line, type: messageBubbleTypes.text })
    return acc
  }, [])
}
