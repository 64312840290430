import { capitalize } from '../../core/util/string/capitalize'
import { lowerCase } from '../../core/util/string/lowerCase'
import { titleCase } from '../../core/util/string/titleCase'
import { upperCase } from '../../core/util/string//upperCase'

export const formatterKeys = {
  capitalize: 'capitalize',
  lowerCase: 'lowerCase',
  titleCase: 'titleCase',
  upperCase: 'upperCase',
}

const formatterMap = {
  [formatterKeys.capitalize]: capitalize,
  [formatterKeys.lowerCase]: lowerCase,
  [formatterKeys.titleCase]: titleCase,
  [formatterKeys.upperCase]: upperCase,
}

export const customFormatProcessor = {
  type: 'postProcessor',
  name: 'customFormat',
  process: (value, key, options /*, translator*/) => {
    const formatter = formatterMap[options.format] || formatterMap[formatterKeys.capitalize]
    if (!formatter) return value
    return formatter(value)
  },
}
