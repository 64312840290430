export const NOTIFICATION_SCHEDULE_TYPES = {
  byAnchor: 'byAnchor',
  bySchedule: 'bySchedule',
  /** @deprecated */
  atDeploy: 'atDeploy',
}

export const notificationAnchorPoints = {
  afterBotLive: 'afterBotLive',
  beforeEventStart: 'beforeEventStart',
  afterEventStart: 'afterEventStart',
  beforeEventEnd: 'beforeEventEnd',
  afterEventEnd: 'afterEventEnd',
  beforeBotDone: 'beforeBotDone',
}

export const NOTIFICATION_DELIVERY_TYPES = {
  emergency: 'emergency',
  immediate: 'immediate',
  scheduledRecurring: 'scheduledRecurring',
  scheduled: 'scheduled',
  scheduledConfirmation: 'scheduledConfirmation',
}

export const NOTIFICATION_DELIVERY_TYPE_LABELS = {
  emergency: 'Emergency',
  immediate: 'Immediate',
  scheduledRecurring: 'Scheduled Recurring',
  scheduled: 'Scheduled',
  scheduledConfirmation: 'Scheduled Confirmation',
}

export const NOTIFICATION_AUDIENCE = {
  all: 'all',
  connected: 'connected',
  unConnected: 'unConnected',
  AllParticipant: 'AllParticipant',
  ConnectedParticipant: 'ConnectedParticipant',
  DesignatedParticipants: 'DesignatedParticipants',
  UnconnectedParticipant: 'UnconnectedParticipant',
}

export const NOTIFICATION_SOURCE_TYPES = {
  calendarReminder: 'calendarReminder',
  calendarEvent: 'calendarEvent',
  botComponent: 'botComponent',
}

export const NOTIFICATION_MESSAGE_CATEGORY = {
  confirmation: 'confirmation',
  discovery: 'discovery',
  eventGreeting: 'eventGreeting',
  generalInformation: 'generalInformation',
  reminder: 'reminder',
  status: 'status',
  triggered: 'triggered',
}

export const NOTIFICATION_MESSAGE_CATEGORY_LABELS = {
  [NOTIFICATION_MESSAGE_CATEGORY.confirmation]: 'Confirmation',
  [NOTIFICATION_MESSAGE_CATEGORY.discovery]: 'Discovery',
  [NOTIFICATION_MESSAGE_CATEGORY.eventGreeting]: 'Event Greeting',
  [NOTIFICATION_MESSAGE_CATEGORY.generalInformation]: 'General Information',
  [NOTIFICATION_MESSAGE_CATEGORY.reminder]: 'Reminder',
  [NOTIFICATION_MESSAGE_CATEGORY.status]: 'Status',
  [NOTIFICATION_MESSAGE_CATEGORY.triggered]: 'Triggered',
}

export const NOTIFICATION_AUDIENCE_LABELS = {
  [NOTIFICATION_AUDIENCE.all]: 'All',
  [NOTIFICATION_AUDIENCE.AllParticipant]: 'All Participants',
  [NOTIFICATION_AUDIENCE.connected]: 'Connected',
  [NOTIFICATION_AUDIENCE.ConnectedParticipant]: 'Connected Participants',
  [NOTIFICATION_AUDIENCE.DesignatedParticipants]: 'Designated Participants',
  [NOTIFICATION_AUDIENCE.unConnected]: 'Not Connected',
  [NOTIFICATION_AUDIENCE.UnconnectedParticipant]: 'Unconnected Participants',
}
