export default {
  promoCodeMessage: () => ['promoCodeMessage'],
  qualifiedTemplates: () => ['qualifiedTemplates'],
  qualifierSurveys: () => ['qualifierSurveys'],
  qualifierSurveysBySlug: (slug = 'default') => ['qualifierSurveys', String(slug)],
  survey: templateId => ['surveys', String(templateId)],
  surveyResults: (orgId, resultId) => ['results', 'org', String(orgId), String(resultId)],
  surveyResultsList: orgId => ['results', 'org', String(orgId)],
  surveys: () => ['surveys'],
  template: id => ['templates', String(id)],
  templates: () => ['templates'],
  surveyResultsPaths: {
    city: () => ['data', 'venue', 'address', 'city'],
    completed: () => ['completed'],
    createdAt: () => ['meta', 'createdAt'],
    eventEndDate: () => ['data', 'eventEndDate'],
    eventName: () => ['data', 'eventName'],
    eventStartDate: () => ['data', 'eventStartDate'],
    facetList: () => ['facetList'],
    hostName: () => ['data', 'hostName'],
    orgId: () => ['orgId'],
    resultId: () => ['id'],
    state: () => ['data', 'venue', 'address', 'state'],
    street1: () => ['data', 'venue', 'address', 'street1'],
    street2: () => ['data', 'venue', 'address', 'street2'],
    templateId: () => ['templateId'],
    timezone: () => ['data', 'timezone'],
    venueName: () => ['data', 'venue', 'name'],
    wifi: () => ['data', 'wifi', 'available'],
  },
  shoppingCart: {
    _root: () => ['shoppingCart'],
    facets: () => ['shoppingCart', 'facets'],
    Id: () => ['shoppingCart', 'id'],
    invoice: () => ['shoppingCart', 'invoice'],
    invoiceItems: () => ['shoppingCart', 'invoice', 'items'],
    products: () => ['shoppingCart', 'products'],
    qualifierResults: () => ['shoppingCart', 'qualifierResults'],
    qualifierSurveyId: () => ['shoppingCart', 'qualifierSurveyId'],
    templateId: () => ['shoppingCart', 'templateId'],
    paths: {
      botResults: () => 'botResults',
      facet: name => ['facets', String(name)],
      invoice: () => 'invoice',
      orgId: () => 'orgId',
      products: () => 'products',
      promoCodes: () => 'promoCodes',
      qualifierResults: () => 'qualifierResults',
      templateId: () => 'templateId',
    },
  },
}
