/**
 * @deprecated
 * start replacing with connectors defs
 */
export const integrations = {
  a2zinc: 'a2zinc',
  aventri: 'aventri',
  bizzabo: 'bizzabo',
  boomset: 'boomset',
  box: 'box',
  cadmiumIntegration: 'cadmiumIntegration',
  cdsBridge: 'cdsBridge',
  completeReg: 'completeReg',
  compuSystems: 'compuSystems',
  connectspace: 'connectspace',
  confex: 'confex',
  cvent: 'cvent',
  edgereg: 'edgereg',
  eShow: 'eShow',
  ev2: 'ev2',
  eventbrite: 'eventbrite',
  eventsquid: 'eventsquid',
  eventRebels: 'eventRebels',
  experient: 'experient',
  expocadIntegration: 'expocadIntegration',
  impexium: 'impexium',
  issiIntegration: 'issiIntegration',
  klik: 'klik',
  mapYourShowIntegration: 'mapYourShowIntegration',
  mci: 'mci',
  mindbody: 'mindbody',
  modex: 'modex',
  myWorldOfExpoIntegration: 'myWorldOfExpoIntegration',
  pheedLoop: 'pheedLoop',
  planstoneIntegration: 'planstoneIntegration',
  regFox: 'regFox',
  salesForce: 'salesForce',
  siliconSlopes: 'siliconSlopes',
  streampoint: 'streampoint',
  swapCard: 'swapCard',
  swoogo: 'swoogo',
  ticketSauce: 'ticketSauce',
}
