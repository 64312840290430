import React from 'react'
import PropTypes from 'prop-types'
import { Drawer, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Flex } from 'mui-blox'
import ClassNames from 'classnames'
import IconButton from '../../_controls/buttons/IconButton'
import { SemanticIcon, iconUsages } from '../../_controls/SemanticIcon'

const FramedDrawer = ({ className, closeDrawer, DrawerProps, open, renderComponent, title }) => {
  const classes = useStyles()

  let resolvedTitle = null
  switch (title != null && title.constructor) {
    case false:
      break
    case String:
      resolvedTitle = (
        <Typography component='h2' variant='h5' color='inherit'>
          {title}
        </Typography>
      )
      break
    case Object:
    default:
      resolvedTitle = title
      break
  }

  return (
    <Drawer
      open={open}
      onClose={closeDrawer}
      anchor='right'
      className={className}
      transitionDuration={200}
      PaperProps={{
        classes: {
          root: ClassNames(classes.root),
        },
      }}
      {...DrawerProps}
    >
      <Flex flexColumn className={ClassNames(classes.content)}>
        <Flex justifyContent='space-between' alignItems='flex-end' className={classes.titleBar}>
          <Flex alignItems='center'>{resolvedTitle}</Flex>
          <IconButton onClick={closeDrawer} color='inherit' size='large'>
            <SemanticIcon usage={iconUsages.close} />
          </IconButton>
        </Flex>
        {renderComponent && renderComponent()}
      </Flex>
    </Drawer>
  )
}

FramedDrawer.propTypes = {
  closeDrawer: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  DrawerProps: PropTypes.object,
  renderComponent: PropTypes.func,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
}

FramedDrawer.defaultProps = {
  DrawerProps: {},
  renderComponent: undefined,
  title: '',
}

const useStyles = makeStyles(({ spacing, layout, breakpoints, palette, transitions }) => ({
  root: {
    maxWidth: '100vw',
  },
  content: {
    width: layout.sideBarLarge,
    [breakpoints.down('sm')]: {
      width: '80vw',
    },
    transition: transitions.create(['width']),
  },
  titleBar: {
    backgroundColor: palette.secondary.light,
    color: palette.secondary.main,
    height: layout.topBarLarge,
    padding: spacing(2),
    alignItems: 'flex-end',
    [breakpoints.down('sm')]: {
      backgroundColor: palette.primary.main,
      color: palette.common.white,
      height: layout.topBar,
      width: '80vw',
      padding: spacing(0.5, 2),
      alignItems: 'center',
    },
  },
}))

export default FramedDrawer
