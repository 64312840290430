import React, { useState } from 'react'
import { ExconTitleContext } from '../hooks/useSetExconTitle'

const ExconTitleProvider = ({ children }) => {
  const [pageTitle, _setPageTitle] = useState('')
  const [pageSubtitle, _setPageSubtitle] = useState('')
  const [windowTitle, _setWindowTitle] = useState('')
  const [tools, _setTools] = useState(null)
  const [showBackButton, _setShowBackButton] = useState(false)
  const [hideTitleBar, _setHideTitleBar] = useState(false)

  const setPageTitle = (title, subTitle, windowTitle, enableBackButton) => {
    _setPageTitle(title)
    _setPageSubtitle(subTitle)
    _setWindowTitle(windowTitle)
    _setShowBackButton(enableBackButton)
  }

  const setHideTitleBar = hide => _setHideTitleBar(hide)

  const setShowBackButton = show => _setShowBackButton(show)

  const setTools = _ => _setTools(_)

  return (
    <ExconTitleContext.Provider
      value={{
        pageTitle,
        pageSubtitle,
        windowTitle,
        setPageTitle,
        setTools,
        tools,
        showBackButton,
        hideTitleBar,
        setHideTitleBar,
        setShowBackButton,
      }}
    >
      {children}
    </ExconTitleContext.Provider>
  )
}

export default ExconTitleProvider
