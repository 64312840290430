import React, { forwardRef, MouseEvent, useCallback, useEffect, useRef, useState } from 'react'
import { Button, Zoom, useTheme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import { useEventBranding } from '~brix/connectors/context/useEventBranding'

import ClassNames from 'classnames'
import { BotBranding } from '../types'
import { messengerButtonStyle, messengerMessages } from '~constants'
import useStickyState from '~hooks/useStickyState'
import { useResizeDetector } from 'react-resize-detector'
import { SelectedAvatar } from '../avatars'
import { BUTTON_SIZE } from '../avatars/constants'
import { useQueryParams } from '../../routing'
import { useMessengerHostListener } from '../hooks/useMessengerHostListener'

const RESIZE_LISTENER_REFRESH_RATE = 300

export interface MessengerButtonProps {
  id: string
  onClick: (e: MouseEvent<HTMLButtonElement>) => void
  style?: Record<string, unknown>
  open: boolean
  omitClosedState?: boolean
}

const parseScale = (scale: any) => {
  if (!scale) {
    return 1
  }

  const parsed = parseFloat(scale)

  if (isNaN(parsed)) {
    return 1
  }

  return parsed
}

export const MessengerButton = forwardRef<HTMLDivElement, MessengerButtonProps>(
  ({ id, onClick, style, open, omitClosedState }, ref) => {
    const theme = useTheme()
    const [isMobile, setIsMobile] = useState(false)
    const { scale, scaleMd } = useQueryParams()
    const mounted = useRef(false)
    const { parentWidth } = useMessengerHostListener()
    const intervalRef = React.useRef<NodeJS.Timer>()

    const requiredScale = isMobile ? scale : scaleMd
    const parsedScale = parseScale(requiredScale)

    useEffect(() => {
      mounted.current = true
    }, [])

    useEffect(() => {
      if (!parentWidth) {
        window.parent.window.postMessage({ type: messengerMessages.requestParentWidth }, window.clientHost)

        return
      }

      setIsMobile(parentWidth < 600)
    }, [parentWidth])

    const { branding, eventId } = useEventBranding() as unknown as { branding: BotBranding; eventId: string }

    const targetRef = useRef<HTMLDivElement>(null)

    const [isBubbleClosed, setIsBubbleClosed] = useState(false)
    const [visibilityStatus, setVisibilityStatus] = useStickyState(true, `${eventId}-MESSENGER-POPUP-VISIBILITY`)

    const displayStyle = branding.display?.displayStyle
    const isTab = displayStyle === messengerButtonStyle.TAB

    const transitionDuration = {
      enter: theme.transitions.duration.enteringScreen,
      exit: theme.transitions.duration.leavingScreen,
    }

    const { ref: messengerTabRef, width, height } = useResizeDetector<HTMLDivElement>()

    const handleResizeFrame = useCallback(() => {
      if (open || isTab) {
        return
      }

      const type = messengerMessages.messengerPopupBubble

      const messengerTabHeight = height || 0
      const messengerTabWidth = width || 0

      window.parent.window.postMessage(
        { type, width: messengerTabWidth, height: messengerTabHeight },
        window.clientHost,
      )
    }, [isTab, open, width, height])

    const {
      popupMessageBackgroundColor,
      popupMessageSecondaryColor,
      popupMessageBorderColor,
      popupMessageTextColor,
      popupMessageText,
      popupMessageActiveStatus,
      popupTitle,
      popupSubtitle,
      buttonPosition,
    } = branding
    const classes = useStyles(branding)

    const isLeft = buttonPosition === 'left'

    useEffect(() => {
      if (!omitClosedState) {
        setIsBubbleClosed(!visibilityStatus)
      }
    }, [visibilityStatus, omitClosedState])

    useEffect(() => {
      if (visibilityStatus || omitClosedState) {
        setIsBubbleClosed(false)
      }
    }, [buttonPosition, visibilityStatus, omitClosedState])

    useEffect(() => {
      if (!omitClosedState && isBubbleClosed) {
        setVisibilityStatus(false)
      }
    }, [isBubbleClosed, setVisibilityStatus, omitClosedState])

    useEffect(() => {
      handleResizeFrame()
    }, [handleResizeFrame])

    useEffect(() => {
      intervalRef.current = setInterval(handleResizeFrame, RESIZE_LISTENER_REFRESH_RATE)

      return () => {
        clearInterval(intervalRef.current as NodeJS.Timeout)
      }
    }, [handleResizeFrame])

    const onFocusDummyButton = () => {
      const e = document.getElementById('send-message-button')

      e?.focus()
    }

    return (
      <section>
        <button
          aria-hidden={true}
          style={{ width: 0, height: 0, border: 'none', outline: 'none', opacity: '0', position: 'absolute' }}
          onFocus={onFocusDummyButton}
          tabIndex={open ? 0 : -1}
        />
        {isTab ? (
          <div className={ClassNames(classes.button)} ref={ref}>
            <Button
              id='avatar-button'
              aria-describedby={id}
              onClick={onClick}
              style={style}
              color='primary'
              variant='contained'
              className={ClassNames(classes.tabButton)}
            >
              {!open ? branding.display?.header : 'Close'}
            </Button>
          </div>
        ) : (
          <div
            ref={messengerTabRef}
            style={{
              position: 'fixed',
              bottom: 0,
              right: isLeft ? 'auto' : 0,
              left: isLeft ? 0 : 'auto',
              zIndex: 1500,
              ...style,
            }}
          >
            <div ref={targetRef}>
              <div ref={ref}>
                <Zoom timeout={transitionDuration} in={mounted.current}>
                  <SelectedAvatar
                    avatarStyle={displayStyle}
                    text={popupMessageText || ''}
                    imageUrl={branding.display.avatarUrl}
                    backgroundColor={popupMessageBackgroundColor || 'white'}
                    secondaryColor={popupMessageSecondaryColor || popupMessageBackgroundColor}
                    borderColor={popupMessageBorderColor || 'black'}
                    textColor={popupMessageTextColor || 'black'}
                    isClosed={isBubbleClosed}
                    setIsClosed={setIsBubbleClosed}
                    isMessageActive={popupMessageActiveStatus}
                    onClick={onClick}
                    isMessengerOpen={open}
                    title={popupTitle}
                    subtitle={popupSubtitle}
                    position={buttonPosition}
                    buttonSize={BUTTON_SIZE * parsedScale}
                    id='avatar-button'
                    ariaLabel='Open conversation'
                  />
                </Zoom>
              </div>
            </div>
          </div>
        )}
      </section>
    )
  },
)

MessengerButton.displayName = 'MessengerButton'

const useStyles = makeStyles(({ spacing }) =>
  createStyles<string, BotBranding>({
    button: {
      position: 'absolute',
      bottom: 0,
      width: '100%',
    },
    tabButton: {
      borderRadius: spacing(1, 1, 0, 0),
      bottom: 0,
      width: '100%',
    },
  }),
)
