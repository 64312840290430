// These ids can be found using the deal api endpoint:
// GET https://api.hubapi.com/deals/v1/pipelines?hapikey=<hapikey></hapikey>
export const PIPELINES = {
  events: {
    pipelineId: 'default',
    stages: {
      openCart: '878e42c7-bc4d-4e75-87a1-298441ea67d2',
      underReview: 'a7cde008-4231-4d44-97e4-031ee3d20f6c',
    },
  },
  templates: {
    pipelineId: 'dfd1d7b3-4846-46a9-aed3-e30248348423',
    stages: {
      openCart: '4a4ed239-ed6b-4a5f-a702-82c2682572d2',
      closedWon: 'fd38fb58-a0a4-44cb-88d7-0a08d45cbaa5',
      paid: 'da8fc540-cc92-4b7d-a65b-c4a3f9264fef',
    },
  },
}

export const LEAD_STATUS = {
  warmTemplate: 'Warm - Template',
  warmCustom: 'Warm - Custom',
  warmOther: 'Warm - Other',
}

export const DEAL_TYPE = {
  quote: {
    pipelineId: PIPELINES.events.pipelineId,
    stage: PIPELINES.events.stages.underReview,
  },
}
