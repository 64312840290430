import { combineActions, handleActions } from 'redux-actions'
import { actions } from './actionCreators'
import { basicState, basicCompleteReducer, basicFailReducer, basicStartReducer } from '../redux-helpers'

const initialState = {
  ...basicState(),
  sentMessages: [],
  currentPhoneNumber: '',
  receivedMessages: [],
  availableNumbers: [],
  selectedCountry: 'US',
  selectedBotPhoneNumber: {},
}

export default handleActions(
  {
    // xxx.start
    [combineActions(actions.postMessage.start, actions.getAvailableNumbers.start)]: basicStartReducer,

    // xxx.fail
    [combineActions(actions.postMessage.fail, actions.getAvailableNumbers.fail)]: basicFailReducer,

    // create
    [actions.postMessage.complete]: (state, action) => {
      const message = action.payload
      const sentMessages = [...state.sentMessages]
      message.id = 'sent' + (sentMessages.length + 1)
      sentMessages.push(message)

      return {
        ...basicCompleteReducer(state, action),
        sentMessages,
      }
    },

    [actions.getAvailableNumbers.complete]: (state, action) => {
      const availableNumbers = action.payload

      return {
        ...basicCompleteReducer(state, action),
        availableNumbers,
      }
    },

    [actions.updatePhoneNumber]: (state, action) => {
      const { phoneNumber } = action.payload

      return {
        ...state,
        currentPhoneNumber: phoneNumber,
      }
    },

    [actions.selectCountry]: (state, action) => {
      const { country } = action.payload

      return {
        ...state,
        selectedCountry: country,
      }
    },

    [actions.selectBotPhoneNumber]: (state, action) => {
      const { eventId, phoneNumber } = action.payload
      const selectedBotPhoneNumber = { ...state.selectedBotPhoneNumber }
      selectedBotPhoneNumber[eventId] = phoneNumber
      return {
        ...state,
        selectedBotPhoneNumber,
      }
    },

    [actions.receiveMessage]: (state, action) => {
      const message = action.payload
      const receivedMessages = [...state.receivedMessages]
      message.id = 'received' + (receivedMessages.length + 1)
      receivedMessages.push(message)

      return {
        ...state,
        receivedMessages,
      }
    },
  },
  initialState,
)
