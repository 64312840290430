import React from 'react'
import PropTypes from 'prop-types'
import ErrorDisplay from './ErrorDisplay'
import MiniErrorDisplay from './MiniErrorDisplay'

class ErrorBoundary extends React.Component {
  static getDerivedStateFromError(error) {
    return { hasError: true, error }
  }

  render() {
    const { boundary, mini, children } = this.props
    const { hasError, error } = this.state
    if (hasError) {
      return boundary || mini ? <MiniErrorDisplay error={error} /> : <ErrorDisplay error={error} />
    }

    return children
  }

  state = { hasError: false }

  componentDidCatch(error, info) {
    console.log('error was caught', error, info)
  }
}

ErrorBoundary.propTypes = {
  boundary: PropTypes.node,
  mini: PropTypes.bool,
}

ErrorBoundary.defaultProps = {
  boundary: undefined,
  mini: false,
}

export default ErrorBoundary
