import React from 'react'
import { Suspense, lazyRetry } from '../../routing'

const Routes = lazyRetry(() =>
  import(
    /* webpackChunkName: "FacebookRoot" */
    './Routes'
  ),
)

const FacebookRoot = () => {
  return (
    <Suspense>
      <Routes />
    </Suspense>
  )
}

FacebookRoot.propTypes = {
  // Add PropTypes for props passed from parent components here.
}

export default FacebookRoot
