import React from 'react'
import {
  MainContainer,
  Avatar,
  BubbleBody,
  BubbleTail,
  CloseButton,
  ActionButton,
  BubbleTailBorder,
} from './variantB.style'
import { Flex } from 'mui-blox'
import { SemanticIcon, iconUsages } from '~controls'
import { AvatarProps } from '../types'

export const AvatarVariantB: React.FC<AvatarProps> = ({
  imageUrl,
  text,
  position,
  textColor,
  backgroundColor,
  className,
  isClosed,
  isMessageActive,
  setIsClosed,
  onClick,
  borderColor,
  buttonSize,
  id,
  ariaLabel,
}) => {
  const isLeft = position === 'left'

  return (
    <MainContainer left={isLeft} className={className}>
      {!isClosed && isMessageActive && (
        <Flex alignItems='flex-start' marginBottom='16px'>
          <Flex flexDirection='column' marginBottom='42px' alignItems={isLeft ? 'flex-start' : 'flex-end'}>
            <BubbleBody style={{ backgroundColor }} borderColor={borderColor}>
              <Flex flexFull alignItems='flex-end'>
                <CloseButton onClick={() => setIsClosed(true)}>
                  <SemanticIcon
                    usage={iconUsages.close}
                    color='white'
                    style={{ width: 'inherit', height: 'inherit' }}
                  />
                </CloseButton>
              </Flex>
              <span style={{ color: textColor }}>{text}</span>
            </BubbleBody>
            <div style={{ position: 'relative' }}>
              <BubbleTail left={isLeft} borderColor={backgroundColor} />
              <BubbleTailBorder left={isLeft} borderColor={borderColor} />
            </div>
          </Flex>
        </Flex>
      )}
      <Flex alignItems='flex-end'>
        <ActionButton onClick={onClick} size={buttonSize} id={id} aria-label={ariaLabel}>
          <Avatar src={imageUrl} />
        </ActionButton>
      </Flex>
    </MainContainer>
  )
}

export default AvatarVariantB
