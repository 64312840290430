import { useMemo, useState } from 'react'
import { Map, fromJS } from 'immutable'
import { useQueryParams } from '../../routing/hooks/useQueryParams'
import { useFetch } from '../../../brix/connectors/fetch/useFetch'

export const useInviteInfo = () => {
  const { email, invite } = useQueryParams()
  const { post, status } = useFetch()
  const [info, setInfo] = useState(Map())

  useMemo(async () => {
    const info = await post('/invites/info', { email, invite })
    setInfo(fromJS(info))
    status.done()
  }, [email, invite])

  return {
    info,
    isFetching: status.isFetching,
  }
}
