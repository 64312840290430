import React from 'react'
import { Flex } from 'mui-blox'
import { WaitingForData, LogoProgress } from '~controls'
import { useParticipantAuthentication } from './hooks/useParticipantAuthentication'

type ComponentProps = {
  children: React.ReactElement
  isWaiting?: boolean
}

const Authorization = ({ children, isWaiting }: ComponentProps): JSX.Element => {
  const { isPosting: isAuthorizing } = useParticipantAuthentication()

  return (
    <WaitingForData
      isWaiting={isWaiting || isAuthorizing}
      renderWaiting={() => (
        <Flex height='100%' width='100%'>
          <LogoProgress />
        </Flex>
      )}
    >
      {children}
    </WaitingForData>
  )
}

export default Authorization
