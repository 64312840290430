import React from 'react'
import { Flex } from 'mui-blox'
import { Typography, TextField, Button, Grid, Hidden } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import ClassNames from 'classnames'
import DotIcon from 'mdi-react/CircleSmallIcon'
import FramedPage from '../_layout/frames/FramedPage'
import { useInviteInfo } from './hooks/useInviteInfo'
import { useAcceptInvite } from './hooks/useAcceptInvite'
import { ToastAlert, WaitingForData, SemanticIcon, iconUsages } from '../_controls'
import { useHistory } from 'react-router'
import { PageTitle, PageTitleProvider } from '../_layout'
import { useQueryParams } from '../routing'

const Invitation = () => {
  const classes = useStyles()
  const { createAccount } = useQueryParams()
  const parsedCreateAccount = createAccount !== 'false'
  const { info, isFetching } = useInviteInfo()
  const { form, existingAccountForm, fields, isAccepting, completed, messages } =
    useAcceptInvite(parsedCreateAccount)
  const { push } = useHistory()

  const renderForm = () => {
    return parsedCreateAccount ? (
      <form.Form>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <TextField {...fields.password} type='password' fullWidth />
            <TextField {...fields.confirmPassword} type='password' fullWidth />
          </Grid>
          <Hidden smDown>
            <Grid item sm={6}>
              {renderMessage()}
            </Grid>
          </Hidden>
          <Grid item xs={12}>
            <Flex pt={2} justifyContent='space-between' alignItems='flex-start'>
              <Hidden smUp>{renderMessage()}</Hidden>
              <Button variant='contained' color='primary' onClick={form.submit}>
                Join
              </Button>
            </Flex>
          </Grid>
        </Grid>
      </form.Form>
    ) : (
      <form.Form>
        <Grid container>
          <Grid item xs={12}>
            <Flex pt={2} justifyContent='space-between' alignItems='flex-start'>
              <Button variant='contained' color='primary' onClick={existingAccountForm.submit}>
                Join
              </Button>
            </Flex>
          </Grid>
        </Grid>
      </form.Form>
    )
  }

  const renderMessage = () => (
    <Flex flexDirection='column'>
      {messages.map(message => (
        <Flex key={message.text} alignItems='center'>
          {message.status === 'none' ? (
            <DotIcon className={ClassNames({ [classes.messageIcon]: true, [classes.indent]: message.indent })} />
          ) : (
            <SemanticIcon
              usage={message.status === 'success' ? iconUsages.check : iconUsages.close}
              className={ClassNames({
                [classes.messageIcon]: true,
                [classes.iconError]: message.status === 'error',
                [classes.iconSuccess]: message.status === 'success',
                [classes.indent]: message.indent,
              })}
            />
          )}

          <Typography>{message.text}</Typography>
        </Flex>
      ))}
    </Flex>
  )

  return (
    <PageTitleProvider>
      <FramedPage>
        <PageTitle title={parsedCreateAccount ? 'Create Account' : 'Join Organization'}>
          <Flex flexColumn p={2}>
            <WaitingForData isWaiting={isFetching || isAccepting}>
              {info.get('email') && (
                <Typography>
                  Email: <strong>{info.get('email')}</strong>
                </Typography>
              )}
              {completed && (
                <Flex pt={1} flexColumn>
                  {parsedCreateAccount && (
                    <Typography>
                      Your account was successfully created! Please login to begin using our platform.
                    </Typography>
                  )}
                  {!parsedCreateAccount && (
                    <Typography>
                      You have successfully joined the organization! Please login to begin using our platform.
                    </Typography>
                  )}
                  <Flex pt={3}>
                    <Button variant='contained' color='primary' onClick={() => push('/')}>
                      Login
                    </Button>
                  </Flex>
                </Flex>
              )}
              {!completed && !info.get('accepted') && (
                <Flex mt={1} flexColumn>
                  <Typography>
                    You&apos;ve been invited to join <strong>{info.get('orgName')}</strong>.
                  </Typography>
                  {renderForm()}
                </Flex>
              )}
              {info.get('accepted') && (
                <Flex pt={1} flexColumn>
                  <Typography>
                    This invite has already been accepted. Please contact{' '}
                    <a href='mailto:support@42chat.com'>support@42chat.com</a> if you believe this is an error.
                  </Typography>
                  <Flex pt={3}>
                    <Button variant='contained' color='primary' onClick={() => push('/')}>
                      Login
                    </Button>
                  </Flex>
                </Flex>
              )}
            </WaitingForData>
            <ToastAlert />
          </Flex>
        </PageTitle>
      </FramedPage>
    </PageTitleProvider>
  )
}

const useStyles = makeStyles(({ spacing, palette }) => ({
  messageIcon: {
    marginRight: spacing(1),
    width: 24,
    height: 24,
  },
  iconError: {
    color: palette.error.main,
  },
  iconSuccess: {
    color: palette.success.main,
  },
  indent: {
    marginRight: spacing(2),
  },
}))

export default Invitation
