import {
  iconButtonClasses,
  // buttonClasses,
  // buttonGroupClasses,
  ComponentsProps,
  ComponentsOverrides,
  Theme,
} from '@mui/material'
import { OverridesStyleRules } from '@mui/material/styles/overrides'

interface ButtonStyles {
  defaultProps: ComponentsProps['MuiButtonGroup']
  styleOverrides: {
    outlinedError?: OverridesStyleRules
  } & ComponentsOverrides<Theme>['MuiButtonGroup']
}

export const MuiButtonGroup = ({ palette }): ButtonStyles => {
  return {
    defaultProps: {},
    styleOverrides: {
      root: {
        [`&>.${iconButtonClasses.root}`]: {
          borderRadius: 0,
        },
        [`&>.${iconButtonClasses.root}:not(last-of-group)`]: {
          borderRightWidth: 0,
        },
        [`&>.${iconButtonClasses.root}:first-of-type`]: {
          borderTopLeftRadius: 4,
          borderBottomLeftRadius: 4,
        },
        [`&>.${iconButtonClasses.root}:last-of-type`]: {
          borderTopRightRadius: 4,
          borderBottomRightRadius: 4,
          borderRightWidth: 1,
        },
        // [`&>.${buttonGroupClasses.grouped}.${buttonClasses.sizeSmall}`]: {
        //   height: '24px',
        // },
      },
      groupedOutlinedPrimary: {
        '&:hover': {
          borderColor: palette.primary.dark,
        },
      },
    },
  }
}
