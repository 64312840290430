import { Components, Theme } from '@mui/material'

export const MuiDrawer = (): Components<Theme>['MuiDrawer'] => {
  return {
    defaultProps: {},
    styleOverrides: {
      paperAnchorLeft: {
        borderWidth: 0,
      },
    },
  }
}
