import { handleActions, combineActions } from 'redux-actions'
import { fromJS, Map } from 'immutable'
import { actions } from './actionCreators'
import paths from './paths'
import { serializeStateToLocalStorage, deserializeStateFromLocalStorage } from '../storageHelpers-immutable'
import { shoppingCartKey } from './keys'

import { arrayToMap, basicCompleteReducer, basicFailReducer, basicStartReducer } from '../redux-helpers-immutable'

export const initialize = () => {
  let root = Map()

  const cart = deserializeStateFromLocalStorage(shoppingCartKey) || Map()
  if (cart.size > 0) {
    root = root.setIn(paths.shoppingCart.Id(), cart.get('cartId'))
  }

  return root
}

export default handleActions(
  {
    [combineActions(
      actions.loadBotSurveys.start,
      actions.loadBotSurvey.start,
      actions.loadBotSurveyResults.start,
      actions.saveSurveyResults.start,
      actions.loadQualifierSurvey.start,
      actions.deleteBotSurveyResult.start,
      actions.setQualifiedTemplates.start,
      actions.chooseBotSurvey.start,
      actions.finalizeCustomBotCart.start,
    )]: basicStartReducer,

    [combineActions(
      actions.loadBotSurveys.fail,
      actions.loadBotSurvey.fail,
      actions.loadBotSurveyResults.fail,
      actions.saveSurveyResults.fail,
      actions.loadQualifierSurvey.fail,
      actions.deleteBotSurveyResult.fail,
      actions.applyPromoCode.fail,
      actions.deletePromoCode.fail,
      actions.deleteProduct.fail,
      actions.setQualifiedTemplates.fail,
      actions.chooseBotSurvey.fail,
      actions.finalizeCustomBotCart.fail,
    )]: basicFailReducer,

    [actions.loadBotSurveys.complete]: (state, { payload: { templates } }) =>
      basicCompleteReducer(state).setIn(paths.templates(), arrayToMap(templates, 'templateId')),

    [actions.loadBotSurvey.complete]: (state, { payload: { survey } }) =>
      basicCompleteReducer(state).setIn(paths.survey(survey.templateId), fromJS(survey)),

    [actions.loadBotSurveyResults.complete]: (state, { payload: { orgId, results } }) =>
      basicCompleteReducer(state).setIn(paths.surveyResultsList(orgId), arrayToMap(results, 'id')),

    [actions.saveSurveyResults.complete]: (state, { payload: { survey } }) =>
      basicCompleteReducer(state).setIn(paths.surveyResults(survey.orgId, survey.id), fromJS(survey)),

    [actions.loadQualifierSurvey.complete]: (state, { payload: { survey } }) =>
      basicCompleteReducer(state).setIn(paths.qualifierSurveysBySlug(survey.slug), fromJS(survey)),

    [actions.deleteBotSurveyResult.complete]: (state, { payload: { orgId, resultId } }) =>
      basicCompleteReducer(state).deleteIn(paths.surveyResults(orgId, resultId)),

    [combineActions(
      actions.saveShoppingCart.complete,
      actions.loadShoppingCart.complete,
      actions.setQualifiedTemplates.complete,
      actions.chooseBotSurvey.complete,
    )]: (state, { payload: { cart } }) => {
      serializeStateToLocalStorage(shoppingCartKey, fromJS({ cartId: cart.id }))
      return basicCompleteReducer(state).setIn(paths.shoppingCart._root(), transformCart(cart))
    },

    [actions.saveShoppingCartId.complete]: (state, { payload: { cart } }) => {
      serializeStateToLocalStorage(shoppingCartKey, fromJS({ cartId: cart.id }))
      return state
    },

    [combineActions(
      actions.applyPromoCode.complete,
      actions.deletePromoCode.complete,
      actions.deleteProduct.complete,
      actions.updateProduct.complete,
    )]: (state, { payload: { cart } }) => {
      serializeStateToLocalStorage(shoppingCartKey, fromJS({ cartId: cart.id }))
      return basicCompleteReducer(state).setIn(paths.shoppingCart._root(), transformCart(cart))
    },

    [actions.getProductsForCart]: (state, { payload: { cart } }) => {
      return basicCompleteReducer(state).mergeIn(paths.shoppingCart._root(), transformCart(cart))
    },

    [actions.promoCodeMessage]: (state, { payload: { message } }) => state.setIn(paths.promoCodeMessage(), message),

    [actions.finalizeCustomBotCart.complete]: state => {
      serializeStateToLocalStorage(shoppingCartKey, fromJS({}))
      return basicCompleteReducer(state).deleteIn(paths.shoppingCart._root())
    },

    [actions.clearCart]: state => {
      serializeStateToLocalStorage(shoppingCartKey, fromJS({}))
      return state.deleteIn(paths.shoppingCart._root())
    },
  },
  initialize(),
)

const transformCart = cart => {
  const facets = arrayToMap(cart.facetList || [], 'name')
  return fromJS(cart).delete('facetList').set('facets', facets)
}
