import { createActions } from 'redux-actions'

const actionCreators = createActions({
  shared: {
    pinColumn: (column, branch) => ({ column, branch }),
    adjustColumn: (column, delta, branch) => ({ column, delta, branch }),
    gridSort: (column, direction, branch) => ({ column, direction, branch }),
  },
})

const actions = actionCreators.shared
export default actions
