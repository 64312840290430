import resultPaths from '../surveys/paths'

export default {
  events: orgId => ['org', orgId, 'eventId'],
  event: (orgId, eventId) => ['org', orgId, 'eventId', eventId],
  filter: () => ['filter'],
  botSurveyResults: () => ['botSurveyResults'],
  provisionMessages: (orgId, surveyResultsId) => [
    'provisionMessages',
    'org',
    String(orgId),
    String(surveyResultsId),
  ],
  eventPaths: {
    eventName: () => ['name'],
    botLiveDate: () => ['botLiveDate'],
    botDoneDate: () => ['botDoneDate'],
    eventStartDate: () => ['eventStartDate'],
    eventEndDate: () => ['eventEndDate'],
    timezone: () => ['timezone'],
    resultStartDate: () => ['result', ...resultPaths.surveyResultsPaths.eventStartDate()],
    venueName: () => ['result', ...resultPaths.surveyResultsPaths.venueName()],
    city: () => ['result', ...resultPaths.surveyResultsPaths.city()],
    state: () => ['result', ...resultPaths.surveyResultsPaths.state()],
    completed: () => ['result', ...resultPaths.surveyResultsPaths.completed()],
    resultOrgId: () => ['result', ...resultPaths.surveyResultsPaths.orgId()],
    templateId: () => ['result', ...resultPaths.surveyResultsPaths.templateId()],
    resultId: () => ['result', ...resultPaths.surveyResultsPaths.resultId()],
    phoneNumbers: {
      array: () => ['phoneNumbers'],
      phoneNumber: () => ['phoneNumber'],
    },
    status: () => ['meta', 'status'],
  },
}
