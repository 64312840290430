import React from 'react'
import { Flex } from 'mui-blox'
import { Snackbar, Typography, Button, Paper } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { SemanticIcon, iconUsages } from '../_controls'
import { useVersionCheck } from './useVersionCheck'

const AppVersionCheck = () => {
  const classes = useStyles()
  const { changed, currentVersion, newVersion, refresh } = useVersionCheck()

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={changed}
    >
      <Paper elevation={4} className={classes.paper}>
        <Flex flexDirection='column' p={2}>
          <Flex mb={1}>
            <SemanticIcon usage={iconUsages.alert} className={classes.icon} />
            {newVersion === 'disconnected' ? (
              <Typography>Connection was lost. Please refresh.</Typography>
            ) : (
              <Flex flexDirection='column'>
                <Flex justifyContent='space-between'>
                  <Typography>A new version is available&nbsp;</Typography>
                  <Typography>{newVersion}</Typography>
                </Flex>
                <Flex justifyContent='space-between'>
                  <Typography>Your version is</Typography>
                  <Typography>{currentVersion}</Typography>
                </Flex>
              </Flex>
            )}
          </Flex>
          <Button color='primary' variant='contained' onClick={refresh}>
            Refresh Application
          </Button>
        </Flex>
      </Paper>
    </Snackbar>
  )
}

const useStyles = makeStyles(({ palette, spacing, mixins: { emphasize, border } }) => ({
  paper: {
    border: border(1, emphasize(palette.background.paper, 0.2)),
  },
  icon: {
    color: palette.error.main,
    marginRight: spacing(1),
  },
}))

export default AppVersionCheck
