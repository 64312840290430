const scopes = require('./scopes')

function scopedToEither(user, orgId, allowedScopes) {
  const userResult = scopes.hasUserScope(user, allowedScopes)
  const orgResult = scopes.hasOrgScope(user, orgId, allowedScopes)
  // console.log(`scopedToOrg orgId: ${orgId} - has *user* scope for ${allowedScopes}: ${userResult}`);
  // console.log(`scopedToOrg orgId: ${orgId} - has *org* scope for ${allowedScopes}: ${orgResult}`);
  return userResult || orgResult
}

function scopedToUser(user, allowedScopes) {
  var result = scopes.hasUserScope(user, allowedScopes)
  // console.log(`scopedToUser - has user scope for ${allowedScopes}: ${result}`);
  return result
}

export default {
  org: (user, orgId, allowedScopes) => scopedToEither(user, orgId, allowedScopes),
  user: (user, allowedScopes) => scopedToUser(user, allowedScopes),
}
