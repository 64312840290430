import React from 'react'
import PropTypes from 'prop-types'
import { SuspenseRoute, lazyRetry } from '../react/routing'

const ShoppingCart = lazyRetry(() =>
  import(
    /* webpackChunkName: "shoppingCart" */
    './Home'
  ),
)

const ShoppingCartRoot = ({ path }) => {
  return <SuspenseRoute path={path} component={ShoppingCart} />
}

ShoppingCartRoot.propTypes = {
  path: PropTypes.string.isRequired,
}

export default ShoppingCartRoot
