import { Components, Theme, fabClasses } from '@mui/material'

export const MuiFab = (): Components<Theme>['MuiFab'] => {
  return {
    defaultProps: {},
    styleOverrides: {
      sizeSmall: {
        height: 34,
        minHeight: 34,
        width: 34,
        minWidth: 34,
        '& svg': {
          height: 20,
          minHeight: 20,
          width: 20,
          minWidth: 20,
        },
      },
      root: {
        textTransform: 'none',
      },
      extended: {
        [`&.${fabClasses.sizeSmall}`]: {
          width: 'auto',
        },
      },
    },
  }
}
