import { Socket } from 'socket.io-client'
import { useEffect } from 'react'
import { ANONYMOUS_SPACES } from '~constants'
import { logger } from '../../../utils/logger'
import { useSocketConnection } from './useSocketConnection'
import { useAuthUser } from '../../auth/useAuthUser'
import { SocketConnection } from '../types'

export const useSocketRoom = ({ namespace, room }: SocketConnection): Socket | undefined => {
  const { user } = useAuthUser()

  let token: string | undefined

  if (!ANONYMOUS_SPACES.includes(namespace)) {
    token = user.get('userId') as string
  }

  const socket = useSocketConnection(namespace, token)

  // room management
  useEffect(() => {
    if (socket && room) {
      const join = () => {
        logger(`joining ${namespace}:${room}`)
        socket.emit('join', { room })
      }
      const leave = () => {
        if (socket) {
          logger(`leaving ${namespace}:${room}`)
          socket.emit('leave', { room })
        }
      }

      if (socket?.connected) {
        join()
      }
      socket.on('connect', () => join())
      socket.on('disconnect', () => leave())

      return () => {
        leave()
      }
    }
  }, [socket, room, namespace])

  return socket
}
