//  Basically
//{
//  summaries: {
//    'eventId': summaries,
//  },
//  results: {
//    'trainingId': results
//  }
//}

export default {
  summaries: eventId => ['summaries', String(eventId)],
  results: trainingId => ['results', String(trainingId), 'result'],
  result: (trainingId, trainingResultId) => ['results', String(trainingId), 'result', String(trainingResultId)],
  context: key => ['context', key],
}
