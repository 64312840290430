import React from 'react'
import PropTypes from 'prop-types'
import { useTransition, animated, config } from 'react-spring'

export const Transition = React.forwardRef(function Transition(
  { in: open, children, onEnter, onExited, onRest, ...other },
  ref,
) {
  const spring = React.useMemo(() => {
    return {
      from: { opacity: 0, transform: 'translateY(100px)' },
      enter: currentlyOpen => async next => {
        if (currentlyOpen && onEnter) {
          onEnter()
        }
        await next({ opacity: 1, transform: 'translateY(0px)', onRest: onRest && onRest() })
        if (!currentlyOpen && onExited) {
          onExited()
        }
      },
      leave: currentlyOpen => async (next /*, cancel*/) => {
        await next({ opacity: 0, transform: 'translateY(50px)' })
        if (currentlyOpen && onExited) {
          //onExited();
        }
      },
      config: { ...config.stiff, tension: 300, clamp: true },
    }
  }, [onEnter, onExited])
  const transitions = useTransition(open, null, spring)

  return (
    <div className='transitional transition-root' ref={ref} {...other}>
      {transitions.map(({ item, key, props }) => {
        return (
          item && (
            <animated.div className='transitional' key={key} style={props}>
              {children}
            </animated.div>
          )
        )
      })}
    </div>
  )
})

Transition.propTypes = {
  children: PropTypes.element,
  in: PropTypes.bool,
  onEnter: PropTypes.func,
  onExited: PropTypes.func,
  onRest: PropTypes.func,
}
