import { createTheme, Theme } from '@mui/material'
import { createSpacing } from '@mui/system'
import { getPalette } from './palette'
import { getLayout } from './spacing'
import { getTypography } from './typography'
import * as mixins from './mixins'
import { getComponents } from './components'

declare module '@mui/styles' {
  interface DefaultTheme extends Theme {
    layout?: Record<string, unknown>
  }
}

export const getTheme = (type = themes.basic, customPalette = {}) => {
  const { palette, hue } = getPalette(type, customPalette)

  const layout = getLayout()
  const typography = getTypography(type, palette, mixins)

  const theme = createTheme({
    palette,
    components: getComponents(type, { hue, palette, typography, spacing: createSpacing(8) }),
    layout,
    typography,
    mixins,
  })

  if (process.env.NODE_ENV === 'development' && window) {
    window['theme'] = theme
  }

  return theme
}

export const themes = {
  basic: 'basic',
  customer: 'customer',
  osh: 'osh',
}
