import React from 'react'
import { Flex } from 'mui-blox'
import { Typography } from '@mui/material'
import Robot from '../../../images/Robot.png'

const MiniErrorDisplay = () => {
  return (
    <Flex alignItems='center'>
      <Typography>Oops!</Typography>
      <img src={Robot} style={{ maxHeight: 38, marginLeft: '3px' }} />
    </Flex>
  )
}

export default MiniErrorDisplay
