import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

/**
 * Be careful using this hook. Iterating breakpoints in this manner only works if the number of
 * breakpoints in theme is static. See https://reactjs.org/docs/hooks-rules.html#only-call-hooks-at-the-top-level
 */
export const useWidth = () => {
  const theme = useTheme()
  const keys = [...theme.breakpoints.keys].reverse()

  const width =
    keys.reduce((output, key) => {
      const matches = useMediaQuery(theme.breakpoints.up(key))
      return !output && matches ? key : output
    }, null) || 'xs'

  return { width }
}
