import React, { FC, useRef, useState } from 'react'
import { Portal } from '@mui/material'
import { MessengerButton } from './MessengerButton'
import { MessengerPopper } from './MessengerPopper'

export interface MessengerFloaterProps {
  id: string
  eventId: string
  onOpen?: (open: boolean) => void
  ButtonStyle?: Record<string, unknown>
  MessengerStyle?: Record<string, unknown>
  omitClosedState?: boolean
}

export const MessengerFloater: FC<MessengerFloaterProps> = ({
  id,
  eventId,
  onOpen,
  ButtonStyle,
  MessengerStyle,
  omitClosedState,
}) => {
  const buttonRef = useRef<HTMLDivElement>(null)

  const [open, setOpen] = useState(false)
  const handleButtonClick = () => {
    setOpen(curr => {
      const open = !curr

      onOpen && onOpen(open)

      // const height = open ? '700px' : '56px'
      // document.body.style.height = height
      return open
    })
  }

  return (
    <Portal>
      <MessengerButton
        style={ButtonStyle}
        id={id}
        onClick={handleButtonClick}
        ref={buttonRef}
        open={open}
        omitClosedState={omitClosedState}
      />
      <MessengerPopper
        style={MessengerStyle}
        id={id}
        open={open}
        eventId={eventId}
        anchorEl={buttonRef.current}
        onClose={handleButtonClick}
      />
    </Portal>
  )
}
