import { amber } from '@mui/material/colors'
import deepmerge from 'deepmerge'
import grey from '../colors/grey'
import light from './light'

export const customer = deepmerge(light, {
  background: {
    default: 'rgba(246, 246, 246, 1)',
    paper: 'rgba(255, 255, 255, 1)',
  },
  primary: {
    main: '#8BC63F',
    contrastText: '#fff',
  },
  secondary: {
    main: 'rgba(76, 76, 76, 1)',
    contrastText: '#EAEAEA',
  },
  tertiary: {
    main: '#0C1F44',
  },
  text: {
    primary: '#0C1F44',
    secondary: '#5B617B',
  },
  status: {
    incomplete: '#DA423A',
    warn: '#FACE91',
    provisioning: '#4183C4',
    upcoming: '#4183C4',
    current: '#43a047',
    warning: amber[400],
    past: grey[300],
    decommissioned: grey[300],
  },
})
