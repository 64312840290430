import React, { useState } from 'react'
import { BreadcrumbContext } from './hooks/useBreadcrumbContext'

const BreadcrumbProvider = ({ children }) => {
  const [breadcrumbs, setBreadcrumbs] = useState()
  const [history, setHistory] = useState()

  return (
    <BreadcrumbContext.Provider value={{ breadcrumbs, setBreadcrumbs, history, setHistory }}>
      {children}
    </BreadcrumbContext.Provider>
  )
}

export default BreadcrumbProvider
