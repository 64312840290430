import React from 'react'
import { useParams } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import { BrandingProvider } from '~brix/connectors/context/useEventBranding'
import { exconEventId } from './state/exconEventId'

export const ExconBrandingProvider = ({ children }) => {
  const params = useParams<{ eventId?: string }>()
  const eventId = useRecoilValue(exconEventId)

  return <BrandingProvider eventId={eventId || params.eventId || ''}>{children}</BrandingProvider>
}
