import React, { useState, PropsWithChildren, useEffect } from 'react'
import PropTypes from 'prop-types'
import { GlobalStyles, ThemeProvider as MuiThemeProvider } from '@mui/material'
import { getTheme } from '.'
import { SetThemeContext } from './useSetTheme'
import { getGlobalStyles } from './getGlobalStyles'

export interface ThemeProviderProps {
  themeName?: string
}

const ThemeProvider = ({ themeName = 'basic', children }: PropsWithChildren<ThemeProviderProps>) => {
  const [currentThemeName, setTheme] = useState<string>(() => themeName)
  const resolvedTheme = getTheme(currentThemeName)

  useEffect(() => {
    setTheme(themeName)
  }, [themeName, setTheme])

  return (
    <SetThemeContext.Provider value={setTheme}>
      <MuiThemeProvider theme={resolvedTheme}>
        <GlobalStyles styles={getGlobalStyles(resolvedTheme)} />
        {children}
      </MuiThemeProvider>
    </SetThemeContext.Provider>
  )
}

ThemeProvider.propTypes = {
  themeName: PropTypes.string,
}

ThemeProvider.defaultProps = {
  theme: undefined,
}

export default ThemeProvider
