export default {
  participants: eventId => ['event', String(eventId), 'participants'],
  participantSearchIds: eventId => ['event', String(eventId), 'searchIds'],
  filterableFields: eventId => ['event', String(eventId), 'filterableFields'],
  customFieldKeys: eventId => ['event', String(eventId), 'customFieldKeys'],
  participantSingle: (eventId, participantId) => ['event', String(eventId), 'participants', String(participantId)],
  filter: () => ['context', 'filter'],
  uploadPercent: () => ['context', 'upload'],
  pageIndex: () => ['context', 'pageIndex'],
  pagingComplete: () => ['context', 'pagingComplete'],
}
