import { Map, fromJS } from 'immutable'
import { combineActions, handleActions } from 'redux-actions'
import { actions } from './actionCreators'
import sharedActions from '../shared/actions'
import { adjustColumnReducer, reduceAndSaveToLocalStorage } from '../shared/reducers'
import { deserializeStateFromLocalStorage, serializeStateToLocalStorage } from '../storageHelpers'
import * as clock from '../../../core/util/clock'
import {
  basicCompleteReducer,
  basicFailReducer,
  basicLoadCompleteReducer,
  basicStartReducer,
  basicState,
  filterClearReducer,
  filterSearchReducer,
  filterState,
  gridSortState,
  gridPinReducer,
  modalState,
  modalShowReducer,
} from '../redux-helpers'
import paths from './paths'

function childModalShowReducer(state, action) {
  return {
    ...state,
    error: undefined,
    childModal: {
      data: action.payload,
      visible: true,
    },
  }
}

export function childModalState() {
  return {
    data: undefined,
    visible: false,
  }
}

const storagePath = 'notifications'

const initialState = {
  ...basicState(),
  byId: {},
  byEvent: {},
  byOrg: {},
  filter: filterState(),
  gridSort: gridSortState(),
  modal: modalState(),
  childModal: childModalState(),
  columnWidths: {},
  ...deserializeStateFromLocalStorage(storagePath),
}

function normalizeNotification(notification) {
  return {
    ...notification,
    isRecurring: notification.recurring === true ? 'Yes' : 'No',
    childNotifications:
      notification.childNotifications == null
        ? []
        : notification.childNotifications.map(n => normalizeNotification(n)),
    customFilter: notification.customFilter || [],
    fullAudienceFilter: buildFullAudienceFilter(notification),
    scheduleTimeLuxon: clock.fromISO(notification.scheduleTime, notification.timezone),
  }
}

function buildFullAudienceFilter(notification) {
  let audience = []

  audience.push(notification.targetAudience)
  if (notification.customFilter && notification.customFilter.length > 0) {
    let joinText = ''
    for (const filter of notification.customFilter) {
      audience.push(`${joinText}${filter.column} ${filter.operator} ${filter.value.join(', ')}`)
    }
  }
  return audience
}

export function childModalHideReducer(state) {
  return {
    ...state,
    childModal: {
      data: undefined,
      visible: false,
    },
  }
}

// function getScheduleTime(notification) {
// if (notification.scheduleTime === 'now' || notification.scheduleTime === null) {
//     if (notification.timeSent != null) {
//       return notification.timeSent
//     }
//   }
//   return notification.scheduleTime
// }

// function getScheduleEndTime(notification) {
//   if (notification.scheduleEndTime === 'now') {
//     if (notification.timeSent != null) {
//       return notification.timeSent
//     }
//     else {
//       return new Date()
//     }
//   }
//   else {
//     return notification.scheduleEndTime
//   }
// }

export default handleActions(
  {
    // xxx.start
    [combineActions(actions.create.start, actions.delete.start, actions.load.start, actions.update.start)]:
      basicStartReducer,

    // xxx.fail
    [combineActions(
      actions.create.fail,
      actions.delete.fail,
      actions.load.fail,
      actions.update.fail,
      actions.audience.fail,
    )]: basicFailReducer,

    // create
    [actions.create.complete]: (state, action) => {
      const { payload } = action
      const { notification } = payload

      const byId = {
        ...state.byId,
        [notification.id]: normalizeNotification(notification),
      }

      const byEvent = {
        ...state.byEvent,
        [notification.eventId]: [...(state.byEvent[notification.eventId] || []), notification.id],
      }

      const byOrg = {
        ...state.byOrg,
        [notification.orgId]: [...(state.byOrg[notification.orgId] || []), notification.id],
      }

      return {
        ...basicCompleteReducer(state, action),
        byEvent,
        byId,
        byOrg,
      }
    },

    // delete
    [actions.delete.complete]: (state, action) => {
      const { eventId, orgId } = action.meta
      const { notificationId } = action.payload

      const byId = {
        ...state.byId,
      }
      delete byId[notificationId]

      const byEvent = {
        ...state.byEvent,
        [eventId]: state.byEvent[eventId].filter(id => id !== notificationId),
      }

      const byOrg = {
        ...state.byOrg,
        [orgId]: state.byOrg[orgId].filter(id => id !== notificationId),
      }

      return {
        ...basicCompleteReducer(state, action),
        byEvent,
        byId,
        byOrg,
      }
    },

    // filter
    [actions.filter.clear]: filterClearReducer,
    [actions.filter.search]: filterSearchReducer(n => n.message),

    // load
    [actions.load.complete]: (state, action) => {
      const { eventId, orgId } = action.meta
      const { notifications } = action.payload

      let byId = { ...state.byId }
      ;(state.byEvent[eventId] || []).forEach(id => delete byId[id])
      byId = notifications.reduce((acc, current) => {
        acc[current.id] = normalizeNotification(current)
        return acc
      }, byId)

      let byEvent = notifications.reduce((acc, current) => {
        if (!acc[current.eventId]) {
          acc[current.eventId] = []
        }
        acc[current.eventId] = acc[current.eventId].concat(current.id)
        return acc
      }, {})

      let byOrg = [...(state.byOrg[orgId] || [])]
      notifications.forEach(n => {
        if (!byOrg.includes(n.id)) {
          byOrg = byOrg.concat(n.id)
        }
      })

      return {
        ...basicLoadCompleteReducer(state, action),
        byId,
        byEvent: {
          ...state.byEvent,
          ...byEvent,
        },
        byOrg: {
          ...state.byOrg,
          [orgId]: byOrg,
        },
      }
    },

    // gridSort
    [actions.gridSort.sort]: (state, action) => {
      return {
        ...state,
        gridSort: action.payload,
      }
    },

    // pinColumn
    [sharedActions.pinColumn]: (state, { payload }) => {
      if (payload.branch === storagePath) {
        const nextState = gridPinReducer(state, payload)
        serializeStateToLocalStorage(
          {
            pinnedColumns: nextState.pinnedColumns,
          },
          storagePath,
        )
        return nextState
      }
      return state
    },

    [sharedActions.adjustColumn]: (state, { payload }) => {
      return reduceAndSaveToLocalStorage(state, payload, 'columnWidths', storagePath, adjustColumnReducer)
    },

    [actions.clearAudience]: (state, { payload: { orgId, eventId, targetAudience } }) => {
      const audience = state.audience || Map()
      return {
        ...state,
        audience: audience.deleteIn(paths.audienceRoot(orgId, eventId, targetAudience)),
      }
    },

    // audience count
    [actions.audience.complete]: (state, { payload, meta: { orgId, eventId, targetAudience, participantType } }) => {
      const audience = state.audience || Map()

      return {
        ...state,
        audience: audience.setIn(paths.audience(orgId, eventId, targetAudience, participantType), fromJS(payload)),
      }
    },

    // modal
    [actions.modal.hide]: state => {
      return {
        ...state,
        modal: {
          data: undefined,
          visible: false,
        },
      }
    },

    [actions.modal.show]: modalShowReducer,
    [actions.childModal.hide]: childModalHideReducer,
    [actions.childModal.show]: childModalShowReducer,

    // update
    [actions.update.complete]: (state, action) => {
      const notification = action.payload

      const byId = {
        ...state.byId,
        [notification.id]: normalizeNotification(notification),
      }

      return {
        ...basicLoadCompleteReducer(state, action),
        byId,
      }
    },
  },
  initialState,
)
