import { combineActions, handleActions } from 'redux-actions'
import { actions } from './actionCreators'
import sharedActions from '../shared/actions'
import { deserializeStateFromLocalStorage, serializeStateToLocalStorage } from '../storageHelpers'
import {
  basicState,
  basicCompleteReducer,
  basicLoadCompleteReducer,
  basicFailReducer,
  basicStartReducer,
  gridSortState,
  gridPinReducer,
  modalHideReducer,
  modalShowReducer,
  modalState,
} from '../redux-helpers'

const storagePath = 'organizations'

const initialState = {
  ...basicState(),
  byId: {},
  gridSort: gridSortState(),
  modal: modalState(),
  seriesModal: modalState(),
  ...deserializeStateFromLocalStorage(storagePath),
}

export default handleActions(
  {
    // xxx.start
    [combineActions(actions.create.start, actions.delete.start, actions.load.start, actions.update.start)]:
      basicStartReducer,

    // xxx.fail
    [combineActions(actions.create.fail, actions.delete.fail, actions.load.fail, actions.update.fail)]:
      basicFailReducer,

    // create
    [actions.create.complete]: (state, action) => {
      const { org } = action.payload
      const byId = {
        ...state.byId,
        [org.orgId]: org,
      }

      return {
        ...basicCompleteReducer(state, action),
        byId,
      }
    },

    // delete
    [actions.delete.complete]: (state, action) => {
      const { orgId } = action.payload

      const byId = {
        ...state.byId,
      }
      delete byId[orgId]

      return {
        ...basicCompleteReducer(state, action),
        byId,
      }
    },

    // gridSort
    [actions.gridSort.sort]: (state, action) => {
      return {
        ...state,
        gridSort: action.payload,
      }
    },

    // pinColumn
    [sharedActions.pinColumn]: (state, { payload }) => {
      if (payload.branch === storagePath) {
        const nextState = gridPinReducer(state, payload)
        serializeStateToLocalStorage(
          {
            pinnedColumns: nextState.pinnedColumns,
          },
          storagePath,
        )
        return nextState
      }
      return state
    },

    // load
    [actions.load.complete]: (state, action) => {
      const { orgs } = action.payload

      const byId = orgs.reduce((acc, current) => {
        acc[current.orgId] = current
        return acc
      }, {})

      return {
        ...basicLoadCompleteReducer(state, action),
        byId,
      }
    },

    // modal
    [actions.modal.hide]: modalHideReducer,
    [actions.modal.show]: modalShowReducer,
    [actions.seriesModal.hide]: state => {
      return {
        ...state,
        seriesModal: {
          data: undefined,
          visible: false,
        },
      }
    },

    [actions.seriesModal.show]: (state, action) => {
      return {
        ...state,
        error: undefined,
        seriesModal: {
          data: action.payload,
          visible: true,
        },
      }
    },

    // update
    [actions.update.complete]: (state, action) => {
      const org = action.payload

      const byId = {
        ...state.byId,
        [org.orgId]: {
          ...org,
        },
      }

      return {
        ...basicLoadCompleteReducer(state, action),
        byId,
      }
    },

    [actions.filterText]: (state, action) => {
      return {
        ...state,
        filterText: action.payload,
      }
    },
  },
  initialState,
)
