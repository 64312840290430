import React from 'react'
import { Suspense, lazyRetry } from '../routing'

const Routes = lazyRetry(() =>
  import(
    /* webpackChunkName: "OrderRoot" */
    './OrderRoutes'
  ),
)

const Root = () => {
  return (
    <Suspense>
      <Routes />
    </Suspense>
  )
}

export default Root
