import { Components, Theme } from '@mui/material'
import { ComponentOptions } from './types'

export const MuiSnackbarContent = ({ palette }: ComponentOptions): Components<Theme>['MuiSnackbarContent'] => {
  return {
    defaultProps: {},
    styleOverrides: {
      root: {
        backgroundColor: palette.background?.paper,
        color: palette.text?.primary,
      },
    },
  }
}
