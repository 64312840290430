import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { ButtonGroup, Button } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { SemanticIcon, iconUsages } from '../SemanticIcon'
import { useBreadcrumbContext } from './hooks/useBreadcrumbContext'
import classNames from 'classnames'

const BreadcrumbButtons = ({ className, allowBack, customGoBackHandler, ...rest }) => {
  const classes = useStyles()
  const history = useHistory()

  const handleGoBack = customGoBackHandler || history.goBack

  const breadcrumbs = useBreadcrumbContext()

  const showBreadcrumb = useMemo(() => {
    return breadcrumbs.size > 0 && breadcrumbs.peekDifferent() && breadcrumbs.peek() !== history.location.pathname
  }, [breadcrumbs, history.location.pathname])

  const buttonClasses = {
    contained: classes.button,
    label: classes.label,
  }

  return (
    <ButtonGroup variant='contained' size='small' className={classNames(classes.group, className)} {...rest}>
      {showBreadcrumb && (
        <Button classes={buttonClasses} onClick={breadcrumbs.goBack}>
          <SemanticIcon usage={iconUsages.navigateBreadcrumb} />
        </Button>
      )}
      {allowBack && (
        <Button classes={buttonClasses} onClick={handleGoBack}>
          <SemanticIcon usage={iconUsages.expandLeft} />
        </Button>
      )}
    </ButtonGroup>
  )
}

BreadcrumbButtons.propTypes = {
  allowBack: PropTypes.bool,
  customGoBackHandler: PropTypes.func,
}

BreadcrumbButtons.defaultProps = {
  allowBack: false,
  customGoBackHandler: undefined,
}

const useStyles = makeStyles(({ palette, mixins: { lighten } }) => ({
  group: {
    backgroundColor: lighten(palette.secondary.main, 0.2),
  },
  button: {
    backgroundColor: lighten(palette.secondary.main, 0.2),
    minWidth: 40,
  },
  label: {
    '& svg': {
      margin: 0,
      color: palette.getContrastText(palette.secondary.main),
    },
  },
}))

export default BreadcrumbButtons
