import { getAuthStorage } from './getAuthStorage'

export const setAuthStorage = (auth: Record<string, unknown>) => {
  const current = getAuthStorage()
  const data = { ...current, ...auth }

  localStorage.setItem('auth', JSON.stringify(data))

  return data
}
