import { fromJS } from 'immutable'
import { handleActions, combineActions } from 'redux-actions'
import { arrayToMap, gridSortReducer, modalHideReducer, modalShowReducer } from '../../redux-helpers-immutable'
import { gridSortState } from '../../redux-helpers'
import { actions } from '../actionCreators'
import { paths } from '../paths'

const getInitialState = () =>
  fromJS({
    gridSort: gridSortState(),
  })

export default handleActions(
  {
    [actions.promoCodes.load]: (state, { payload: { promoCodes } }) =>
      state.setIn(paths.promoCodes(), arrayToMap(promoCodes, 'id')),

    [actions.promoCodes.gridSort.sort]: gridSortReducer,
    [actions.promoCodes.modal.show]: modalShowReducer,
    [actions.promoCodes.modal.hide]: modalHideReducer,

    [combineActions(actions.promoCodes.create.complete, actions.promoCodes.update.complete)]: (
      state,
      { payload: { promoCode } },
    ) => state.setIn(paths.promoCode(promoCode.id), fromJS(promoCode)),

    [actions.promoCodes.delete.complete]: (state, { payload: { id } }) => {
      return state.deleteIn(paths.promoCode(id))
    },
  },
  getInitialState(),
)
