export const wellKnownVariables = {
  avatarUrl: 'avatar_url',
  botName: 'bot_name',
  directions: 'directions',
  eventName: 'event_name',
  locationAddress: 'location_address',
  venueName: 'venue_name',
  zipcode: 'zipcode',
}

export const wellKnownIntents = {
  idk: 'idk',
  initGreeting: 'init_greeting',
  mms: 'mms',
  optIn: 'Opt_In',
  optOut: 'Opt_Out',
  smallTalkGreetingsHello: 'smalltalk_greetings_hello',
}
