export const FORM_STATUS = {
  approved: 'approved',
  complete: 'complete',
  inReview: 'inReview',
  new: 'new',
  submitted: 'submitted',
  closed: 'closed',
} as const

export const FORM_STATUS_LABELS = {
  [FORM_STATUS.approved]: 'Approved',
  [FORM_STATUS.complete]: 'Complete',
  [FORM_STATUS.inReview]: 'In Review',
  [FORM_STATUS.new]: 'New',
  [FORM_STATUS.submitted]: 'Submitted',
  [FORM_STATUS.closed]: 'Closed',
}

export const FORM_ACTIONS = {
  triggeredEmailNotification: 'triggeredEmailNotification',
  triggeredNotification: 'triggeredNotification',
  triggeredNotificationSequence: 'triggeredNotificationSequence',
}
