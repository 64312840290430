const BotTesting = function (restClient) {
  this.restClient = restClient
}

BotTesting.prototype.postMessage = function ({ orgId, eventId, phoneNumber, content, channelId }) {
  return this.restClient.post(
    `/orgs/${orgId}/events/${eventId}/botTesting/${phoneNumber}/sendMessage/${channelId}`,
    { content },
  )
}

BotTesting.prototype.getAvailableNumbers = function ({ orgId, eventId, botId }) {
  return eventId
    ? this.restClient.get(`/orgs/${orgId}/events/${eventId}/botTesting/availableNumbers`)
    : this.restClient.get(`/orgs/${orgId}/bots/${botId}/botTesting/availableNumbers`)
}

export default BotTesting
