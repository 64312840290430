export const PHONE_NUMBER_TYPES = {
  shortCode: 'shortCode',
  longCode: 'longCode',
  tollFree: 'toll-free',
}

export const PHONE_NUMBER_STATUS = {
  claimed: 'claimed',
  pool: 'pool',
}

export const PHONE_NUMBER_EXTERNAL_STATUS = {
  active: 'active',
  pending: 'pending',
}

export const PHONE_NUMBER_PROVIDERS = {
  twilio: 'twilio',
  telnyx: 'telnyx',
}

export const PHONE_NUMBER_USAGE_TYPES = {
  bot: 'bot',
  testing: 'testing',
}

export const PHONE_NUMBER_DEPLOY_ACTIONS = {
  release: 'release',
  return: 'return',
}

export const PHONE_NUMBER_PROVISIONING_TYPES = {
  pool: 'pool',
  acquire: 'acquire',
}
