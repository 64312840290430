import React, { FC } from 'react'
import PropTypes from 'prop-types'
import { eventSettingsKeys, messengerVariants } from '~constants'
import { DataProvider } from '~controls'
import { MessengerBranderProps } from '../types'
import { BrandingProvider, useEventBranding } from '~brix/connectors/context/useEventBranding'
import { MessengerFramed } from './MessengerFramed'
import { MessengerFloater } from './MessengerFloater'
import { messengerMessages } from '~constants'
import { NoFrameMessenger } from './NoFrameMessenger'

const RenderMessenger = ({ children, eventId }) => {
  const { branding } = useEventBranding()

  if (branding.messengerEnabled) return children(branding)

  console.warn(`Messenger channel is not enabled for ${eventId}!`)

  return null
}

export const MessengerBrander: FC<MessengerBranderProps> = ({ eventId, variant, fallback = undefined }) => {
  const id = '42chat-chatbot'

  const handleOpenChange = open => {
    const type = open ? messengerMessages.messengerOpen : messengerMessages.messengerClose

    window.parent.window.postMessage({ type }, window.clientHost)
  }

  const renderContent = branding => {
    const selectedVariant = variant || branding.variant

    if (selectedVariant === messengerVariants.EMBEDDED) {
      return (
        <MessengerFramed eventId={eventId} frame={branding.showPhone ? 'phone' : ''} color={branding.phoneColor} />
      )
    } else if (selectedVariant === messengerVariants.NO_FRAME) {
      return <NoFrameMessenger eventId={eventId} />
    } else {
      return <MessengerFloater id={id} eventId={eventId} onOpen={handleOpenChange} />
    }
  }

  return (
    <BrandingProvider
      eventId={eventId}
      settingKey={eventSettingsKeys.chatbotBranding}
      fallback={fallback}
      disableGlobal
    >
      <RenderMessenger eventId={eventId}>
        {branding => {
          return (
            <DataProvider value={eventId} dataKey='eventId'>
              {renderContent(branding)}
            </DataProvider>
          )
        }}
      </RenderMessenger>
    </BrandingProvider>
  )
}

MessengerBrander.propTypes = {
  eventId: PropTypes.string.isRequired,
  variant: PropTypes.string,
  fallback: PropTypes.element,
}
