import React from 'react'
import PropTypes from 'prop-types'
import { AppBar, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import ClassNames from 'classnames'
import { Flex } from 'mui-blox'
import { COMPANY_TITLE } from '~constants'
import { useTriggeredScroll, useBreakpoint } from '~layout'
import Title from '../../page/Title'
import { useEventBranding } from '../../../brix/connectors/context/useEventBranding'
import Logo from '../logo/LogoLarge'
import { useSetPageTitle } from '../hooks/useSetPageTitle'

const TitleBar = React.forwardRef(
  ({ className, children, pageTitle, pageHeader, logoUrl, contentProps = {}, titleProps = {} }, ref) => {
    const classes = useStyles()

    const isSm = useBreakpoint('md').down

    const [hasScrolled] = useTriggeredScroll()
    const { branding, BRANDING_KEYS } = useEventBranding()
    const { pageSubtitle, tools } = useSetPageTitle()

    const resolvedTitle = pageTitle ? `${pageTitle} - ${COMPANY_TITLE}` : COMPANY_TITLE
    const resolvedHeader = branding[BRANDING_KEYS.title] || pageHeader || pageSubtitle
    const resolvedLogoUrl = branding[BRANDING_KEYS.logoUrl] || logoUrl

    const hasTools = React.Children.count(tools) > 0 || React.Children.count(children) > 0

    return (
      <AppBar
        ref={ref}
        color='secondary'
        position='static'
        elevation={hasScrolled ? 4 : 0}
        className={ClassNames(classes.root, /*{ [classes.rootLarge]: !isSm }, */ className)}
      >
        <Title render={resolvedTitle} />
        {resolvedLogoUrl ? (
          <img src={resolvedLogoUrl} className={ClassNames(classes.logo, classes.image)} alt='app-logo' />
        ) : (
          <Logo className={classes.logo} />
        )}
        <Flex alignItems='center' className={classes.content} {...contentProps}>
          <Flex flexDirection='column' alignItems='flex-end' mr={hasTools ? 1 : 0} {...titleProps}>
            <Typography color='inherit' variant={isSm ? 'h4' : 'h2'} component='h1'>
              {pageTitle}
            </Typography>
            {resolvedHeader && (
              <Typography variant='caption' align='right' color='inherit' className={classes.subTitle}>
                {resolvedHeader}
              </Typography>
            )}
          </Flex>
          {hasTools && (
            <Flex flexDirection='row' height='100%' alignItems='center'>
              {tools}
              {children}
            </Flex>
          )}
        </Flex>
      </AppBar>
    )
  },
)

TitleBar.propTypes = {
  pageTitle: PropTypes.string,
  titleProps: PropTypes.object,
  contentProps: PropTypes.object,
  pageHeader: PropTypes.string,
  logoUrl: PropTypes.string,
}

const useStyles = makeStyles(({ spacing, mixins: { rem }, layout, palette }) => ({
  root: {
    width: '100%',
    height: layout.topBarMedium,
    position: 'relative',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingRight: spacing(1),
    backgroundColor: palette.header?.main ?? palette.secondary.main,
    color: palette.header?.contrastText ?? palette.secondary.contrastText,
  },
  rootLarge: {
    height: layout.topBarLarge,
  },
  image: {
    maxHeight: spacing(6),
    maxWidth: spacing(22.5),
  },
  logo: {
    maxWidth: '50%',
    margin: spacing(1),
    alignSelf: 'center',
  },
  content: {
    marginRight: spacing(1),
  },
  subTitle: {
    fontSize: rem(0.7),
    lineHeight: 1,
  },
}))

export default TitleBar
