import { get } from './get'
import { post, patch, deleteIt } from './requestWithBody'
import { requestWithFile } from './requestWithFile'

export default {
  get,
  post,
  patch,
  delete: deleteIt,
  requestWithFile,
}
