import { Map, fromJS } from 'immutable'
import paths from '../paths'
import { loadIDKs, loadIDKsByEvent } from '../actions'
import { getDispatch } from '../..'
import { selectIsAdmin } from '../../auth/selectors'
import { getOrgId, getEventId } from './routes'
import {
  filterEscalated,
  filterUnresolved,
  filterUnhandled,
  sortByTimestamp,
  sortDirections,
  isForEvent,
  filterMarkedForRetro,
} from './helpers'
import { idks as filterIdks } from '../apiFilters'

const _getSystemIdks = state => state.support.getIn(paths.systemIdks())

const _getEventIdks = (state, orgId, eventId) => state.support.getIn(paths.idks(orgId, eventId))

const _getIdks = state => {
  const orgId = getOrgId(state)
  const eventId = getEventId(state)
  return isForEvent(state) ? _getEventIdks(state, orgId, eventId) : _getSystemIdks(state)
}

export const getIdksIsFetching = state => !Boolean(_getIdks(state))

const getIdksMap = (state, filter) => {
  const orgId = getOrgId(state)
  const eventId = getEventId(state)
  const isEvent = isForEvent(state)

  const idks = _getIdks(state)

  const count = isEvent
    ? state.support.getIn(paths.idkCount(orgId, eventId, filter))
    : state.support.getIn(paths.systemIdkCount(filter))

  if (!idks || count === undefined) {
    getDispatch()(isEvent ? loadIDKsByEvent(orgId, eventId, filter) : loadIDKs(filter))
    return Map()
  }

  return idks
}

export const getIdks = (state, filter) => {
  const idks = getIdksMap(state, filter)
  return sortByTimestamp(idks.toList(), sortDirections.desc)
}

export const getUnresolvedIdks = state => {
  const idks = getIdks(state, filterIdks.unresolved)
  return filterUnresolved(idks)
}

export const getUnhandledIdks = state => {
  const idks = getIdks(state, filterIdks.unhandled)
  return filterUnhandled(idks)
}

export const getEscalatedIdks = state => {
  const idks = getIdks(state, filterIdks.escalated)
  return filterEscalated(idks)
}

export const getMarkedForRetroIdks = state => {
  const idks = getIdks(state, filterIdks.markedForRetro)
  return filterMarkedForRetro(idks)
}

export const getIdk = (state, idkId) => {
  const idksMap = getIdksMap(state)
  return idksMap.get(idkId, Map())
}

export const getCount = (state, filter) => {
  const isEvent = isForEvent(state)
  const orgId = getOrgId(state)
  const eventId = getEventId(state)

  const count = isEvent
    ? state.support.getIn(paths.idkCount(orgId, eventId, filter))
    : state.support.getIn(paths.systemIdkCount(filter))

  if (count === undefined) {
    getDispatch()(isEvent ? loadIDKsByEvent(orgId, eventId, filter) : loadIDKs(filter))
    return 0
  }

  return count
}

export const getUnresolvedIdksCount = state => getCount(state, filterIdks.unresolved)

export const getUnhandledIdksCount = state => getCount(state, filterIdks.unhandled)

export const getEscalatedIdksCount = state => getCount(state, filterIdks.escalated)

export const getMarkedForRetroCount = state => getCount(state, filterIdks.markedForRetro)

export const getAllIdksCount = state => getCount(state)

export const getIdkCounts = state => {
  const isAdmin = selectIsAdmin(state)
  const unhandled = isAdmin
    ? [
        {
          label: 'Unhandled',
          count: getUnhandledIdksCount(state),
          filter: idkFilters.idksUnhandled.name,
        },
      ]
    : []

  return fromJS([
    {
      label: 'Unresolved',
      count: getUnresolvedIdksCount(state),
      filter: idkFilters.idksUnresolved.name,
    },
    {
      label: 'Escalated',
      count: getEscalatedIdksCount(state),
      filter: idkFilters.idksEscalated.name,
    },
    {
      label: 'Retro',
      count: getMarkedForRetroCount(state),
      filter: idkFilters.idksMarkedForRetro.name,
      eventOnly: true,
    },
    ...unhandled,
    {
      label: 'Total',
      count: getAllIdksCount(state),
      filter: idkFilters.idksAll.name,
    },
  ])
}

export const getSystemPageNumberByFilter = (state, filter) => state.support.getIn(paths.systemIdkPage(filter), 0)

export const getEventPageNumberByFilter = (state, orgId, eventId, filter) =>
  state.support.getIn(paths.idkPage(orgId, eventId, filter), 0)

export const idkFilters = {
  idksUnhandled: {
    name: 'idksUnhandled',
    apiFilter: filterIdks.unhandled,
    selector: getUnhandledIdks,
    isFetchingSelector: getIdksIsFetching,
    countSelector: getUnhandledIdksCount,
  },
  idksUnresolved: {
    name: 'idksUnresolved',
    apiFilter: filterIdks.unresolved,
    selector: getUnresolvedIdks,
    isFetchingSelector: getIdksIsFetching,
    countSelector: getUnresolvedIdksCount,
  },
  idksEscalated: {
    name: 'idksEscalated',
    apiFilter: filterIdks.escalated,
    selector: getEscalatedIdks,
    isFetchingSelector: getIdksIsFetching,
    countSelector: getEscalatedIdksCount,
  },
  idksMarkedForRetro: {
    name: 'idksMarkedForRetro',
    apiFilter: filterIdks.markedForRetro,
    selector: getMarkedForRetroIdks,
    isFetchingSelector: getIdksIsFetching,
    countSelector: getMarkedForRetroCount,
  },
  idksAll: {
    name: 'idksAll',
    apiFilter: filterIdks.all,
    selector: getIdks,
    isFetchingSelector: getIdksIsFetching,
    countSelector: getAllIdksCount,
  },
}

export const idkValidity = [{ key: 'Valid' }, { key: 'Invalid' }, { key: 'Out of Scope' }]
