import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import React from 'react'
import withStyles from '@mui/styles/withStyles'
import ClassNames from 'classnames'
import useScrollTrigger from '@mui/material/useScrollTrigger'
import { useTriggeredScroll } from '~layout'

const Scrollable = ({ classes, className, children, horizontal, vertical, stretch, ...rest }) => {
  const [target, setTarget] = useState()

  const [, setTriggered] = useTriggeredScroll()

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: target,
  })

  useEffect(() => {
    setTriggered(trigger)
  }, [setTriggered, trigger])

  return (
    <div
      ref={node => setTarget(node)}
      className={ClassNames({
        [classes.scrollable]: horizontal === vertical,
        [classes.vertical]: vertical,
        [classes.horizontal]: horizontal,
        [classes.stretch]: stretch,
        [className]: className,
      })}
      {...rest}
    >
      {children}
    </div>
  )
}

Scrollable.defaultProps = {
  horizontal: false,
  vertical: true,
  stretch: true,
}

Scrollable.propTypes = {
  horizontal: PropTypes.bool,
  vertical: PropTypes.bool,
  stretch: PropTypes.bool,
}

const styles = () => ({
  scrollable: {
    overflow: 'auto',
    marginTop: '1em',
    padding: 1,
  },
  horizontal: {
    overflowX: 'auto',
    overflowY: 'hidden',
  },
  vertical: {
    overflowX: 'hidden',
    overflowY: 'auto',
  },
  stretch: {
    flex: '1',
  },
})

export default withStyles(styles)(Scrollable)
