import React from 'react'
import { Suspense, lazyRetry } from './react/routing'

const Routes = lazyRetry(() =>
  import(
    /* webpackChunkName: "siteRoot" */
    './siteRoutes'
  ),
)

const SiteRoot = () => {
  return (
    <Suspense>
      <Routes />
    </Suspense>
  )
}

export default SiteRoot
