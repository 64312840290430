import React, { useCallback, useEffect, useState } from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import { CustomFormData, CustomFormProps, SaveParticipantsResponse } from './types'
import CustomField from './CustomField'
import { Flex } from 'mui-blox'
import { useQueryParams } from '../routing'
import { Box, Button, CircularProgress, Typography } from '@mui/material'
import { useCustomForms } from './hooks/useCustomForms'

const MIN_HEIGHT = 200

export const CustomForms = () => {
  const { formId } = useQueryParams()
  const { fetchForm, isLoading, saveParticipants } = useCustomForms({ formId })
  const [isComplete, setIsComplete] = useState(false)
  const methods = useForm<CustomFormProps>({ mode: 'onChange' })
  const { handleSubmit, watch, reset } = methods
  const [formData, setFormData] = useState<CustomFormData | null>(null)

  const hasGuest = watch('participant.hasGuest')

  const handleOnSaved = (result?: SaveParticipantsResponse) => {
    const status = result?.status || 0

    if (status >= 200 && status <= 299) {
      reset()
      setIsComplete(true)
    }
  }

  const onSubmit = (data: CustomFormProps) => saveParticipants({ payload: data, onSave: handleOnSaved })

  const handleOnFetchForm = useCallback((data?: CustomFormData) => {
    if (data && data.status >= 200 && data.status <= 299) {
      setFormData(data)
    }
  }, [])

  useEffect(() => {
    if (formId) {
      fetchForm(handleOnFetchForm)
    }
  }, [fetchForm, formId, handleOnFetchForm])

  if (isComplete) {
    return (
      <Box
        sx={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', height: MIN_HEIGHT }}
      >
        <Typography>Thanks for registering!</Typography>
      </Box>
    )
  }

  if (!formId) {
    return (
      <Box
        sx={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', height: MIN_HEIGHT }}
      >
        <Typography>No Form Id provided</Typography>
      </Box>
    )
  }

  if (isLoading) {
    return (
      <Box
        sx={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', height: MIN_HEIGHT }}
      >
        <CircularProgress />
      </Box>
    )
  }

  if (!formData) {
    return (
      <Box
        sx={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', height: MIN_HEIGHT }}
      >
        <Typography>Invalid Form Id</Typography>
      </Box>
    )
  }

  return (
    <div style={{ width: '100%', padding: 8 }}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Flex gap={1} flexColumn justifyContent='center' alignItems='center' width='100%'>
            <div style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '8px' }}>
              {formData?.data?.participantFields.map(field => (
                <CustomField {...field} key={field.name} prefix='participant' />
              ))}
            </div>
            {hasGuest && (
              <div style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '8px' }}>
                {formData?.data?.guestFields
                  .filter(field => field.name !== 'hasGuest')
                  .map(field => (
                    <CustomField {...field} key={field.name} prefix='guest' />
                  ))}
              </div>
            )}
            <Flex width='100%' marginTop={1}>
              <Button
                type='submit'
                size='small'
                color='info'
                variant='contained'
                style={{ textTransform: 'capitalize' }}
              >
                Submit
              </Button>
            </Flex>
          </Flex>
        </form>
      </FormProvider>
    </div>
  )
}

export default CustomForms
