import { createActions } from 'redux-actions'
import { simpleAction } from '../redux-helpers'

const createdActions = createActions({
  osh: {
    clinics: {
      loadClinics: simpleAction,
      loadCounties: simpleAction,
      loadPayers: simpleAction,
      setResults: simpleAction,
    },
    hospitals: {
      centers: simpleAction,
      detail: simpleAction,
      markets: simpleAction,
      setResults: simpleAction,
    },
    providers: {
      centers: simpleAction,
      context: {
        setShowBy: simpleAction,
      },
      markets: simpleAction,
      payers: simpleAction,
      provider: simpleAction,
      setResults: simpleAction,
    },
    tables: {
      saveFilters: simpleAction,
    },
  },
})

/** @type { import('./actionTypes').IOSHActions } */
export const actions = createdActions.osh
