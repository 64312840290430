import React, { FC } from 'react'
import { CircularProgress } from '@mui/material'
import { Flex } from 'mui-blox'
import LoadingIndicatorBar from './LoadingIndicatorBar'

interface WaitingProps {
  isWaiting: boolean
  className?: string
  isSmall?: boolean
  variant?: 'circular' | 'linear' | 'fullLinear'
  WaitingProps?: Record<string, any>
  children: React.ReactNode | (() => React.ReactNode)
}

interface WaitingForDataProps extends WaitingProps {
  renderWaiting?: (props: WaitingProps) => React.ReactElement
}

const WaitingForData: FC<WaitingForDataProps> = ({ renderWaiting = undefined, ...props }) => {
  const { isWaiting, className, children, variant = 'circular', isSmall = false, WaitingProps = {} } = props

  let waitingComponent

  if (renderWaiting) {
    waitingComponent = renderWaiting(props)
  } else if (variant === 'fullLinear') {
    waitingComponent = (
      <Flex flexColumn>
        <LoadingIndicatorBar isLoading={isWaiting} {...WaitingProps} />
      </Flex>
    )
  } else if (variant === 'linear') {
    waitingComponent = <LoadingIndicatorBar isLoading={isWaiting} {...WaitingProps} />
  } else if (variant === 'circular') {
    waitingComponent = (
      <Flex flex='1' justifyContent='center' alignItems='center' className={className}>
        <CircularProgress size={isSmall ? 25 : 100} thickness={4} {...WaitingProps} />
      </Flex>
    )
  }

  if (isWaiting) return waitingComponent

  if (typeof children === 'function') return children()

  return children
}

export default WaitingForData
