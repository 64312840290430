const intToHex = int => {
  const hex = int.toString(16)
  return hex.length === 1 ? `0${hex}` : hex
}

const toHex = values => {
  return `#${values.map(n => intToHex(n)).join('')}`.toUpperCase()
}

export const rgbaToHex = color => {
  if (!color) return color

  if (color[0] === '#') return padHex(color).toUpperCase()

  const match = /\(([0-9, .]*)\)/.exec(color)
  const values = match[1]
    .split(',')
    .splice(0, 3)
    .map(v => parseFloat(v))
  return toHex(values)
}

const padHex = hex => {
  if (hex.length === 7) return hex

  return `#${hex
    .slice(1)
    .split('')
    .map(c => `${c}${c}`)
    .join('')}`
}
