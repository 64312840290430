import { Map, fromJS } from 'immutable'
import { actions } from '../actionCreators'
import { paths } from './paths'
import { arrayToMap } from '../../redux-helpers-immutable'

const reducerMap = {
  [actions.hospitals.centers]: (state, { centers }) => state.setIn(paths.centers(), fromJS(centers)),

  [actions.hospitals.markets]: (state, { markets }) => state.setIn(paths.markets(), fromJS(markets)),

  [actions.hospitals.setResults]: (state, { searchType, searchText, hospitals }) =>
    state.setIn(paths.results(searchType, searchText), arrayToMap(hospitals)),

  [actions.hospitals.detail]: (state, { hospital }) =>
    state.setIn(paths.hospitalCache(hospital.id), fromJS(hospital)),
}

export const reducers = (state = Map(), action) => {
  const reducer = reducerMap[action.type]
  if (reducer) {
    return reducer(state, action.payload)
  }

  return state
}
