const lineHeight = 1.3

export const customer = (palette, rem) => ({
  htmlFontSize: 18,
  fontSize: 18,
  fontWeightBold: 700,
  fontWeightLight: 300,
  fontWeightMedium: 400,
  fontWeightRegular: 400,
  h1: {
    fontSize: rem(2),
    fontWeight: 400,
    lineHeight: 1,
    color: palette.text.primary,
  },
  h2: {
    fontSize: rem(2),
    fontWeight: 700,
    lineHeight: 1,
    color: palette.text.primary,
  },
  h3: {
    fontSize: rem(1.5),
    fontWeight: 400,
    lineHeight,
    color: palette.text.primary,
  },
  h4: {
    fontSize: rem(1.5),
    fontWeight: 700,
    lineHeight,
    color: palette.text.primary,
  },
  h5: {
    fontSize: rem(1.33),
    fontWeight: 400,
    lineHeight,
    color: palette.text.primary,
  },
  h6: {
    fontSize: rem(1.33),
    fontWeight: 700,
    lineHeight,
    color: palette.text.primary,
  },
  subtitle1: {
    fontSize: rem(0.9),
    fontWeight: 400,
    lineHeight,
    color: palette.text.primary,
  },
  subtitle2: {
    fontSize: rem(0.9),
    fontWeight: 700,
    lineHeight,
    color: palette.text.secondary,
  },
  body1: {
    fontSize: rem(1),
    color: palette.text.primary,
    lineHeight,
  },
  body2: {
    fontSize: rem(1),
    color: palette.text.secondary,
    lineHeight,
  },
  caption: {
    color: palette.text.secondary,
    lineHeight,
  },
  button: {
    color: palette.text.primary,
  },
  overline: {
    color: palette.text.primary,
  },
})
