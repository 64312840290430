import React from 'react'
import { Suspense, lazyRetry } from '../../../routing'

const Routes = lazyRetry(() =>
  import(
    /* webpackChunkName: "profileMatch" */
    './Routes'
  ),
)

const Root = props => (
  <Suspense>
    <Routes {...props} />
  </Suspense>
)

export default Root
