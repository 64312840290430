import React from 'react'
import { CustomField as CustomFieldType, CustomFormProps } from './types'
import { Controller, useFormContext } from 'react-hook-form'
import { Switch, TextField, Typography } from '@mui/material'
import { Flex } from 'mui-blox'
import { makeStyles } from '@mui/styles'
import { EMAIL_REGEX, PHONE_NUMBER_REGEX } from '~constants'

const useStyles = makeStyles(() => ({
  root: {
    background: 'white',
    borderRadius: '5px',
  },
}))

interface CustomFieldProps extends CustomFieldType {
  prefix: 'participant' | 'guest'
}

export const CustomField: React.FC<CustomFieldProps> = ({ isRequired, name, label, type, prefix }) => {
  const classes = useStyles()
  const { control, watch } = useFormContext<CustomFormProps>()

  const isValueDuplicated = (fieldName: string, valueA: string, valueB: string) => {
    if (!valueA || !valueB) {
      return
    }

    if (valueA === valueB) {
      return `The participant and Guest cannot have the same ${fieldName}.`
    }
  }

  if (type === 'text') {
    return (
      <Controller
        name={`${prefix}.${name}`}
        control={control}
        rules={{ required: { value: isRequired, message: 'This field is required' } }}
        render={({ field, fieldState: { error } }) => {
          const errorMessage = error?.message

          return (
            <Flex width='100%' flexColumn>
              <Typography fontSize='12px'>{label}</Typography>
              <TextField
                {...field}
                inputProps={{ style: { padding: 5 } }}
                style={{ padding: 0, margin: 0, width: '100%' }}
                className={classes.root}
              />
              {errorMessage && (
                <Typography color='red' fontSize='10px'>
                  {errorMessage}
                </Typography>
              )}
            </Flex>
          )
        }}
      />
    )
  }

  if (type === 'email') {
    return (
      <Controller
        name={`${prefix}.${name}`}
        control={control}
        rules={{
          required: { value: isRequired, message: 'This field is required' },
          pattern: { value: EMAIL_REGEX, message: 'Enter a valid email' },
          validate: value =>
            isValueDuplicated(
              'email',
              String(value || ''),
              String(watch(prefix === 'guest' ? 'participant.email' : 'guest.email') || ''),
            ),
        }}
        render={({ field, fieldState: { error } }) => {
          const errorMessage = error?.message

          return (
            <Flex width='100%' flexColumn>
              <Typography fontSize='12px'>{label}</Typography>
              <TextField
                {...field}
                inputProps={{ style: { padding: 5 } }}
                style={{ padding: 0, margin: 0, width: '100%' }}
                className={classes.root}
              />
              {errorMessage && (
                <Typography color='red' fontSize='10px'>
                  {errorMessage}
                </Typography>
              )}
            </Flex>
          )
        }}
      />
    )
  }

  if (type === 'mobile') {
    return (
      <Controller
        name={`${prefix}.${name}`}
        control={control}
        rules={{
          required: { value: isRequired, message: 'This field is required' },
          pattern: { value: PHONE_NUMBER_REGEX, message: 'Enter a valid phone number' },
          validate: value =>
            isValueDuplicated(
              'mobile',
              String(value || ''),
              String(watch(prefix === 'guest' ? 'participant.mobile' : 'guest.mobile') || ''),
            ),
        }}
        render={({ field, fieldState: { error } }) => {
          const errorMessage = error?.message

          return (
            <Flex width='100%' flexColumn>
              <Typography fontSize='12px'>{label}</Typography>
              <TextField
                {...field}
                inputProps={{ style: { padding: 5 } }}
                style={{ padding: 0, margin: 0, width: '100%' }}
                className={classes.root}
              />
              {errorMessage && (
                <Typography color='red' fontSize='10px'>
                  {errorMessage}
                </Typography>
              )}
            </Flex>
          )
        }}
      />
    )
  }

  if (type === 'boolean') {
    return (
      <Controller
        name={`${prefix}.${name}`}
        control={control}
        rules={{ required: { value: isRequired, message: 'This field is required' } }}
        render={({ field, fieldState: { error } }) => {
          const errorMessage = error?.message

          return (
            <Flex width='100%' flexColumn>
              <Typography fontSize='12px'>{label}</Typography>
              <Switch {...field} />
              {errorMessage && (
                <Typography color='red' fontSize='10px'>
                  {errorMessage}
                </Typography>
              )}
            </Flex>
          )
        }}
      />
    )
  }

  return null
}

export default CustomField
