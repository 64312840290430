export const osh = (palette, rem) => ({
  default: {
    fontSize: rem(1),
  },
  body1: {
    fontSize: rem(0.875),
    fontWeight: 400,
  },
  h1: {
    fontSize: rem(1.2125),
    fontWeight: 'bold',
  },
  h6: {
    fontSize: rem(1.2125),
    fontWeight: 'bold',
  },
  subtitle1: {
    fontSize: rem(1),
    color: palette.grey[600],
    fontWeight: 'bold',
  },
})
