import { useCallback } from 'react'
import { useRecoilState } from 'recoil'
import { toastNotification, ToastNotification } from './state/toastNotification'

interface ReturnProps {
  current: ToastNotification
  hash: string
  clear: (n: ToastNotification) => void
}

export const useToaster = (): ReturnProps => {
  const [value, setValue] = useRecoilState(toastNotification)

  const clear = useCallback(
    (n: ToastNotification): void => {
      setValue(state => {
        return state.filter(_n => _n.id !== n.id)
      })
    },
    [setValue],
  )

  return {
    current: value[0],
    hash: value.reduce((acc, v) => acc + v.id, ''),
    clear,
  }
}
