import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'

import authReducer from './auth/reducers'
import botTestingReducer from './botTesting/reducers'
import botTrainingResultsReducer from './botTrainingResults/reducers'
import botTrainingSummaryReducer from './botTrainingSummaries/reducers'
import contextReducer from './context/reducers'
import customerEventReducer from './customerEvents/reducers'
import customerNotificationsReducer from './customerNotifications/reducers'
import customerParticipantReducer from './customerParticipants/reducers'
import customerResponsesReducer from './customerResponses/reducers'
import eventsReducer from './events/reducers'
import filterReducer from './filters/reducers'
import idksReducer from './idks/reducers'
import nlpLogsReducer from './nlpLogs/reducers'
import notificationsReducer from './notifications/reducers'
import { oshReducers } from './osh/reducers'
import orgsReducer from './organizations/reducers'
import participantsReducer from './participants/reducers'
import billingReducer from './billing/reducers/reducers'
import responseGroupsReducer from './responseGroups/reducers'
import responsesReducer from './responses/reducers'
import supportReducer from './support/reducers'
import surveysReducer from './surveys/reducers'
import usersReducer from './users/reducers'

const reducers = combineReducers({
  auth: authReducer,
  botTesting: botTestingReducer,
  botTrainingResults: botTrainingResultsReducer,
  botTrainingSummaries: botTrainingSummaryReducer,
  context: contextReducer,
  customerEvents: customerEventReducer,
  customerNotifications: customerNotificationsReducer,
  customerParticipants: customerParticipantReducer,
  customerResponses: customerResponsesReducer,
  events: eventsReducer,
  filter: filterReducer,
  form: formReducer,
  idks: idksReducer,
  nlpLogs: nlpLogsReducer,
  notifications: notificationsReducer,
  orgs: orgsReducer,
  osh: oshReducers,
  participants: participantsReducer,
  billing: billingReducer,
  responseGroups: responseGroupsReducer,
  responses: responsesReducer,
  support: supportReducer,
  surveys: surveysReducer,
  users: usersReducer,
})

export default reducers
