import { selectOrgId, selectEventId } from './selectors'
import fetch from '../../api/fetch'
import { actions } from './actionCreators'
import { getNow } from '../../../core/util/clock'

export const themePreview = theme => actions.themePreview(theme)

const defaultMediaMeta = {
  isGlobal: false,
  minWidth: 100,
  minHeight: 100,
  ratio: 10 / 13,
  showDescription: false,
}

export const showMediaUploadModal = (onImageUploaded, title = 'Upload Image', ratio) => {
  const ratioProps = ratio ? { ratio } : {}
  return actions.showMediaUploadModal({
    ...defaultMediaMeta,
    onImageUploaded,
    title,
    showDescription: true,
    ...ratioProps,
  })
}

export const showAvatarUpload = (onImageUploaded, isGlobal = true) =>
  actions.showMediaUploadModal({
    ...defaultMediaMeta,
    onImageUploaded,
    title: 'Upload Avatar',
    ratio: 1,
    isGlobal,
  })

export const showBusinessIconUpload = (onImageUploaded, title) =>
  actions.showMediaUploadModal({
    ...defaultMediaMeta,
    onImageUploaded,
    title,
    ratio: 1,
    maxWidth: 200,
    maxHeight: 200,
  })

export const showButtonImageUpload = (onImageUploaded, title) =>
  actions.showMediaUploadModal({
    ...defaultMediaMeta,
    onImageUploaded,
    title,
    ratio: 1,
    minWidth: 50,
    minHeight: 20,
    maxWidth: 200,
    maxHeight: 200,
  })

export const showMessengerBackgroundImageUpload = (onImageUploaded, title) =>
  actions.showMediaUploadModal({
    ...defaultMediaMeta,
    onImageUploaded,
    title,
    ratio: 1,
    minWidth: 10,
    minHeight: 10,
  })

export const showImageUpload = (onImageUploaded, title) =>
  actions.showMediaUploadModal({
    isGlobal: true,
    onImageUploaded,
    title,
    minWidth: 10,
    minHeight: 10,
    showDescription: false,
  })

export const hideMediaUploadModal = () => actions.hideMediaUploadModal()

export const getSignedUrl = (file, callback, isGlobal) => {
  const fileName = `${getNow().toFormat('yyyyLLddhhmm')}-${file.name}`
  file.uniqueName = fileName

  return getSignedUrlForFile(file, callback, fileName, isGlobal)
}

export const getSignedUrlForFile = (file, callback, fileName, isGlobal = false) => {
  return async (dispatch, getState) => {
    const orgId = selectOrgId(getState())
    const eventId = selectEventId(getState())
    try {
      let url
      if (orgId == null) {
        url = await fetch.get(`/intake/storage/uploadUrl?fileName=${fileName}&fileType=${file.type}`)
      } else {
        url =
          isGlobal || !eventId || eventId.toLowerCase() === 'create'
            ? await fetch.get(`/orgs/${orgId}/storage/uploadUrl?fileName=${fileName}&fileType=${file.type}`)
            : await fetch.get(
                `/orgs/${orgId}/events/${eventId}/storage/uploadUrl?fileName=${fileName}&fileType=${file.type}`,
              )
      }
      callback(url)
    } catch (error) {
      // swallow while we remove old notifier
    }
  }
}

export const showModal = (modalType, meta) => actions.showModal(modalType, meta)

export const hideModal = modalType => actions.hideModal(modalType)

export const setCardLocation = rect => actions.setCardLocation(rect)
