import React from 'react'
import { Suspense, lazyRetry } from '../../../routing'

const RosterRoutes = lazyRetry(() =>
  import(
    /* webpackChunkName: "roster" */
    './Routes'
  ),
)

const Root = props => (
  <Suspense>
    <RosterRoutes {...props} />
  </Suspense>
)

export default Root
