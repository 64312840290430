import { combineActions, handleActions } from 'redux-actions'
import { actions, branch } from './actionCreators'
import sharedActions from '../shared/actions'
import { adjustColumnReducer, reduceAndSaveToLocalStorage } from '../shared/reducers'
import { deserializeStateFromLocalStorage } from '../storageHelpers'
import {
  basicState,
  basicLoadCompleteReducer,
  basicFailReducer,
  basicStartReducer,
  gridPinReducer,
  gridSortState,
} from '../redux-helpers'

const initialState = {
  ...basicState(),
  byId: {},
  gridSort: gridSortState(),
  columnWidths: {},
  ...deserializeStateFromLocalStorage(branch),
  context: {},
}

export default handleActions(
  {
    [combineActions(actions.load.start, actions.save.start)]: basicStartReducer,

    [combineActions(actions.load.fail, actions.save.fail)]: basicFailReducer,

    [actions.load.complete]: (state, action) => {
      const { nlpLogs, complete, eventId } = action.payload

      let byId = { ...state.byId }

      nlpLogs.forEach(nlpLog => {
        byId[nlpLog.id] = {
          ...nlpLog,
          reviewed: Boolean(nlpLog.reviewed),
          validity: Boolean(nlpLog.validity),
          isEscalated: Boolean(nlpLog.isEscalated),
        }
      })

      const eventContext = state.context[eventId] || {}

      return {
        ...basicLoadCompleteReducer(state, action),
        byId,
        context: {
          ...state.context,
          [eventId]: {
            ...eventContext,
            pagingComplete: complete,
          },
        },
      }
    },

    // pinColumn
    [sharedActions.pinColumn]: (state, { payload }) => {
      return reduceAndSaveToLocalStorage(state, payload, 'pinnedColumns', branch, gridPinReducer)
    },

    [sharedActions.adjustColumn]: (state, { payload }) => {
      return reduceAndSaveToLocalStorage(state, payload, 'columnWidths', branch, adjustColumnReducer)
    },

    [actions.gridSort.sort]: (state, action) => ({
      ...state,
      gridSort: action.payload,
    }),

    [actions.save.complete]: (state, action) => {
      const { nlpLog } = action.payload
      const previousNlpLog = state.byId[nlpLog.id]

      const nextState = {
        ...state,
        byId: {
          ...state.byId,
          [nlpLog.id]: {
            ...previousNlpLog,
            ...nlpLog,
          },
        },
      }

      return basicLoadCompleteReducer(nextState, action)
    },

    [actions.incrementPageIndex]: (state, { payload: eventId }) => {
      const eventContext = state.context[eventId] || {}

      return {
        ...state,
        context: {
          ...state.context,
          [eventId]: {
            ...eventContext,
            pageIndex: (eventContext.pageIndex || 0) + 1,
          },
        },
      }
    },

    [actions.resetPageIndex]: (state, { payload: eventId }) => {
      const eventContext = state.context[eventId] || {}

      return {
        ...state,
        byId: {}, // clear this for new results, otherwise will just keep showing everything.
        context: {
          ...state.context,
          [eventId]: {
            ...eventContext,
            pageIndex: 0,
          },
        },
      }
    },

    [actions.filterableFields]: (state, { payload: { fields } }) => {
      return {
        ...state,
        filterableFields: fields,
      }
    },
  },
  initialState,
)
