import React from 'react'
import { Suspense, lazyRetry } from '../../../../routing'
import { ExconFrame } from '~layout'

const Routes = lazyRetry(() =>
  import(
    /* webpackChunkName: "calendar" */
    './Routes'
  ),
)

const Root = props => (
  <Suspense>
    <Routes {...props} RootComponent={ExconFrame} />
  </Suspense>
)

export default Root
