const systemIdks = ['system', 'idks']
const systemSupportRequests = ['system', 'supportRequests']
const systemUserConversations = ['system', 'userConversations']

export default {
  idks: (orgId, eventId) => ['orgs', String(orgId), 'events', String(eventId), 'idks'],
  idk: (orgId, eventId, id) => ['orgs', String(orgId), 'events', String(eventId), 'idks', String(id)],
  idkCount: (orgId, eventId, filter = 'all') => [
    'orgs',
    String(orgId),
    'events',
    String(eventId),
    `idk_${filter}_count`,
  ],
  idkPage: (orgId, eventId, filter = 'all') => [
    'orgs',
    String(orgId),
    'events',
    String(eventId),
    `idk_${filter}_page`,
  ],
  platformLogs: (orgId, eventId) => ['orgs', String(orgId), 'events', String(eventId)],
  platformLog: (orgId, eventId, smoochId) => [
    'orgs',
    String(orgId),
    'events',
    String(eventId),
    'platformLogs',
    String(smoochId),
  ],
  systemIdks: () => systemIdks,
  systemIdk: id => systemIdks.concat([String(id)]),
  systemIdkCount: (filter = 'all') => ['system', `idk_${filter}_count`],
  systemIdkPage: (filter = 'all') => ['system', `idk_${filter}_page`],
  eventInfo: (orgId, eventId) => ['orgs', String(orgId), 'events', String(eventId), 'eventInfo'],
  conversation: smoochId => ['conversations', String(smoochId)],
  conversationEntry: (smoochId, messageLogId) => ['conversations', String(smoochId), String(messageLogId)],
  systemSupportRequests: () => systemSupportRequests,
  systemSupportRequest: id => systemSupportRequests.concat([String(id)]),
  systemSupportRequestCount: (filter = 'all') => ['system', `supportRequest_${filter}_count`],
  systemSupportRequestPage: (filter = 'all') => ['system', `supportRequest_${filter}_page`],
  supportRequests: (orgId, eventId) => ['orgs', String(orgId), 'events', String(eventId), 'supportRequests'],
  supportRequest: (orgId, eventId, id) => [
    'orgs',
    String(orgId),
    'events',
    String(eventId),
    'supportRequests',
    String(id),
  ],
  supportRequestCount: (orgId, eventId, filter = 'all') => [
    'orgs',
    String(orgId),
    'events',
    String(eventId),
    `supportRequest_${filter}_count`,
  ],
  supportRequestPage: (orgId, eventId, filter = 'all') => [
    'orgs',
    String(orgId),
    'events',
    String(eventId),
    `supportRequest_${filter}_page`,
  ],
  contextListScroll: path => ['context', 'listScroll', String(path)],
  userConversations: (orgId, eventId) => ['orgs', String(orgId), 'events', String(eventId), 'userConversations'],
  userConversationCount: (orgId, eventId) => [
    'orgs',
    String(orgId),
    'events',
    String(eventId),
    'userConversation_count',
  ],
  userConversationPage: (orgId, eventId) => [
    'orgs',
    String(orgId),
    'events',
    String(eventId),
    'userConversation_page',
  ],
  userConversation: (orgId, eventId, id) => [
    'orgs',
    String(orgId),
    'events',
    String(eventId),
    'userConversations',
    String(id),
  ],
  systemUserConversations: () => systemUserConversations,
  systemUserConversationCount: (filter = 'all') => ['system', `userConversation_${filter}_count`],
  systemUserConversationPage: (filter = 'all') => ['system', `userConversation_${filter}_page`],
  systemUserConversation: id => systemUserConversations.concat([String(id)]),
  systemUserConversationsLiveEvent: () => ['system', 'userConversationsLiveEvent'],
  systemUserConversationLiveEvent: id => ['system', 'userConversationsLiveEvent', String(id)],
}
