import { Map, fromJS } from 'immutable'

export const serializeStateToLocalStorage = (key, state) => serializeState(localStorage, key, state)

export const deserializeStateFromLocalStorage = (key, notSetValue) =>
  deserializeState(localStorage, key, notSetValue)

const serializeState = (storageType, key, state) => {
  storageType[key] = JSON.stringify(state)
  return state
}

const deserializeState = (storageType, key, notSetValue = Map()) => {
  try {
    if (storageType[key]) {
      return fromJS(JSON.parse(storageType[key]))
    }
  } catch (error) {
    return notSetValue
  }
  return notSetValue
}
