import { Components, iconButtonClasses, Theme } from '@mui/material'
import { ComponentOptions } from './types'

export const MuiInputAdornment = ({ spacing }: ComponentOptions): Components<Theme>['MuiInputAdornment'] => {
  return {
    defaultProps: {},
    styleOverrides: {
      root: {
        [`& .${iconButtonClasses.root}`]: {
          borderColor: 'transparent',
        },
      },
      positionEnd: {
        marginRight: spacing(0.5),
      },
    },
  }
}
