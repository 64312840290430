import { Components, Theme } from '@mui/material'
import { ComponentOptions } from './types'

import { getOSHComponents as osh } from './osh/getOSHComponents'
import { MuiAutocomplete } from './MuiAutocomplete'
import { MuiButton } from './MuiButton'
import { MuiButtonGroup } from './MuiButtonGroup'
import { MuiCheckbox } from './MuiCheckbox'
import { MuiChip } from './MuiChip'
import { MuiCircularProgress } from './MuiCircularProgress'
import { MuiDialogTitle } from './MuiDialogTitle'
import { MuiDrawer } from './MuiDrawer'
import { MuiFab } from './MuiFab'
import { MuiFormControl } from './MuiFormControl'
import { MuiFormHelperText } from './MuiFormHelperText'
import { MuiGrid } from './MuiGrid'
import { MuiIconButton } from './MuiIconButton'
import { MuiInputAdornment } from './MuiInputAdornment'
import { MuiInputBase } from './MuiInputBase'
import { MuiInputLabel } from './MuiInputLabel'
import { MuiMenuItem } from './MuiMenuItem'
import { MuiOutlinedInput } from './MuiOutlinedInput'
import { MuiPaper } from './MuiPaper'
import { MuiRadio } from './MuiRadio'
import { MuiSelect } from './MuiSelect'
import { MuiSnackbarContent } from './MuiSnackbarContent'
import { MuiSwitch } from './MuiSwitch'
import { MuiTableCell } from './MuiTableCell'
import { MuiTabs } from './MuiTabs'
import { MuiTextField } from './MuiTextField'

export const getComponents = (type, options: ComponentOptions): Components<Theme> => {
  const variants = { osh }
  const variant = variants[type]

  return {
    MuiAutocomplete: MuiAutocomplete(options),
    MuiButton: MuiButton(options),
    MuiButtonGroup: MuiButtonGroup(options),
    MuiCheckbox: MuiCheckbox(),
    MuiChip: MuiChip(options),
    MuiCircularProgress: MuiCircularProgress(options),
    MuiDialogTitle: MuiDialogTitle(options),
    MuiDrawer: MuiDrawer(),
    MuiFab: MuiFab(),
    MuiFormControl: MuiFormControl(),
    MuiFormHelperText: MuiFormHelperText(),
    MuiGrid: MuiGrid(),
    MuiIconButton: MuiIconButton(options),
    MuiInputAdornment: MuiInputAdornment(options),
    MuiInputBase: MuiInputBase(options),
    MuiInputLabel: MuiInputLabel(),
    MuiMenuItem: MuiMenuItem(options),
    MuiOutlinedInput: MuiOutlinedInput(options),
    MuiPaper: MuiPaper(),
    MuiRadio: MuiRadio(),
    MuiSelect: MuiSelect(options),
    MuiSnackbarContent: MuiSnackbarContent(options),
    MuiSwitch: MuiSwitch(options),
    MuiTableCell: MuiTableCell(options),
    MuiTabs: MuiTabs(options),
    MuiTextField: MuiTextField(),
    ...(variant && variant(options)),
  }
}
