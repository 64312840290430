import React from 'react'
import { Flex } from 'mui-blox'
import { useUsers } from './hooks/useUsers'
import { Typography, Button, ListItem } from '@mui/material'
import { ActivityContainer, AsyncAutocompleteField } from '~controls'
import { useForm } from 'react-hook-form'
import { FieldController } from '~form'
import { useImpersonation } from './hooks/useImpersonation'

type FormData = {
  userId: string
}

const ImpersonationForm = ({ labelClassName, ...rest }): JSX.Element => {
  const { getData, isGetting: isLoading, initialOptions } = useUsers()
  const { impersonate, clearImpersonation, isDeleting, isPosting } = useImpersonation()
  const { control, handleSubmit, watch } = useForm<FormData>()

  const getOptionLabel = o => o.email

  const getOptionValue = o => (o ? o.id : '')

  const attemptSubmit = handleSubmit(values => {
    impersonate(values.userId)
  })
  const endSession = () => clearImpersonation()
  const canSubmit = watch('userId')

  return (
    <Flex {...rest} flexColumn>
      <Typography variant='body2' className={labelClassName}>
        Login as user:
      </Typography>
      <FieldController control={control} name='userId'>
        {({ field }) => (
          <AsyncAutocompleteField
            {...field}
            isLoading={isLoading}
            getOptionLabel={getOptionLabel}
            getOptionValue={getOptionValue}
            initialOptions={initialOptions}
            onChange={(e, v) => field.onChange(v)}
            getOptionsAsync={({ input }, onLoad) => getData(input, onLoad)}
            renderOption={(props, option) => {
              return (
                <ListItem {...props} key={option.id}>
                  {option.email || '<unknown>'}
                </ListItem>
              )
            }}
            fullWidth
          />
        )}
      </FieldController>
      <Flex mt={1} justifyContent='space-between'>
        <ActivityContainer isActive={isDeleting}>
          <Button onClick={endSession}>End Session</Button>
        </ActivityContainer>
        <ActivityContainer isActive={isPosting}>
          <Button onClick={attemptSubmit} color='primary' disabled={!canSubmit}>
            Begin Session
          </Button>
        </ActivityContainer>
      </Flex>
    </Flex>
  )
}

export default ImpersonationForm
