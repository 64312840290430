import { createContext, useContext } from 'react'

export const PageTitleContext = createContext({
  pageTitle: '',
  pageSubtitle: '',
  windowTitle: '',
  setPageTitle: Function,
})

export const useSetPageTitle = () => useContext(PageTitleContext)
