import { Map, List, fromJS } from 'immutable'
import { serializeStateToLocalStorage, deserializeStateFromLocalStorage } from './storageHelpers'
import { getFilterFunction } from './redux-helpers'
import { arrayToMap } from '../utils/arrayToMap'

export { arrayToMap }

export const contextPathKeys = {
  error: 'error',
  isFetching: 'isFetching',
  pinnedColumns: 'pinnedColumns',
  gridSort: 'gridSort',
  columnAdjustments: 'columnAdjustments',
}

export const paths = {
  context: () => ['context'],
  contextKey: key => ['context', key],
  modal: () => ['modal'],
  pinnedColumns: () => ['context', contextPathKeys.pinnedColumns],
  gridSort: () => ['context', contextPathKeys.gridSort],
  gridSortColumn: () => ['context', contextPathKeys.gridSort, 'column'],
  gridSortDirection: () => ['context', contextPathKeys.gridSort, 'direction'],
  isFetching: () => ['context', 'isFetching'],
  filter: () => ['context', 'filterFunc'],
  error: () => ['context', 'error'],
  columnWidths: () => ['context', contextPathKeys.columnAdjustments],
  columnAdjustments: column => {
    if (column) {
      return ['context', contextPathKeys.columnAdjustments, column]
    }
    return ['context', contextPathKeys.columnAdjustments]
  },
}

export const basicCompleteReducer = state =>
  state.mergeIn(
    paths.context(),
    Map({
      error: List(),
      isFetching: false,
    }),
  )

export const basicFailReducer = (state, { payload }) =>
  state.mergeIn(
    paths.context(),
    Map({
      error: payload,
      isFetching: false,
    }),
  )

export const basicStartReducer = state =>
  state.mergeIn(
    paths.context(),
    Map({
      error: List(),
      isFetching: true,
    }),
  )

export const modalHideReducer = state =>
  state.mergeIn(
    paths.modal(),
    Map({
      data: undefined,
      visible: false,
    }),
  )

export const modalShowReducer = (state, { payload }) =>
  state.mergeIn(
    paths.modal(),
    Map({
      data: payload,
      visible: true,
    }),
  )

export const gridPinReducer = (state, { payload: { column } }, branch) => {
  let pinnedColumns = state.getIn(paths.pinnedColumns(), List())
  const index = pinnedColumns.findIndex(c => c === column)
  if (index === -1) {
    pinnedColumns = pinnedColumns.push(column)
  } else {
    pinnedColumns = pinnedColumns.delete(index)
  }

  serializeStateToLocalStorage(
    {
      pinnedColumns,
    },
    branch,
  )

  return state.setIn(paths.pinnedColumns(), pinnedColumns)
}

export const gridPinState = (state, branch) => {
  const data = deserializeStateFromLocalStorage(branch)
  return state.setIn(paths.pinnedColumns(), fromJS(data.pinnedColumns) || List())
}

export const gridSortReducer = (state, { payload: { column, direction } }) => {
  return state.setIn(paths.gridSortColumn(), column).setIn(paths.gridSortDirection(), direction)
}

export const columnAdjustmentReducer = (state, { payload: { column, delta } }, branch) => {
  const currentAdjustment = state.getIn(paths.columnAdjustments(column), 0)
  const nextState = state.setIn(paths.columnAdjustments(column), currentAdjustment + delta)
  serializeStateToLocalStorage(
    {
      columnWidths: nextState.getIn(paths.columnWidths()),
    },
    branch,
  )
  return nextState
}

export const columnWidthState = (state, branch) => {
  const data = deserializeStateFromLocalStorage(branch)
  return state.setIn(paths.columnWidths(), fromJS(data.columnWidths) || Map())
}

export const branchReduce = (state, action, branch, reducer) => {
  if (action.payload.branch === branch) {
    return reducer(state, action, branch)
  }
  return state
}

export const filterSearchReducer =
  (...selectors) =>
  (state, { payload }) => {
    return state.setIn(paths.filter(), getFilterFunction(payload, selectors))
  }

export const filterClearReducer = state => state.deleteIn(paths.filter())

export const getLengthFromListOrArray = items => {
  if (!items) return 0

  return List.isList(items) ? items.size : items.length
}
