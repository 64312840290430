import { useCallback } from 'react'
import { useLocation } from 'react-router'
import { useSetRecoilState } from 'recoil'
import { contentWidth } from '../state/contentWidth'

export const useSetContentWidth = () => {
  const { pathname } = useLocation()
  const setter = useSetRecoilState(contentWidth)

  return useCallback(value => setter(current => ({ ...current, [pathname]: value })), [setter, pathname])
}
