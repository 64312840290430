import { fromJS } from 'immutable'
import { combineActions, handleActions } from 'redux-actions'
import { sciensio } from '../../config'
import { actions } from './actionCreators'
import sharedActions from '../shared/actions'
import { parsePhoneNumber } from '../../utils/countryCodes'
import { deserializeStateFromLocalStorage, serializeStateToLocalStorage } from '../storageHelpers'
import {
  basicState,
  basicCompleteReducer,
  basicLoadCompleteReducer,
  basicFailReducer,
  basicStartReducer,
  gridSortState,
  modalState,
  uploadState,
  uploadProgressState,
  gridPinReducer,
} from '../redux-helpers'

const storagePath = 'events'

const initialState = {
  ...basicState(),
  byId: {},
  byOrg: {},
  channels: {},
  scriptTemplates: {},
  gridSort: gridSortState(),
  linksModal: modalState(),
  modal: modalState(),
  phoneNumberBuyModal: modalState(),
  phoneSidModal: modalState(),
  upload: uploadState(),
  uploadProgress: uploadProgressState(),
  botPhoneNumbers: {},
  timeFilter: 'current',
  ...deserializeStateFromLocalStorage(storagePath),
  imageUploadFile: [],
  cropPreview: '',
}

function buildWebMessengerUrl(event) {
  if (event.featureFlags && event.featureFlags.webMessenger) {
    if (event.customWebMessengerDomain != null) {
      return `https://${event.customWebMessengerDomain}/m/${event.id}`
    }

    return `${sciensio.webMessengerUrl}m/${event.id}`
  }

  return null
}

function normalizeEvent(event) {
  const normalized = {
    ...event,
    phoneNumbersDisplay: event.twilio && event.twilio.map(t => t.phoneNumber).join(', '),
    phoneNumbers: event.twilio && event.twilio.map(t => parsePhoneNumber(t.phoneNumber)),
    previewUrl: `${sciensio.previewUrl}?eventId=${event.id}`,
    webMessengerUrl: buildWebMessengerUrl(event),
    seriesDisplay: event.series || '',
  }

  return normalized
}

export default handleActions(
  {
    // xxx.start
    [combineActions(
      actions.addEventImage.start,
      actions.updateEventImageDescription.start,
      actions.create.start,
      actions.delete.start,
      actions.getShortenedUrl.start,
      actions.mergeFields.start,
      actions.removeEventImages.start,
      actions.resetSmoochWebIntegrations.start,
      actions.update.start,
      actions.updateEventLinks.start,
    )]: basicStartReducer,

    // xxx.fail
    [combineActions(
      actions.addEventImage.fail,
      actions.updateEventImageDescription.fail,
      actions.channels.fail,
      actions.create.fail,
      actions.delete.fail,
      actions.getShortenedUrl.fail,
      actions.load.fail,
      actions.mergeFields.fail,
      actions.removeEventImages.fail,
      actions.resetSmoochWebIntegrations.fail,
      actions.smoochApps.fail,
      actions.update.fail,
      actions.updateEventLinks.fail,
    )]: basicFailReducer,

    // create
    [actions.create.complete]: (state, action) => {
      const { payload } = action
      const { event } = payload

      const byId = {
        ...state.byId,
        [event.id]: normalizeEvent(event),
      }

      const byOrg = {
        ...state.byOrg,
        [event.orgId]: [...(state.byOrg[event.orgId] || []), event.id],
      }

      return {
        ...basicCompleteReducer(state, action),
        byId,
        byOrg,
      }
    },

    // delete
    [actions.delete.complete]: (state, action) => {
      const { payload } = action
      const { eventId, orgId } = payload

      const byId = {
        ...state.byId,
      }
      delete byId[eventId]

      const byOrg = {
        ...state.byOrg,
        [orgId]: state.byOrg[orgId].filter(id => id !== eventId),
      }

      return {
        ...basicCompleteReducer(state, action),
        byId,
        byOrg,
      }
    },

    // load
    [actions.load.complete]: (state, action) => {
      const { payload } = action
      const { orgId, events, series } = payload

      let byId = { ...state.byId }
      ;(state.byOrg[orgId] || []).forEach(id => delete byId[id])
      byId = events.reduce((acc, current) => {
        acc[current.id] = normalizeEvent(current)
        return acc
      }, byId)

      const byOrg = events.map(e => e.id)

      return {
        ...basicLoadCompleteReducer(state, action),
        byId,
        byOrg: {
          ...state.byOrg,
          [orgId]: byOrg,
        },
        series,
      }
    },

    // gridSort
    [actions.gridSort.sort]: (state, action) => {
      return {
        ...state,
        gridSort: action.payload,
      }
    },

    //pinColumn
    [sharedActions.pinColumn]: (state, { payload }) => {
      if (payload.branch === storagePath) {
        const nextState = gridPinReducer(state, payload)
        serializeStateToLocalStorage(
          {
            pinnedColumns: nextState.pinnedColumns,
          },
          storagePath,
        )
        return nextState
      }
      return state
    },

    [combineActions(
      actions.addEventImage.complete,
      actions.updateEventImageDescription.complete,
      actions.mergeFields.complete,
      actions.removeEventImages.complete,
      actions.update.complete,
      actions.updateEventLinks.complete,
    )]: (state, action) => {
      const { payload } = action
      const { event } = payload
      const byId = {
        ...state.byId,
        [event.id]: normalizeEvent(event),
      }

      return {
        ...basicLoadCompleteReducer(state, action),
        byId,
      }
    },

    [actions.smoochApps.complete]: (state, { payload: { eventId, smoochApps } }) => {
      const previousSmoochApps = state.smoochApps
      return {
        ...basicCompleteReducer(state),
        smoochApps: {
          ...previousSmoochApps,
          [eventId]: smoochApps,
        },
      }
    },

    [actions.channels.complete]: (state, { payload: { eventId, channels } }) => {
      const previousChannels = state.channels
      return {
        ...basicCompleteReducer(state),
        channels: {
          ...previousChannels,
          [eventId]: channels,
        },
      }
    },

    [actions.scriptTemplates.complete]: (state, { payload: { eventId, templates } }) => {
      const previous = state.scriptTemplates
      return {
        ...state,
        scriptTemplates: {
          ...previous,
          [eventId]: templates,
        },
      }
    },

    [actions.getShortenedUrl.complete]: (state, action) => {
      const { payload } = action
      const { longUrl, shortUrl, eventId } = payload
      const byId = {
        ...state.byId,
      }
      const event = byId[eventId]

      if (event.eventLinks) {
        event.eventLinks.filter(l => l.url === longUrl)[0].shortUrl = shortUrl
      }

      return {
        ...basicLoadCompleteReducer(state, action),
        byId,
      }
    },

    [actions.resetSmoochWebIntegrations.complete]: (state, action) => {
      return {
        ...basicCompleteReducer(state, action),
      }
    },

    [actions.phoneNumberBuyModal.hide]: state => {
      return {
        ...state,
        phoneNumberBuyModal: {
          data: undefined,
          visible: false,
        },
      }
    },
    [actions.phoneNumberBuyModal.show]: (state, action) => {
      return {
        ...state,
        error: undefined,
        phoneNumberBuyModal: {
          data: action.payload,
          visible: true,
        },
      }
    },
    [actions.phoneSidModal.hide]: state => {
      return {
        ...state,
        phoneSidModal: {
          data: undefined,
          visible: false,
        },
      }
    },
    [actions.phoneSidModal.show]: (state, action) => {
      return {
        ...state,
        error: undefined,
        phoneSidModal: {
          data: action.payload,
          visible: true,
        },
      }
    },

    [actions.scriptModal.show]: state => {
      return {
        ...state,
        showScriptModal: true,
      }
    },

    [actions.scriptModal.hide]: state => {
      return {
        ...state,
        showScriptModal: false,
      }
    },

    [actions.linksModal.show]: state => {
      return {
        ...state,
        showLinksModal: true,
      }
    },

    [actions.linksModal.hide]: state => {
      return {
        ...state,
        showLinksModal: false,
      }
    },

    [actions.uploadMediaProgress.percentComplete]: (state, action) => {
      return {
        ...state,
        uploadProgress: action.payload,
      }
    },

    [actions.selectFile]: (state, { payload: { file } }) => {
      return {
        ...state,
        imageUploadFile: Array.isArray(file) ? file : [file],
      }
    },

    [actions.timeFilter]: (state, { payload }) => {
      let { gridSort } = state
      switch (payload) {
        case 'current':
          gridSort.column = 'start'
          gridSort.direction = 'ascending'
          break
        default:
          gridSort.column = 'start'
          gridSort.direction = 'descending'
      }

      return {
        ...state,
        timeFilter: payload,
        gridSort,
      }
    },

    [actions.featureFlagDefinitions.complete]: (state, { payload: { featureFlags } }) => {
      return {
        ...state,
        featureFlags: fromJS(featureFlags),
      }
    },

    [actions.loadLiveEvents.complete]: (state, { payload: { events } }) => {
      return {
        ...state,
        liveEvents: fromJS(events),
      }
    },

    [actions.loadAvailableEvents]: (state, { payload: { events } }) => {
      return {
        ...state,
        availableEvents: fromJS(events),
      }
    },

    [combineActions(actions.loadTrafficController, actions.saveTrafficController.complete)]: (
      state,
      { payload: { trafficController } },
    ) => {
      return {
        ...state,
        trafficController: trafficController,
      }
    },

    [actions.loadAiAgentPoolInfo]: (state, { payload: { aiAgents } }) => {
      return {
        ...state,
        aiAgents,
      }
    },
  },
  initialState,
)
