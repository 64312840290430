import deepmerge from 'deepmerge'
import light from './light'

export const osh = deepmerge(light, {
  background: {
    default: '#FFF', //'#EFF4F6',
    paper: '#FFF',
  },
  primary: {
    lighter: '#98B564',
    light: '#9CBA67',
    main: '#78a12f',
    dark: '#587623',
    contrastText: '#FFF',
  },
  secondary: {
    light: '#FFF',
    main: '#FFF',
    dark: '#FFF',
    contrastText: '#78a12f',
  },
  tertiary: {
    lighter: '#98B564',
    light: '#9CBA67',
    main: '#78a12f',
    dark: '#587623',
    contrastText: '#FFF',
  },
})
