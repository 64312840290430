const Notifications = function (restClient) {
  this.restClient = restClient
}

Notifications.prototype.deleteNotification = function (orgId, eventId, notificationId) {
  return this.restClient.delete(`/orgs/${orgId}/events/${eventId}/notifications/${notificationId}`)
}

Notifications.prototype.getAll = function (orgId, eventId) {
  return this.restClient.get(`/orgs/${orgId}/events/${eventId}/notifications`)
}

export default Notifications
