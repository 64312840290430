import { useRecoilState } from 'recoil'
import { usePost, useDelete } from '~brix/connectors/fetch'
import { useAuthUser } from '../../auth/useAuthUser'
import { impersonatedUser, isImpersonating } from './../state/impersonatedUser'

const baseUrl = 'customer2/users'

export const useImpersonation = () => {
  const { post, isPosting } = usePost()
  const { del, isDeleting } = useDelete()
  const { forceLoad } = useAuthUser()
  const [, setImpersonatedUser] = useRecoilState(impersonatedUser)
  const [, setImpersonating] = useRecoilState(isImpersonating)

  const impersonate = async (userId: string) => {
    await post(`${baseUrl}/impersonate`, { userId })
    const impersonate = await forceLoad()

    setImpersonatedUser(impersonate)
    setImpersonating(true)
    location.reload()
  }

  const clearImpersonation = async () => {
    await del(`${baseUrl}/impersonate`)
    setImpersonatedUser(null)
    setImpersonating(false)
    await forceLoad()
    location.reload()
  }

  return {
    impersonate,
    clearImpersonation,
    isPosting,
    isDeleting,
  } as const
}
