import { atom } from 'recoil'

export type ToastNotificationType = 'error' | 'info' | 'warning' | 'success'

export interface ToastNotification {
  message?: string
  type?: ToastNotificationType
  id?: string
}

export const toastNotification = atom<ToastNotification[]>({
  key: 'global/toastNotification/atom',
  default: [],
})
