import { createActions } from 'redux-actions'
import api from '../../api/sciensio-api'
import { basicActions, dispatchBasicActions } from '../redux-helpers'

const actionCreators = createActions({
  responseGroups: {
    loadResponseGroups: basicActions(),
    updateResponseGroups: basicActions(),
  },
})
export const actions = actionCreators.responseGroups

export function loadResponseGroups(orgId, eventId, intentId) {
  return dispatchBasicActions(actions.loadResponseGroups, async () => {
    const responseGroups = await api.responseGroups.get(orgId, eventId, intentId)
    return {
      responseGroups,
      intentId,
    }
  })
}

export function updateResponseGroups(orgId, eventId, intentId, responseGroups) {
  return dispatchBasicActions(actions.updateResponseGroups, async () => {
    try {
      const updatedGroups = await api.responseGroups.update(orgId, eventId, intentId, responseGroups)
      return {
        responseGroups: updatedGroups,
        intentId,
      }
    } catch (err) {
      return {
        intentId,
      }
    }
  })
}
