import { Map, List } from 'immutable'
import scoped from '../../lib/scoped-middleware'
import { appScopes } from '../../lib/scopes'
import paths from './paths'
import { getUser } from './actions'
import { getDispatch } from '..'
import { deserializeStateFromLocalStorage } from '../storageHelpers-immutable'
import { authKey } from './reducers'

const getUsername = (state, path) => {
  const email = state.auth.getIn(path, '')
  return email.split('@')[0]
}

export const selectUsername = state => getUsername(state, paths.email())

export const selectUsernameAuthenticated = state => getUsername(state, paths.authenticatedEmail())

export const selectPictureUrlForAuthenticated = state => state.auth.getIn(paths.authenticatedPictureUrl(), '')

const tryGetUser = (state, path) => {
  const user = state.auth.getIn(path)
  if (user === undefined) {
    const token = selectToken(state)
    if (token) {
      getDispatch()(getUser())
    }
    return Map()
  }
  return user
}

export const selectUser = state => tryGetUser(state, paths.user())

export const selectUserAuthenticated = state => tryGetUser(state, paths.authenticatedUser())

export const selectToken = () => {
  const identity = deserializeStateFromLocalStorage(authKey) || Map()
  return identity.get('token')
}

const getUsersOrg = (state, path) => {
  const orgs = state.auth.getIn(path, List())
  return orgs.find(x => x.get('orgId') !== undefined, null, Map()).get('orgId')
}

export const selectUsersOrg = state => getUsersOrg(state, paths.userOrgs())

export const selectUsersOrgAuthenticated = state => getUsersOrg(state, paths.authenticatedUserOrgs())

export const selectIsAdmin = state => {
  const user = selectUser(state)
  return scoped.user(user.toJS(), appScopes.any)
}

export const selectIsAdminSite = state => {
  const user = selectUser(state)
  return user.get('scopes', List()).size > 0
}

export const selectIsAdminAuthenticated = state => {
  const user = selectUserAuthenticated(state)
  return scoped.user(user.toJS(), appScopes.any)
}

export const selectUserHasScope = (state, orgId, scopes) => {
  const user = selectUser(state)
  return scoped.org(user.toJS(), orgId, scopes)
}

export const selectIsImpersonating = state =>
  selectUser(state).get('userId') !== selectUserAuthenticated(state).get('userId')

export const selectIsReadonly = (state, orgId, scope) => {
  if (!orgId || !scope) return true

  return (
    selectUserHasScope(state, orgId, appScopes.readonly) ||
    (selectUserHasScope(state, orgId, scope.read) && !selectUserHasScope(state, orgId, scope.update))
  )
}

export const selectRedirect = state => state.auth.getIn(paths.redirect())
