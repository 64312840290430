import { Map, List, fromJS } from 'immutable'
import paths from './paths'
import { FilterTypes, FilterOperators } from '../../constants'

export const filterTables = {
  falsePositives: 'falsePositives',
  idks: 'idks',
  participants: 'participants',
}

const take = 2
const formatValue = value => {
  if (List.isList(value)) {
    return `${value
      .take(take)
      .map(v => (v === '' ? '<empty>' : v))
      .join(', ')}${value.size > take ? '...' : ''}`
  }
  return value
}

export const selectFilterKey = (column, operator, value) => {
  const normalizedValue = List.isList(value) ? formatValue(value) : value

  return `${column.get('value')}|${operator.get('value')}|${normalizedValue}`
}

export const selectFilter = (state, eventId, table) =>
  state.filter
    .getIn(paths.filters(eventId, table), Map())
    .map(x => x.set('value', formatValue(x.get('value'))))
    .toList()

const normalizeValueSearch = value => {
  let valueList = List.isList(value) ? value : List([value])
  return valueList.reduce((list, v) => {
    if (v === '') {
      return list.concat(['', undefined, null])
    }

    return list.push(v)
  }, List())
}

export const selectFilterPayload = (state, eventId, table) => {
  return state.filter
    .getIn(paths.filters(eventId, table), Map())
    .toList()
    .map(f => {
      return Map({
        column: f.getIn(paths.columnCustomValue(), f.getIn(paths.columnValue())),
        operator: f.getIn(paths.operatorValue()),
        value: normalizeValueSearch(f.get('value')),
      })
    })
    .toJS()
}

export const selectColumn = (state, eventId, table) =>
  state.filter.getIn(paths.selectedColumn(eventId, table), Map())

export const selectOperator = (state, eventId, table) =>
  state.filter.getIn(paths.selectedOperator(eventId, table), Map())

export const selectItemText = item => {
  if (Map.isMap(item)) {
    return item.get('text', '')
  }
  return ''
}

const typeOperators = {
  string: [
    { value: FilterOperators.contains, text: 'contains' },
    { value: FilterOperators.notContains, text: 'does not contain' },
    { value: FilterOperators.equal, text: 'equals' },
    { value: FilterOperators.notEqual, text: 'does not equal' },
  ],
  number: [
    { value: FilterOperators.equal, text: 'equals' },
    { value: FilterOperators.greaterThan, text: 'greater than' },
    { value: FilterOperators.lessThan, text: 'less than' },
  ],
  bool: [
    { value: FilterOperators.equal, text: 'equals' },
    { value: FilterOperators.notEqual, text: 'does not equal' },
  ],
}

export const selectOperators = column => {
  let operators
  switch (column.get('filterType')) {
    case FilterTypes.timestamp:
    case FilterTypes.number:
      operators = typeOperators.number
      break
    case FilterTypes.bool:
      operators = typeOperators.bool
      break
    case FilterTypes.text:
    default:
      operators = typeOperators.string
      break
  }

  return fromJS(operators)
}

export const selectValueType = (column, operator) => {
  const key = `${column.get('filterType')}${operator.get('value')}`
  switch (key) {
    case `${FilterTypes.bool}${FilterOperators.equal}`:
    case `${FilterTypes.bool}${FilterOperators.notEqual}`:
      return 'bool'
    case `${FilterTypes.text}${FilterOperators.equal}`:
    case `${FilterTypes.text}${FilterOperators.notEqual}`:
    case `${FilterTypes.enum}${FilterOperators.equal}`:
    case `${FilterTypes.enum}${FilterOperators.notEqual}`:
      return 'select'
    case `${FilterTypes.number}${FilterOperators.equal}`:
    case `${FilterTypes.number}${FilterOperators.notEqual}`:
    case `${FilterTypes.number}${FilterOperators.greaterThan}`:
    case `${FilterTypes.number}${FilterOperators.lessThan}`:
      return 'number'
    case `${FilterTypes.text}${FilterOperators.contains}`:
    default:
      return 'text'
  }
}

export const selectSelectedValue = (state, eventId, table) => state.filter.getIn(paths.selectedValue(eventId, table))
