import { combineActions, handleActions } from 'redux-actions'
import { Map, fromJS } from 'immutable'
import { actions, branch } from './actionCreators'
import paths from './paths'
import sharedActions from '../shared/actions'
import {
  gridPinState,
  columnWidthState,
  basicCompleteReducer,
  basicFailReducer,
  basicStartReducer,
  gridPinReducer,
  gridSortReducer,
  branchReduce,
  columnAdjustmentReducer,
  modalHideReducer,
  modalShowReducer,
  arrayToMap,
} from '../redux-helpers-immutable'

function normalizeParticipant(participant) {
  const phoneType = participant.get('phoneType')

  return participant.set('phoneType', phoneType === null ? '' : phoneType)
}

export default handleActions(
  {
    // xxx.start
    [combineActions(
      actions.create.start,
      actions.delete.start,
      actions.load.start,
      actions.update.start,
      actions.import.start,
    )]: basicStartReducer,

    // xxx.fail
    [combineActions(
      actions.create.fail,
      actions.delete.fail,
      actions.load.fail,
      actions.update.fail,
      actions.import.fail,
    )]: basicFailReducer,

    // create
    [actions.create.complete]: (state, { payload: { participant } }) =>
      basicCompleteReducer(state).setIn(
        paths.participantSingle(participant.eventId, participant.id),
        normalizeParticipant(fromJS(participant)),
      ),

    // delete
    [actions.delete.complete]: (state, { payload: { participantId }, meta: { eventId } }) =>
      basicCompleteReducer(state).deleteIn(paths.participantSingle(eventId, participantId)),

    // load
    [actions.load.complete]: (state, action) => {
      const {
        payload: { participants, complete },
        meta: { eventId, page },
      } = action

      const pList = arrayToMap(participants, 'id', normalizeParticipant)
      if (page === 0) {
        return basicCompleteReducer(state)
          .setIn(paths.participants(eventId), pList)
          .setIn(paths.pagingComplete(), complete)
      }
      return basicCompleteReducer(state)
        .mergeDeepIn(paths.participants(eventId), pList)
        .setIn(paths.pagingComplete(), complete)
    },

    // update
    [actions.update.complete]: (state, { payload, meta: { eventId } }) => {
      return basicCompleteReducer(state).setIn(
        paths.participantSingle(eventId, payload.id),
        normalizeParticipant(fromJS(payload)),
      )
    },

    [actions.loadParticipant]: (
      state,
      {
        payload: {
          participant,
          meta: { eventId },
        },
      },
    ) => {
      return state.setIn(paths.participantSingle(eventId, participant.id), normalizeParticipant(fromJS(participant)))
    },

    // gridSort
    [sharedActions.gridSort]: (state, action) => {
      // reset page
      return branchReduce(state, action, branch, gridSortReducer)
    },

    // pinColumn
    [sharedActions.pinColumn]: (state, action) => branchReduce(state, action, branch, gridPinReducer),

    [actions.uploadProgress.percentComplete]: (state, { payload: { percentComplete } }) => {
      return state.setIn(paths.uploadPercent(), percentComplete)
    },
    // modal
    [actions.modal.hide]: modalHideReducer,
    [actions.modal.show]: modalShowReducer,

    [actions.import.complete]: state => basicCompleteReducer(state),

    [sharedActions.adjustColumn]: (state, action) => branchReduce(state, action, branch, columnAdjustmentReducer),

    [actions.incrementPageIndex]: state => state.setIn(paths.pageIndex(), state.getIn(paths.pageIndex(), 0) + 1),

    [actions.resetPageIndex]: state => state.setIn(paths.pageIndex(), 0),

    [actions.filterableFields]: (
      state,
      {
        payload: {
          fields,
          meta: { eventId },
        },
      },
    ) => state.setIn(paths.filterableFields(eventId), fromJS(fields)),

    [actions.customFields]: (state, { payload: { fields, eventId } }) =>
      state.setIn(paths.customFieldKeys(eventId), fromJS(fields)),
  },
  columnWidthState(gridPinState(Map(), branch), branch),
)
