import React, { FC } from 'react'
import { Flex } from 'mui-blox'
import { useScript } from '~brix/connectors/context/useScript'
import { useQueryParams } from '../routing'
import { sciensio } from '../../config'
import { buildQuery } from '~core/util/buildQuery'
import { messengerVariants } from '~constants'

export const EmbeddedMessengerConversation: FC = () => {
  const { event } = useQueryParams()

  const scriptUrl = buildQuery(`${sciensio.apiUrl}/api/messenger/event/${event}`, {
    variant: messengerVariants.NO_FRAME,
    height: '100%',
    width: '100%',
  })

  useScript(scriptUrl)

  return (
    <Flex flexColumn>
      <div id='42chat-messenger' style={{ height: '100%', width: '100%' }} />
    </Flex>
  )
}
