export const CXP_COMPARATORS = {
  NE: 'notEqual',
  E: 'equal',
  IMPERATIVE: 'imperative',
} as const

export const BYOD_FIELD_NAMES = {
  field1: 'field1',
  field2: 'field2',
  field3: 'field3',
  field4: 'field4',
  field5: 'field5',
  field6: 'field6',
  field7: 'field7',
  field8: 'field8',
  field9: 'field9',
} as const

export const CXP_STEP_FIELDS = {
  0: [BYOD_FIELD_NAMES.field1, BYOD_FIELD_NAMES.field2],
  1: [BYOD_FIELD_NAMES.field3],
  2: [BYOD_FIELD_NAMES.field4],
  3: [BYOD_FIELD_NAMES.field5],
  4: [BYOD_FIELD_NAMES.field6, BYOD_FIELD_NAMES.field7, BYOD_FIELD_NAMES.field8, BYOD_FIELD_NAMES.field9],
} as const

export const BYOD_FIELD_LABELS = {
  [BYOD_FIELD_NAMES.field1]: 'Do you have a wired internet connection at your home office location?',
  [BYOD_FIELD_NAMES.field2]: 'Was Upload speed at least 15 Mbps and download speed at least 2 Mbps?',
  [BYOD_FIELD_NAMES.field3]: 'What type of computer do you have?',
  [BYOD_FIELD_NAMES.field4]: '',
  [BYOD_FIELD_NAMES.field5]:
    'Do you have antivirus software installed on your computer, and is it up-to-date with automatic updates turned on?',
  [BYOD_FIELD_NAMES.field6]: 'Keyboard',
  [BYOD_FIELD_NAMES.field7]: 'Mouse',
  [BYOD_FIELD_NAMES.field8]: 'Second Monitor',
  [BYOD_FIELD_NAMES.field9]: 'Webcam',
} as const
