import { atom } from 'recoil'
import { recoilPersist } from '~hooks/recoilPersist'

const key = 'customer/impersonate'
const isImpersonatingKey = 'customer/isImpersonating'

const { persistAtom: persistImpersonatedUserAtom } = recoilPersist({
  key,
  storage: localStorage,
})

const { persistAtom: persistIsImpersonatingAtom } = recoilPersist({
  key: isImpersonatingKey,
  storage: localStorage,
})

export const impersonatedUser = atom({
  key,
  default: null,
  effects_UNSTABLE: [persistImpersonatedUserAtom],
})

export const isImpersonating = atom({
  key: isImpersonatingKey,
  default: false,
  effects_UNSTABLE: [persistIsImpersonatingAtom],
})
