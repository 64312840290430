import React from 'react'
import PropTypes from 'prop-types'
import { Route } from 'react-router-dom'
import Suspense from './Suspense'

const SuspenseRoute = ({ path, component, exact, strict, ...rest }) => {
  return (
    <Route
      path={path}
      strict={strict}
      exact={exact}
      render={props => {
        const child = React.createElement(component, { ...props, ...rest })
        return <Suspense>{child}</Suspense>
      }}
    />
  )
}

SuspenseRoute.propTypes = {
  component: PropTypes.object.isRequired,
  exact: PropTypes.bool,
  path: PropTypes.string.isRequired,
  strict: PropTypes.bool,
}

SuspenseRoute.defaultProps = {
  exact: false,
  strict: false,
}

export default SuspenseRoute
