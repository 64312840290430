export { emphasize, darken, alpha, lighten } from '@mui/material/styles'

export const rem = value => `${value}rem`
export const px = value => `${value}px`
export const percent = value => `${value}%`
export const border = (value, color, variant = 'solid') => `${px(value)} ${variant} ${color}`
export const important = value => `${value} !important`
export const vh = value => `calc(var(--vh, 1vh) * ${value})`
export const transition = (property, duration = '0.2s', timingFunc = 'ease', timingDelay) =>
  [property, duration, timingFunc, timingDelay].filter(v => !!v).join(' ')

export const combine = (...args) =>
  [...args]
    .map(x => {
      if (typeof x === 'number') {
        return px(x)
      }
      return x
    })
    .join(' ')
