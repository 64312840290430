import { useState } from 'react'
import { useToast } from '~hooks/useToast'
import { usePost } from '~brix/connectors/fetch'

export type sentResult = { status: string; phoneNumber: string }

export const useSubmitNotifications = exconSettingsId => {
  const { push } = useToast()
  const [result, setResult] = useState<sentResult[] | []>([])

  const { post, isPosting } = usePost()

  const processResponse = response => {
    const { failed, sent } = response
    const submitResult: sentResult[] = []

    failed.forEach(f => submitResult.push({ status: 'failed', phoneNumber: f.participant.phoneNumber }))
    sent.forEach(s => submitResult.push({ status: 'success', phoneNumber: s.mobileNumber || s.rawMobileNumber }))
    setResult(submitResult)
    push(
      `${sent.length} ${sent.length > 1 ? 'Notifications' : 'Notification'} Successfully Sent, ${
        failed.length
      } failed.`,
    )
  }

  const submit = async values => {
    const response = await post(`/excon/${exconSettingsId}/sendDiscoveryNotifications`, values)

    processResponse(response)
  }

  return {
    result,
    submit,
    isSubmitting: isPosting,
  }
}
