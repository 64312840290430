import React from 'react'
import PropTypes from 'prop-types'
import withStyles from '@mui/styles/withStyles'
import ClassNames from 'classnames'
import Bubble from './Bubble'

const BotBubble = ({ classes, className, children, ...rest }) => {
  return (
    <Bubble className={ClassNames(classes.root, className)} {...rest}>
      {children}
    </Bubble>
  )
}

BotBubble.propTypes = {
  tick: PropTypes.bool,
}

BotBubble.defaultProps = {
  tick: false,
}

const styles = ({ palette, mixins: { emphasize } }) => {
  const bgColor = emphasize(palette.background.default, 0.1)
  const color = palette.getContrastText(bgColor)
  return {
    root: {
      width: '100%',
      backgroundColor: bgColor,
      color,
      '&:after': {
        borderTopColor: bgColor,
        left: 0,
        marginLeft: 4,
        borderLeft: 0,
      },
    },
  }
}

export default withStyles(styles)(BotBubble)
