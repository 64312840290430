import { Components, Theme, formHelperTextClasses, formControlClasses } from '@mui/material'

const centeredMargin = 22

export const MuiFormControl = (): Components<Theme>['MuiFormControl'] => {
  return {
    defaultProps: { variant: 'outlined' },
    styleOverrides: {
      root: ({ ownerState }) => ({
        marginTop: centeredMargin,
        paddingBottom: centeredMargin,
        [`& .${formHelperTextClasses.root}`]: {
          textAlign: 'right',
          marginRight: 0,
          fontSize: '.8rem',
          position: 'absolute',
          bottom: 6,
          left: 0,
          right: 0,
        },
        [`&.${formControlClasses.root}`]: ownerState.hiddenLabel
          ? {
              marginTop: 0,
              paddingBottom: 0,
            }
          : {},
      }),
    },
  }
}
