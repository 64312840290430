import React, { useEffect, useState } from 'react'
import { Route, Switch, Redirect, useRouteMatch } from 'react-router-dom'
import { Flex } from 'mui-blox'
import { combineUrl } from '~core/util/string/combineUrl'
import { ToastAlert, BreadcrumbProvider } from '~controls'
import { useGlobalStyles } from './useGlobalStyles'
import SendDiscoveryNotifications from './views/sendDiscoveryNotifications/SendDiscoveryNotifications'
import Home from './Home'
import Unauthorized from './common/Unauthorized'
import { ParticipantProvider } from './common/ParticipantProvider'

import BadgeRoot from './views/badge/Root'
import CalendarAdminRoot from './views/calendar/admin/Root'
import CalendarViewRoot from './views/calendar/view/Root'
import ContentFormRoot from './views/contentForm/Root'
import ContentListRoot from './views/contentList/Root'
import ContentRoot from './views/displayContent/Root'
import CxpRoot from './views/cxp/Root'
import ExhibitorRoot from './views/exhibitorSearch/Root'
import FeedbackRoot from './views/feedback/Root'
import FileUploadToolRoot from './views/fileUploadTool/Root'
import GlossaryRoot from './views/glossary/Root'
import HealthScreeningManagerRoot from './views/healthScreeningManager/Root'
import HealthScreeningRoot from './views/healthScreening/Root'
import ItineraryRoot from './views/itinerary/Root'
import Inventory from './views/inventory/Root'
import FormsRoot from './views/form/Root'
import MeetingRoot from './views/meetings/Root'
import MenuRoot from './views/menu/Root'
import NotificationsRoot from './views/notifications/Root'
import OshRoot from './views/osh/Root'
import PartsInventoryRoot from './views/partsInventory/Root'
import PollyRoot from './views/polly/Root'
import ProfileMatchRoot from './views/profileMatch/Root'
import ProfileRoot from './views/profile/Root'
import Quotes from './views/quotes/Root'
import RosterRoot from './views/roster/Root'
import ScheduleRoot from './views/schedule/Root'
import SearchableList from './views/lsd/Root'
import SmsSignupRoot from './views/smsSignup/Root'
import SpeakerSearchAdminRoot from './views/speakerSearch/admin/Root'
import SpeakerSearchRoot from './views/speakerSearch/user/Root'
import TournamentRoot from './views/tournament/Root'
import VotingAdminRoot from './views/voting/admin/Root'
import VotingViewRoot from './views/voting/view/Root'
import InteractiveFormAdminRoot from './views/interactiveForm/admin/Root'
import InteractiveFormAgentRoot from './views/interactiveForm/agent/Root'

const ExconRoutes = () => {
  const { path } = useRouteMatch()
  const [, setInnerHeight] = useState()

  useGlobalStyles()

  useEffect(() => {
    // set vh for mobile devices`
    const calculateVH = () => {
      let vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)
      setInnerHeight(window.innerHeight)
    }

    calculateVH()

    window.addEventListener('resize', calculateVH)
    return () => {
      window.removeEventListener('resize', calculateVH)
    }
  }, [])

  return (
    <BreadcrumbProvider>
      <Flex flexColumn width='100%' className='app'>
        <Switch>
          <Route path={combineUrl(path, 'sendDiscoveryNotifications')} component={SendDiscoveryNotifications}>
            <ParticipantProvider>
              <SendDiscoveryNotifications />
            </ParticipantProvider>
          </Route>
          <Route path={combineUrl(path, 'badge')}>
            <ParticipantProvider>
              <BadgeRoot />
            </ParticipantProvider>
          </Route>
          <Route path={combineUrl(path, 'calendar/admin')}>
            <ParticipantProvider>
              <CalendarAdminRoot />
            </ParticipantProvider>
          </Route>
          <Route path={combineUrl(path, 'calendar/view')}>
            <ParticipantProvider>
              <CalendarViewRoot />
            </ParticipantProvider>
          </Route>
          <Route path={combineUrl(path, 'contentForm/:slug?')}>
            <ParticipantProvider>
              <ContentFormRoot />
            </ParticipantProvider>
          </Route>
          <Route path={combineUrl(path, 'contentList/:slug?')}>
            <ParticipantProvider>
              <ContentListRoot />
            </ParticipantProvider>
          </Route>
          <Route path={combineUrl(path, 'cxp')}>
            <ParticipantProvider>
              <CxpRoot />
            </ParticipantProvider>
          </Route>
          <Route path={combineUrl(path, 'exhibitorSearch')}>
            <ParticipantProvider>
              <ExhibitorRoot />
            </ParticipantProvider>
          </Route>
          <Route path={combineUrl(path, 'feedback/:eventId')}>
            <ParticipantProvider>
              <FeedbackRoot />
            </ParticipantProvider>
          </Route>
          <Route path={combineUrl(path, 'fileUploadTool')}>
            <ParticipantProvider>
              <FileUploadToolRoot />
            </ParticipantProvider>
          </Route>
          <Route path={combineUrl(path, 'form')}>
            <ParticipantProvider>
              <FormsRoot />
            </ParticipantProvider>
          </Route>
          <Route path={combineUrl(path, 'healthScreening')}>
            <ParticipantProvider>
              <HealthScreeningRoot />
            </ParticipantProvider>
          </Route>
          <Route path={combineUrl(path, 'healthScreeningManager')}>
            <ParticipantProvider>
              <HealthScreeningManagerRoot />
            </ParticipantProvider>
          </Route>
          <Route path={combineUrl(path, 'list')}>
            <ParticipantProvider>
              <SearchableList />
            </ParticipantProvider>
          </Route>
          <Route path={combineUrl(path, 'inventory')}>
            <ParticipantProvider>
              <Inventory />
            </ParticipantProvider>
          </Route>
          <Route path={combineUrl(path, 'meetings')}>
            <ParticipantProvider>
              <MeetingRoot />
            </ParticipantProvider>
          </Route>
          <Route path={combineUrl(path, 'menu')}>
            <ParticipantProvider>
              <MenuRoot />
            </ParticipantProvider>
          </Route>
          <Route path={combineUrl(path, 'notifications')}>
            <ParticipantProvider>
              <NotificationsRoot />
            </ParticipantProvider>
          </Route>
          <Route path={combineUrl(path, 'partsInventory')}>
            <ParticipantProvider>
              <PartsInventoryRoot />
            </ParticipantProvider>
          </Route>
          <Route path={combineUrl(path, 'polly')}>
            <ParticipantProvider>
              <PollyRoot />
            </ParticipantProvider>
          </Route>
          <Route path={combineUrl(path, 'profile')}>
            <ParticipantProvider>
              <ProfileRoot />
            </ParticipantProvider>
          </Route>
          <Route path={combineUrl(path, 'profileMatch')}>
            <ParticipantProvider>
              <ProfileMatchRoot />
            </ParticipantProvider>
          </Route>
          <Route path={combineUrl(path, 'schedule')}>
            <ParticipantProvider>
              <ScheduleRoot />
            </ParticipantProvider>
          </Route>
          <Route path={combineUrl(path, 'smsSignup')}>
            <ParticipantProvider>
              <SmsSignupRoot />
            </ParticipantProvider>
          </Route>
          <Route path={combineUrl(path, 'speakerSearch/admin')}>
            <ParticipantProvider>
              <SpeakerSearchAdminRoot />
            </ParticipantProvider>
          </Route>
          <Route path={combineUrl(path, 'speakerSearch/user/:type')}>
            <ParticipantProvider>
              <SpeakerSearchRoot />
            </ParticipantProvider>
          </Route>
          <Route path={combineUrl(path, 'tourney')}>
            <ParticipantProvider>
              <TournamentRoot />
            </ParticipantProvider>
          </Route>
          <Route path={combineUrl(path, 'voting/admin')}>
            <ParticipantProvider>
              <VotingAdminRoot />
            </ParticipantProvider>
          </Route>
          <Route path={combineUrl(path, 'voting/view')}>
            <ParticipantProvider>
              <VotingViewRoot />
            </ParticipantProvider>
          </Route>
          <Route path={combineUrl(path, 'iform/agent')}>
            <ParticipantProvider>
              <InteractiveFormAgentRoot />
            </ParticipantProvider>
          </Route>
          {/** AKA "Status Tool" */}
          <Route path={combineUrl(path, 'iform/admin')}>
            <ParticipantProvider>
              <InteractiveFormAdminRoot />
            </ParticipantProvider>
          </Route>
          <Route path={combineUrl(path, 'osh')}>
            <ParticipantProvider>
              <OshRoot />
            </ParticipantProvider>
          </Route>
          {/* Not participant routes */}
          <Route path={combineUrl(path, 'content/event/:eventId')} component={ContentRoot} />
          <Route path={combineUrl(path, 'glossary/:eventId')} component={GlossaryRoot} />
          {/* Quotes decodes token without setting in storage and routing */}
          <Route path={combineUrl(path, 'quotes')} component={Quotes} />
          {/* Roster currently uses alternative excon setting keys for logoUrl and display overrides */}
          <Route path={combineUrl(path, 'roster/:eventId/:type')} component={RosterRoot} />
          <Route path={combineUrl(path, 'itinerary')} component={ItineraryRoot} />
          <Route path={combineUrl(path, 'authFailure')} component={Unauthorized} />
          <Route exact path={path} component={Home} />
          <Redirect to={path} />
        </Switch>
        <ToastAlert />
      </Flex>
    </BreadcrumbProvider>
  )
}

export default ExconRoutes
