import { createActions } from 'redux-actions'
import { basicActions, gridSortActions, modalActions, simpleAction } from '../redux-helpers'

const actionCreators = createActions({
  promoCodes: {
    create: basicActions(),
    delete: basicActions(),
    gridSort: gridSortActions(),
    load: simpleAction,
    modal: modalActions(),
    update: basicActions(),
  },
})

/** @type { import('./actionTypes').IBillingActions } */
export const actions = actionCreators
