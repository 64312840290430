export const botModes = {
  duringEvent: 'Event',
  preEvent: 'Pre-Event',
  postEvent: 'Post-Event',
  silent: 'Silent',
  test: 'Test',
  any: 'Any',
}

export const BOT_TIMEFRAME = {
  all: 'all',
  live: 'live',
  past: 'past',
  upcoming: 'upcoming',
}

export const BOT_DEPLOYMENT_STATUS = {
  all: 'all',
  decommissioned: 'decommissioned',
  deployed: 'deployed',
  changesPending: 'changesPending',
  undeployed: 'undeployed',
}

export const BOT_TYPE = {
  bot: 'bot',
  legacyBot: 'legacyBot',
}

export const KNOWN_BOT_TAGS = {
  all: 'all',
  eventBots: 'eventBots',
  perpetualBots: 'perpetualBots',
  healthShield: 'healthShield',
}

export const KNOWN_BOT_TAGS_LABELS = {
  [KNOWN_BOT_TAGS.all]: 'All Bots',
  [KNOWN_BOT_TAGS.eventBots]: 'Event Bots',
  [KNOWN_BOT_TAGS.perpetualBots]: 'Perpetual Bots',
  [KNOWN_BOT_TAGS.healthShield]: 'HealthShield Bots',
}

export const TAGS_FILTER_PRESETS = {
  [KNOWN_BOT_TAGS.eventBots]: [
    'ALBOT',
    'CBOT',
    'CBOT+',
    'CSUB',
    'CSUB+',
    'FBOT',
    'FSUB',
    'GBOT',
    'GSUB',
    'MBOT',
    'SCSUB+',
    'SCSUB',
    'SMBOT',
    'TBOT',
    'TSUB',
    'VBOT',
    'PBOT',
    'WBOT',
  ],
  [KNOWN_BOT_TAGS.healthShield]: ['DBOT', 'PDBOT', 'DHSB', 'EHSB', 'PHSB'],
  [KNOWN_BOT_TAGS.perpetualBots]: ['ABOT', 'MBRBOT', 'DEMO', 'ALSUB'],
}
