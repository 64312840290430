import { useCallback } from 'react'
import fetch from 'isomorphic-fetch'
import { useUnauthorizedHandler } from '../auth/useUnauthorizedHandler'
import { processApiError } from './processApiError'
import { resolveResponse } from './resolveResponse'

export const useProcessFetch = () => {
  const unAuthHandler = useUnauthorizedHandler()

  const processFetch = useCallback(
    async (url, payload) => {
      const request = await fetch(url, payload)

      if (request.ok) {
        const response = await resolveResponse(request)

        return response
      } else {
        processApiError(request, { unAuthHandler })
      }
    },
    [unAuthHandler],
  )

  return processFetch
}
