import { createActions } from 'redux-actions'

const actionCreators = createActions({
  filters: {
    selectColumn: (eventId, table, column) => ({
      eventId,
      table,
      column,
    }),
    clearColumn: (eventId, table) => ({
      eventId,
      table,
    }),
    clearOperator: (eventId, table) => ({
      eventId,
      table,
    }),
    selectOperator: (eventId, table, operator) => ({
      eventId,
      table,
      operator,
    }),
    addFilter: (eventId, table, column, operator, value) => ({
      eventId,
      table,
      column,
      operator,
      value,
    }),
    removeFilter: (eventId, table, key) => ({
      eventId,
      table,
      key,
    }),
    resetFilter: (eventId, table) => ({
      eventId,
      table,
    }),
    editFilter: (eventId, table, key) => ({
      eventId,
      table,
      key,
    }),
    selectValue: (eventId, table, value) => ({
      eventId,
      table,
      value,
    }),
  },
})

export const actions = actionCreators.filters

export const selectColumn = actions.selectColumn
export const clearColumn = actions.clearColumn
export const clearOperator = actions.clearOperator
export const selectOperator = actions.selectOperator
export const addFilter = actions.addFilter
export const removeFilter = actions.removeFilter
export const resetFilter = actions.resetFilter
export const editFilter = actions.editFilter
export const selectValue = actions.selectValue
