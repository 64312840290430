import { Map, fromJS } from 'immutable'
import { handleActions } from 'redux-actions'
import { actions } from './actionCreators'
import paths from './paths'
import { basicFailReducer, basicStartReducer } from '../redux-helpers-immutable'
import { deserializeStateFromLocalStorage, serializeStateToLocalStorage } from '../storageHelpers-immutable'

export const authKey = 'auth'

const loadAuthorization = () => deserializeStateFromLocalStorage(authKey, Map())

export const initialize = () => {
  const identity = loadAuthorization() || Map()
  return Map().setIn(paths.identity(), identity)
}

export default handleActions(
  {
    [actions.whoami.start]: basicStartReducer,

    [actions.whoami.fail]: basicFailReducer,

    [actions.whoami.complete]: (state, { payload: { user, loggedInAs } }) => {
      const nextState = state.setIn(paths.authenticatedUser(), fromJS(user)).setIn(paths.user(), fromJS(loggedInAs))

      serializeStateToLocalStorage(authKey, nextState.getIn(paths.identity(), Map()))
      return nextState
    },
    [actions.setAuth]: (state, { payload: { auth } }) => {
      return state.setIn(paths.identity(), fromJS(auth))
    },

    [actions.logout]: state => {
      const nextState = state.deleteIn(paths.identity())
      serializeStateToLocalStorage(authKey, Map())
      return nextState
    },

    [actions.saveRedirect]: (state, { payload: { redirect } }) => state.setIn(paths.redirect(), redirect),

    [actions.clearRedirect]: state => state.removeIn(paths.redirect()),
  },
  initialize(),
)
