import deepmerge from 'deepmerge'
import light from './light'
import grey from '../colors/grey'

const darkPalette = deepmerge(light, {
  background: {
    default: '#000',
    paper: '#212121',
  },
  secondary: {
    lighter: grey[300],
    light: grey[400],
    main: grey[700],
    dark: grey[900],
    contrastText: '#EAEAEA',
  },
  text: {
    primary: 'rgba(255,255,255, 0.87)',
    secondary: 'rgba(255,255,255, 0.54)',
    disabled: 'rgba(255,255,255, 0.38)',
    hint: 'rgba(255,255,255, 0.38)',
  },
  divider: grey[900],
})

export default darkPalette
