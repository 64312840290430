const paths = {
  markets: () => ['values', 'markets'],
  centers: () => ['values', 'centers'],
  payers: () => ['values', 'payers'],
  provider: id => ['results', String(id)],
  providerCache: id => ['providerCache', String(id)],
  results: (searchType, searchText) => ['results', String(searchType), String(searchText)],
  showBy: () => ['context', 'showBy'],
}

export { paths }
