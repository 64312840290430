import fetch from 'isomorphic-fetch'
import { sciensio as config } from '../../config'
import { getPayload } from './getPayload'
import { fetchOnce } from './fetchOnce'
import { processResponse } from './processResponse'
import { combineUrl } from '../../../core/util/string/combineUrl'
import { interceptor } from './interceptors'

export const requestWithBody = (url, body, method, unAuthHandler) => {
  return fetchOnce(url, method, () => {
    const payload = getPayload(method, body)
    const promise = new Promise((resolve, reject) => {
      fetch(combineUrl(config.apiUrl, 'api', url), payload)
        .then(response => {
          interceptor.intercept(response)
          processResponse({ resolve, reject, response, url, method, unAuthHandler })
        })
        .catch(() => {
          reject({
            status: -1,
            message: 'Could not access api',
          })
        })
    })

    return promise
  })
}

export const post = (url, body, unAuthHandler) => requestWithBody(url, body, 'POST', unAuthHandler)
export const patch = (url, body, unAuthHandler) => requestWithBody(url, body, 'PATCH', unAuthHandler)
export const deleteIt = (url, body, unAuthHandler) => requestWithBody(url, body, 'DELETE', unAuthHandler)
