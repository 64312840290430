interface Handlers {
  unAuthHandler?: (args: Record<string, unknown>) => void
}

export const processApiError = (response, handlers: Handlers) => {
  switch (response.status) {
    case 401:
    case 403: {
      handleUnauthorized(response, handlers)

      return
    }
    case 404:
      throw { status: response.status, message: 'Resource was not found' }
    default:
      throw { status: response.status, message: response.error }
  }
}

const handleUnauthorized = (response, handlers: Handlers = {}) => {
  const args = {
    url: response.url,
    status: response.status,
    statusText: response.statusText,
    message: 'User is not authorized',
  }

  const { unAuthHandler } = handlers

  if (unAuthHandler) {
    return unAuthHandler(args)
  }

  throw args
}
