import { useCallback, useEffect, useState } from 'react'
import useSWR from 'swr'
import { useDataContext } from '../../_controls'
import { useFetch } from '../../../brix/connectors/fetch/useFetch'
import { MESSAGE_DIRECTIONS, MESSAGE_SOURCES } from '../../../../constants'
import { useBotMessageListener } from './useBotMessageListener'

export const useConversation = (conversationId, ready) => {
  const [loading, setIsLoading] = useState(false)
  const eventId = useDataContext('eventId')
  const { get, post } = useFetch()

  const {
    data: conversation,
    mutate,
    isValidating,
  } = useSWR(
    ready ? `/messenger/event/${eventId}/id/${conversationId}` : null,
    async url => {
      const result = await get(url)
      sessionStorage.setItem(getStorageKey(conversationId), JSON.stringify(result))
      return result
    },
    { revalidateOnFocus: false },
  )

  const addMessage = useCallback(
    async message => {
      await mutate(current => {
        return [...current, message]
      }, false)
    },
    [mutate],
  )

  useBotMessageListener(conversationId, addMessage)

  useEffect(() => {
    if (loading) {
      const timer = setInterval(() => setIsLoading(false), 3000)

      return () => {
        clearInterval(timer)
      }
    }
  }, [loading])

  const onSendMessage = useCallback(
    async text => {
      const message = {
        message: text,
        direction: MESSAGE_DIRECTIONS.incoming,
        source: MESSAGE_SOURCES.endUser,
      }
      const result = await post(`/messenger/event/${eventId}/id/${conversationId}`, { message: text })
      message.messageTimestamp = result.received

      await addMessage(message)
      setIsLoading(true)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [mutate],
  )

  return { conversation, isFetching: conversation === undefined, isLoading: isValidating || loading, onSendMessage }
}

const getStorageKey = id => `conversation_${id}`
