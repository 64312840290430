import React, { FC, useState } from 'react'
import { Popper } from '@mui/material'
import PopupMessengerContainer from './PopupMessengerContainer'
import { Transition } from '../common/ConversationTransition'
import { useConversationId } from '../hooks/useConversationId'
import MessengerConversation from '../conversation/MessengerConversation'
import { useEventBranding } from '~brix/connectors/context/useEventBranding'
import { messengerButtonStyle } from '~constants'

export interface MessengerPopperProps {
  id: string
  eventId: string
  anchorEl: HTMLDivElement | null
  style?: Record<string, unknown>
  open: boolean
  onClose: () => void
}

export const MessengerPopper: FC<MessengerPopperProps> = ({ style, id, open, anchorEl, eventId, onClose }) => {
  const { branding } = useEventBranding()
  const [canClose, setCanClose] = useState(true)
  const [ready, setReady] = useState(false)
  const handleTransitioned = () => setReady(true)
  const { conversationId } = useConversationId(eventId)
  const isInternal = Boolean(style)

  if (!open) {
    return null
  }

  return (
    <Popper
      role={undefined}
      id={id}
      style={style}
      transition
      placement='top-start'
      sx={{
        display: 'flex',
        right: 0,
        height: `calc(100% - ${branding.display?.displayStyle === messengerButtonStyle.TAB ? 62 : 115}px)`,
        width: '100%',
        '& .transitional': {
          display: 'flex',
          flex: 1,
        },
      }}
      modifiers={[
        {
          name: 'flip',
          enabled: false,
        },
        {
          name: 'preventOverflow',
          enabled: true,
          options: {
            boundariesElement: 'scrollParent',
            padding: isInternal ? branding.buttonPadding : 0,
          },
        },
        {
          name: 'offset',
          enabled: isInternal,
          options: {
            offset: `0, -${branding.display?.displayStyle === messengerButtonStyle.TAB ? 62 : 106}px`,
          },
        },
        {
          name: 'offset',
          enabled: true,
          options: {
            offset: [0, 20],
          },
        },
      ]}
      open={open}
      anchorEl={anchorEl}
    >
      {({ TransitionProps }) => (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <Transition {...TransitionProps} onRest={handleTransitioned}>
          <PopupMessengerContainer onClose={onClose} showClose={canClose}>
            <MessengerConversation
              onModeChange={mode => setCanClose(mode !== 'excon')}
              conversationId={conversationId}
              ready={ready}
            />
          </PopupMessengerContainer>
        </Transition>
      )}
    </Popper>
  )
}
