import React from 'react'
import { ExconFrame } from '~layout'
import { lazyRetry, Suspense } from '../../../routing'

const Routes = lazyRetry(() =>
  import(
    /* webpackChunkName: "profile" */
    './Routes'
  ),
)

const Root = props => (
  <Suspense>
    <Routes {...props} RootComponent={ExconFrame} />
  </Suspense>
)

export default Root
