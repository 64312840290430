import { createActions } from 'redux-actions'
import { basicActions } from '../redux-helpers'

export const branch = 'botTrainingResults'

const actionCreators = createActions({
  trainingResults: {
    loadResults: basicActions(),
    save: basicActions(),
  },
})

export const actions = actionCreators.trainingResults
