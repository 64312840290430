import { useContext, createContext, useEffect } from 'react'
import { Stack } from 'immutable'
import { useHistory, useLocation } from 'react-router-dom'

export const BreadcrumbContext = createContext({
  history: undefined,
  setHistory: () => null,
  breadcrumbs: undefined,
  setBreadcrumbs: () => null,
})

export const useBreadcrumbContext = () => {
  const routerHistory = useHistory()
  const routerLocation = useLocation()

  const context = useContext(BreadcrumbContext)

  const breadcrumbs = context.breadcrumbs || Stack()
  const history = context.history || Stack()

  const setBreadcrumbs = context.setBreadcrumbs
  const setHistory = context.setHistory

  useEffect(() => {
    const item = routerLocation.pathname
    if (item !== history.peek()) {
      setHistory(history.push(item).take(2))
    }
  }, [routerLocation.pathname])

  const breadcrumbsSize = breadcrumbs.size

  const goBack = () => {
    if (breadcrumbsSize === 0) return

    const url = breadcrumbs.peek()

    setBreadcrumbs(breadcrumbs.pop())
    routerHistory.push(url)
  }

  const push = (url, backUrl) => {
    const crumb = backUrl || routerLocation.pathname

    if (breadcrumbs.peek() !== crumb) {
      setBreadcrumbs(breadcrumbs.push(crumb))
    }

    routerHistory.push(url)
  }

  const clear = () => {
    setBreadcrumbs(breadcrumbs.clear())
  }

  const peek = index => {
    return index ? breadcrumbs.get(index) : breadcrumbs.peek()
  }

  const peekHistory = index => {
    return index ? history.get(index) : history.peek()
  }

  const peekDifferent = () => {
    return breadcrumbs.peek() !== peekHistory(1)
  }

  return {
    goBack,
    push,
    clear,
    size: breadcrumbsSize,
    peek,
    peekDifferent,
  }
}
