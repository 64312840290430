const userPath = ['identity', 'user']
const authenticatedUserPath = ['identity', 'authenticatedUser']

export default {
  identity: () => ['identity'],
  user: () => userPath,
  userOrgs: () => userPath.concat(['orgs']),
  email: () => userPath.concat(['email']),
  authenticatedUser: () => authenticatedUserPath,
  authenticatedUserOrgs: () => authenticatedUserPath.concat(['orgs']),
  authenticatedEmail: () => authenticatedUserPath.concat(['email']),
  authenticatedPictureUrl: () => authenticatedUserPath.concat(['pictureUrl']),
  activityStatus: activity => ['context', 'activities', activity],
  redirect: () => ['context', 'redirect'],
}
