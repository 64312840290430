import React, { useContext, createContext } from 'react'

export type MeasureCell = () => void

export const MeasureCellContext = createContext<MeasureCell>(() => undefined)

export const useMeasureCell = (): MeasureCell => {
  return useContext(MeasureCellContext)
}

interface MeasureCellProviderProps {
  measure: MeasureCell
  children: React.ReactNode
}

export const MeasureCellProvider = ({ children, measure }: MeasureCellProviderProps) => {
  return <MeasureCellContext.Provider value={measure}>{children}</MeasureCellContext.Provider>
}
