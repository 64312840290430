import { createActions } from 'redux-actions'
import { simpleAction, basicActions, gridSortActions } from '../redux-helpers'

const actionCreators = createActions({
  customerParticipants: {
    loadParticipants: basicActions(),
    gridSort: gridSortActions(),
    setFilterValue: filter => ({ filter }),
    saveParticipant: basicActions(),
    deleteParticipant: basicActions(),
    clear: simpleAction,
  },
})

export const actions = actionCreators.customerParticipants
