export const MESSAGE_SOURCES = {
  botResponse: 'botResponse',
  endUser: 'endUser',
} as const

export const MESSAGE_DIRECTIONS = {
  incoming: 'incoming',
  outgoing: 'outgoing',
} as const

export const MESSAGE_STATUS = {
  delivered: 'delivered',
  failed: 'failed',
  pending: 'pending',
  posted: 'posted',
  queued: 'queued',
  sending: 'sending',
  sent: 'sent',
  undelivered: 'undelivered',
  unknown: 'unknown',
  incomingReceived: 'received',
} as const

export const MESSAGE_STATUS_ORDER = [
  MESSAGE_STATUS.queued, //queued to our messaging pipeline
  MESSAGE_STATUS.posted,
  MESSAGE_STATUS.pending,
  MESSAGE_STATUS.sending,
  MESSAGE_STATUS.sent,
  MESSAGE_STATUS.delivered,
]

export const MESSAGE_ERROR_CODE = {
  40001: 40001,
  40002: 40002,
  40003: 40003,
  40004: 40004,
  40005: 40005,
  40006: 40006,
  40007: 40007,
  40008: 40008,
  40009: 40009,
  40999: 40999,
}

export const MESSAGE_ERROR_DETAIL = {
  [MESSAGE_ERROR_CODE[40001]]: 'Unable to post message to provider',
  [MESSAGE_ERROR_CODE[40002]]: 'Provider system error',
  [MESSAGE_ERROR_CODE[40003]]: 'Provider account error',
  [MESSAGE_ERROR_CODE[40004]]: 'Unreachable destination',
  [MESSAGE_ERROR_CODE[40005]]: 'Message blocked',
  [MESSAGE_ERROR_CODE[40006]]: 'Message filtered',
  [MESSAGE_ERROR_CODE[40007]]: 'Message blocked due to STOP',
  [MESSAGE_ERROR_CODE[40008]]: 'Message configuration problem',
  [MESSAGE_ERROR_CODE[40009]]: 'Message undeliverable (unknown)',
  [MESSAGE_ERROR_CODE[40999]]: 'Unknown error',
}

export const MESSAGE_EVENT_TYPES = {
  botResponse: 'botResponse',
  endUser: 'endUser',
  notification: 'notification',
  supportResponse: 'supportResponse',
  testingChannel: 'testingChannel',
  trafficControllerResponse: 'trafficControllerResponse',
} as const

export const MESSAGE_EVENT_VARIANTS = {
  [MESSAGE_EVENT_TYPES.botResponse]: {
    initGreeting: 'initGreeting',
    nlpResponse: 'nlpResponse',
    optIn: 'optIn',
    optOut: 'optOut',
    error: 'error',
  },
  [MESSAGE_EVENT_TYPES.endUser]: {
    disabled: 'disabled',
    helpRequest: 'helpRequest',
    initGreeting: 'initGreeting',
    mms: 'mms',
    optIn: 'optIn',
    optOut: 'optOut',
    quoteRequest: 'quoteRequest',
    silent: 'silent',
    startRequest: 'startRequest',
    stopped: 'stopped',
    stopRequest: 'stopRequest',
    supportRequest: 'supportRequest',
    trafficControllerReset: 'trafficControllerReset',
    trafficControllerStatus: 'trafficControllerStatus',
    userMessage: 'userMessage',
  },
  [MESSAGE_EVENT_TYPES.notification]: {
    confirmation: 'confirmation',
    discovery: 'discovery',
    eventGreeting: 'eventGreeting',
    generalInformation: 'generalInformation',
    reminder: 'reminder',
    status: 'status',
    triggered: 'triggered',
  },
  [MESSAGE_EVENT_TYPES.testingChannel]: {
    highVolume: 'highVolume',
    recurring: 'recurring',
  },
} as const

export const MESSAGING_CHANNELS = {
  facebookMessenger: 'facebookMessenger',
  facebookWorkplace: 'facebookWorkplace',
  sms: 'sms',
  web: 'web',
  whatsApp: 'whatsApp',
  smoochProxy: 'smoochProxy',
  twitter: 'twitter',
} as const

export const MESSAGING_PROVIDERS = {
  facebookWorkplace: 'facebookWorkplace',
  messenger: 'messenger',
  smooch: 'smooch',
  telnyx: 'telnyx',
  twilio: 'twilio',
  ytel: 'ytel',
  zipwhip: 'zipwhip',
} as const

export const MESSAGE_PRIORITY_TYPES = {
  emergencyNotification: 'emergencyNotification',
  botResponse: 'botResponse',
  scheduledNotification: 'scheduledNotification',
} as const

export const MESSAGE_PRIORITIES = {
  [MESSAGE_PRIORITY_TYPES.emergencyNotification]: 1,
  [MESSAGE_PRIORITY_TYPES.botResponse]: 2,
  [MESSAGE_PRIORITY_TYPES.scheduledNotification]: 3,
} as const

export const NEW_LINK_DELIVERY_PLATFORMS = {
  email: 'email',
  [MESSAGING_CHANNELS.sms]: MESSAGING_CHANNELS.sms,
  [MESSAGING_CHANNELS.web]: MESSAGING_CHANNELS.web,
} as const
