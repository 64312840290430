import { atom, useRecoilState } from 'recoil'

const state = atom({
  key: 'app/triggeredScroll',
  default: false,
})

export const useTriggeredScroll = () => {
  return useRecoilState(state)
}
