import { getDispatch } from '../redux'
import { actions as authActions } from '../redux/auth/actionCreators'
import { ApiRequestRejection } from '../constants'
import rootFetch from './rootFetch'

const get = async url => {
  try {
    return await rootFetch.get(url)
  } catch (err) {
    processError(err)
  }
}

const methods = {
  POST: rootFetch.post,
  PATCH: rootFetch.patch,
  DELETE: rootFetch.delete,
}

const requestWithBody = async (url, body, method) => {
  try {
    return await methods[method](url, body)
  } catch (err) {
    processError(err)
  }
}

const processError = err => {
  if (err.status === 401) {
    getDispatch()(authActions.logout())
    window._logout()
  }
  if (err.status === 403) {
    throw err.message
  }

  if (err.status === 404) {
    // the action is responsible for consuming this.
    throw new Error(`${ApiRequestRejection.notFound} ${err.message}`)
  }

  throw err.message
}

export default {
  get,
  post: (url, body) => requestWithBody(url, body, 'POST'),
  patch: (url, body) => requestWithBody(url, body, 'PATCH'),
  delete: (url, body) => requestWithBody(url, body, 'DELETE'),
}
