import React from 'react'
import { Flex } from 'mui-blox'
import { makeStyles } from '@mui/styles'
import classNames from 'classnames'
import { useBreakpoint } from '../hooks/useBreakpoint'
import { useContentWidth } from './hooks/useContentWidth'

const HeaderFrame = ({ children, className, ...rest }) => {
  const classes = useStyles()

  const isXs = useBreakpoint('sm').down
  const { width } = useContentWidth()

  return (
    <Flex
      width='100%'
      justifyContent='center'
      alignItems={isXs ? 'center' : 'flex-end'}
      pb={isXs ? 0 : 0.5}
      {...rest}
      className={classNames(classes.topFrame, className)}
    >
      <Flex width='100%' maxWidth={width} justifyContent='space-between' alignItems='center' px={2}>
        {children}
      </Flex>
    </Flex>
  )
}

const useStyles = makeStyles(({ spacing, palette, breakpoints, layout, transitions }) => {
  const transition = transitions.create('padding', {
    easing: transitions.easing.sharp,
    duration: transitions.duration.short,
  })

  return {
    topFrame: {
      height: layout.topBarLarge,
      paddingLeft: layout.sideBarLarge,
      paddingBottom: spacing(2),
      [breakpoints.down('md')]: {
        paddingLeft: layout.sidebar,
        transition,
      },
      [breakpoints.down('sm')]: {
        paddingLeft: layout.topBar,
        backgroundColor: palette.primary.main,
        color: palette.common.white,
        height: layout.topBar,
        paddingBottom: 0,
      },
    },
  }
})

export default HeaderFrame
