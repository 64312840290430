import { useLocation, useHistory } from 'react-router'
import { parseQuery } from '../../../../core/util/parseQuery'
import { buildQuery } from '../../../../core/util/buildQuery'

export const useQueryParams = () => {
  const { search, pathname } = useLocation()
  const { push } = useHistory()

  const parsed = !search ? {} : parseQuery(search)

  const clear = () => {
    push(pathname)
  }

  const clearParam = key => {
    if (!(key in parsed)) return

    delete parsed[key]

    push(buildQuery(pathname, parsed))
  }

  const setParam = (key, value) => {
    push(buildQuery(pathname, { ...parsed, [key]: value }))
  }

  return {
    ...parsed,
    clear,
    clearParam,
    setParam,
  }
}
