export const BLOCK_TYPES = {
  keyDef: 'keyDef',
  data: 'data',
  form: 'form',
  hubspot: 'hubspot',
}

export const wellknownBlockKeys = {
  contactFirstName: 'contactFirstName',
  contactLastName: 'contactLastName',
  email: 'email',
  endDate: 'endDate',
  eventId: 'eventId',
  hubspotDealId: 'hubspotDealId',
  mobile: 'mobile',
  organizationName: 'organizationName',
  startDate: 'startDate',
}
