import { parseQuery } from '../../../redux/navigator/parseQuery'

export const getTokenFromSearch = queryString => {
  if (queryString) {
    const search = parseQuery(queryString)

    if (!!search.token && search.token !== '' && search.token !== 'undefined') {
      return search.token
    }
  }

  return null
}
