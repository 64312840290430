import React from 'react'
import PropTypes from 'prop-types'
import { Flex } from 'mui-blox'
import { Typography, Button } from '@mui/material'
import { useRedirectLink } from './hooks/useRedirectLink'
import { LogoProgress } from '../_controls'

const Redirector = ({ history: { goBack } }) => {
  const isRedirecting = useRedirectLink()

  return (
    <Flex flexColumn>
      <LogoProgress />
      {!isRedirecting && (
        <Flex flexColumn alignItems='center' pt={6}>
          <Typography>Our apologies. We were not able to redirect you.</Typography>
          <Button onClick={goBack}>Go Back</Button>
        </Flex>
      )}
    </Flex>
  )
}

Redirector.propTypes = {
  history: PropTypes.object.isRequired,
}

export default Redirector
