import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@mui/styles'
import ClassNames from 'classnames'
import { Flex } from 'mui-blox'
import useScrollTrigger from '@mui/material/useScrollTrigger'
import { useTriggeredScroll } from '~layout'

const Scrollable = ({ className = undefined, children, horizontal = false, vertical = true, ...rest }) => {
  const classes = useStyles()

  const [target, setTarget] = useState()

  const [, setTriggered] = useTriggeredScroll()

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: target,
  })

  useEffect(() => {
    setTriggered(trigger)
  }, [setTriggered, trigger])

  return (
    <Flex
      ref={node => setTarget(node)}
      flexDirection='column'
      className={ClassNames(className, {
        [classes.scrollable]: horizontal === vertical,
        [classes.vertical]: vertical,
        [classes.horizontal]: horizontal,
      })}
      {...rest}
    >
      {children}
    </Flex>
  )
}

Scrollable.propTypes = {
  horizontal: PropTypes.bool,
  vertical: PropTypes.bool,
}

const useStyles = makeStyles(({ breakpoints }) => ({
  scrollable: {
    [breakpoints.up('sm')]: {
      overflow: 'auto',
      marginTop: '1em',
      padding: 1,
    },
  },
  horizontal: {
    overflowX: 'auto',
    overflowY: 'hidden',
  },
  vertical: {
    [breakpoints.up('sm')]: {
      overflowX: 'hidden',
      overflowY: 'auto',
    },
  },
}))

export default Scrollable
