const trimQuery = x => x.trim().replace(/^(\?|#|&)/, '')
const decode = x => decodeURIComponent(x)

const assemblePairs = (params, pair) => {
  const newPair = pair.split('=').map(decode)
  const key = newPair[0]
  const val = newPair[1] || ''
  return {
    ...params,
    [key]: val,
  }
}

export const parseQuery = query => {
  if (typeof query !== 'string' || query.length === 0) return {}
  return trimQuery(query).split('&').reduce(assemblePairs, {})
}
