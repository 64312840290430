import { Components, Theme } from '@mui/material'
import { ComponentOptions } from './types'

export const MuiMenuItem = ({ typography }: ComponentOptions): Components<Theme>['MuiMenuItem'] => {
  return {
    defaultProps: {},
    styleOverrides: {
      root: {
        fontWeight: typography.fontWeightRegular,
      },
    },
  }
}
