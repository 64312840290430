import { useCallback } from 'react'
import { getApiUrl } from './getApiUrl'
import { FetchOptions } from './types'
import { useFetchHandler } from './useFetchHandler'
import { Body, usePayload } from './usePayload'
import { useProcessFetch } from './useProcessFetch'

export const useDelete = <TReturn>(options?: FetchOptions) => {
  const { fetch, isFetching } = useFetchHandler<TReturn>(options)
  const getPayload = usePayload('DELETE')
  const processFetch = useProcessFetch()

  const del = useCallback(
    async (url: string, body?: Body) => {
      return fetch(() => processFetch(getApiUrl(url), getPayload(body)))
    },
    [fetch, getPayload, processFetch],
  )

  return {
    del,
    isDeleting: isFetching,
  }
}
