import { useEffect, useCallback } from 'react'
import { logger } from '../../../utils/logger'
import { useSocketRoom } from './useSocketRoom'
import { SocketConnection, SocketActionHandler } from '../types'

export const useSocketAction = <T>(
  { namespace, room }: SocketConnection,
  actionName: string,
  handler: SocketActionHandler<T>,
): void => {
  const socket = useSocketRoom({ namespace, room })

  const subscription = useCallback(
    (payload, from) => {
      logger(`socket action:${actionName}`)
      handler(payload, from)
    },
    [actionName, handler],
  )

  // action handler
  useEffect(() => {
    if (socket) {
      logger(`socket.on ${actionName} with namespace:${namespace}`)
      socket.on(actionName, subscription)
    }

    return () => {
      if (socket) {
        logger(`socket.off ${actionName} with namespace:${namespace}`)
        socket.off(actionName, subscription)
      }
    }
  }, [subscription, socket, actionName, namespace])
}
