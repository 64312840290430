import { serializeStateToLocalStorage } from '../storageHelpers'

export function reduceAndSaveToLocalStorage(state, payload, stateField, storagePath, reducer) {
  if (payload.branch === storagePath) {
    const nextState = reducer(state, payload)
    serializeStateToLocalStorage(
      {
        [stateField]: nextState[stateField],
      },
      storagePath,
    )
    return nextState
  }

  return state
}

export function adjustColumnReducer(state, { column, delta }) {
  const current = { ...state.columnWidths }
  const currentDelta = current[column] ? current[column] : 0
  const newDelta = currentDelta + delta
  current[column] = newDelta
  return {
    ...state,
    columnWidths: current,
  }
}
