import { dispatchFetchingAction, dispatchPublishingAction } from '../../redux-helpers'
import { actions } from '../actionCreators'
import fetch from '../../../api/fetch'
import { getSystemPageNumberByFilter, getEventPageNumberByFilter } from '../selectors/idks'
import { getOrgId, getEventId } from '../selectors/routes'
import { isForEvent } from '../selectors/helpers'
import { idks as idkFilters } from '../apiFilters'

export const idkActivities = {
  setIdkValidity: actions.setIdkValidity,
  setIdkResolved: actions.setIdkResolved,
  setIdkEscalated: actions.setIdkEscalated,
}

export const loadIDKs = (filter = idkFilters.all) =>
  dispatchFetchingAction(actions.loadIdks, {
    payload: async (dispatch, getState) => {
      const page = getSystemPageNumberByFilter(getState(), filter)
      const result = await fetch.get(`/support/idks?filter=${filter}&page=${page}`)
      return {
        filter,
        page: page + 1,
        ...result,
      }
    },
    failPayload: () => ({
      filter,
      idks: [],
      platformLogs: [],
      count: 0,
    }),
  })

export const loadIDKsByEvent = (orgId, eventId, filter = idkFilters.all) =>
  dispatchFetchingAction(actions.loadIdksForEvent, {
    payload: async (dispatch, getState) => {
      const page = getEventPageNumberByFilter(getState(), orgId, eventId, filter)
      const results = await fetch.get(`/support/orgs/${orgId}/events/${eventId}/idks?filter=${filter}&page=${page}`)
      return {
        ...results,
        filter,
        page: page + 1,
        orgId,
        eventId,
      }
    },
    failPayload: () => ({
      idks: [],
      filter,
      page: 0,
      count: 0,
      orgId,
      eventId,
    }),
  })

export const autoLoadIdksByFilter = filter => async (dispatch, getState) => {
  const state = getState()
  if (isForEvent(state)) {
    const orgId = getOrgId(state)
    const eventId = getEventId(state)
    await dispatch(loadIDKsByEvent(orgId, eventId, filter))
  } else {
    await dispatch(loadIDKs(filter))
  }
}

const updateIdk = updatedIdk => async () => await fetch.patch(`/support/idks/${updatedIdk.get('id')}`, updatedIdk)

export const setIdkValidity = (idk, validity) =>
  dispatchPublishingAction(actions.setIdkValidity, {
    payload: updateIdk(idk.set('validity', validity)),
  })

export const toggleIdkResolved = idk =>
  dispatchPublishingAction(actions.setIdkResolved, {
    payload: updateIdk(idk.set('isResolved', !idk.get('isResolved', false))),
  })

export const toggleIsEscalated = idk =>
  dispatchPublishingAction(actions.setIdkEscalated, {
    payload: updateIdk(idk.set('isEscalated', !idk.get('isEscalated', false))),
  })

export const handleIdkSocketMessage = idk => actions.setIdk.complete({ idk })
