const PhoneNumbers = function (restClient) {
  this.restClient = restClient
}

PhoneNumbers.prototype.validatePhoneSID = async function (orgId, phoneSid) {
  return await this.restClient.post(`/orgs/${orgId}/validatePhoneSID`, {
    phoneSid,
  })
}

export default PhoneNumbers
