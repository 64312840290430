import { rgbaToHex } from './rgbaToHex'

const notSetValue = '#F1F1F1'

export const calculateSwatches = (value, { palette, mixins }, coefficient = 0.2) => {
  if (!value) {
    return {
      backgrounds: [],
      color: value,
    }
  }

  let lighter, light, dark, color
  try {
    lighter = rgbaToHex(mixins.lighten(value, coefficient * 2)).toUpperCase()
  } catch {
    lighter = notSetValue
  }
  try {
    light = rgbaToHex(mixins.lighten(value, coefficient)).toUpperCase()
  } catch {
    light = notSetValue
  }
  try {
    dark = rgbaToHex(mixins.darken(value, coefficient)).toUpperCase()
  } catch {
    dark = notSetValue
  }
  try {
    color = rgbaToHex(palette.getContrastText(dark)).toUpperCase()
  } catch {
    color = notSetValue
  }

  const main = rgbaToHex(value || notSetValue)

  return {
    backgrounds: [lighter, light, main, dark],
    color,
  }
}
