import { Map, fromJS } from 'immutable'
import { actions } from '../actionCreators'
import { paths } from './paths'

const reducerMap = {
  [actions.clinics.loadClinics]: (state, { clinics }) => {
    return state.setIn(paths.clinics(), fromJS(clinics))
  },
  [actions.clinics.loadCounties]: (state, { counties }) => {
    return state.setIn(paths.counties(), fromJS(counties))
  },
  [actions.clinics.loadPayers]: (state, { payers }) => {
    return state.setIn(paths.payers(), fromJS(payers))
  },
  [actions.clinics.setResults]: (state, { clinics, searchType, searchTerm }) =>
    state.setIn(paths.searchResults(searchType, searchTerm), fromJS(clinics)),
}

export const reducers = (state = Map(), action) => {
  const reducer = reducerMap[action.type]
  if (reducer) {
    return reducer(state, action.payload)
  }

  return state
}
