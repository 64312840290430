export enum socketNamespaces {
  admins = 'admins',
  api = 'api',
  app = 'app',
  bulldogger = 'bulldogger',
  customerOrgs = 'customerOrgs',
  eventBot = 'eventBot',
  extensionApi = 'extensionApi',
  extensionClient = 'extensionClient',
  inboundWebhooks = 'inboundWebhooks',
  orgs = 'orgs',
  participants = 'participants',
  root = '',
  testing = 'testing',
  users = 'users',
  workers = 'workers',
}

export const ANONYMOUS_SPACES = [
  socketNamespaces.extensionClient,
  socketNamespaces.root,
  socketNamespaces.participants,
  socketNamespaces.app,
  socketNamespaces.users,
]

export const socketActions = {
  APP: {
    version: 'version',
  },
  API: {
    authenticate: 'API.authenticate',
    notAuthenticated: 'API.notAuthenticated',
  },
  botAnswers: {
    update: 'botAnswers.update',
  },
  botReservations: {
    create: 'botReservations.create',
    cancel: 'botReservations.cancel',
  },
  botActiveState: 'botActiveState',
  botDeploy: {
    initiated: 'botDeploy.initiated',
    progress: 'botDeploy.progress',
    complete: 'botDeploy.complete',
    error: 'botDeploy.error',
  },
  botList: {
    downloadActive: {
      progress: 'adminBotList.active.download.progress',
      complete: 'adminBotList.active.download.complete',
      error: 'adminBotList.active.download.error',
    },
    download: {
      progress: 'adminBotList.download.progress',
      complete: 'adminBotList.download.complete',
      error: 'adminBotList.download.error',
    },
  },
  botMessage: 'botMessage',
  botMetrics: {
    error: 'botMetrics.error',
    progress: 'botMetrics.progress',
  },
  botQueue: {
    create: 'botQueue.create',
    update: 'botQueue.update',
  },
  botReview: {
    update: 'botReview.update',
  },
  calendar: {
    create: 'calendar.create',
    delete: 'calendar.delete',
    update: 'calendar.update',
    guest: 'calendar.guest',
  },
  conversationLog: {
    create: 'conversationLog.create',
  },
  conversationLogs: {
    download: {
      progress: 'messageLogs.download.progress',
      complete: 'messageLogs.download.complete',
      error: 'messageLogs.download.error',
    },
  },
  conversations: {
    syncProgress: 'conversations.syncProgress',
    syncComplete: 'conversations.syncComplete',
  },
  customerParticipants: {
    create: 'customerParticipants.create',
    update: 'customerParticipants.update',
    delete: 'customerParticipants.delete',
    download: {
      progress: 'customerParticipants.download.progress',
      complete: 'customerParticipants.download.complete',
      error: 'customerParticipants.download.error',
    },
  },
  demoBot: {
    deactivate: {
      progress: 'demoBot.deactivate.progress',
      complete: 'demoBot.deactivate.complete',
      error: 'demoBot.deactivate.error',
    },
  },
  errors: {
    generic: 'errors.generic',
  },
  event: {
    create: 'event.create',
    update: 'event.update',
    delete: 'event.delete',
    provisionComplete: 'event.provisionComplete',
    provisionUpdate: 'event.provisionUpdate',
    surveyUpdated: 'event.surveyResultsUpdated',
  },
  falsePositives: {
    create: 'falsePositives.create',
    update: 'falsePositives.update',
  },
  fileImports: {
    progress: 'fileImports.progress',
    complete: 'fileImports.complete',
    error: 'fileImports.error',
  },
  fileUploadTool: {
    validated: 'fileUploadTool.validated',
    uploaded: 'fileUploadTool.uploaded',
  },
  fileUploadLogs: {
    download: {
      progress: 'fileUploadLogs.download.progress',
      complete: 'fileUploadLogs.download.complete',
      error: 'fileUploadLogs.download.error',
    },
  },
  formResult: {
    updated: 'formResult.updated',
    created: 'formResult.creatd',
  },
  idk: {
    create: 'idk.create',
    update: 'idk.update',
  },
  liveChat: {
    available: 'liveChat.available',
  },
  messageLogs: {
    download: {
      progress: 'messageLogs.download.progress',
      complete: 'messageLogs.download.complete',
      error: 'messageLogs.download.error',
    },
  },
  participant: {
    create: 'participant.create',
    update: 'participant.update',
    delete: 'participant.delete',
    import: {
      started: 'participant.import.started',
      completed: 'participant.import.completed',
    },
  },
  platformLog: {
    create: 'platformLog.create',
    update: 'platformLog.update',
  },
  screening: {
    update: 'screening.update',
  },
  speakerSearch: {
    question: {
      created: 'speakerSearch.question.created',
    },
  },
  screeningLogs: {
    download: {
      progress: 'screeningLogs.download.progress',
      complete: 'screeningLogs.download.complete',
      error: 'screeningLogs.download.error',
    },
  },
  support: {
    create: 'support.create',
    update: 'support.update',
  },
  surveyResults: {
    create: 'surveyResults.create',
    update: 'surveyResults.update',
    delete: 'surveyResults.delete',
  },
  TESTING: {
    messageReceived: 'TESTING.messageReceived',
  },
  trafficController: {
    progress: 'trafficController.progress',
    complete: 'trafficController.complete',
    error: 'trafficController.error',
  },
  voting: {
    poll: {
      create: 'voting.poll.create',
      update: 'voting.poll.update',
      delete: 'voting.poll.delete',
      writeIn: 'voting.poll.writeIn',
    },
    response: {
      submit: 'voting.response.submit',
    },
  },
}
