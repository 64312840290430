import { Components, SimplePaletteColorOptions, Theme, tabClasses } from '@mui/material'
import { ComponentOptions } from './types'

export const MuiTabs = ({ palette }: ComponentOptions): Components<Theme>['MuiTabs'] => {
  const tertiary = palette.secondary as SimplePaletteColorOptions

  return {
    defaultProps: {},
    styleOverrides: {
      root: {
        fontWeight: 400,
        fontSize: '0.857rem',
        [`&.${tabClasses.selected}`]: {
          fontWeight: 700,
          color: tertiary.main,
        },
      },
    },
  }
}
