import { Theme, getContrastRatio, darken, lighten } from '@mui/material/styles'
import { rgbaToHex } from './rgbaToHex'

const notSetValue = '#F1F1F1'
const backgroundRatio = 1.3
const maxAttempts = 10

type LocalTheme = Theme & {
  mixins: Theme['mixins'] & {
    lighten: typeof lighten
    darken: typeof darken
  }
}

export const calculateBackgrounds = (_value = notSetValue, { mixins, palette }: LocalTheme, coefficient = 0.1) => {
  const value = _value || notSetValue

  let co: number = coefficient
  let attempts = 1
  let prev: string = value
  let curr: string | null = null
  let paper: string | null = null

  while (!paper) {
    curr = rgbaToHex(mixins.lighten(prev, co)).toUpperCase()
    const ratio = getContrastRatio(value, curr as string)

    if (ratio >= backgroundRatio) {
      paper = curr
    }
    if (prev === curr) {
      paper = curr
    }

    if (attempts > 6) {
      co = 1
    } else {
      co = co * 1.08 ** attempts
    }

    if (attempts++ >= maxAttempts) {
      paper = curr
    }

    prev = curr as string
  }

  const colorDefault = rgbaToHex(value)

  return {
    default: colorDefault,
    paper,
    backgrounds: [colorDefault, paper],
    color: rgbaToHex(palette.getContrastText(value)),
  } as const
}
