import { Socket } from 'socket.io-client'
import { createContext, useContext, useMemo } from 'react'
import { socketNamespaces } from '~constants'

type SetSocket = (socket: Socket, namespace: socketNamespaces) => void

interface AppSocketSpace {
  setSocket: SetSocket
  socket?: Socket
}

interface AppSocketSpaces {
  setSocket: SetSocket
  spaces: Partial<Record<socketNamespaces, Socket>>
}

export const SocketSpacesContext = createContext<AppSocketSpaces>({ setSocket: () => undefined, spaces: {} })

export const useSocketSpace = (namespace: socketNamespaces): AppSocketSpace => {
  const { spaces, setSocket } = useContext(SocketSpacesContext) as AppSocketSpaces

  const socket = useMemo(() => {
    return spaces[namespace]
  }, [namespace, spaces])

  return { socket, setSocket }
}
