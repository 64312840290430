import { combineActions, handleActions } from 'redux-actions'
import { actions, branchName } from './actionCreators'
import sharedActions from '../shared/actions'
import { adjustColumnReducer, reduceAndSaveToLocalStorage } from '../shared/reducers'
import { deserializeStateFromLocalStorage } from '../storageHelpers'
import {
  basicState,
  basicLoadCompleteReducer,
  basicFailReducer,
  basicStartReducer,
  gridPinReducer,
  gridSortState,
} from '../redux-helpers'

const storagePath = branchName

const initialState = {
  ...basicState(),
  byId: {},
  gridSort: gridSortState(),
  columnWidths: {},
  ...deserializeStateFromLocalStorage(storagePath),
  context: {},
}

export default handleActions(
  {
    [combineActions(actions.load.start)]: basicStartReducer,

    [combineActions(actions.load.fail)]: basicFailReducer,

    [actions.load.complete]: (state, action) => {
      const { idks, complete, eventId } = action.payload

      let byId = { ...state.byId }

      idks.forEach(idk => {
        byId[idk.id] = {
          ...idk,
          isEscalated: Boolean(idk.isEscalated),
          isResolved: Boolean(idk.isResolved),
          postEventRetro: Boolean(idk.postEventRetro),
        }
      })

      const eventContext = state.context[eventId] || {}

      return {
        ...basicLoadCompleteReducer(state, action),
        byId,
        context: {
          ...state.context,
          [eventId]: {
            ...eventContext,
            pagingComplete: complete,
          },
        },
      }
    },

    // pinColumn
    [sharedActions.pinColumn]: (state, { payload }) => {
      return reduceAndSaveToLocalStorage(state, payload, 'pinnedColumns', storagePath, gridPinReducer)
    },

    [sharedActions.adjustColumn]: (state, { payload }) => {
      return reduceAndSaveToLocalStorage(state, payload, 'columnWidths', storagePath, adjustColumnReducer)
    },

    [actions.gridSort.sort]: (state, action) => {
      return {
        ...state,
        gridSort: action.payload,
      }
    },

    [actions.save.complete]: (state, action) => {
      const { idk } = action.payload
      const updated = { ...state.byId }
      updated[idk.id] = idk
      return {
        ...basicLoadCompleteReducer(state, action),
        byId: updated,
      }
    },

    [actions.incrementPageIndex]: (state, { payload: eventId }) => {
      const eventContext = state.context[eventId] || {}

      return {
        ...state,
        context: {
          ...state.context,
          [eventId]: {
            ...eventContext,
            pageIndex: (eventContext.pageIndex || 0) + 1,
          },
        },
      }
    },

    [actions.resetPageIndex]: (state, { payload: eventId }) => {
      const eventContext = state.context[eventId] || {}

      return {
        ...state,
        byId: {},
        context: {
          ...state.context,
          [eventId]: {
            ...eventContext,
            pageIndex: 0,
            pagingComplete: false,
          },
        },
      }
    },

    [actions.filterableFields]: (state, { payload: { fields } }) => {
      return {
        ...state,
        filterableFields: fields,
      }
    },
    [actions.scrollLeft]: (state, { payload }) => {
      return {
        ...state,
        scrollLeft: payload,
      }
    },
  },
  initialState,
)
