export const basic = (palette, rem) => ({
  fontSize: 16,
  fontWeightMedium: 700,
  fontWeightLight: 300,
  fontWeightBold: 900,
  fontWeightRegular: 400,
  h1: {
    fontSize: rem(2),
    fontWeight: 600,
    lineHeight: 1.1,
    color: palette.text.primary,
  },
  h2: {
    fontSize: rem(2),
    fontWeight: 400,
    lineHeight: 1.1,
    color: palette.text.primary,
  },
  h3: {
    fontSize: rem(1.5),
    fontWeight: 600,
    lineHeight: 1.2,
    color: palette.text.primary,
  },
  h4: {
    fontSize: rem(1.5),
    fontWeight: 400,
    lineHeight: 1.2,
    color: palette.text.primary,
  },
  h5: {
    fontSize: rem(1.25),
    fontWeight: 600,
    lineHeight: 1.3,
    color: palette.text.primary,
  },
  h6: {
    fontSize: rem(1.25),
    fontWeight: 400,
    lineHeight: 1.3,
    color: palette.text.primary,
  },
  subtitle1: {
    fontSize: rem(1.1),
    fontWeight: 600,
    lineHeight: 1.3,
    color: palette.text.primary,
  },
  subtitle2: {
    fontSize: rem(1.1),
    fontWeight: 400,
    lineHeight: 1.3,
    color: palette.text.secondary,
  },
  body1: {
    fontSize: rem(1),
    color: palette.text.primary,
    lineHeight: 1.3,
  },
  body2: {
    fontSize: rem(1),
    color: palette.text.secondary,
    lineHeight: 1.3,
  },
  caption: {
    color: palette.text.secondary,
    lineHeight: 1.3,
  },
  button: {
    color: palette.text.primary,
  },
  overline: {
    color: palette.text.primary,
  },
})
