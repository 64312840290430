export const JOB_STATUS = {
  complete: 'complete',
  queued: 'queued',
  waiting: 'waiting',
  failed: 'failed',
  cronEnabled: 'cronEnabled',
  cronDisabled: 'cronDisabled',
}

export const JOB_TYPES = {
  botDeploy: 'botDeploy',
  cleanseBotParticipantsData: 'cleanseBotParticipantsData',
  generateInventoryFavoritesReport: 'generateInventoryFavoritesReport',
  generateEmailReport: 'generateEmailReport',
  messageErrorNotification: 'messageErrorNotification',
  messageStatusUpdate: 'messageStatusUpdate',
  migrateBotChannel: 'migrateBotChannel',
  processBatchTriggeredNotification: 'processBatchTriggeredNotification',
  processInventoryData: 'processInventoryData',
  processTriggeredNotification: 'processTriggeredNotification',
  nipr: {
    processArl: 'nipr.processArl',
  },
  adp: {
    processAdp: 'adp.processAdp',
  },
}

export const JOB_PRIORITIES = {
  [JOB_TYPES.botDeploy]: 1,
  [JOB_TYPES.cleanseBotParticipantsData]: 3,
  [JOB_TYPES.generateInventoryFavoritesReport]: 3,
  [JOB_TYPES.generateEmailReport]: 2,
  [JOB_TYPES.messageErrorNotification]: 1,
  [JOB_TYPES.messageStatusUpdate]: 3,
  [JOB_TYPES.migrateBotChannel]: 1,
  [JOB_TYPES.processBatchTriggeredNotification]: 1,
  [JOB_TYPES.processInventoryData]: 2,
  [JOB_TYPES.processTriggeredNotification]: 1,
  [JOB_TYPES.nipr.processArl]: 2,
  [JOB_TYPES.adp.processAdp]: 2,
}
