import { createActions } from 'redux-actions'
import { basicActions } from '../redux-helpers'

const actionCreators = createActions({
  templates: {
    applyPromoCode: basicActions(),
    chooseBotSurvey: basicActions(),
    clearCart: () => {},
    deleteBotSurveyResult: basicActions(),
    deleteProduct: basicActions(),
    deletePromoCode: basicActions(),
    finalizeCustomBotCart: basicActions(),
    getProductsForCart: payload => payload,
    loadBotSurvey: basicActions(),
    loadBotSurveyResults: basicActions(),
    loadBotSurveys: basicActions(),
    loadQualifierSurvey: basicActions(),
    loadShoppingCart: basicActions(),
    promoCodeMessage: message => ({ message }),
    saveShoppingCart: basicActions(),
    saveShoppingCartId: basicActions(),
    saveSurveyResults: basicActions(),
    setQualifiedTemplates: basicActions(),
    updateCartQuantity: basicActions(),
    updateProduct: basicActions(),
  },
})

export const actions = actionCreators.templates
