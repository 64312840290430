import React from 'react'
import { Flex } from 'mui-blox'
import { Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useSetPageTitle } from '../hooks/useSetPageTitle'
import Title from '../../page/Title'
import { useBreakpoint } from '../hooks/useBreakpoint'
import { typify } from '~core/util/data/typify'

const FramedTitle = () => {
  const classes = useStyles()

  const { pageTitle, pageSubtitle, windowTitle } = useSetPageTitle()

  const isSmall = useBreakpoint('sm').down

  const resolvedWindowTitle = windowTitle || typify.isString(pageTitle) ? pageTitle : '' || ''
  let resolvedTitle = pageTitle || ''
  let resolvedSubtitle = pageSubtitle || ''

  if (typify.isString(resolvedTitle))
    resolvedTitle = (
      <Typography color='inherit' className={classes.title}>
        {pageTitle}
      </Typography>
    )
  if (typify.isString(resolvedSubtitle))
    resolvedSubtitle = (
      <Typography color='inherit' className={classes.title}>
        {pageSubtitle}
      </Typography>
    )

  resolvedTitle = React.cloneElement(resolvedTitle, {
    noWrap: true,
    variant: 'h3',
    component: 'h1',
  })
  resolvedSubtitle = React.cloneElement(resolvedSubtitle, {
    variant: isSmall ? 'caption' : 'body2',
    component: 'h2',
  })

  return (
    <>
      <Title render={`${resolvedWindowTitle ? `${resolvedWindowTitle} - ` : ''}42Chat`} />
      <Flex flexDirection='column' width='100%' overflow='hidden' pb={isSmall ? 0 : 1}>
        {resolvedTitle}
        {resolvedSubtitle}
      </Flex>
    </>
  )
}

const useStyles = makeStyles(({ typography }) => ({
  title: {
    lineHeight: typography.h3.lineHeight,
  },
}))

export default FramedTitle
