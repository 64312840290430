import { Map, fromJS } from 'immutable'
import { actions } from '../actionCreators'
import { paths } from './paths'
import { arrayToMap } from '../../redux-helpers-immutable'

const reducerMap = {
  [actions.providers.setResults]: (state, { searchType, searchText, providers }) => {
    return state.setIn(paths.results(searchType, searchText), arrayToMap(providers))
  },
  [actions.providers.provider]: (state, { provider }) => {
    return state.setIn(paths.providerCache(provider.id), fromJS(provider))
  },
  [actions.providers.centers]: (state, { clinics }) => {
    return state.setIn(paths.centers(), fromJS(clinics))
  },
  [actions.providers.markets]: (state, { markets }) => {
    return state.setIn(paths.markets(), fromJS(markets))
  },
  [actions.providers.payers]: (state, { payers }) => {
    return state.setIn(paths.payers(), fromJS(payers))
  },
  [actions.providers.context.setShowBy]: (state, payload) => {
    return state.setIn(paths.showBy(), payload)
  },
}

export const reducers = (state = Map(), action) => {
  const reducer = reducerMap[action.type]
  if (reducer) {
    return reducer(state, action.payload)
  }

  return state
}
