export const trimIfNeeded = value => {
  if (!value) return value

  if (typeof value === 'string') {
    return value.trim()
  }

  return value
}

export const getRegExpFilter = value => {
  return {
    $regex: RegExp(value, 'i'),
  }
}

export const formatSupportUsername = username => {
  if (!username) {
    return username
  }
  if (username.includes('Twilio')) {
    return username.replace(/(Twilio.*\+)/, '+')
  }
  if (username.includes('Web User')) {
    return username.replace(/(Web User )/, 'Web:')
  }

  return username
}

export const getBooleanValue = value => {
  if (!value) {
    return false
  }
  if (typeof value === 'boolean') {
    return value
  }
  if (typeof value === 'string') {
    if (value.toLowerCase() === 'true' || value.toLowerCase() === 'yes') {
      return true
    } else if (value.toLowerCase() === 'false' || value.toLowerCase() === 'no') {
      return false
    }
  }
}
