import { io, Socket } from 'socket.io-client'

const connectAttemptLimit = 15

export const socketClient = (namespace: string, url: string, token: string): Socket => {
  let connectAttempts = 0

  const connectUrl = `${url}/${namespace}?token=${token}`
  const socket = io(connectUrl)

  socket.on('connect_error', () => {
    if (connectAttempts >= connectAttemptLimit) {
      socket.disconnect()
    }
    connectAttempts++
  })

  return socket
}
