import React from 'react'
import { LinearProgress } from '@mui/material'
import { Flex } from 'mui-blox'

type ComponentProps = {
  isLoading?: boolean
  height?: string
  progress?: number
  variant?: 'determinate' | 'indeterminate'
}

const LoadingIndicatorBar = ({
  isLoading = false,
  height = '4px',
  progress,
  variant,
  ...other
}: ComponentProps): JSX.Element => {
  const showProgress = isLoading && progress != null

  return isLoading ? (
    <LinearProgress
      color='primary'
      style={{ height }}
      variant={variant ? variant : showProgress ? 'determinate' : 'indeterminate'}
      value={progress}
      {...other}
    />
  ) : (
    <Flex sx={{ height, width: '100%', backgroundColor: 'inherit' }} />
  )
}

export default LoadingIndicatorBar
