import { ThemeProvider } from '@mui/styles'
import React, { useCallback, useEffect, useState } from 'react'
import { useResizeDetector } from 'react-resize-detector'
import { CustomFormMessages } from '~constants'
import { getTheme } from '~style'
import CustomForms from './CustomForms'
import { Flex } from 'mui-blox'
import { useCustomFormsHostListener } from './hooks/useCustomFormsHostListener'

const HEIGHT_OFFSET = 30

export const CustomFormsRoot = () => {
  const { ackHeight } = useCustomFormsHostListener()
  const [normalizedHeight, setNormalizedHeight] = useState(0)
  const intervalRef = React.useRef<NodeJS.Timer>()

  const theme = getTheme()

  const handleResizeFrame = useCallback((height: number) => {
    const type = CustomFormMessages.formDimensionsChanged

    window.parent.window.postMessage({ type, height }, window.clientHost)
  }, [])

  const { ref: formRef, height } = useResizeDetector<HTMLFormElement>()

  useEffect(() => {
    handleResizeFrame(normalizedHeight)
  }, [normalizedHeight, handleResizeFrame])

  useEffect(() => {
    const newHeight = Math.ceil((height || 0) + HEIGHT_OFFSET)

    setNormalizedHeight(newHeight)
  }, [height])

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      const h1 = ackHeight
      const h2 = normalizedHeight

      if (Math.abs(h1 - h2) > 5) {
        handleResizeFrame(normalizedHeight)
      }
    }, 50)

    return () => {
      clearInterval(intervalRef.current as NodeJS.Timeout)
    }
  }, [ackHeight, normalizedHeight, handleResizeFrame])

  return (
    <ThemeProvider theme={theme}>
      <Flex display='inline-block' width='100%' style={{ backgroundColor: 'transparent' }}>
        <Flex ref={formRef} width='100%' style={{ backgroundColor: 'white', borderRadius: 5 }}>
          <CustomForms />
        </Flex>
      </Flex>
    </ThemeProvider>
  )
}
