const CONTACT = {
  address: 'address',
  city: 'city',
  country: 'country',
  customerName: 'customer_name', // used for full name
  email: 'email',
  customerEmail: 'customer_email',
  firstname: 'firstname',
  lastname: 'lastname',
  mobilephone: 'mobilephone',
  organizationName: 'organization_name',
  state: 'state',
  zip: 'zip',
}

const EVENT = {
  agendaLinks: 'agenda_links',
  attendeeCount: 'estimated_attendees',
  featureBadgeDelivery: 'badge_delivery',
  eventApp: 'event_app',
  eventAppOtherDescribe: 'event_app_other_description',
  eventBotAvailabilityDate: 'eventbot_availability_date',
  eventDate: 'event_date',
  eventEndDate: 'event_end_date',
  eventName: 'name_of_event',
  eventType: 'event_type',
  eventTypeOtherDescribe: 'event_type_other_please_describe_',
  eventWebsite: 'event_website',
  exhibitionPlanningSoftware: 'exhibition_planning_software',
  exhibitionPlanningSoftwareOtherDescribe: 'exhibition_planning_software_other_description',
  exhibitorCount: 'number_of_exhibitors',
  meetingSchedulingSoftware: 'scheduling_software',
  meetingSchedulingSoftwareOtherDescribe: 'scheduling_software_other_description',
  MESSAGING_CHANNELS: 'messaging_channels',
  registrationSystem: 'registration_system',
  registrationSystemOtherDescribe: 'registration_system_other_description',
  schedulingSoftware: 'schedule_provider',
  speakerCount: 'number_of_speakers',
  educationTrackCount: 'education_track_count',
  featureSessionSearch: 'session_search',
  featureExhibitorSearch: 'exhibitor_search',
  featureSpeakerNotification: 'notify_speaker',
  featureSpeakerSearch: 'speaker_search',
  featureMeetingSchedules: 'personalized_schedule',
  featureItineraries: 'personalized_itinerary',
  featureVipResponses: 'vip_library',
  venueCity: 'venue_city',
  venueNamePrimary: 'primary_venue_name',
  websiteUrl: 'website_url',
  comments: 'description',
}

const HUBSPOT = {
  dealName: 'dealname', // same as event name
  leadStatus: 'hs_lead_status',
  proposalContactEmail: 'proposal_contact_email', // same as customer email
  pipeline: 'pipeline',
  dealstage: 'dealstage',
}

export const HUBSPOT_DESTINATIONS = {
  ...CONTACT,
  ...EVENT,
  ...HUBSPOT,
}
