export enum messengerMessages {
  frameHost = 'FRAME_HOST',
  messengerOpen = 'MESSENGER_OPEN',
  messengerClose = 'MESSENGER_CLOSE',
  messengerPopupBubble = 'MESSENGER_POPUP_BUBBLE',
  parentWidth = 'PARENT_WIDTH',
  requestParentWidth = 'REQUEST_PARENT_WIDTH',
}

export enum messengerVariants {
  BUTTON = 'button',
  EMBEDDED = 'embedded',
  NO_FRAME = 'noFrame',
}

export enum messengerButtonStyle {
  FLOATING_BUTTON = 'floatingButton',
  TAB = 'tab',
}
