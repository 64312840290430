export const billingMetrics = {
  healthScreenings: 'healthScreenings',
}

export const billingUsageTypes = {
  daily: 'daily',
  monthly: 'monthly',
}

export const billingEventTypes = {
  healthScreenings: 'healthScreenings',
  incomingUserMessage: 'incomingUserMessage',
  botResponse: 'botResponse',
  support: 'support',
  initGreeting: 'initGreeting',
  adhocNotification: 'adhocNotification', // (Not Created in BotBuilder)
  scheduledNotification: 'scheduledNotification', //(Created in BotBuilder),
  exconNotification: 'exconNotification', // (sent as a result of an excon activity like completed screen, update profile, etc.)
  optOut: 'optOut',
  optIn: 'optIn',
}

export const billingEventSubTypes = {
  nlpResponse: 'nlpResponse',
  notificationRecurring: 'recurring',
  trafficController: 'trafficController',
  userMessage: 'userMessage',
}

export const billingEventTypeLabels = {
  [billingEventTypes.healthScreenings]: 'Health Screenings',
  [billingEventTypes.incomingUserMessage]: 'Incoming User Message',
  [billingEventTypes.botResponse]: 'Bot Responses',
  [billingEventTypes.initGreeting]: 'Init Greeting',
  [billingEventTypes.adhocNotification]: 'Ad-Hoc Notification',
  [billingEventTypes.optOut]: 'Opt Out',
  [billingEventTypes.optIn]: 'Opt In',
  [billingEventTypes.scheduledNotification]: 'Scheduled Notification',
}

export const billingExclusionTypes = {
  participant: 'participant',
  screening: 'screening',
}
