export const CALENDAR_RESOURCES = {
  items: 'items',
  upload: 'upload',
  reminders: 'reminders',
  guests: 'guests',
}

export const CALENDAR_EVENT_REGISTRATION = {
  invite: 'invite',
  open: 'open',
  closed: 'closed',
}

export const CALENDAR_EVENT_TYPE = {
  date: 'date',
  dateTime: 'dateTime',
}

export const CALENDAR_RESOURCE_NAMES = {
  [CALENDAR_RESOURCES.items]: 'Modify Items',
  [CALENDAR_RESOURCES.upload]: 'Upload Items',
  [CALENDAR_RESOURCES.reminders]: 'Modify Reminders',
  [CALENDAR_RESOURCES.guests]: 'Modify Guests',
}

export const CALENDAR_PERMIT_RULES = {
  participantType: 'participantType',
  participantId: 'participantId',
  participantTag: 'participantTag',
}

export const CALENDAR_PERMIT_RULES_NAMES = {
  [CALENDAR_PERMIT_RULES.participantType]: 'Participant Type',
  [CALENDAR_PERMIT_RULES.participantId]: 'Participant Ids',
  [CALENDAR_PERMIT_RULES.participantTag]: 'Participant Tags',
}

export const CALENDAR_ACTION_TRIGGERS = {
  checkIn: 'checkIn',
  signUp: 'signUp',
}

export const CALENDAR_VERBS = {
  subscribed: 'subscribed',
  unsubscribed: 'unsubscribed',
  confirmed: 'confirmed',
  unconfirmed: 'unconfirmed',
}
