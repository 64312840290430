import { fromJS, Map } from 'immutable'
import { handleActions } from 'redux-actions'
import paths from './paths'
import { actions } from './actionCreators'
import { deserializeStateFromLocalStorage } from '../storageHelpers'

const getStoredState = () => fromJS(deserializeStateFromLocalStorage(paths._root()))

const initialize = () => {
  return Map().merge(getStoredState())
}

const handleRect = ({ width, height, top, right, bottom, left }) => ({
  width,
  height,
  top,
  right,
  bottom,
  left,
})

export default handleActions(
  {
    [actions.themePreview]: (state, { payload: { themePreview } }) =>
      state.setIn(paths.themePreview(), fromJS(themePreview)),

    [actions.showMediaUploadModal]: (state, { payload: { meta } }) =>
      state.setIn(paths.mediaUploadModal(), fromJS({ visible: true, meta })),

    [actions.hideMediaUploadModal]: state =>
      state.setIn(paths.mediaUploadModal(), fromJS({ visible: false, meta: {} })),

    [actions.showModal]: (state, { payload: { modalType, meta } }) =>
      state.setIn(paths.modal(), fromJS({ modalType, meta })),

    [actions.hideModal]: state => state.setIn(paths.modal(), fromJS({ modalType: null, meta: {} })),

    [actions.activityStarted]: (state, { payload: { activity } }) =>
      state.setIn(paths.activityStatus(activity), true),

    [actions.activityEnded]: (state, { payload: { activity } }) =>
      state.setIn(paths.activityStatus(activity), false),

    [actions.setCardLocation]: (state, { payload: { rect } }) =>
      state.setIn(paths.cardLocation(), fromJS(handleRect(rect))),
  },
  initialize(),
)
