import { useEffect } from 'react'
import { Socket } from 'socket.io-client'
import { logger } from '../../../utils/logger'
import { socketNamespaces } from '~constants'
import { socketClient } from '~socketv4'
import { sciensio } from '../../../config'
import { useSocketSpace } from './useSocketSpace'

export const useSocketConnection = (namespace: socketNamespaces, token?: string): Socket | undefined => {
  const { socket, setSocket } = useSocketSpace(namespace)

  useEffect(() => {
    if (!socket) {
      const { socketPort, socketUrl, anonymousKey } = sciensio

      const url = socketPort ? `${socketUrl}:${socketPort}` : socketUrl
      const key = token || (anonymousKey as string)

      const io = socketClient(namespace, url, key)

      io.on('connect', () => logger(`socket connected on ${namespace}`))
      io.on('disconnect', () => logger(`socket disconnected from ${namespace}`))

      setSocket(io, namespace)
    }
  }, [socket, namespace, token, setSocket])

  return socket
}
