import React from 'react'
import PropTypes from 'prop-types'
import IPropTypes from 'react-immutable-proptypes'
import { isImmutable } from 'immutable'
import { Flex } from 'mui-blox'
import { Datography } from '../_controls'
import { extractMessage } from '../../../core/botMessages/sendPipeline/extractMessage'
import RenderBubbleContent from './RenderBubbleContent'
import { CONVERSATION_SOURCE_TYPES } from '~constants'
import FlexBubble from '../_controls/conversation/FlexBubble'
import { getBubbleSource } from './hooks/getBubbleSource'

const NewConversationRow = ({
  log: rawLog,
  next: rawNext,
  prev: rawPrev,
  primarySource = CONVERSATION_SOURCE_TYPES.user,
}) => {
  const log = isImmutable(rawLog) ? rawLog.toJS() : rawLog
  const next = isImmutable(rawNext) ? rawNext.toJS() : rawNext
  const prev = isImmutable(rawPrev) ? rawPrev.toJS() : rawPrev

  const bubbleSource = getBubbleSource(log)
  const nextSource = getBubbleSource(next)
  const prevSource = getBubbleSource(prev)

  const pending = log.pending

  let alignment
  let tick
  let color

  switch (bubbleSource) {
    case CONVERSATION_SOURCE_TYPES.support:
      switch (primarySource) {
        case CONVERSATION_SOURCE_TYPES.support:
          alignment = 'flex-end'
          color = 'primary'
          tick = 'right'
          break
        case CONVERSATION_SOURCE_TYPES.user:
        default:
          alignment = 'flex-start'
          color = 'secondary'
          tick = 'left'
          break
      }
      break
    case CONVERSATION_SOURCE_TYPES.bot:
      switch (primarySource) {
        case CONVERSATION_SOURCE_TYPES.support:
          alignment = 'flex-end'
          color = 'secondary'
          tick = 'right'
          break
        case CONVERSATION_SOURCE_TYPES.user:
        default:
          alignment = 'flex-start'
          color = 'secondary'
          tick = 'left'
          break
      }
      break
    case CONVERSATION_SOURCE_TYPES.user:
    default:
      switch (primarySource) {
        case CONVERSATION_SOURCE_TYPES.support:
          alignment = 'flex-start'
          color = 'secondary'
          tick = 'left'
          break
        case CONVERSATION_SOURCE_TYPES.user:
        default:
          alignment = 'flex-end'
          color = 'branding'
          tick = 'right'
          break
      }
      break
  }

  if (nextSource && bubbleSource === nextSource) {
    tick = null
  }

  const showTime = bubbleSource !== prevSource
  const lines = extractMessage(log.message || '')

  return (
    <Flex flexColumn alignItems={alignment} mb={tick ? 2 : 0.5} pt={0.25}>
      {showTime && (
        <Datography variant='caption' showLocal style={{ fontSize: '0.75rem', marginBottom: '2px' }}>
          {log.messageTimestamp}
        </Datography>
      )}
      <Flex width={0} height={0} />
      <Flex flexColumn alignItems={alignment}>
        <FlexBubble tick={tick} color={color} pending={pending}>
          {lines.map((line, index) => {
            const key = `${log.messageTimestamp}${String(index)}`
            return <RenderBubbleContent bubble={line} key={key} />
          })}
        </FlexBubble>
      </Flex>
    </Flex>
  )
}

NewConversationRow.propTypes = {
  log: PropTypes.oneOfType([IPropTypes.map, PropTypes.object]).isRequired,
  next: PropTypes.oneOfType([IPropTypes.map, PropTypes.object]),
  prev: PropTypes.oneOfType([IPropTypes.map, PropTypes.object]),
  primarySource: PropTypes.oneOf(Object.values(CONVERSATION_SOURCE_TYPES)),
}

export default NewConversationRow
