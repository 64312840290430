export const serializeStateToLocalStorage = (state, storageLocation) =>
  serializeState(localStorage, { ...deserializeState(localStorage, storageLocation), ...state }, storageLocation)

export const serializeStateToSessionStorage = (state, storageKey) =>
  serializeState(sessionStorage, { ...deserializeState(localStorage, storageKey), ...state }, storageKey)

export const deserializeStateFromLocalStorage = storageLocation => deserializeState(localStorage, storageLocation)

export const deserializeStateFromSessionStorage = storageKey => deserializeState(sessionStorage, storageKey)

const serializeState = (storageType, state, storageLocation) => {
  storageType[storageLocation] = JSON.stringify(state)
  return state
}

const deserializeState = (storageType, storageLocation) => {
  try {
    if (storageType[storageLocation]) {
      return JSON.parse(storageType[storageLocation])
    }
  } catch (error) {
    return {}
  }
  return {}
}
