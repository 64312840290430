import { createActions } from 'redux-actions'
import { basicActions, simpleAction, gridSortActions } from '../redux-helpers'

const actionCreators = createActions({
  nlpLogs: {
    gridSort: gridSortActions(),
    incrementPageIndex: simpleAction,
    load: basicActions(),
    resetPageIndex: simpleAction,
    save: basicActions(),
    filterableFields: simpleAction,
  },
})

export const actions = actionCreators.nlpLogs
export const branch = 'nlpLogs'
