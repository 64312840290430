import { getParticipantBearerToken } from './getParticipantBearerToken'
import { getBearerToken } from './getBearerToken'

export const getPayload = (method, body) => {
  const bearer = getBearerToken()
  // TODO:participantAuth remove after 1 week
  const participantBearer = getParticipantBearerToken()

  const payload = {
    credentials: 'include',
    method,
    headers: {
      'Content-Type': 'application/json',
      Authorization: bearer,
      ParticipantAuth: participantBearer,
    },
  }

  return body
    ? {
        ...payload,
        body: JSON.stringify(body),
      }
    : payload
}
