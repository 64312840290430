import { typify } from '../util/data/typify'

export const definitions = {
  any: '*:*',
  readonly: '*:read',
  recordPayments: '*:recordPayments',
  events: {
    all: 'events:*',
    create: 'events:create',
    delete: 'events:delete',
    read: 'events:read',
    update: 'events:update',
  },
  exhibitors: {
    import: 'exhibitors:import',
  },
  documentUploads: {
    all: 'documentUploads:*',
    read: 'documentUploads:read',
    update: 'documentUploads:update',
  },
  votingControls: {
    all: 'voting:*',
    read: 'voting:read',
    update: 'voting:update',
  },
  invites: {
    all: 'invites:*',
    create: 'invites:create',
    delete: 'invites:delete',
    read: 'invites:read',
    update: 'invites:update',
  },
  nlpLogs: {
    all: 'nlpLogs:*',
    read: 'nlpLogs:read',
    update: 'nlpLogs:update',
  },
  notifications: {
    all: 'notifications:*',
    create: 'notifications:create',
    delete: 'notifications:delete',
    read: 'notifications:read',
    update: 'notifications:update',
  },
  orgs: {
    all: 'orgs:*',
    create: 'orgs:create',
    delete: 'orgs:delete',
    read: 'orgs:read',
    update: 'orgs:update',
  },
  participants: {
    all: 'participants:*',
    create: 'participants:create',
    delete: 'participants:delete',
    read: 'participants:read',
    update: 'participants:update',
    import: 'participants:import',
  },
  questions: {
    all: 'questions:*',
    create: 'questions:create',
    delete: 'questions:delete',
    read: 'questions:read',
    update: 'questions:update',
    import: 'questions:import',
  },
  responses: {
    all: 'responses:*',
    create: 'responses:create',
    delete: 'responses:delete',
    read: 'responses:read',
    update: 'responses:update',
  },
  scheduleItems: {
    import: 'scheduleItems:import',
  },
  sessions: {
    import: 'sessions:import',
  },
  speakers: {
    import: 'speakers:import',
  },
  sponsors: {
    import: 'sponsors:import',
  },
  storage: {
    all: 'storage:*',
    upload: 'storage:upload',
  },
  tournaments: {
    import: 'tournament:import',
  },
  users: {
    all: 'users:*',
    create: 'users:create',
    delete: 'users:delete',
    read: 'users:read',
    update: 'users:update',
  },
  idks: {
    all: 'idks:*',
    read: 'idks:read',
    update: 'idks:update',
  },
  messages: {
    all: 'messages:*',
    send: 'messages:send',
  },
  support: {
    all: 'support:*',
    read: 'support:read',
    update: 'support:update',
  },
  training: {
    all: 'training:*',
  },
  // REMINDER: UPDATE THE DEFAULT CUSTOMER SCOPES BELOW IF NEEDED
  customerEvents: {
    all: 'customerEvents:*',
    create: 'customerEvents:create',
    delete: 'customerEvents:delete',
    read: 'customerEvents:read',
    update: 'customerEvents:update',
  },
  customerParticipants: {
    all: 'customerParticipants:*',
    create: 'customerParticipants:create',
    delete: 'customerParticipants:delete',
    read: 'customerParticipants:read',
    update: 'customerParticipants:update',
  },
  customerNotifications: {
    all: 'customerNotifications:*',
    create: 'customerNotifications:create',
    delete: 'customerNotifications:delete',
    read: 'customerNotifications:read',
    update: 'customerNotifications:update',
  },
  customerResponses: {
    all: 'customerResponses:*',
    create: 'customerResponses:create',
    delete: 'customerResponses:delete',
    read: 'customerResponses:read',
    update: 'customerResponses:update',
  },
  customerBots: {
    all: 'customerBots:*',
    create: 'customerBots:create',
    delete: 'customerBots:delete',
    read: 'customerBots:read',
    update: 'customerBots:update',
  },
  customerQuestionnaire: {
    all: 'customerQuestionnaire:*',
    read: 'customerQuestionnaire:read',
    update: 'customerQuestionnaire:update',
  },
  customerScreeningManager: {
    all: 'customerScreeningManager:*',
    create: 'customerScreeningManager:create',
    read: 'customerScreeningManager:read',
    update: 'customerScreeningManager:update',
  },
  customerScreeningReports: {
    all: 'customerScreeningReports:*',
    create: 'customerScreeningReports:create',
    read: 'customerScreeningReports:read',
    update: 'customerScreeningReports:update',
  },
  customerCalendarManager: {
    all: 'customerCalendarManager:*',
    create: 'customerCalendarManager:create',
    read: 'customerCalendarManager:read',
    update: 'customerCalendarManager:update',
  },
  customerSupport: {
    all: 'customerSupport:*',
    create: 'customerSupport:create',
    read: 'customerSupport:read',
    update: 'customerSupport:update',
  },
  customerAdminStatusTool: {
    all: 'customerAdminStatusTool:*',
    create: 'customerAdminStatusTool:create',
    read: 'customerAdminStatusTool:read',
    update: 'customerAdminStatusTool:update',
  },
  customerBYODStatusAdmin: {
    all: 'customerBYODStatusAdmin:*',
    create: 'customerBYODStatusAdmin:create',
    read: 'customerBYODStatusAdmin:read',
    update: 'customerBYODStatusAdmin:update',
  },
}

export const allScopes = Object.values(definitions).reduce((acc, value) => {
  if (typify.isString(value)) return acc
  return [...acc, ...Object.values(value)]
}, [])
