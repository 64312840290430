import React from 'react'
import PropTypes from 'prop-types'
import { useSpring, animated } from 'react-spring'
import { makeStyles } from '@mui/styles'

const ExconView = ({ url }) => {
  const classes = useStyles()
  const style = useSpring({
    from: { opacity: 0, transform: 'translate3d(100%,0,0)' },
    to: { opacity: 1, transform: 'translate3d(0%,0,0)' },
  })

  return <animated.iframe src={url} className={classes.root} style={style} title='42Chat-excon-frame' />
}

ExconView.propTypes = {
  url: PropTypes.string.isRequired,
}

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
    width: '100%',
    border: 'none',
  },
}))
export default ExconView
