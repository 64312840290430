import { handleActions, combineActions } from 'redux-actions'
import { Map, fromJS } from 'immutable'
import { actions } from './actionCreators'
import paths from './paths'

import {
  arrayToMap,
  basicCompleteReducer,
  basicFailReducer,
  basicStartReducer,
  gridSortReducer,
} from '../redux-helpers-immutable'

export default handleActions(
  {
    [combineActions(actions.loadParticipants.start, actions.saveParticipant.start, actions.deleteParticipant.start)]:
      basicStartReducer,

    [combineActions(actions.loadParticipants.fail, actions.saveParticipant.fail, actions.deleteParticipant.fail)]:
      basicFailReducer,

    [actions.loadParticipants.complete]: (state, { payload: { participants, orgId, eventId } }) =>
      basicCompleteReducer(state).setIn(paths.participants(orgId, eventId), arrayToMap(participants)),

    [actions.clear]: (state, { payload: { orgId, eventId } }) => state.deleteIn(paths.participants(orgId, eventId)),

    [actions.gridSort.sort]: (state, action) => gridSortReducer(state, action),

    [actions.saveParticipant.complete]: (state, { payload: { participant } }) =>
      state.setIn(paths.participant(participant.orgId, participant.eventId, participant.id), fromJS(participant)),
    //  [actions.setFilterValue]: (state, { payload: { filter } }) =>
    //    state.setIn(paths.filter(), filter),

    [actions.deleteParticipant.complete]: (state, { payload: { orgId, eventId, participantId } }) =>
      state.deleteIn(paths.participant(orgId, eventId, participantId)),
  },
  Map(),
)
