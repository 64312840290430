import { Components, Theme } from '@mui/material'
import { ComponentOptions } from './types'

export const MuiTableCell = ({ palette }: ComponentOptions): Components<Theme>['MuiTableCell'] => {
  return {
    defaultProps: {},
    styleOverrides: {
      root: {
        borderBottomColor: palette.divider,
      },
    },
  }
}
