import fetch from '../api/rootFetch'

export const simpleFetch = {
  get: (url, options) => handler(fetch.get(url, true), options),
  delete: (url, body, options) => handler(fetch.delete(url, body), options),
  post: (url, body, options) => handler(fetch.post(url, body), options),
  patch: (url, body, options) => handler(fetch.patch(url, body), options),
}

async function handler(promise, options) {
  try {
    options?.onInit?.()
    const result = await promise
    options?.onSuccess?.()
    return result
  } catch (err) {
    options?.onError?.(err)
  } finally {
    options?.onFinally?.()
  }
}
