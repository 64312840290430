import { fromJS, List } from 'immutable'
import React, { useMemo } from 'react'
import { useLocation } from 'react-router'
import { POWER_UP_KEYS } from '~constants'
import { ImmutableMap } from '~core/botPlan/executors/types'
import TitleBar from '../topbar/TitleBar'
import { useSetExconTitle } from '~layout'
import BreadcrumbButtons from '../../_controls/navigation/BreadcrumbButtons'
import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

type BotMenuSettings = { homePageEnabled: boolean; availableExcons: ImmutableMap<Record<string, unknown>>[] }
interface TopBarProps {
  logoUrl?: string
  pageTitle?: string
  settings: ImmutableMap<{
    branding: Record<string, unknown>
    powerUpSettings: { botMenu?: BotMenuSettings }
    hostname?: string
    eventId: string
  }>
}

const ExconTopBar = ({ logoUrl, pageTitle = '', settings }: TopBarProps): JSX.Element | null => {
  const classes = useStyles()

  const botMenuSettings: ImmutableMap<BotMenuSettings> = settings.getIn(
    ['powerUpSettings', POWER_UP_KEYS.botMenu],
    fromJS({}),
  ) as ImmutableMap<BotMenuSettings>
  const { pathname } = useLocation()
  const { hideTitleBar } = useSetExconTitle()

  const shellEnabled = useMemo(() => {
    const homePageEnabled = botMenuSettings.get('homePageEnabled', false)
    const currentExcon = botMenuSettings
      .get('availableExcons', List())
      .find((excon: ImmutableMap<{ exconId: string }>) => {
        return pathname.indexOf(excon.get('exconId', '')) > 0
      })

    return homePageEnabled && !!currentExcon
  }, [botMenuSettings, pathname])

  const { pageTitle: contextPageTitle, showBackButton } = useSetExconTitle()

  const resolvedPageTitle = pageTitle || contextPageTitle

  const handleGoBack = () => {
    const hash = new Date().getTime()
    const eventId = settings.get('eventId')

    window.location.href = `/chatbot?eventId=${eventId}&variant=embedded&hash=${hash}`
  }

  if (shellEnabled || hideTitleBar) return null

  return (
    <>
      <TitleBar
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        pageTitle={resolvedPageTitle}
        logoUrl={logoUrl}
      />
      <BreadcrumbButtons
        allowBack={showBackButton}
        className={classes.navButtons}
        customGoBackHandler={handleGoBack}
      />
    </>
  )
}

const useStyles = makeStyles<Theme & { layout: { topBarMedium: number } }>(
  ({ shadows, spacing, zIndex, layout }) => ({
    root: {
      position: 'relative',
    },
    navButtons: () => ({
      height: '22px',
      position: 'absolute',
      top: `${layout.topBarMedium - 11}px`,
      left: spacing(1.5),
      zIndex: zIndex.appBar + 1,
      boxShadow: shadows[3],
    }),
  }),
)

export default ExconTopBar
