// import { serializeStateToLocalStorage } from '../../redux/storageHelpers-immutable'

const handleUnauthorized = (response, reject, handlers = {}) => {
  const args = {
    url: response.url,
    status: response.status,
    statusText: response.statusText,
    message: 'User is not authorized',
  }

  console.error(`Unauthorized: ${JSON.stringify(args)}`)

  const { unAuthHandler } = handlers
  if (unAuthHandler) {
    return unAuthHandler(args)
  }

  // Note: These things are branch-specific (i.e. excons or admin app), and should be handled using unauthorizedHandlers

  // serializeStateToLocalStorage('auth', {})
  // serializeStateToLocalStorage('participantAuth', {})

  reject(args)

  return
}

export const processError = async (response, reject, handlers = {}) => {
  switch (response.status) {
    case 401:
    case 403: {
      handleUnauthorized(response, reject, handlers)
      return
    }
    case 404:
      reject({
        status: response.status,
        message: 'Resource was not found',
      })
      return
    default: {
      const error = await response.text()
      let errorMessage

      try {
        errorMessage = getErrorMessage(JSON.parse(error)) || error
      } catch {
        errorMessage = getErrorMessage(error)
      }

      if (Array.isArray(errorMessage)) {
        errorMessage = errorMessage.join(', ')
      }

      reject({
        status: response.status,
        message: errorMessage,
      })
    }
  }
}

const getErrorMessage = error => {
  if (error.error) return error.error
  if (error.errors) return error.errors
  if (error.message) return error.message

  return ''
}
