import { useEffect, useState } from 'react'

let cachedLinks = []
export const useScript = src => {
  const [state, setState] = useState({
    loaded: false,
    error: false,
  })

  useEffect(() => {
    if (cachedLinks.includes(src)) {
      setState({
        loaded: true,
        error: false,
      })
    } else {
      cachedLinks.push(src)

      let script = document.createElement('script')

      script.src = src
      script.async = true

      const onLinkLoad = () => {
        setState({
          loaded: true,
          error: false,
        })
      }

      const onLinkError = err => {
        console.error('Error loading script', err)
        const index = cachedLinks.indexOf(src)
        if (index >= 0) cachedLinks.splice(index, 1)
        script.remove()

        setState({
          loaded: true,
          error: true,
        })
      }

      script.addEventListener('load', onLinkLoad)
      script.addEventListener('error', onLinkError)

      document.head.appendChild(script)

      return () => {
        script.removeEventListener('load', onLinkLoad)
        script.removeEventListener('error', onLinkError)
      }
    }
  }, [src])

  return [state.loaded, state.error]
}
