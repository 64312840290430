import React from 'react'
import PropTypes from 'prop-types'
import { Button as MuiButton } from '@mui/material'
import { withStyles } from '@mui/styles'
import ClassNames from 'classnames'

const Button = ({ classes, className, children, iconRight, spaced, primary, size, ...rest }) => {
  return (
    <MuiButton
      className={ClassNames(
        {
          [classes.root]: true,
          [classes.iconRight]: iconRight,
          [classes.spaced]: spaced,
          [classes.small]: size === 'small',
        },
        className,
      )}
      variant={primary ? 'contained' : undefined}
      color={primary ? 'primary' : undefined}
      {...rest}
    >
      {children}
    </MuiButton>
  )
}

Button.propTypes = {
  iconRight: PropTypes.bool,
  spaced: PropTypes.bool,
  primary: PropTypes.bool,
  size: PropTypes.oneOf(['normal', 'small']),
}

Button.defaultProps = {
  iconRight: false,
  spaced: false,
  primary: false,
  size: 'normal',
}

const styles = ({ spacing }) => ({
  root: {
    '& svg': {
      marginRight: spacing(1),
    },
  },
  spaced: {
    marginLeft: spacing(1),
  },
  iconRight: {
    '& svg': {
      marginLeft: spacing(1),
      marginRight: 0,
    },
  },
  small: {
    padding: '4px',
    minHeight: 'unset',
  },
})

export default withStyles(styles)(Button)
