import { createActions } from 'redux-actions'
import { basicActions, filterActions, gridSortActions, modalActions, basicActionsWithMeta } from '../redux-helpers'

const actionCreators = createActions({
  responses: {
    exportIntents: basicActions(),
    filter: filterActions(),
    gridSort: gridSortActions(),
    load: basicActionsWithMeta(),
    syncIntents: basicActions(),
    toggleSelection: id => id,
    trainingModal: modalActions(),
    runTraining: basicActions(),
    removeExtension: id => ({ id }),
    addExtension: (id, extension) => ({ id, extension }),
  },
})

export const branchName = 'responses'

export const actions = actionCreators.responses
