import { useState, useCallback, useEffect } from 'react'
import { useGet } from '../fetch'
import { useTheme } from '../../../react/branding/hooks/useTheme'
import { DeepPartial } from '~models/util'
import { BotBranding } from '../../../react/messenger/types'

export type BrandingValues = DeepPartial<BotBranding>

interface Options {
  themeName?: string
  disabled?: boolean
  eventId?: string
  settingKey?: string
  initialValues?: BrandingValues
}

export const useGetEventTheme = ({ disabled, eventId, settingKey, initialValues, themeName }: Options) => {
  const [branding, setBranding] = useState(initialValues)

  const { get, isGetting } = useGet<{ setting: { value: BrandingValues } }>()

  const retrieveBranding = useCallback(async () => {
    if (eventId && !disabled) {
      const { setting } = await get(`/settings/events/${eventId}/key/${settingKey}`)

      setBranding(setting ? setting.value : {})
    }
  }, [disabled, eventId, get, settingKey])

  useEffect(() => {
    if (!isGetting && !branding && eventId && settingKey) {
      retrieveBranding()
    }
  }, [disabled, eventId, get, settingKey, retrieveBranding, branding, isGetting])

  useEffect(() => {
    if (initialValues) setBranding(initialValues)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setBranding, JSON.stringify(initialValues)])

  const resolvedBranding = branding || defaultBrandingValues
  const { theme } = useTheme(
    themeName,
    resolvedBranding.primary,
    resolvedBranding.secondary,
    resolvedBranding.tertiary,
    resolvedBranding.background,
    resolvedBranding.headerBackground,
  )

  return { theme, isGetting, refresh: retrieveBranding, branding: branding || defaultBrandingValues }
}

const defaultBrandingValues: Partial<BrandingValues> = {}
