import { createActions } from 'redux-actions'
import { basicActionsWithMeta } from '../redux-helpers'

export const branch = 'users'

const actionCreators = createActions({
  users: {
    delete: basicActionsWithMeta(),
    load: basicActionsWithMeta(),
    userQuery: basicActionsWithMeta(),
  },
})
export const actions = actionCreators.users
