import React, { useEffect } from 'react'
import { useSetRecoilState } from 'recoil'
import { Flex } from 'mui-blox'
import { ParticipantModel } from '~models/data'
import { useParticipantIdentity } from '~brix/connectors/auth'
import { LoadingIndicatorBar } from '~controls'
import { exconEventId } from './state/exconEventId'
import { authParticipant } from './state/authParticipant'

type ComponentProps = { children: React.ReactElement }

export const ParticipantProvider = ({ children }: ComponentProps): JSX.Element => {
  const { participantIdentity, isLoading } = useParticipantIdentity()

  const setEventId = useSetRecoilState(exconEventId)
  const setAuthParticipant = useSetRecoilState(authParticipant)

  const pEventId: string = participantIdentity?.get('eventId') as string

  useEffect(() => {
    setAuthParticipant(participantIdentity?.toJS() as unknown as ParticipantModel)
  }, [participantIdentity, setAuthParticipant])

  useEffect(() => {
    if (pEventId) setEventId(pEventId)
  }, [pEventId, setEventId])

  if (isLoading) {
    return (
      <Flex flexColumn>
        <LoadingIndicatorBar isLoading={true} />
      </Flex>
    )
  }

  return children
}
