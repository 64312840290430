import React, { useState, useCallback, Ref, ReactElement, useMemo, useEffect } from 'react'
import { Autocomplete, AutocompleteProps, CircularProgress, TextField } from '@mui/material'
import { typify } from '~core/util/data/typify'
import { throttle } from '~core/util/functions/throttle'

export type Option = string | number | Record<string, unknown> | unknown
export type Value = string | string[]

interface AsyncAutocompleteFieldProps<TOption>
  extends Omit<AutocompleteProps<TOption, false, false, false>, 'options' | 'renderInput'> {
  getOptionsAsync: (request: { input: string }, onLoad: (results?: any[]) => void) => void
  isLoading: boolean
  error?: boolean
  helperText?: string
  getOptionLabel?: <TO = TOption>(o: TO) => string
  getOptionValue?: <TO = TOption>(o: TO) => unknown
  initialOptions?: TOption[]
}

const AsyncAutocompleteField = <TOption extends unknown>(props: AsyncAutocompleteFieldProps<TOption>, ref) => {
  const {
    getOptionsAsync,
    helperText,
    multiple,
    onChange,
    initialOptions = [],
    error = false,
    getOptionLabel = (o: TOption) => getFromOption<TOption>(o, 'label'),
    getOptionValue = (o: TOption) => getFromOption<TOption>(o, 'value'),
    isLoading = false,
    noOptionsText = 'Loading...',
    value = null,
    ...rest
  } = props

  const [inputValue, setInputValue] = useState(value)
  const [options, setOptions] = useState<any[]>(initialOptions)
  const [open, setOpen] = useState(false)

  useEffect(() => {
    setOptions(o => [...o, ...initialOptions])
  }, [initialOptions])

  const onOptionsLoaded = useCallback(
    options => {
      setOptions(options)
    },
    [setOptions],
  )

  const throttledGetter = useMemo(
    () =>
      throttle(
        (request: { input: string }, cb: (results?: any[]) => void) => {
          getOptionsAsync(request, cb)
        },
        1000,
        { trailing: true },
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  const handleOpen = () => {
    if (options.length === 0) {
      throttledGetter({ input: '' }, onOptionsLoaded)
    }
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleInputChange = (e, v) => {
    setInputValue(v)
  }

  const selected = multiple
    ? (options as TOption[]).filter(o => {
        return (value as any[])?.some?.(v => v === getOptionValue(o))
      })
    : options.find(o => {
        return value === getOptionValue(o)
      }) || value

  useEffect(() => {
    if (inputValue !== '' && inputValue !== getOptionValue(selected)) {
      throttledGetter({ input: inputValue }, onOptionsLoaded)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue, throttledGetter])

  const handleChange = (e, v, reason, details) => {
    const changeValue = multiple ? v.map(o => getOptionValue(o)) : getOptionValue(v)

    if (onChange) {
      onChange(e, changeValue, reason, details)
    }

    if (reason === 'clear') {
      throttledGetter({ input: '' }, onOptionsLoaded)
    }
  }

  return (
    <Autocomplete
      {...rest}
      ref={ref}
      value={selected || null}
      getOptionLabel={(o: string | string[]) => {
        const resolved = typify.isArray(o) ? o[0] : o

        return o != null && o !== '' ? getOptionLabel(resolved as TOption) : ''
      }}
      onChange={handleChange}
      onInputChange={handleInputChange}
      filterOptions={v => v}
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
      options={options}
      noOptionsText={noOptionsText}
      renderInput={params => (
        <TextField
          {...params}
          error={error}
          helperText={helperText}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading && <CircularProgress size={20} sx={{ mr: 3 }} />}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  )
}

export default React.forwardRef(AsyncAutocompleteField) as <TOption extends unknown>(
  props: AsyncAutocompleteFieldProps<TOption> & { ref?: Ref<HTMLInputElement> },
) => ReactElement | null

function getFromOption<O>(option: O | O[], key: string): string {
  const resolved = typify.isArray(option) ? option[0] : option

  if (typify.isObject(resolved)) return resolved[key]

  return resolved != null ? resolved : ''
}
