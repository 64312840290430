import { useEffect, useState } from 'react'
import { CustomFormMessages } from '~constants'

declare global {
  interface Window {
    clientHost: string
  }
}

export const useCustomFormsHostListener = () => {
  const [ackHeight, setAckValue] = useState(0)

  useEffect(() => {
    const listener = event => {
      if (event.data.type === CustomFormMessages.formFrameHost) {
        window.clientHost = event.origin
      }

      if (event.data.type === CustomFormMessages.acknowledge) {
        setAckValue(event.data.receivedHeight || 0)
      }
    }

    window.addEventListener('message', listener)

    return () => {
      window.removeEventListener('message', listener)
    }
  }, [])

  return { ackHeight }
}
