import { parseQuery } from '../redux/navigator/parseQuery'

const { logging } = parseQuery(window.location.search)
if (logging) {
  console.warn('Logging is turned on.')
}

export const logger = (...args) => {
  if (logging) {
    console.log('Logger:')
    console.log(...args)
  }
}
