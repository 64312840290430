import { createActions } from 'redux-actions'
import { basicActions } from '../redux-helpers'

const actionCreators = createActions({
  customerEvents: {
    loadEvents: basicActions(),
    setFilterValue: filter => ({ filter }),
    saveBotSurveyResults: results => ({ results }),
    provisionUpdate: updateMessage => updateMessage,
  },
})

export const actions = actionCreators.customerEvents
