import { useCallback } from 'react'
import useSWR, { useSWRConfig } from 'swr'
import { fromJS, Map } from 'immutable'
import fetch from '../../api/fetch'
import { getDispatch } from '../../redux'
import { actions } from '../../redux/auth/actionCreators'
import { setAuthStorage } from './setAuthStorage'
import { getAuthStorage } from './getAuthStorage'

const url = '/auth/whoami'

interface WhoAmI {
  user: Record<string, unknown>
  loggedInAs: Record<string, unknown>
}

export const useAuthUser = () => {
  const { mutate } = useSWRConfig()

  const load = useCallback(async () => {
    const whoami = (await fetch.get(url)) as WhoAmI | null

    if (!whoami) return null

    const auth = setAuthStorage({
      authenticatedUser: whoami.user,
      user: whoami.loggedInAs,
    })

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    getDispatch()(actions.setAuth(auth))
    const data = fromJS(whoami.user)

    mutate(url, data)

    return data
  }, [mutate])

  const lazyLoad = useCallback(async () => {
    const current = getAuthStorage()

    if (current.user) {
      return fromJS(current.user)
    }

    if (current.authenticatedUser) {
      return fromJS(current.authenticatedUser)
    }

    return load()
  }, [load])

  const forceLoad = useCallback(async () => load(), [load])

  const initialize = () => {
    const current = getAuthStorage()

    return fromJS(current.authenticatedUser)
  }

  const { data } = useSWR(url, async () => initialize())

  return {
    user: data || Map(),
    load: lazyLoad,
    forceLoad,
  }
}
