import React from 'react'
import PropTypes from 'prop-types'
import { MessageActionContext } from '../hooks/useMessageAction'

const MessageActionProvider = ({ children, postBack, linkTo }) => {
  return <MessageActionContext.Provider value={{ postBack, linkTo }}>{children}</MessageActionContext.Provider>
}

MessageActionProvider.propTypes = {
  postBack: PropTypes.func.isRequired,
  linkTo: PropTypes.func.isRequired,
}

export default MessageActionProvider
