import { useMemo, useState, useCallback, useRef, useEffect } from 'react'
import fetch from '../../../api/rootFetch'
import { useUnauthorizedHandler } from '../auth/useUnauthorizedHandler'
import { useApiMessageToast } from '../common'

/**
 * @param  options { import(".").IFetchOptions}
 * @returns { import(".").IFetchFunctions}
 * @deprecated useGet, usePost, usePatch, useDelete instead
 */
export const useFetch = (options = {}) => {
  const cancelRef = useRef(false)
  useEffect(() => {
    return () => {
      cancelRef.current = true
    }
  }, [])

  const [isFetching, updateFetching] = useState(Boolean(options.initialLoading))
  const unAuthHandler = useUnauthorizedHandler()

  const { pushError } = useApiMessageToast()

  const { onStarting, onSuccess, onCatch, onFinally, autoDone } = options

  const handleUpdateFetching = update => {
    if (!cancelRef.current) updateFetching(update)
  }

  const handler = useCallback(
    async promise => {
      try {
        onStarting && onStarting()
        handleUpdateFetching(true)

        const result = await promise
        onSuccess && onSuccess()

        return result
      } catch (err) {
        handleUpdateFetching(false)
        if (onCatch) {
          onCatch(err)
        } else {
          pushError(err.message, err.status)
          throw err
        }
      } finally {
        if (autoDone) {
          handleUpdateFetching(false)
        }
        onFinally && onFinally()
      }
    },
    [autoDone, onCatch, onFinally, onStarting, onSuccess, pushError],
  )

  const get = useCallback(
    url => {
      return handler(fetch.get(url, true, unAuthHandler))
    },
    [handler, unAuthHandler],
  )

  const deleteIt = useCallback(
    (url, body) => {
      return handler(fetch.delete(url, body, unAuthHandler))
    },
    [handler, unAuthHandler],
  )

  const patch = useCallback(
    (url, body) => {
      return handler(fetch.patch(url, body, unAuthHandler))
    },
    [handler, unAuthHandler],
  )

  const post = useCallback(
    (url, body) => {
      return handler(fetch.post(url, body, unAuthHandler))
    },
    [handler, unAuthHandler],
  )

  const postWithFile = useCallback(
    (url, fileData, jsonData, onProgress) => {
      return handler(fetch.requestWithFile(url, fileData, jsonData, onProgress))
    },
    [handler],
  )
  const done = useCallback(() => {
    updateFetching(false)
  }, [updateFetching])

  const status = useMemo(
    () => ({
      isFetching,
      done,
    }),
    [done, isFetching],
  )

  return {
    deleteIt,
    get,
    patch,
    post,
    postWithFile,
    setError: msg => pushError(msg),
    status,
  }
}
