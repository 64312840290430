import { useEffect, useState } from 'react'
import { messengerMessages } from '~constants'

declare global {
  interface Window {
    clientHost: string
  }
}

interface ListenerResponse {
  parentWidth: number
}

export const useMessengerHostListener = (): ListenerResponse => {
  const [parentWidth, setParentWidth] = useState(0)

  useEffect(() => {
    const listener = event => {
      if (event.data.type === messengerMessages.frameHost) {
        window.clientHost = event.origin
      }

      if (event.data.type === messengerMessages.parentWidth) {
        setParentWidth(Number(event.data.parentWidth || 0))
      }
    }

    window.addEventListener('message', listener)

    return () => {
      window.removeEventListener('message', listener)
    }
  }, [])

  return { parentWidth }
}
