import { useState, useMemo } from 'react'
import { useForm } from 'react-hooks-useform'
import { useQueryParams } from '../../routing'
import { useFetch } from '../../../brix/connectors/fetch/useFetch'
import { useToast } from '~hooks/useToast'

export const useAcceptInvite = () => {
  const { invite: inviteId } = useQueryParams()
  const { post, status } = useFetch()
  const { push } = useToast()
  const [completed, setCompleted] = useState(false)

  const [fields, form] = useForm({
    fields: [
      {
        name: 'password',
        label: 'Password',
      },
      {
        name: 'confirmPassword',
        label: 'Confirm Password',
      },
    ],
    validate: validatePasswords,
    submit: async values => {
      try {
        await post('/invites/accept', { inviteId, password: values.get('password') })
        setCompleted(true)
        status.done()
      } catch (err) {
        push(err.message, 'error')
      }
    },
  })

  const [, existingAccountForm] = useForm({
    fields: [],
    submit: async () => {
      try {
        await post('/invites/accept', { inviteId })
        setCompleted(true)
        status.done()
      } catch (err) {
        push(err.message, 'error')
      }
    },
  })

  const messages = useMemo(() => {
    const password = fields.password.value
    const confirmPassword = fields.confirmPassword.value

    const list = {
      length: { text: 'At least 8 characters', status: 'none' },
      rules: { text: 'Must contain at least 3', status: 'none' },
      uppercase: { text: 'Uppercase', status: 'none', indent: true },
      lowercase: { text: 'Lowercase', status: 'none', indent: true },
      number: { text: 'Numbers', status: 'none', indent: true },
      symbols: { text: 'Symbols', status: 'none', indent: true },
    }

    if (password.length > 0) {
      list.length.status = password.length >= 8 ? 'success' : 'error'
      list.uppercase.status = /[A-Z]/.test(password) ? 'success' : 'error'
      list.lowercase.status = /[a-z]/.test(password) ? 'success' : 'error'
      list.number.status = /[0-9]/.test(password) ? 'success' : 'error'
      list.symbols.status = /[=`~!@#$%^&*()_+{}|[\]\\:';'<>?,./-]/.test(password) ? 'success' : 'error'
      list.rules.status =
        [list.uppercase.status, list.lowercase.status, list.number.status, list.symbols.status].filter(
          s => s === 'success',
        ).length >= 3
          ? 'success'
          : 'error'
    }

    const matching =
      password && confirmPassword && password !== confirmPassword
        ? [
            {
              text: 'Passwords do not match',
              status: 'error',
            },
          ]
        : []

    return [...Object.values(list), ...matching]
  }, [fields.password.value, fields.confirmPassword.value])

  return {
    form,
    existingAccountForm,
    fields,
    isAccepting: status.isFetching,
    completed,
    messages,
  }
}

export const validatePasswords = ({ password, confirmPassword }) => {
  if (password !== confirmPassword) {
    return { confirmPassword: 'Does not match password' }
  }

  if (password.length < 8) {
    return {
      password: 'Password must be at least 8 characters',
    }
  }

  const rules = [/[A-Z]/, /[a-z]/, /[0-9]/, /[!@#$%^&*(),.?":{}|<> ]/]
  const matchedRules = rules.filter(rule => rule.test(password))
  if (matchedRules.length < 3) {
    return { password: 'Must contain 3 of Uppercase, lowercase, numbers, and special characters' }
  }
  return {}
}
