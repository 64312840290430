import { Components, Theme, outlinedInputClasses } from '@mui/material'

export const MuiTextField = (): Components<Theme>['MuiTextField'] => {
  return {
    defaultProps: {
      variant: 'outlined',
      size: 'small',
    },
    styleOverrides: {
      root: {
        [`& .${outlinedInputClasses.notchedOutline}`]: {
          top: 0,
          '& legend': {
            display: 'none',
          },
        },
      },
    },
  }
}
