import { Map, fromJS } from 'immutable'
import useSWR from 'swr'
import { useGet } from '../fetch'
import { isValue } from '~core/validation/isValue'

export const useParticipantIdentity = () => {
  const { get } = useGet()

  const { data, isValidating, mutate } = useSWR(
    '/auth/participant/me',
    async url => {
      const result = await get(url)
      const me = result ? result.me : {}

      return fromJS(me)
    },
    { revalidateOnMount: true, revalidateOnFocus: false },
  )

  const clearIdentity = () => mutate(null)
  const revalidate = () => mutate()

  return {
    participantIdentity: !isValue(data) ? Map() : data,
    id: !isValue(data) ? '' : data.get('id'),
    isLoading: !isValue(data),
    isFetching: isValidating,
    clearIdentity,
    revalidate,
  }
}
