import { autocompleteClasses, Components, inputBaseClasses, outlinedInputClasses, Theme } from '@mui/material'
import { ComponentOptions } from './types'

export const MuiAutocomplete = ({ spacing, palette }: ComponentOptions): Components<Theme>['MuiAutocomplete'] => {
  return {
    defaultProps: {
      size: 'small',
    },
    styleOverrides: {
      root: {
        [`& .${outlinedInputClasses.root}.${inputBaseClasses.sizeSmall}`]: {
          paddingTop: spacing(0.8125),
          paddingBottom: spacing(0.8125),
        },
        [`& .${inputBaseClasses.sizeSmall} .${autocompleteClasses.endAdornment}`]: {
          top: `calc(50% - ${spacing(1.875)})`,
          right: spacing(0.75),
        },
        [`& .${inputBaseClasses.sizeSmall} .${autocompleteClasses.clearIndicator}`]: {
          padding: 3,
          '& svg': {
            width: 22,
            height: 22,
          },
        },
        [`& .${inputBaseClasses.sizeSmall} .${autocompleteClasses.popupIndicator}`]: {
          padding: 3,
          '& svg': {
            width: 22,
            height: 22,
          },
        },
        [`& .${autocompleteClasses.tagSizeSmall}`]: {
          margin: '0px 2px 2px 0px',
          height: '24px',
          lineHeight: '24px',
        },
      },
      inputRoot: {
        borderRadius: 4,
        padding: 0,
      },
      clearIndicator: {
        borderColor: 'transparent',
      },
      popupIndicator: {
        borderColor: 'transparent',
      },
      paper: {
        borderColor: palette.divider,
        borderStyle: 'solid',
        borderWidth: 1,
      },
      tag: {
        marginBottom: spacing(0.25),
      },
    },
  }
}
