export const participantFieldKeys = {
  firstName: 'firstName',
  lastName: 'lastName',
  email: 'email',
}

export const participantPowerUpProfileFields = {
  firstName: 'First Name',
  lastName: 'Last Name',
  email: 'Email',
}

export const participantTagTypeIdentifiers = {
  nonBillable: 'Role::Testing',
}

export const WELL_KNOWN_CUSTOM_KEYS = {
  uploadValidated: 'uploadValidated',
  uploadValidatedTimestamp: 'uploadValidatedTimestamp',
  uploadSubmittedTimestamp: 'uploadSubmittedTimestamp',
  upload2SubmittedTimestamp: 'upload2SubmittedTimestamp',
  uploadAdminComment: 'uploadAdminComment',
  optOut: 'optOut',
}
