import React from 'react'
import { Flex } from 'mui-blox'
import { makeStyles } from '@mui/styles'
import classNames from 'classnames'
import { useBreakpoint } from '../hooks/useBreakpoint'

const TopFrame = ({ children, className, ...rest }) => {
  const classes = useStyles()
  const isSmall = useBreakpoint('sm').down
  return (
    <Flex
      alignItems={isSmall ? 'center' : 'flex-end'}
      justifyContent='space-between'
      pb={isSmall ? 0 : 0.5}
      {...rest}
      className={classNames(classes.topFrame, className)}
    >
      {children}
    </Flex>
  )
}

TopFrame.propTypes = {
  // Add PropTypes for props passed from parent components here.
}

const useStyles = makeStyles(({ spacing, palette, breakpoints, layout }) => ({
  topFrame: {
    height: layout.topBarLarge,
    paddingBottom: spacing(2),
    [breakpoints.down('sm')]: {
      backgroundColor: palette.primary.main,
      color: palette.common.white,
      height: layout.topBar,
      paddingBottom: 0,
    },
  },
}))

export default TopFrame
