import { Components, Theme, SimplePaletteColorOptions } from '@mui/material'
import { ComponentOptions } from './types'

export const MuiChip = ({ palette }: ComponentOptions): Components<Theme>['MuiChip'] => {
  const secondary = palette.secondary as SimplePaletteColorOptions

  return {
    defaultProps: {},
    styleOverrides: {
      root: {
        borderRadius: 4,
        height: 28,
        // backgroundColor: alpha(secondary.lighter, 0.3),
        // '&:hover': {
        //   backgroundColor: alpha(secondary.lighter, 0.4),
        // },
      },
      icon: {
        color: 'inherit',
      },
      deleteIcon: {
        width: 18,
        height: 18,
      },
      sizeSmall: {
        height: '24px',
        fontSize: '0.9rem',
      },
      iconSmall: {
        height: '18px',
        width: '18px',
      },
      colorSecondary: {
        backgroundColor: secondary.lighter,
      },
    },
  }
}
