const USA = 219
const CANADA = 38

const createPhoneObject = (raw, code, phone = '', flag = '', name = '') => ({
  code,
  flag,
  name,
  phone,
  raw,
})

const countryToPhoneObject = (number, country) => {
  return createPhoneObject(
    number,
    country.code,
    number.replace('+', '').slice(String(country.code).length),
    country.flag,
    country.name,
  )
}

const determineUSorCanada = number => {
  const areaCode = Number(number.slice(1, 4))
  return canadaAreaCodes.find(c => c === areaCode) ? countryCodes[CANADA] : countryCodes[USA]
}

export const parsePhoneNumber = number => {
  if (!number) return createPhoneObject(number)

  const noPlus = String(number).replace('+', '')
  if (noPlus.length < 6) return createPhoneObject(number)

  let codeLength = 4
  while (codeLength > 0) {
    const code = Number(noPlus.slice(0, codeLength))
    if (code === 1) {
      const usOrCanada = determineUSorCanada(noPlus)
      return countryToPhoneObject(number, usOrCanada)
    }
    const country = countryCodes.find(c => c.code === code)
    if (country) {
      return countryToPhoneObject(number, country)
    }
    codeLength--
  }

  return createPhoneObject(number, undefined, noPlus)
}

export const countryCodes = [
  { code: 93, flag: 'af', name: 'Afghanistan' },
  { code: 355, flag: 'al', name: 'Albania' },
  { code: 213, flag: 'dz', name: 'Algeria' },
  { code: 684, flag: 'as', name: 'American Samoa' },
  { code: 376, flag: 'ad', name: 'Andorra' },
  { code: 244, flag: 'ao', name: 'Angola' },
  { code: 809, flag: 'ai', name: 'Anguilla' },
  { code: 268, flag: 'ag', name: 'Antigua' },
  { code: 54, flag: 'ar', name: 'Argentina' },
  { code: 374, flag: 'am', name: 'Armenia' },
  { code: 297, flag: 'aw', name: 'Aruba' },
  { code: 247, flag: 'sh', name: 'Ascension Island' },
  { code: 61, flag: 'au', name: 'Australia' },
  { code: 672, flag: 'au', name: 'Australian External Territories' },
  { code: 43, flag: 'at', name: 'Austria' },
  { code: 994, flag: 'az', name: 'Azerbaijan' },
  { code: 242, flag: 'bs', name: 'Bahamas' },
  { code: 246, flag: 'bb', name: 'Barbados' },
  { code: 973, flag: 'bh', name: 'Bahrain' },
  { code: 880, flag: 'bd', name: 'Bangladesh' },
  { code: 375, flag: 'by', name: 'Belarus' },
  { code: 32, flag: 'be', name: 'Belgium' },
  { code: 501, flag: 'bz', name: 'Belize' },
  { code: 229, flag: 'bj', name: 'Benin' },
  { code: 809, flag: 'bm', name: 'Bermuda' },
  { code: 975, flag: 'bt', name: 'Bhutan' },
  { code: 284, flag: 'vg', name: 'British Virgin Islands' },
  { code: 591, flag: 'bo', name: 'Bolivia' },
  { code: 387, flag: 'ba', name: 'Bosnia and Hercegovina' },
  { code: 267, flag: 'bw', name: 'Botswana' },
  { code: 55, flag: 'br', name: 'Brazil' },
  { code: 284, flag: 'vg', name: 'British V.I.' },
  { code: 673, flag: 'bn', name: 'Brunei Darussalm' },
  { code: 359, flag: 'bg', name: 'Bulgaria' },
  { code: 226, flag: 'bf', name: 'Burkina Faso' },
  { code: 257, flag: 'bi', name: 'Burundi' },
  { code: 855, flag: 'kh', name: 'Cambodia' },
  { code: 237, flag: 'cm', name: 'Cameroon' },
  { code: 1, flag: 'ca', name: 'Canada' },
  { code: 238, flag: 'cv', name: 'CapeVerde Islands' },
  { code: 1, flag: '', name: 'Caribbean Nations' },
  { code: 345, flag: 'ky', name: 'Cayman Islands' },
  { code: 238, flag: 'cv', name: 'Cape Verdi' },
  { code: 236, flag: 'cf', name: 'Central African Republic' },
  { code: 235, flag: 'td', name: 'Chad' },
  { code: 56, flag: 'cl', name: 'Chile' },
  { code: 86, flag: 'cn', name: "China (People's Republic)" },
  { code: 886, flag: 'ch', name: 'China-Taiwan' },
  { code: 57, flag: 'co', name: 'Colombia' },
  { code: 269, flag: 'km', name: 'Comoros and Mayotte' },
  { code: 242, flag: 'cg', name: 'Congo' },
  { code: 682, flag: 'ck', name: 'Cook Islands' },
  { code: 506, flag: 'cr', name: 'Costa Rica' },
  { code: 385, flag: 'hr', name: 'Croatia' },
  { code: 53, flag: 'cu', name: 'Cuba' },
  { code: 357, flag: 'cy', name: 'Cyprus' },
  { code: 420, flag: 'cz', name: 'Czech Republic' },
  { code: 45, flag: 'dk', name: 'Denmark' },
  { code: 246, flag: 'io', name: 'Diego Garcia' },
  { code: 767, flag: 'dm', name: 'Dominca' },
  { code: 809, flag: 'do', name: 'Dominican Republic' },
  { code: 253, flag: 'dj', name: 'Djibouti' },
  { code: 593, flag: 'ec', name: 'Ecuador' },
  { code: 20, flag: 'eg', name: 'Egypt' },
  { code: 503, flag: 'sv', name: 'El Salvador' },
  { code: 240, flag: 'gq', name: 'Equatorial Guinea' },
  { code: 291, flag: 'er', name: 'Eritrea' },
  { code: 372, flag: 'ee', name: 'Estonia' },
  { code: 251, flag: 'et', name: 'Ethiopia' },
  { code: 500, flag: 'fk', name: 'Falkland Islands' },
  { code: 298, flag: 'fo', name: 'Faroe (Faeroe) Islands (Denmark)' },
  { code: 679, flag: 'fj', name: 'Fiji' },
  { code: 358, flag: 'fi', name: 'Finland' },
  { code: 33, flag: 'fr', name: 'France' },
  { code: 596, flag: '', name: 'French Antilles' },
  { code: 594, flag: 'gf', name: 'French Guiana' },
  { code: 241, flag: 'ga', name: 'Gabon (Gabonese Republic)' },
  { code: 220, flag: 'gm', name: 'Gambia' },
  { code: 995, flag: 'ge', name: 'Georgia' },
  { code: 49, flag: 'de', name: 'Germany' },
  { code: 233, flag: 'gh', name: 'Ghana' },
  { code: 350, flag: 'gi', name: 'Gibraltar' },
  { code: 30, flag: 'gr', name: 'Greece' },
  { code: 299, flag: 'gl', name: 'Greenland' },
  { code: 473, flag: 'gd', name: 'Grenada/Carricou' },
  { code: 671, flag: 'gu', name: 'Guam' },
  { code: 502, flag: 'gt', name: 'Guatemala' },
  { code: 224, flag: 'gn', name: 'Guinea' },
  { code: 245, flag: 'gw', name: 'Guinea-Bissau' },
  { code: 592, flag: 'gy', name: 'Guyana' },
  { code: 509, flag: 'ht', name: 'Haiti' },
  { code: 504, flag: 'hn', name: 'Honduras' },
  { code: 852, flag: 'hk', name: 'Hong Kong' },
  { code: 36, flag: 'hu', name: 'Hungary' },
  { code: 354, flag: 'is', name: 'Iceland' },
  { code: 91, flag: 'in', name: 'India' },
  { code: 62, flag: 'id', name: 'Indonesia' },
  { code: 98, flag: 'ir', name: 'Iran' },
  { code: 964, flag: 'iq', name: 'Iraq' },
  { code: 353, flag: 'ie', name: 'Ireland (Irish Republic; Eire)' },
  { code: 972, flag: 'il', name: 'Israel' },
  { code: 39, flag: 'it', name: 'Italy' },
  { code: 225, flag: '', name: "Ivory Coast (La Cote d'Ivoire)" },
  { code: 876, flag: 'jm', name: 'Jamaica' },
  { code: 81, flag: 'jp', name: 'Japan' },
  { code: 962, flag: 'jo', name: 'Jordan' },
  { code: 7, flag: 'kz', name: 'Kazakhstan' },
  { code: 254, flag: 'ke', name: 'Kenya' },
  { code: 855, flag: 'kh', name: 'Khmer Republic (Cambodia/Kampuchea)' },
  { code: 686, flag: 'ki', name: 'Kiribati Republic (Gilbert Islands)' },
  { code: 82, flag: 'kr', name: 'Korea, Republic of (South Korea)' },
  { code: 850, flag: 'kp', name: "Korea, People's Republic of (North Korea)" },
  { code: 965, flag: 'kw', name: 'Kuwait' },
  { code: 996, flag: 'kg', name: 'Kyrgyz Republic' },
  { code: 371, flag: 'lv', name: 'Latvia' },
  { code: 856, flag: 'la', name: 'Laos' },
  { code: 961, flag: 'lb', name: 'Lebanon' },
  { code: 266, flag: 'ls', name: 'Lesotho' },
  { code: 231, flag: 'lr', name: 'Liberia' },
  { code: 370, flag: 'lt', name: 'Lithuania' },
  { code: 218, flag: 'ly', name: 'Libya' },
  { code: 423, flag: 'li', name: 'Liechtenstein' },
  { code: 352, flag: 'lu', name: 'Luxembourg' },
  { code: 853, flag: 'mo', name: 'Macao' },
  { code: 389, flag: 'mk', name: 'Macedonia' },
  { code: 261, flag: 'mg', name: 'Madagascar' },
  { code: 265, flag: 'mw', name: 'Malawi' },
  { code: 60, flag: 'my', name: 'Malaysia' },
  { code: 960, flag: 'mv', name: 'Maldives' },
  { code: 223, flag: 'ml', name: 'Mali' },
  { code: 356, flag: 'mt', name: 'Malta' },
  { code: 692, flag: 'mh', name: 'Marshall Islands' },
  { code: 596, flag: 'mq', name: 'Martinique (French Antilles)' },
  { code: 222, flag: 'mr', name: 'Mauritania' },
  { code: 230, flag: 'mu', name: 'Mauritius' },
  { code: 269, flag: 'yt', name: 'Mayolte' },
  { code: 52, flag: 'mx', name: 'Mexico' },
  { code: 691, flag: 'fm', name: 'Micronesia (F.S. of Polynesia)' },
  { code: 373, flag: 'md', name: 'Moldova' },
  { code: 33, flag: 'mc', name: 'Monaco' },
  { code: 976, flag: 'mn', name: 'Mongolia' },
  { code: 473, flag: 'ms', name: 'Montserrat' },
  { code: 212, flag: 'ma', name: 'Morocco' },
  { code: 258, flag: 'mz', name: 'Mozambique' },
  { code: 95, flag: 'mm', name: 'Myanmar (former Burma)' },
  { code: 264, flag: 'ma', name: 'Namibia (former South-West Africa)' },
  { code: 674, flag: 'mr', name: 'Nauru' },
  { code: 977, flag: 'mp', name: 'Nepal' },
  { code: 31, flag: 'nl', name: 'Netherlands' },
  { code: 599, flag: 'nl', name: 'Netherlands Antilles' },
  { code: 869, flag: 'kn', name: 'Nevis' },
  { code: 687, flag: 'nc', name: 'New Caledonia' },
  { code: 64, flag: 'nz', name: 'New Zealand' },
  { code: 505, flag: 'ni', name: 'Nicaragua' },
  { code: 227, flag: 'ne', name: 'Niger' },
  { code: 234, flag: 'ng', name: 'Nigeria' },
  { code: 683, flag: 'nu', name: 'Niue' },
  { code: 850, flag: 'kp', name: 'North Korea' },
  { code: 1670, flag: 'mp', name: 'North Mariana Islands (Saipan)' },
  { code: 47, flag: 'no', name: 'Norway' },
  { code: 968, flag: 'om', name: 'Oman' },
  { code: 92, flag: 'pk', name: 'Pakistan' },
  { code: 680, flag: 'pw', name: 'Palau' },
  { code: 507, flag: 'pa', name: 'Panama' },
  { code: 675, flag: 'pg', name: 'Papua New Guinea' },
  { code: 595, flag: 'py', name: 'Paraguay' },
  { code: 51, flag: 'pe', name: 'Peru' },
  { code: 63, flag: 'ph', name: 'Philippines' },
  { code: 48, flag: 'pl', name: 'Poland' },
  { code: 351, flag: 'pt', name: 'Portugal (includes Azores)' },
  { code: 1787, flag: 'pr', name: 'Puerto Rico' },
  { code: 974, flag: 'qa', name: 'Qatar' },
  { code: 262, flag: 'fr', name: 'Reunion (France)' },
  { code: 40, flag: 'ro', name: 'Romania' },
  { code: 7, flag: 'ru', name: 'Russia' },
  { code: 250, flag: 'rw', name: 'Rwanda (Rwandese Republic)' },
  { code: 670, flag: '', name: 'Saipan' },
  { code: 378, flag: 'sm', name: 'San Marino' },
  { code: 239, flag: 'st', name: 'Sao Tome and Principe' },
  { code: 966, flag: 'sa', name: 'Saudi Arabia' },
  { code: 221, flag: 'sn', name: 'Senegal' },
  { code: 381, flag: 'rs', name: 'Serbia and Montenegro' },
  { code: 248, flag: 'sc', name: 'Seychelles' },
  { code: 232, flag: 'sl', name: 'Sierra Leone' },
  { code: 65, flag: 'sg', name: 'Singapore' },
  { code: 421, flag: 'sk', name: 'Slovakia' },
  { code: 386, flag: 'sl', name: 'Slovenia' },
  { code: 677, flag: 'sb', name: 'Solomon Islands' },
  { code: 252, flag: 'so', name: 'Somalia' },
  { code: 27, flag: 'za', name: 'South Africa' },
  { code: 34, flag: 'es', name: 'Spain' },
  { code: 94, flag: 'lk', name: 'Sri Lanka' },
  { code: 290, flag: 'sh', name: 'St. Helena' },
  { code: 869, flag: 'kn', name: 'St. Kitts/Nevis' },
  { code: 508, flag: 'pm', name: 'St. Pierre &(et) Miquelon (France)' },
  { code: 249, flag: 'sd', name: 'Sudan' },
  { code: 597, flag: 'sr', name: 'Suriname' },
  { code: 268, flag: 'sz', name: 'Swaziland' },
  { code: 46, flag: 'se', name: 'Sweden' },
  { code: 41, flag: 'ch', name: 'Switzerland' },
  { code: 963, flag: 'sy', name: 'Syrian Arab Republic (Syria)' },
  { code: 689, flag: 'pf', name: 'Tahiti (French Polynesia)' },
  { code: 886, flag: 'tw', name: 'Taiwan' },
  { code: 7, flag: 'tj', name: 'Tajikistan' },
  { code: 255, flag: 'tz', name: 'Tanzania (includes Zanzibar)' },
  { code: 66, flag: 'th', name: 'Thailand' },
  { code: 228, flag: 'tg', name: 'Togo (Togolese Republic)' },
  { code: 690, flag: 'tk', name: 'Tokelau' },
  { code: 676, flag: 'to', name: 'Tonga' },
  { code: 1868, flag: 'tt', name: 'Trinidad and Tobago' },
  { code: 216, flag: 'tn', name: 'Tunisia' },
  { code: 90, flag: 'tr', name: 'Turkey' },
  { code: 993, flag: 'tm', name: 'Turkmenistan' },
  { code: 688, flag: 'tv', name: 'Tuvalu (Ellice Islands)' },
  { code: 256, flag: 'ug', name: 'Uganda' },
  { code: 380, flag: 'ua', name: 'Ukraine' },
  { code: 971, flag: 'ae', name: 'United Arab Emirates' },
  { code: 44, flag: 'gb', name: 'United Kingdom' },
  { code: 598, flag: 'uy', name: 'Uruguay' },
  { code: 1, flag: 'us', name: 'USA' },
  { code: 7, flag: 'uz', name: 'Uzbekistan' },
  { code: 678, flag: 'vu', name: 'Vanuatu (New Hebrides)' },
  { code: 39, flag: 'va', name: 'Vatican City' },
  { code: 58, flag: 've', name: 'Venezuela' },
  { code: 84, flag: 'vn', name: 'Viet Nam' },
  { code: 1340, flag: 'vg', name: 'Virgin Islands' },
  { code: 681, flag: 'wf', name: 'Wallis and Futuna' },
  { code: 685, flag: 'as', name: 'Western Samoa' },
  { code: 381, flag: 'ye', name: "Yemen (People's Democratic Republic of)" },
  { code: 967, flag: 'ye', name: 'Yemen Arab Republic (North Yemen)' },
  { code: 381, flag: 'mk', name: 'Yugoslavia (discontinued)' },
  { code: 243, flag: '', name: 'Zaire' },
  { code: 260, flag: 'zm', name: 'Zambia' },
  { code: 263, flag: 'zw', name: 'Zimbabwe' },
]

const canadaAreaCodes = [
  403, 587, 780, 825, 250, 778, 236, 604, 204, 431, 506, 709, 867, 902, 782, 867, 365, 613, 807, 226, 289, 437, 416,
  519, 647, 905, 249, 343, 548, 705, 782, 902, 579, 873, 514, 581, 819, 438, 418, 450, 639, 306, 867,
]
