import { createActions } from 'redux-actions'

const actionCreators = createActions({
  context: {
    themePreview: themePreview => ({ themePreview }),
    showMediaUploadModal: meta => ({ meta }),
    hideMediaUploadModal: () => null,
    showModal: (modalType, meta) => ({ modalType, meta }),
    hideModal: modalType => ({ modalType }),
    setCardLocation: rect => ({ rect }),
    activityStarted: activity => ({ activity }),
    activityEnded: activity => ({ activity }),
  },
})
export const actions = actionCreators.context
