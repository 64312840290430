import { Map, fromJS } from 'immutable'
import { combineActions, handleActions } from 'redux-actions'
import { actions, branch } from './actionCreators'
import paths from './paths'
import {
  arrayToMap,
  basicCompleteReducer,
  basicFailReducer,
  basicStartReducer,
  gridPinReducer,
  gridSortReducer,
  columnAdjustmentReducer,
  branchReduce,
} from '../redux-helpers-immutable'
import sharedActions from '../shared/actions'

export default handleActions(
  {
    // xxx.start
    [combineActions(actions.delete.start, actions.userQuery.start, actions.load.start)]: basicStartReducer,

    // xxx.fail
    [combineActions(actions.delete.fail, actions.userQuery.fail, actions.load.fail)]: basicFailReducer,

    // delete
    [actions.delete.complete]: (state, { meta: { orgId, userId } }) =>
      basicCompleteReducer(state).deleteIn(paths.user(orgId, userId)),

    // load
    [actions.load.complete]: (state, { payload: { users }, meta: { orgId } }) =>
      basicCompleteReducer(state).setIn(paths.users(orgId), arrayToMap(users)),

    [actions.userQuery.complete]: (state, { payload: { users } }) =>
      basicCompleteReducer(state).setIn(paths.userQuery(), fromJS(users)),

    [sharedActions.pinColumn]: (state, action) => branchReduce(state, action, branch, gridPinReducer),

    [sharedActions.gridSort]: (state, action) => branchReduce(state, action, branch, gridSortReducer),

    [sharedActions.adjustColumn]: (state, action) => branchReduce(state, action, branch, columnAdjustmentReducer),
  },
  Map(),
)
