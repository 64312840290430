import { useCallback } from 'react'
import { useGet, usePost } from '~brix/connectors/fetch'
import { CustomFormProps } from '../types'

type CustomFormParams = {
  formId: string
}

export const useCustomForms = ({ formId }: CustomFormParams) => {
  const { post, isPosting } = usePost()
  const { get, isGetting } = useGet()

  const baseUrl = `/custom-forms/form/${formId}`

  const fetchForm = useCallback(
    async (onFetch?: (result: unknown) => void) => {
      const result = await get(baseUrl)

      onFetch && onFetch(result)
    },
    [baseUrl, get],
  )

  const saveParticipants = useCallback(
    async ({ payload, onSave }: { payload: CustomFormProps; onSave?: (result: unknown) => void }) => {
      const result = await post(`${baseUrl}/save-participants`, payload)

      onSave && onSave(result)
    },
    [baseUrl, post],
  )

  return {
    fetchForm,
    saveParticipants,
    isLoading: isPosting || isGetting,
  } as const
}
