import { combineActions, handleActions } from 'redux-actions'
import { actions } from './actions'
import { basicState, basicFailReducer, basicLoadCompleteReducer, basicStartReducer } from '../redux-helpers'

const initialState = {
  ...basicState(),
  byIntentId: {},
}

export default handleActions(
  {
    // xxx.start
    [combineActions(actions.loadResponseGroups.start, actions.updateResponseGroups.start)]: basicStartReducer,

    // xxx.fail
    [combineActions(actions.loadResponseGroups.fail, actions.updateResponseGroups.fail)]: basicFailReducer,

    [actions.loadResponseGroups.complete]: (state, action) => {
      const { responseGroups, intentId } = action.payload

      let byIntentId = { ...state.byIntentId }
      byIntentId[intentId] = responseGroups

      return {
        ...basicLoadCompleteReducer(state, action),
        byIntentId,
      }
    },

    [actions.updateResponseGroups.complete]: (state, action) => {
      const { responseGroups, intentId } = action.payload
      if (!responseGroups) {
        return basicLoadCompleteReducer(state, action)
      }

      let byIntentId = { ...state.byIntentId }
      byIntentId[intentId] = responseGroups

      return {
        ...basicLoadCompleteReducer(state, action),
        byIntentId,
      }
    },
  },
  initialState,
)
