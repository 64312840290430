import Immutable from 'immutable'
import { DateTime } from 'luxon'
import { ValueOf } from '~models/util'
import { getTag } from './getTag'

interface MaybeElement {
  nodeType?: number
}

type Check = (x: unknown) => boolean
type TFunc = (x: unknown) => ValueOf<typeof TYPES>
interface Typify extends TFunc {
  isArray: Check
  isBoolean: Check
  isDate: Check
  isElement: Check
  isFunction: Check
  isInfinite: Check
  isNaN: Check
  isNull: Check
  isNumber: Check
  isObject: Check
  isRegExp: Check
  isString: Check
  isSymbol: Check
  isUndefined: Check
  // Immutable
  isImmutable: Check
  isIterable: Check
  isList: Check
  isMap: Check
  isOrderedMap: Check
  isSet: Check
  isStack: Check
  // DateTime
  isDateTime: Check
}

export const TYPES = {
  array: 'array',
  boolean: 'boolean',
  date: 'date',
  element: 'element',
  function: 'function',
  infinite: 'infinite',
  nan: 'nan',
  null: 'null',
  number: 'number',
  object: 'object',
  regexp: 'regexp',
  string: 'string',
  symbol: 'symbol',
  undefined: 'undefined',
  // immutable
  iterable: 'iterable',
  list: 'list',
  map: 'map',
  orderedmap: 'orderedmap',
  set: 'set',
  stack: 'stack',
  // luxon
  datetime: 'datetime',
}

const TYPE_NAMES = {
  [TYPES.array]: 'Array',
  [TYPES.boolean]: 'Boolean',
  [TYPES.date]: 'Date',
  [TYPES.element]: 'Element',
  [TYPES.function]: 'Function',
  [TYPES.infinite]: 'Infinite',
  [TYPES.nan]: 'NaN',
  [TYPES.null]: 'Null',
  [TYPES.number]: 'Number',
  [TYPES.object]: 'Object',
  [TYPES.regexp]: 'RegExp',
  [TYPES.string]: 'String',
  [TYPES.symbol]: 'Symbol',
  [TYPES.undefined]: 'Undefined',
}

const IMMUTABLE_NAMES = {
  [TYPES.iterable]: 'Iterable',
  [TYPES.list]: 'List',
  [TYPES.map]: 'Map',
  [TYPES.orderedmap]: 'OrderedMap',
  [TYPES.set]: 'Set',
  [TYPES.stack]: 'Stack',
}

const LUXON_NAMES = {
  [TYPES.datetime]: 'DateTime',
}

const immutableTypes = [TYPES.iterable, TYPES.list, TYPES.map, TYPES.orderedmap, TYPES.set, TYPES.stack]
const luxonTypes = [TYPES.datetime]

export const typify = (x => {
  if (x === null) return TYPES.null
  if (x && ((x as MaybeElement).nodeType === 1 || (x as MaybeElement).nodeType === 9)) {
    return TYPES.element
  }

  const y = getTag(x)
  const t = y.match(/\[object (.*?)\]/)[1].toLowerCase()

  if (t === TYPES.number) {
    if (Number.isNaN(x)) return TYPES.nan
    if (!Number.isFinite(x)) return TYPES.infinite
  }

  if (t === TYPES.object && Immutable.isImmutable(x)) {
    return immutableTypes.reduce((acc, it) => {
      return Immutable[IMMUTABLE_NAMES[it]][`is${IMMUTABLE_NAMES[it]}`](x) ? it : acc
    }, t)
  }

  if (t === TYPES.object && DateTime.isDateTime(x)) {
    return TYPES.datetime
  }

  return t
}) as Typify

export const cast = <T extends unknown>(x: string): T | undefined => {
  if (x === 'undefined') {
    return undefined
  }

  try {
    return JSON.parse(x as string)
  } catch (e) {
    return x as never
  }
}

Object.values(TYPES).forEach(t => {
  typify[`is${TYPE_NAMES[t]}`] = x => typify(x) === t
})

immutableTypes.forEach(t => {
  typify[`is${IMMUTABLE_NAMES[t]}`] = x => typify(x) === t
})

luxonTypes.forEach(t => {
  typify[`is${LUXON_NAMES[t]}`] = x => typify(x) === t
})

typify.isImmutable = x => Immutable.isImmutable(x)
