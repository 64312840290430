import RestClient from './rest-client'
import Auth from './auth-apis'
import BotTesting from './botTesting-apis'
import BotTraining from './botTraining-api'
import Events from './events-api'
import Links from './links-api'
import Notifications from './notifications-api'
import Orgs from './orgs-api'
import Participants from './participants-api'
import PhoneNumbers from './phoneNumbers-api'
import ResponseGroups from './responseGroups-api'
import Responses from './responses-api'
import { sciensio as config } from '../config'
import { combineUrl } from '../../core/util/string/combineUrl'

const Sciensio = function () {
  const self = this

  self.restClient = new RestClient(combineUrl(config.apiUrl, 'api'))

  this.setToken = function (token) {
    self.restClient.token = token
  }

  this.auth = new Auth(self.restClient)
  this.botTesting = new BotTesting(self.restClient)
  this.botTraining = new BotTraining(self.restClient)
  this.events = new Events(self.restClient)
  this.links = new Links(self.restClient)
  this.notifications = new Notifications(self.restClient)
  this.orgs = new Orgs(self.restClient)
  this.participants = new Participants(self.restClient)
  this.phoneNumbers = new PhoneNumbers(self.restClient)
  this.responseGroups = new ResponseGroups(self.restClient)
  this.responses = new Responses(self.restClient)
}

export default new Sciensio()
