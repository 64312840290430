import React from 'react'
import PropTypes from 'prop-types'
import ClassNames from 'classnames'
import { Checkbox as MuiCheckbox, FormControlLabel, FormControl, FormHelperText } from '@mui/material'
import { makeStyles } from '@mui/styles'

const RenderFunc = ({ className, label, fullWidth = true, helperText = '', checked, value, ...rest }, ref) => {
  const classes = useStyles()
  const resolvedValue = value != null ? value : checked
  return (
    <FormControl fullWidth={fullWidth} className={ClassNames(classes.root, className)}>
      <FormControlLabel control={<MuiCheckbox inputRef={ref} checked={resolvedValue} {...rest} />} label={label} />
      {helperText && <FormHelperText className={classes.helperText}>{helperText}</FormHelperText>}
    </FormControl>
  )
}

const useStyles = makeStyles(() => ({
  root: {
    minHeight: 'unset',
  },
  helperText: {
    marginLeft: 35,
    marginTop: -12,
  },
}))

const FormControlCheckbox = React.forwardRef(RenderFunc)
FormControlCheckbox.displayName = 'FormControlCheckbox'

FormControlCheckbox.propTypes = {
  label: PropTypes.string.isRequired,
  fullWidth: PropTypes.bool,
  helperText: PropTypes.string,
}

export default FormControlCheckbox
