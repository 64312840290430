import { typify, TYPES } from './typify'

// eslint-disable-next-line @typescript-eslint/ban-types
type ValueElseUndefined<T> = T extends string | number | boolean | symbol | object ? T : undefined

export type GetIn = {
  <T extends any = unknown>(obj: Record<string, any> | null, maybePath: string[] | string, defaultValue: T): T
  <T extends any = unknown>(
    obj: Record<string, any> | null,
    maybePath: string[] | string,
    defaultValue?: T,
  ): ValueElseUndefined<typeof defaultValue>
}

// Some duplication of typing is necessary here for both a) type safety inside function definition below
// and b) properly overloading for different return types based on inclusion of defaultValue where function is called.
export const getIn: GetIn = <T extends any = unknown>(
  obj: Record<string, any> | null,
  maybePath: string[] | string,
  defaultValue?: T | undefined,
): ValueElseUndefined<typeof defaultValue> => {
  if (obj == null) return defaultValue as any

  let path: string[]

  if (Array.isArray(maybePath)) path = maybePath
  else if (isKey(maybePath, obj)) path = [maybePath as string]
  else if (typeof maybePath === 'string') path = stringToPath(maybePath)
  else path = []

  let index = 0
  const length = path.length

  while (obj != null && index < length) {
    obj = obj[toKey(path[index++])]
  }

  return ((index && index == length ? obj : undefined) ?? defaultValue) as any
}

const charCodeOfDot = '.'.charCodeAt(0)
const reEscapeChar = /\\(\\)?/g
const rePropName = RegExp(
  // Match anything that isn't a dot or bracket.
  '[^.[\\]]+' +
    '|' +
    // Or match property names within brackets.
    '\\[(?:' +
    // Match a non-string expression.
    '([^"\'][^[]*)' +
    '|' +
    // Or match strings (supports escaping characters).
    '(["\'])((?:(?!\\2)[^\\\\]|\\\\.)*?)\\2' +
    ')\\]' +
    '|' +
    // Or match "" as the space between consecutive dots or empty brackets.
    '(?=(?:\\.|\\[\\])(?:\\.|\\[\\]|$))',
  'g',
)

function stringToPath(str: string): string[] {
  const result: string[] = []

  if (str.charCodeAt(0) === charCodeOfDot) {
    result.push('')
  }

  str.replace(rePropName, (match, expression, quote, subString) => {
    let key: string = match

    if (quote) {
      key = subString.replace(reEscapeChar, '$1')
    } else if (expression) {
      key = expression.trim()
    }

    result.push(key)

    return ''
  })

  return result
}

const reIsDeepProp = /\.|\[(?:[^[\]]*|(["'])(?:(?!\1)[^\\]|\\.)*?\1)\]/
const reIsPlainProp = /^\w*$/

function isKey(value, obj) {
  const t = typify(value)

  if (t === TYPES.array) {
    return false
  }

  if (value == null || t === TYPES.number || t === TYPES.boolean || t === TYPES.symbol) {
    return true
  }

  return reIsPlainProp.test(value) || !reIsDeepProp.test(value) || (obj != null && value in Object(obj))
}

const INFINITY = 1 / 0

function toKey(value) {
  if (typeof value === 'string' || typify.isSymbol(value)) {
    return value
  }

  const result = `${value}`

  return result == '0' && 1 / value == -INFINITY ? '-0' : result
}
