const audienceRoot = (orgId, eventId) => ['org', String(orgId), 'event', String(eventId)]
const audienceBranch = (orgId, eventId, targetAudience, participantType) =>
  audienceRoot(orgId, eventId).concat([
    'audience',
    String(targetAudience),
    'participantType',
    String(participantType),
  ])

export default {
  audienceRoot: (orgId, eventId, targetAudience) => audienceRoot(orgId, eventId, targetAudience),
  audience: (orgId, eventId, targetAudience, participantType) =>
    audienceBranch(orgId, eventId, targetAudience, participantType),
  audienceCount: (orgId, eventId, targetAudience, participantType) =>
    audienceBranch(orgId, eventId, targetAudience, participantType).concat(['audienceCount']),
  smoochCount: (orgId, eventId, targetAudience, participantType) =>
    audienceBranch(orgId, eventId, targetAudience, participantType).concat(['smoochCount']),
}
