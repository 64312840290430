export const SHORT_LINK_REPORT_TYPES = {
  all: 'all',
  byTargetUrl: 'byTargetUrl',
  byParticipant: 'byParticipant',
  // Disabling this option due to inconsistencies in the report
  // byShortLink: 'byShortLink',
} as const

export const SHORT_LINK_REPORT_LABELS = {
  [SHORT_LINK_REPORT_TYPES.all]: 'All Clicks',
  [SHORT_LINK_REPORT_TYPES.byTargetUrl]: 'Clicks by Target URL',
  [SHORT_LINK_REPORT_TYPES.byParticipant]: 'Clicks by Participant',
  // Disabling this option due to inconsistencies in the report
  // [SHORT_LINK_REPORT_TYPES.byShortLink]: 'Clicks by ShortLink (legacy)',
}
