import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { languageCodes } from '../../constants'
import { EN, ES, RU, BN } from './locales'
import { customFormatProcessor } from './customFormatProcessor'

const resources = {
  [languageCodes.en]: {
    translation: EN,
  },
  [languageCodes.es]: {
    translation: ES,
  },
  [languageCodes.ru]: {
    translation: RU,
  },
  [languageCodes.bn]: {
    translation: BN,
  },
}

const initOptions = {
  // debug: true,
  resources,
  detection: {
    order: ['cookie', 'localStorage', 'sessionStorage', 'navigator'],
    lookupQuerystring: 'lng',
    lookupCookie: 'i18next',
    lookupLocalStorage: 'i18nextLng',
    lookupSessionStorage: 'i18nextLng',
    lookupFromPathIndex: 0,
    lookupFromSubdomainIndex: 0,
    caches: ['cookie', 'localStorage', 'sessionStorage'],
    excludeCacheFor: [],
  },
  fallbackLng: languageCodes.en,
  interpolation: {
    escapeValue: false, // react already safes from xss
  },
  postProcess: [customFormatProcessor.name],
  react: {
    wait: true,
  },
}

i18n.use(LanguageDetector).use(customFormatProcessor).init(initOptions)

export default i18n
