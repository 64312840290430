export const FILE_TYPES = {
  CSV: 'csv',
  PDF: 'pdf',
}

export const ASSET_TYPES = {
  file: 'file',
  link: 'link',
}

export const MIME_TYPES = {
  bmp: 'image/bmp',
  jpeg: 'image/jpeg',
  pdf: 'application/pdf',
  png: 'image/png',
  tiff: 'image/tiff',
}
