const Participants = function (restClient) {
  this.restClient = restClient
}

Participants.prototype.createParticipant = function (orgId, eventId, participant) {
  return this.restClient.post(`/orgs/${orgId}/events/${eventId}/participants`, participant)
}

Participants.prototype.deleteParticipant = function (orgId, eventId, participantId) {
  return this.restClient.delete(`/orgs/${orgId}/events/${eventId}/participants/${participantId}`)
}

Participants.prototype.getAll = function (orgId, eventId) {
  return this.restClient.get(`/orgs/${orgId}/events/${eventId}/participants`)
}

Participants.prototype.customSearch = function (orgId, eventId, filter) {
  return this.restClient.post(`/orgs/${orgId}/events/${eventId}/participants/search`, filter)
}

Participants.prototype.getPage = function (orgId, eventId, page, perPage) {
  return this.restClient.get(`/orgs/${orgId}/events/${eventId}/participants?page=${page}&perPage=${perPage}`)
}

Participants.prototype.updateParticipant = function (orgId, eventId, participant) {
  const body = { updatedParticipant: participant }
  return this.restClient.patch(`/orgs/${orgId}/events/${eventId}/participants/${participant.id}`, body)
}

Participants.prototype.importParticipants = function (orgId, eventId, fileName, type) {
  const body = { fileName, type }
  return this.restClient.post(`/orgs/${orgId}/events/${eventId}/participants/import`, body)
}

export default Participants
