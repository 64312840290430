const BotTraining = function (restClient) {
  this.restClient = restClient
}

BotTraining.prototype.getTrainingSummaries = function (orgId, eventId) {
  return this.restClient.get(`/orgs/${orgId}/events/${eventId}/botTraining`)
}

BotTraining.prototype.getTrainingResults = function (orgId, eventId, trainingId) {
  return this.restClient.get(`/orgs/${orgId}/events/${eventId}/botTraining/${trainingId}/results`)
}

BotTraining.prototype.runTraining = function (orgId, eventId, numUtterances, intentIds) {
  return this.restClient.post(`/orgs/${orgId}/events/${eventId}/botTraining`, {
    numUtterances,
    intentIds,
  })
}

BotTraining.prototype.updateTrainingResult = function (orgId, eventId, trainingId, trainingResult) {
  return this.restClient.patch(
    `/orgs/${orgId}/events/${eventId}/botTraining/${trainingId}/results/${trainingResult.id}`,
    trainingResult,
  )
}

export default BotTraining
