export default {
  main: '#4C4C4C',
  50: '#f4f4f4',
  100: '#ebebeb',
  200: '#e0e0e0',
  300: '#ccc',
  400: '#adadad',
  tes: '#a7a7a7',
  500: '#999',
  600: '#686868',
  700: '#555',
  800: '#444',
  900: '#222',
}
