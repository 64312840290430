import { FieldErrors, FieldPath, FieldError } from 'react-hook-form'
import { getIn } from '~core/util/data/getIn'

export const getErrorProps = (
  path: FieldPath<Record<string, unknown>>,
  errors: FieldErrors,
  errorMessages?: Record<string, string>,
) => {
  const error = getIn<FieldError>(errors, path)

  if (!error) {
    return {}
  }

  let helperText = error.message

  if (error.type === 'required') {
    helperText = 'Required'
  } else if (errorMessages?.[error.type]) {
    helperText = errorMessages?.[error.type]
  } else {
    helperText = error.type
  }

  return {
    error: Boolean(helperText),
    helperText,
  }
}
