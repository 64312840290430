import React from 'react'
import { Flex } from 'mui-blox'
import { Typography, TextField, Card, CardContent, CardActions } from '@mui/material'
import { Button, ActivityContainer, SemanticIcon, iconUsages, IconButton } from '~controls'
import { sentResult, useSubmitNotifications } from './hooks/useSubmitNotifications'
import { useParams } from 'react-router-dom'
import { ExconFrame } from '~layout'
import { useExconSettingsContext } from '~brix/connectors/context'
import { Scrollable } from '../../../page'
import { useForm, useFieldArray } from 'react-hook-form'
import { FieldController } from '~form'
import { parsePhoneNumber } from '~core/util/normalize/parsePhoneNumber'

type FormData = {
  notificationTemplate: string
  recipients: { firstName?: string; lastName?: string; phoneNumber: string }[]
}

const SendDiscoveryNotifications = (): JSX.Element => {
  const { exconId } = useParams<{ exconId: string }>()
  const settings = useExconSettingsContext()
  const { result, submit: triggerNotifications, isSubmitting } = useSubmitNotifications(exconId)
  const { control, handleSubmit, watch } = useForm<FormData>({
    defaultValues: {
      notificationTemplate: settings.get('defaultNotificationTemplate'),
      recipients: [{ phoneNumber: '', firstName: '', lastName: '' }],
    },
  })

  const recipients = watch('recipients')

  const { fields, remove, append } = useFieldArray({
    control,
    name: 'recipients',
  })

  const submit = handleSubmit(values => {
    triggerNotifications(values)
  })

  const renderStatusIcon = index => {
    if (!recipients[index]) return null

    const resultItem = result.find((r: sentResult) => {
      return (
        parsePhoneNumber(r.phoneNumber).phoneNumber === parsePhoneNumber(recipients[index].phoneNumber).phoneNumber
      )
    })

    if (!resultItem) return null

    return resultItem.status === 'success' ? (
      <SemanticIcon usage={iconUsages.check} color='success' style={{ alignSelf: 'center', marginRight: '8px' }} />
    ) : (
      <SemanticIcon usage={iconUsages.error} color='error' style={{ alignSelf: 'center', marginRight: '8px' }} />
    )
  }

  const title = settings.get('title') || 'Send Discovery Notifications'

  return (
    <ExconFrame>
      <Scrollable>
        <Flex flexColumn p={2}>
          <Typography variant='h6'>{title}</Typography>
          <Flex flexColumn alignContent='center' mt={1}>
            <FieldController control={control} name='notificationTemplate'>
              {({ field }) => <TextField multiline {...field} placeholder='Type your text message here...' />}
            </FieldController>
            {fields.map((recipient, index) => {
              return (
                <Card key={recipient.id} sx={{ p: 1, marginTop: index > 0 ? 1 : 'initial' }}>
                  <CardContent>
                    <Flex flexColumn>
                      <FieldController
                        control={control}
                        name={`recipients.${index}.phoneNumber`}
                        rules={{ required: true }}
                      >
                        {({ field }) => (
                          <TextField
                            {...field}
                            label='Phone Number'
                            InputProps={{ endAdornment: renderStatusIcon(index) }}
                          />
                        )}
                      </FieldController>
                      <Flex>
                        <FieldController control={control} name={`recipients.${index}.firstName`}>
                          {({ field }) => <TextField {...field} label='First Name' sx={{ flex: 1 }} />}
                        </FieldController>
                        <FieldController control={control} name={`recipients.${index}.lastName`}>
                          {field => <TextField {...field} label='Last Name' sx={{ flex: 1, marginLeft: 1 }} />}
                        </FieldController>
                      </Flex>
                    </Flex>
                  </CardContent>
                  <CardActions>
                    <Flex justifyContent='flex-end' flex={1}>
                      <IconButton color='warning' size='small' onClick={() => remove(index)}>
                        <SemanticIcon usage={iconUsages.delete} />
                      </IconButton>
                    </Flex>
                  </CardActions>
                </Card>
              )
            })}
          </Flex>
          <Flex justifyContent='flex-end' mt={1}>
            <Flex mr={1}>
              <Button
                color='primary'
                variant='outlined'
                onClick={() => append({ firstName: '', lastName: '', phoneNumber: '' })}
              >
                Add Recipient
              </Button>
            </Flex>
            <ActivityContainer isActive={isSubmitting}>
              <Button color='primary' variant='contained' type='submit' onClick={submit}>
                Submit
              </Button>
            </ActivityContainer>
          </Flex>
        </Flex>
      </Scrollable>
    </ExconFrame>
  )
}

export default SendDiscoveryNotifications
