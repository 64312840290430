import React from 'react'
import PropTypes from 'prop-types'
import withStyles from '@mui/styles/withStyles'
import ClassNames from 'classnames'
import { logoWithoutDesc, logoXSmall } from '../../../images'

const LogoSmall = ({ classes, className = undefined, hide, variant = 'logoXSmall' }) => {
  const logoMap = {
    logoXSmall: logoXSmall,
    logoWithoutDesc: logoWithoutDesc,
  }

  return (
    <img
      src={logoMap[variant]}
      alt='logo'
      className={ClassNames(
        classes.logoSmall,
        {
          [classes.logoSmallVisible]: !hide,
        },
        className,
      )}
    />
  )
}

LogoSmall.propTypes = {
  hide: PropTypes.bool,
  variant: PropTypes.oneOf(['logoXSmall', 'logoWithoutDesc']),
}

LogoSmall.defaultProps = {
  hide: false,
  variant: 'logoXSmall',
}

const styles = ({ layout, transitions }) => ({
  logoSmall: {
    maxWidth: layout.sidebarCollapsed - 1,
    height: layout.topBar,
    margin: 0,
    width: 0,
    opacity: 0,
  },
  logoSmallVisible: {
    padding: '10px',
    width: layout.sidebarCollapsed,
    opacity: 100,
    transition: transitions.create(['opacity'], {
      duration: transitions.duration.standard,
    }),
  },
})

export default withStyles(styles)(LogoSmall)
