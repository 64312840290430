import { Map } from 'immutable'

let urlMap = Map()

export function fetchOnce(url, method, callback, sharedPromise = false) {
  let promise = urlMap.getIn([method.toLowerCase(), url])
  if (promise) return sharedPromise ? promise : new Promise(() => {})

  promise = callback()
  setUrlPromiseMap(url, method, promise)

  return promise
}

const setUrlPromiseMap = (url, method, promise) => {
  urlMap = urlMap.setIn([method.toLowerCase(), url], promise)
}

export const clearPromiseForUrl = (url, method) => {
  urlMap = urlMap.deleteIn([method.toLowerCase(), url])
}
