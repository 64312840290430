import React, { useState } from 'react'
import { Map } from 'immutable'
import { typify, TYPES } from '../../../../../core/util/data/typify'

/**
 *
 * @deprecated
 */
export const useSideDrawer = () => {
  const [state, setState] = useState(Map())
  const setAll = all => setState(current => current.merge(all))

  const openDrawer = (Component, onOpenProps = {}) => {
    const { DrawerProps = {}, drawerTitle, ...componentProps } = onOpenProps
    const { onClosed, expandable = true, defaultExpanded = false, expandedVW, title } = DrawerProps

    setAll(
      Map([
        ['Component', Component],
        ['componentProps', componentProps],
        ['open', true],
        ['onClosed', onClosed],
        ['DrawerProps', { title: title || drawerTitle, expandable, defaultExpanded, expandedVW }],
      ]),
    )
  }
  const handleSet = (key, value) => setState(current => current.set(key, value))
  const setOpen = o => handleSet('open', o)

  const Component = state.get('Component', null)
  const componentProps = state.get('componentProps', {})
  const DrawerProps = state.get('DrawerProps', { expandable: true, expandedVW: false, defaultExpanded: false })
  const onClosed = state.get('onClosed', () => null)
  const open = state.get('open', false)
  const title = state.get('title', '')

  const closeDrawer = (options = {}) => {
    setOpen(false)

    const { cancelOnClosed } = options
    if (cancelOnClosed) return

    onClosed && onClosed()
  }

  const renderComponent = (renderProps = {}) => {
    switch (typify(Component)) {
      case TYPES.function:
        return React.createElement(Component, { ...componentProps, ...renderProps })
      case TYPES.object:
        return React.cloneElement(Component, { ...componentProps, ...renderProps })
      default:
        return null
    }
  }

  return {
    openDrawer,
    closeDrawer,
    drawerProps: {
      openDrawer,
      open,
      renderComponent,
      closeDrawer,
      title,
      ...DrawerProps,
    },
  }
}
