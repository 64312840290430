export const DEMO_TYPES = {
  badge: 'badge',
  exhibitorSearch: 'exhibitorSearch',
  itineraries: 'itineraries',
  meetingSchedule: 'meetingSchedule',
  sessionSearch: 'sessionSearch',
  speakerNotification: 'speakerNotification',
  profileSearch: 'profileSearch',
  vipResponses: 'vipResponses',
}
