import { useCallback, useState, useRef, useEffect } from 'react'
import fetch from 'isomorphic-fetch'
import { useUnauthorizedHandler } from '../auth/useUnauthorizedHandler'
import { processApiError } from './processApiError'
import { UseFetchRetry } from './useFetchRetry'
import { resolveResponse } from './resolveResponse'

export const useProcessGet = (canRetry: UseFetchRetry['canRetry'], setTried: UseFetchRetry['setTried']) => {
  const cancelRef = useRef(false)

  useEffect(() => {
    return () => {
      cancelRef.current = true
    }
  })

  const unAuthHandler = useUnauthorizedHandler()
  const [errors, setError] = useState({})

  const processFetch = useCallback(
    async (url, payload) => {
      if (errors[url]) {
        const canProceed = !cancelRef.current && (await canRetry(url))

        if (!canProceed) {
          return processApiError(errors[url], { unAuthHandler })
        }
      }

      const request = await fetch(url, payload)

      setTried(url)

      if (request.ok) {
        const response = await resolveResponse(request)

        return response
      } else {
        !cancelRef.current &&
          setError(curr => {
            curr[url] = request

            return curr
          })
        processApiError(request, { unAuthHandler })
      }
    },
    [errors, setTried, canRetry, unAuthHandler],
  )

  return processFetch
}
