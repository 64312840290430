export const screeningStatus = {
  accepted: 'accepted',
  expired: 'expired',
  rejected: 'rejected',
  waitingApproval: 'waitingApproval',
  waitingTemperature: 'waitingTemperature',
}

export const screeningQuestionnaireAnswers = {
  yes: 'yes',
  no: 'no',
  neutral: 'n/a',
}

export const screeningRecordTemperature = {
  adminRequired: 'adminRequired',
  codeOptional: 'codeOptional',
  codeRequired: 'codeRequired',
  none: 'none',
  selfOptional: 'selfOptional',
  selfRequired: 'selfRequired',
  secondTestOptional: 'secondTestOptional',
  secondTestRequired: 'secondTestRequired',
}

export const SCREENING_CONFIRMATION = {
  none: 'none',
  required: 'required',
  optional: 'optional',
}

export const screeningErrorTypes = {
  missingAnswers: 'missingAnswers',
  missingQuestions: 'missingQuestions',
  missingQuestionnaire: 'missingQuestionnaire',
  missingSettings: 'missingSettings',
  tooSoon: 'tooSoon',
}

// Displays temperature controls in screening status page
export const screeningSelfTemperatureOptions = [
  screeningRecordTemperature.codeOptional,
  screeningRecordTemperature.codeRequired,
  screeningRecordTemperature.selfOptional,
  screeningRecordTemperature.selfRequired,
]

// Displays qr code in screening status page
export const screeningCodeOptions = [
  screeningRecordTemperature.codeOptional,
  screeningRecordTemperature.codeRequired,
]

// Displays skip temperature option in screening status page
export const screeningTemperatureOptionalOptions = [
  screeningRecordTemperature.selfOptional,
  screeningRecordTemperature.codeOptional,
]

// Displays confirmation instructions in screening status page
export const SCREENING_CONFIRMATION_OPTIONS = [SCREENING_CONFIRMATION.optional, SCREENING_CONFIRMATION.required]
