import { isImmutable } from 'immutable'

export const isValue = value => {
  if (value === null || value === undefined || !value.constructor) return false

  switch (value.constructor.name) {
    case 'Number':
      return !isNaN(value)
    case 'Function':
      return true
    case 'String':
      return value !== ''
    case 'Boolean':
      return value === true || value === false
    case 'Array':
      return value.length > 0
    case 'Object':
      return Object.keys(value).length > 0

    default: {
      // babel transpilation mutates prototype name for immutable objects, so those need to be checked here
      if (isImmutable(value)) {
        return value.size !== 0
      }

      // console.log('Value not recognized:', value)
      return false
    }
  }
}
