export const PARTICIPANT_FIELD_PRIVACY = {
  contactsOnly: 'contactsOnly',
  private: 'private',
  public: 'public',
}

export const PARTICIPANT_FIELD_PRIVACY_LABELS = {
  [PARTICIPANT_FIELD_PRIVACY.contactsOnly]: 'Share with contacts only',
  [PARTICIPANT_FIELD_PRIVACY.private]: 'Private',
  [PARTICIPANT_FIELD_PRIVACY.public]: 'Public',
}
