import { createActions } from 'redux-actions'
import { basicActions } from '../redux-helpers'

const actionCreators = createActions({
  customerResponses: {
    loadResponses: basicActions(),
    setFilterValue: filter => ({ filter }),
  },
})

export const actions = actionCreators.customerResponses
