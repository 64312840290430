import React from 'react'
import { Box, Typography, Button } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Flex } from 'mui-blox'
import { useAuthUser } from '../auth/useAuthUser'
import { useAuth0 } from '@auth0/auth0-react'
import ImpersonationForm from './ImpersonationForm'
import { hasUserScope, appScopes } from '../../lib/scopes'

const Account = () => {
  const classes = useStyles()

  const { user } = useAuthUser()
  const { logout } = useAuth0()
  const canImpersonate = hasUserScope(user.toJS(), [appScopes.any])

  const InfoItem = ({ label, value, ...other }) => (
    <Flex justifyContent='space-between' flexWrap='wrap' alignItems='center' {...other}>
      <Box>
        <Typography variant='body2' className={classes.label}>
          {label}
        </Typography>
      </Box>
      <Box minWidth={240}>
        <Typography variant='subtitle1'>{value}</Typography>
      </Box>
    </Flex>
  )

  return (
    <Flex flexColumn p={2.5}>
      <Flex flexColumn>
        <InfoItem label='Name' value={user.get('name')} />
        <InfoItem label='Email' value={user.get('email')} mt={1} />
        {canImpersonate && <ImpersonationForm mt={1} labelClassName={classes.label} />}
      </Flex>
      <Button color='primary' onClick={() => logout()}>
        Log out
      </Button>
    </Flex>
  )
}

const useStyles = makeStyles(({ typography }) => ({
  label: {
    fontWeight: typography.fontWeightBold,
  },
}))

export default Account
