import React from 'react'
import { Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useEventBranding } from '~brix/connectors/context/useEventBranding'
import { BotBubble } from '~controls'
import { Skeleton } from '@mui/material'

const Intro = ({ loading, text }) => {
  const classes = useStyles()
  const { branding } = useEventBranding()
  return loading ? (
    <Skeleton className={classes.root} height={100} />
  ) : (
    <BotBubble className={classes.root}>
      <Typography color='inherit'>
        <span id='conversation-intro'>{text || branding.display.introductionText}</span>
      </Typography>
    </BotBubble>
  )
}

const width = 286
const useStyles = makeStyles(() => ({
  root: {
    position: 'absolute',
    bottom: 80,
    left: '50%',
    marginLeft: -width / 2,
    width,
  },
}))

export default Intro
