export const POWER_UP_KEYS = {
  botMenu: 'botMenu',
  participantFields: 'participantFields',
  calendar: 'calendar',
  clientInventory: 'clientInventory',
  connectedStatus: 'connectedStatus',
}

export const searchPowerUpKeysModes = {
  and: 'and',
  or: 'or',
}

export const searchPowerUpKeysModesLabels = {
  [searchPowerUpKeysModes.and]: 'Has all tags',
  [searchPowerUpKeysModes.or]: 'Has any tags',
}

export const PARTICIPANT_FIELDS_DELIMITER = '::'

export const POWER_UP_ACTIONS = {
  confirmScreening: 'confirmScreening',
  addTimeStampToParticipant: 'addTimestampToParticipant',
}

export const BOT_MENU_ITEM_TYPE = {
  excon: 'excon',
  assetLink: 'assetLink',
  assetFile: 'assetFile',
}

export const BOT_MENU_ITEM_TYPE_LABEL = {
  [BOT_MENU_ITEM_TYPE.excon]: 'excon',
  [BOT_MENU_ITEM_TYPE.assetFile]: 'File Asset',
  [BOT_MENU_ITEM_TYPE.assetLink]: 'Link Asset',
}
