import { createActions } from 'redux-actions'
import { basicActions, basicActionsWithMeta, filterActions, gridSortActions, modalActions } from '../redux-helpers'

const actionCreators = createActions({
  notifications: {
    create: basicActions(),
    delete: basicActionsWithMeta(),
    filter: filterActions(),
    gridSort: gridSortActions(),
    load: basicActionsWithMeta(),
    modal: modalActions(),
    update: basicActionsWithMeta(),
    audience: basicActionsWithMeta(),
    clearAudience: (orgId, eventId) => ({ orgId, eventId }),
    childModal: modalActions(),
  },
})
export const actions = actionCreators.notifications
