export const SURVEY_BLOCK_TYPES = {
  autocomplete: 'autocomplete',
  boolean: 'boolean',
  bubbleMaker: 'bubbleMaker',
  chipList: 'chipList',
  custom: 'custom',
  color: 'color',
  date: 'date',
  dateTime: 'dateTime',
  email: 'email',
  float: 'float',
  group: 'group',
  header: 'header',
  image: 'image',
  imageSmall: 'imageSmall',
  imageAsset: 'imageAsset',
  linkAsset: 'linkAsset',
  list: 'list',
  markdown: 'markdown',
  markdownText: 'markdownText',
  money: 'money',
  number: 'number',
  phone: 'phone',
  postalCode: 'postalCode',
  readOnly: 'readOnly',
  select: 'select',
  selectBasic: 'selectBasic',
  shareViaEmail: 'shareViaEmail',
  simpleTimeSpan: 'simpleTimeSpan',
  static: 'static',
  string: 'string',
  stringList: 'stringList',
  time: 'time',
  total: 'total',
  transferList: 'transferList',
  url: 'url',
}
