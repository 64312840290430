import { useCallback } from 'react'
import { useFetchHandler } from './useFetchHandler'
import { getApiUrl } from './getApiUrl'
import { usePayload } from './usePayload'
import { useProcessGet } from './useProcessGet'
import { FetchOptions } from './types'
import { useFetchRetry, RetryOptions } from './useFetchRetry'

export const useGet = <TReturn>(options?: FetchOptions & RetryOptions) => {
  const { fetch, isFetching } = useFetchHandler<TReturn>(options)

  const getPayload = usePayload('GET')

  const { canRetry, setTried } = useFetchRetry(options)
  const processFetch = useProcessGet(canRetry, setTried)

  const get = useCallback(
    (url: string): Promise<TReturn> => {
      return fetch(() => processFetch(getApiUrl(url), getPayload()))
    },
    [getPayload, fetch, processFetch],
  )

  return { get, isGetting: isFetching }
}
