import { makeStyles } from '@mui/styles'

export const useGlobalStyles = makeStyles(({ breakpoints, mixins: { vh } }) => ({
  '@global': {
    'html, body, #root': {
      height: vh(100),
    },
    '#root': {
      [breakpoints.up('sm')]: {
        height: vh(100),
      },
    },
    'html, body, #root, #root>div': {
      [breakpoints.up('sm')]: {
        overflow: 'hidden',
      },
    },
    '#root, form': {
      display: 'flex',
      flex: 1,
    },
    form: {
      flexDirection: 'column',
      [breakpoints.up('sm')]: {
        flex: 1,
      },
    },
  },
}))
