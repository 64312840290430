import React from 'react'
import { makeStyles } from '@mui/styles'
import { useEventBranding } from '~brix/connectors/context/useEventBranding'
import SendMessage from '../../conversations/SendMessage'

const ConversationInput = ({ onSendMessage }) => {
  const classes = useStyles()
  const { branding } = useEventBranding()

  return (
    <div className={classes.root}>
      <SendMessage
        onSendMessage={message => onSendMessage(message)}
        placeholder={branding?.display?.inputPlaceholder || 'Message'}
        fullWidth
      />
    </div>
  )
}

const useStyles = makeStyles(({ palette, spacing, mixins: { alpha, border } }) => ({
  root: {
    borderBottom: border(1, alpha(palette.secondary.light, 0.3)),
    backgroundColor: palette.background.default,
    padding: spacing(1),
  },
}))

export default ConversationInput
