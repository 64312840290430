import { definitions as appScopes } from '../../core/scopes/definitions'

function checkScope(scopes, allowedScopes) {
  if (!scopes?.length || scopes.length === 0) return false

  return scopes.some(s => allowedScopes.includes(s))
}

function getOrgScopes(user) {
  const orgIds = user?.orgs?.map(o => o.orgId)
  return orgIds || []
}

function hasOrgScope(user, orgId, allowedScopes) {
  if (orgId === undefined) {
    return false
  }

  const org = user?.orgs?.filter(o => o.orgId === orgId)[0] || {}
  return checkScope(org?.scopes, allowedScopes)
}

function hasUserScope(user, allowedScopes) {
  return checkScope(user?.scopes, allowedScopes)
}

export { appScopes, getOrgScopes, hasOrgScope, hasUserScope }
