import React from 'react'
import { IconButton, Avatar } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Flex } from 'mui-blox'
import classNames from 'classnames'
import { useSideDrawer, SemanticIcon, iconUsages } from '../../_controls'
import Account from '../Account'
import FramedDrawer from '../../_layout/frames/FramedDrawer'
import { useAuthUser } from '../../auth/useAuthUser'

export const useAccount = () => {
  const classes = useStyles()

  const { user } = useAuthUser()
  const { openDrawer, drawerProps } = useSideDrawer()
  const avatar = user.get('pictureUrl') ? (
    <Avatar src={user.get('pictureUrl')} className={classes.avatar} />
  ) : (
    <Avatar className={classes.avatar}>
      <SemanticIcon usage={iconUsages.participant} className={classes.icon} />
    </Avatar>
  )

  const handleClick = () => {
    openDrawer(Account, {
      drawerTitle: avatar,
    })
  }

  const renderButton = ({ className }) => {
    if (user.size === 0) return null
    return (
      <Flex className={classNames(classes.account, className)}>
        <IconButton onClick={handleClick} color='inherit' size='large'>
          <SemanticIcon usage={iconUsages.participant} />
        </IconButton>
        <FramedDrawer {...drawerProps} />
      </Flex>
    )
  }

  return { renderButton }
}

const useStyles = makeStyles(({ spacing, breakpoints }) => ({
  icon: {
    height: spacing(4),
    width: spacing(4),
  },
  avatar: {
    color: 'inherit',
    marginLeft: spacing(0.5),
    backgroundColor: 'inherit',
    height: spacing(6),
    width: spacing(6),
    [breakpoints.down('sm')]: {
      height: spacing(5),
      width: spacing(5),
    },
  },
}))
