import { applyMiddleware, createStore } from 'redux'
import thunkMiddleware from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'

import reducers from './reducersCombined'

const composeEnhancers = composeWithDevTools({ trace: true, traceLimit: 25 })
export const store = createStore(reducers, composeEnhancers(applyMiddleware(thunkMiddleware)))

export const getDispatch = () => store.dispatch

export const getStore = () => store
