export const systemEntities = {
  dialogflow: [
    '@sys.address',
    '@sys.age',
    '@sys.airport',
    '@sys.any',
    '@sys.cardinal',
    '@sys.color',
    '@sys.currency-name',
    '@sys.date-period',
    '@sys.date-time',
    '@sys.date',
    '@sys.duration',
    '@sys.email',
    '@sys.flight-number',
    '@sys.geo-capital',
    '@sys.geo-city',
    '@sys.geo-country-code',
    '@sys.geo-country',
    '@sys.geo-state',
    '@sys.given-name',
    '@sys.language',
    '@sys.last-name',
    '@sys.location',
    '@sys.music-artist',
    '@sys.music-genre',
    '@sys.number-sequence',
    '@sys.number',
    '@sys.ordinal',
    '@sys.percentage',
    '@sys.phone-number',
    '@sys.place-attraction',
    '@sys.sys.number-integer',
    '@sys.temperature',
    '@sys.time-period',
    '@sys.time',
    '@sys.unit-area-name',
    '@sys.unit-area',
    '@sys.unit-currency',
    '@sys.unit-information-name',
    '@sys.unit-length-name',
    '@sys.unit-length',
    '@sys.unit-speed-name',
    '@sys.unit-speed',
    '@sys.unit-volume-name',
    '@sys.unit-volume',
    '@sys.unit-weight-name',
    '@sys.unit-weight',
    '@sys.url',
    '@sys.zip-code',
  ],
}
