import fetch from '../../api/fetch'
import { actions, branch } from './actionCreators'
import sharedActions from '../shared/actions'
import { dispatchBasicActions } from '../redux-helpers'

export function deleteUser(orgId, userId) {
  return dispatchBasicActions(actions.delete, async () => {
    await fetch.delete(`/orgs/${orgId}/users/${userId}`)
    return {
      payload: {},
      meta: { orgId, userId },
    }
  })
}

export function loadUsers(orgId) {
  return dispatchBasicActions(actions.load, async () => {
    const users = await fetch.get(`/orgs/${orgId}/users`)
    return {
      payload: { users },
      meta: { orgId },
    }
  })
}

export const queryUsers = query =>
  dispatchBasicActions(actions.userQuery, async () => {
    if (!query) {
      return {
        payload: {
          users: [],
        },
      }
    }

    const users = await fetch.get(`/users/query/${query}`)
    return {
      payload: { users },
    }
  })

export const pinColumn = column => sharedActions.pinColumn(column, branch)

export const gridSort = (column, direction) => sharedActions.gridSort(column, direction, branch)

export const adjustColumn = (column, delta) => sharedActions.adjustColumn(column, delta, branch)
