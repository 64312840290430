import React from 'react'
import { Flex } from 'mui-blox'
import { Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import ClassNames from 'classnames'
import LogoSmall from '../../_layout/logo/LogoSmall'

const Advert = ({ action }) => {
  const classes = useStyles()

  return (
    <Flex
      className={ClassNames({
        [classes.root]: true,
        [classes.sizeLarge]: Boolean(action),
      })}
      justifyContent='center'
      alignItems='center'
    >
      <Flex mb={0.5}>
        <Typography variant='h6' sx={{ display: 'inline', fontSize: '0.875rem', fontWeight: 500 }}>
          Powered by&nbsp;
        </Typography>
      </Flex>
      <LogoSmall variant='logoWithoutDesc' className={classes.footerIcon} />

      <div className={classes.action}>{action}</div>
    </Flex>
  )
}

const useStyles = makeStyles(({ palette, spacing, transitions }) => ({
  root: {
    backgroundColor: palette.background.paper,
    borderRadius: spacing(0, 0, 0.5, 0.5),
    borderTop: '2px solid',
    borderTopColor: palette.grey[200],
    minHeight: 0,
    padding: spacing(1),
    position: 'relative',
    transition: transitions.create(['minHeight']),
  },
  footerIcon: {
    height: spacing(3),
    padding: 0,
  },
  sizeLarge: {
    height: 40,
    minHeight: 40,
  },
  company: {
    color: '#8BC53F',
  },
  action: {
    position: 'absolute',
    left: spacing(0.5),
    top: spacing(0.5),
  },
}))

export default Advert
