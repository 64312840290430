import { createActions } from 'redux-actions'
import { basicActions } from '../redux-helpers'

export const branch = 'botTrainingSummaries'

const actionCreators = createActions({
  trainingSummaries: {
    loadSummaries: basicActions(),
  },
})

export const actions = actionCreators.trainingSummaries
