export const getGlobalStyles = ({ palette, typography }) => ({
  html: {
    fontSize: typography.htmlFontSize || typography.fontSize || 14,
  },
  body: {
    backgroundColor: palette.background.default,
    color: palette.text.primary,
  },
  a: {
    color: palette.tertiary.dark,
    textDecoration: 'none',
    backgroundColor: 'transparent',
    '&:hover': {
      color: palette.tertiary.dark,
    },
  },
  '.ReactVirtualized__Grid:focus': {
    outline: 'none',
  },
})
