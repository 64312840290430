import React from 'react'
import { Flex } from 'mui-blox'
import { AppBar, Toolbar, Button, Stack, Paper, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import ClassNames from 'classnames'
import Scrollable from '../page/ResponsiveScrollable'
import { useScript } from '~brix/connectors/context/useScript'
import { useQueryParams } from '../routing'
import { sciensio } from '../../config'

export const MessengerCustomerMock = () => {
  const classes = useStyles()
  const { event } = useQueryParams()

  useScript(`${sciensio.apiUrl}/api/messenger/event/${event}${window.location.search}`)

  return (
    <Flex flexDirection='column'>
      <AppBar color='secondary' position='static'>
        <Toolbar>
          <img
            className={classes.logo}
            src='https://intelliweb.network/hub/wp-content/uploads/sites/7/2019/01/generic-company-logo-png-5.png'
          />
          {['Home', 'Find Stuff', 'Learn Stuff', 'Contact Us', 'Register'].map(title => (
            <Button key={title} variant='text' color='inherit'>
              {title}
            </Button>
          ))}
        </Toolbar>
      </AppBar>
      <Scrollable>
        <section className={ClassNames(classes.section)}>
          <Flex>
            <Flex flex={2} className={classes.gridRoot}>
              <Stack className={classes.gridList}>
                {tileData.map(tile => (
                  <Stack key={tile.img}>
                    <img src={tile.img} alt={tile.title} />
                  </Stack>
                ))}
              </Stack>
            </Flex>
            <Paper className={classes.note}>
              <Typography variant='h2'>We like stuff. Come see our stuff.</Typography>
              <Typography>
                Rest assured that our stuff is the best. If you can find better stuff out there, then we will give
                you our stuff and take their stuff so that we can always have the best stuff.
              </Typography>
            </Paper>
          </Flex>
        </section>
        <section className={classes.section}>
          <Flex>
            <Flex flex={1}>
              <Flex flex={2} id='42chat-messenger'></Flex>
              <Flex flex={1}>
                <Paper className={classes.note}>
                  <Typography variant='h4'>Chatting about our stuff is fun!</Typography>
                  <Typography>
                    People love to chat and converse about our stuff day and night. Try our bot to get your fix.
                  </Typography>
                </Paper>
              </Flex>
            </Flex>
          </Flex>
        </section>
        <section className={classes.section}>
          <Flex>
            <Flex flex={1}>
              <Paper className={classes.note}>
                <Typography variant='h4'>People love our stuff!</Typography>
                <Typography>{`People that use our stuff never die! Well, actually they probably do. We aren't around when they die so we really don't know. But if they die, they die happy!`}</Typography>
              </Paper>
            </Flex>
            <Flex flex={2}>
              <img
                className={classes.section2Image}
                src='https://www.bostoneveningtherapy.com/wp-content/uploads/2018/04/portrait_smile_felicidade_happiness_vida_alegria_sorriso-1024x683.jpg'
              />
            </Flex>
          </Flex>
        </section>
      </Scrollable>
    </Flex>
  )
}

const useStyles = makeStyles(({ palette, spacing }) => ({
  '@global': {
    body: {
      height: 'unset',
      overflow: 'auto',
    },
  },
  logo: {
    width: 200,
    height: 84,
    marginRight: 16,
  },
  section: {
    padding: spacing(4),
  },
  note: {
    padding: spacing(2),
    flex: '1',
    '& p': {
      paddingTop: spacing(2),
    },
  },
  gridRoot: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: palette.background.paper,
  },
  gridList: {
    height: 450,
  },
  section2Image: {
    width: '100%',
    height: 'auto',
  },
}))

const tileData = [
  {
    img: 'https://scx2.b-cdn.net/gfx/news/hires/2016/howcuttingdo.jpg',
    title: 'burgers',
    cols: 2,
  },
  {
    img: 'https://media.self.com/photos/5f189b76c58e27c99fbef9e3/1:1/w_768,c_limit/blackberry-vanilla-french-toast.jpg',
    title: 'Breakfast',
    cols: 1,
  },
  {
    img: 'https://img-new.cgtrader.com/items/842623/ee4db8adb9/cartoon-rocket-ship-v2-3d-model-low-poly-max-obj-3ds-fbx-c4d-tbscene.jpg',
    title: 'rocket',
    cols: 1,
  },
  {
    img: 'https://www.vmcdn.ca/f/files/princegeorgematters/images/shutterstock/sports-stock.jpg;w=960',
    title: 'sports',
    cols: 2,
  },
  {
    img: 'https://cdn.vox-cdn.com/thumbor/IvF5PsylXtWchyzrjPtWjx-hCyc=/1400x1050/filters:format(jpeg)/cdn.vox-cdn.com/uploads/chorus_asset/file/19967467/Grow_grass_iStock_899234322.jpg',
    title: 'grass',
    cols: 1,
  },
  {
    img: 'https://ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B0855NLMWL&Format=_ML160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=toysrus090-20&language=en_US',
    title: 'toy1',
    cols: 1,
  },
  {
    img: 'https://ae01.alicdn.com/kf/HTB1tmf.XozrK1RjSspmq6AOdFXa6/Adjustable-Height-Bath-And-Shower-Chair-Top-Rated-Shower-Bench-Safety-Seat-shower-Stool-For-Elderly.jpg_Q90.jpg_.webp',
    title: 'chair',
    cols: 1,
  },
  {
    img: 'https://img.sndimg.com/food/image/upload/c_thumb,q_80,w_412,h_232/v1/img/recipes/27/78/6/picB92GVL.jpg',
    title: 'beans',
    cols: 2,
  },
  {
    img: 'https://i.pinimg.com/originals/15/cd/01/15cd014155ec4370e9c8416c663ace12.jpg',
    title: 'watch',
    cols: 1,
  },
]
