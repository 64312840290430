import React from 'react'
import PropTypes from 'prop-types'

class Title extends React.PureComponent {
  render() {
    return this.props.children || null
  }

  state = {}

  static getDerivedStateFromProps(nextProps) {
    document.title = nextProps.render
    return {}
  }
}

Title.propTypes = {
  render: PropTypes.string.isRequired, // eslint-disable-line react/no-unused-prop-types
}

export default Title
