import React from 'react'
import PropTypes from 'prop-types'
import { Flex } from 'mui-blox'
import { makeStyles } from '@mui/styles'
import ClassNames from 'classnames'

const PhoneFrame = ({ children, frame, color, style }) => {
  const classes = useStyles()
  return (
    <Flex
      style={style}
      flexColumn
      className={ClassNames({ [classes.phone]: frame === 'phone', [classes.invertColors]: color === 'black' })}
    >
      {children}
    </Flex>
  )
}

PhoneFrame.propTypes = {
  frame: PropTypes.string,
  color: PropTypes.string,
  style: PropTypes.object,
}

const borderTopHeight = 40
const borderBottomHeight = 60
const useStyles = makeStyles(({ palette, mixins: { border, alpha, lighten } }) => ({
  phone: {
    position: 'relative',
    border: border(3, alpha(palette.common.white, 0.78)),
    borderTopWidth: borderTopHeight,
    borderBottomWidth: borderBottomHeight,
    borderRadius: 34,
    '&:before': {
      content: '""',
      display: 'block',
      width: 42,
      height: 5,
      position: 'absolute',
      top: -borderTopHeight / 2,
      left: '50%',
      transform: 'translate(-50%, -50%)',
      background: alpha(palette.common.white, 0.92),
      borderRadius: 10,
    },
    '&:after': {
      content: '""',
      display: 'block',
      width: borderBottomHeight - 20,
      height: borderBottomHeight - 20,
      position: 'absolute',
      left: '50%',
      bottom: -borderBottomHeight - 10,
      transform: 'translate(-50%, -50%)',
      background: alpha(palette.common.white, 0.92),
      borderRadius: '50%',
    },
  },
  invertColors: {
    borderColor: lighten(palette.common.black, 0.2),
    '&:before': {
      background: alpha(palette.common.black, 0.92),
    },
    '&:after': {
      background: alpha(palette.common.black, 0.92),
    },
  },
}))

export default PhoneFrame
