export const participantTypes = {
  admin: 'admin',
  attendee: 'attendee',
  customerAdmin: 'customeradmin',
  support: 'support',
  unknown: 'unknown',
}

export const allParticipantTypes = {
  ...participantTypes,
}

export const associatedParticipantTypes = {
  guardian: 'guardian',
  dependent: 'dependent',
}

export const participantAdminTypes = [
  participantTypes.admin,
  participantTypes.support,
  participantTypes.customerAdmin,
]

export const participantTypeLabels = {
  [allParticipantTypes.admin]: 'Admin',
  [allParticipantTypes.attendee]: 'Attendee',
  [allParticipantTypes.customerAdmin]: 'Customer Admin',
  [allParticipantTypes.support]: 'Support',
  [allParticipantTypes.unknown]: 'Unknown',
}
