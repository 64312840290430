import React from 'react'
import { IconButton as MuiIconButton, IconButtonProps, Theme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import classNames from 'classnames'
import withTooltip from '../withTooltip'

export type ComponentProps = IconButtonProps & {
  'aria-label'?: string
  className?: string
  emphasize?: boolean
  disabled?: boolean
  tooltip?: string
  TooltipProps?: Record<string, unknown>
  variant?: 'outlined' | 'contained'
  color?: IconButtonProps['color']
  buttonComponent?: React.ComponentType<any>
}

const RenderFunc: React.ForwardRefRenderFunction<HTMLButtonElement, ComponentProps> = (
  {
    children,
    className,
    size = 'large',
    tooltip,
    TooltipProps,
    emphasize = false,
    buttonComponent,
    variant = 'outlined',
    color = 'secondary',
    ...other
  },
  ref,
) => {
  const classes = useStyles()

  const count = React.Children.toArray(children)?.length || 0
  let ButtonComponent = buttonComponent ?? MuiIconButton

  return withTooltip(
    tooltip,
    other.disabled,
    TooltipProps,
  )(
    <ButtonComponent
      ref={ref}
      size={size}
      color={color}
      className={classNames(
        {
          [classes.multiple]: count > 1,
          [classes.emphasize]: emphasize,
          [classes.contained]: variant === 'contained',
          [classes.colorPrimary]: color === 'primary',
          [classes.colorSecondary]: color === 'secondary',
          [classes.colorInfo]: color === 'info',
          [classes.colorWarning]: color === 'warning',
          [classes.colorError]: color === 'error',
        },
        className,
      )}
      {...other}
    >
      {React.Children.map(children, child => {
        if (!React.isValidElement(child)) {
          return child
        }

        return React.cloneElement(child)
      })}
    </ButtonComponent>,
  )
}

const useStyles = makeStyles((theme: Theme) => {
  const {
    spacing,
    palette,
    mixins: { alpha },
  } = theme

  return createStyles({
    contained: {
      '&$colorPrimary': {
        backgroundColor: palette.primary.main,
        borderColor: palette.primary.main,
        color: palette.primary.contrastText,
      },
      '&$colorSecondary': {
        backgroundColor: palette.secondary.main,
        borderColor: palette.secondary.main,
        color: palette.secondary.contrastText,
      },
      '&$colorInfo': {
        backgroundColor: palette.info.main,
        borderColor: palette.info.main,
        color: palette.info.contrastText,
      },
      '&$colorWarning': {
        backgroundColor: palette.warning.main,
        borderColor: palette.warning.main,
        color: palette.warning.contrastText,
      },
      '&$colorError': {
        backgroundColor: palette.error.main,
        borderColor: palette.error.main,
        color: palette.error.contrastText,
      },
    },
    iconMd: {
      height: spacing(2.5),
      width: spacing(2.5),
    },
    multiple: {
      width: 'auto',
      paddingLeft: spacing(1.25),
      paddingRight: spacing(1.25),
      '& > svg:not(:last-of-type)': {
        marginRight: spacing(0.5),
      },
    },
    emphasize: {
      backgroundColor: alpha(palette.action.hover, palette.action.hoverOpacity),
      '&:hover': {
        backgroundColor: alpha(palette.action.hover, palette.action.hoverOpacity * 2),
      },
    },
    colorPrimary: {},
    colorSecondary: {},
    colorInfo: {},
    colorWarning: {},
    colorError: {},
  })
})

const IconButton = React.forwardRef(RenderFunc)

IconButton.displayName = 'IconButton'

export default IconButton
