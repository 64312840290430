const Orgs = function (restClient) {
  this.restClient = restClient
}

Orgs.prototype.create = function (org) {
  return this.restClient.post('/orgs', org)
}

Orgs.prototype.delete = function (orgId) {
  return this.restClient.delete(`/orgs/${orgId}`)
}

Orgs.prototype.getAll = function () {
  return this.restClient.get('/orgs')
}

export default Orgs
