import React from 'react'
import { Suspense, lazyRetry } from '../../../routing'

const Router = lazyRetry(() =>
  import(
    /* webpackChunkName: "osh" */
    './Router'
  ),
)

const Root = props => (
  <Suspense>
    <Router {...props} />
  </Suspense>
)

export default Root
