import { Map, fromJS } from 'immutable'
import { handleActions, combineActions } from 'redux-actions'
import { actions } from '../actionCreators'
import paths from '../paths'
import { arrayToMap } from '../../redux-helpers-immutable'
import { setIdk } from './setIdk'
import { userConversation as conversationFilters } from '../apiFilters'

const getOrgEventMap = (array = [], pathFunc, idField = 'id') =>
  fromJS(array).reduce((acc, item) => {
    const orgId = item.get('orgId')
    const eventId = item.get('eventId')
    const id = item.get(idField)

    return acc.setIn(pathFunc(orgId, eventId, id), item)
  }, Map())

const setPlatformLogs = (state, platformLogs) => {
  const platformLogMap = getOrgEventMap(platformLogs, paths.platformLog, 'smoochId')

  return state.mergeDeep(platformLogMap)
}

export default handleActions(
  {
    [actions.loadIdks]: (state, { payload: { idks, filter, platformLogs, count, page } }) => {
      const idkMap = arrayToMap(idks)
      const nextState = setPlatformLogs(state, platformLogs)
        .setIn(paths.systemIdkCount(filter), count)
        .setIn(paths.systemIdkPage(filter), page)

      return !state.getIn(paths.systemIdks())
        ? nextState.setIn(paths.systemIdks(), idkMap)
        : nextState.mergeIn(paths.systemIdks(), idkMap)
    },

    [actions.loadIdksForEvent]: (
      state,
      { payload: { orgId, eventId, idks, filter, page, count, platformLogs } },
    ) => {
      let idkMap = getOrgEventMap(idks, paths.idk)
      if (idkMap.size === 0) {
        idkMap = idkMap.setIn(paths.idks(orgId, eventId), Map())
      }

      const nextState = setPlatformLogs(state, platformLogs)
        .setIn(paths.idkPage(orgId, eventId, filter), page)
        .setIn(paths.idkCount(orgId, eventId, filter), count)

      return nextState.mergeDeep(idkMap)
    },

    [actions.loadConversation]: (state, { payload: { smoochId, conversation } }) => {
      const nextState = state.setIn(paths.conversation(smoochId), fromJS(conversation))
      return nextState
    },

    [actions.setConversationLogEntry]: (state, { payload }) => {
      const { lastLogEntry, ...rest } = payload

      const data = fromJS({ ...lastLogEntry, ...rest })

      const orgId = data.get('orgId')
      const eventId = data.get('eventId')
      const smoochId = data.get('smoochId')
      const id = data.get('id')

      const replaceIfInitialized = (state, getPath, setPath) => {
        const summaries = state.getIn(getPath)
        if (summaries) {
          return state.setIn(setPath, data)
        }
        return state
      }

      let nextState = replaceIfInitialized(
        state,
        paths.userConversations(orgId, eventId),
        paths.userConversation(orgId, eventId, id),
      )
      nextState = replaceIfInitialized(nextState, paths.systemUserConversations(), paths.systemUserConversation(id))
      nextState = replaceIfInitialized(
        nextState,
        paths.systemUserConversationsLiveEvent(),
        paths.systemUserConversationLiveEvent(id),
      )

      return nextState.setIn(paths.conversationEntry(smoochId, lastLogEntry.id), fromJS(lastLogEntry))
    },

    [actions.incrementUserConversationCount]: (state, { payload: { orgId, eventId } }) => {
      const path = paths.userConversationCount(orgId, eventId)
      return state.setIn(path, state.getIn(path, 0) + 1)
    },

    [actions.incrementSystemUserConversationCount]: (state, { payload: { filter } }) => {
      const path = paths.systemUserConversationCount(filter)
      return state.setIn(path, state.getIn(path, 0) + 1)
    },

    [combineActions(actions.setIdk.complete, actions.setIdkValidity.complete, actions.setIdkResolved.complete)]:
      setIdk,

    [actions.loadEventInfo]: (state, { payload: { orgId, eventId, info } }) =>
      state.setIn(paths.eventInfo(orgId, eventId), fromJS(info)),

    [actions.setBotActiveState.complete]: (state, { payload: { platformLog } }) =>
      state.setIn(
        paths.platformLog(platformLog.orgId, platformLog.eventId, platformLog.smoochId),
        fromJS(platformLog),
      ),

    [actions.updateBotActiveState]: (state, { payload: { orgId, eventId, smoochId, botActive } }) => {
      const platformLog = state.getIn(paths.platformLog(orgId, eventId, smoochId))
      if (platformLog) {
        return state.mergeIn(paths.platformLog(orgId, eventId, smoochId), {
          botActive,
        })
      }

      return state
    },

    [actions.loadSupportRequests]: (state, { payload: { supportRequests, platformLogs, filter, count, page } }) => {
      const supportRequestMap = arrayToMap(supportRequests)
      const nextState = setPlatformLogs(state, platformLogs)
        .setIn(paths.systemSupportRequestCount(filter), count)
        .setIn(paths.systemSupportRequestPage(filter), page)

      return !state.getIn(paths.systemSupportRequests())
        ? nextState.setIn(paths.systemSupportRequests(), supportRequestMap)
        : nextState.mergeIn(paths.systemSupportRequests(), supportRequestMap)
    },

    [actions.loadSupportRequestsByEvent]: (
      state,
      { payload: { orgId, eventId, supportRequests, platformLogs, filter, page, count } },
    ) => {
      let supportRequestMap = getOrgEventMap(supportRequests, paths.supportRequest)
      if (supportRequestMap.size === 0) {
        supportRequestMap = supportRequestMap.setIn(paths.supportRequests(orgId, eventId), Map())
      }

      const nextState = setPlatformLogs(state, platformLogs)
        .setIn(paths.supportRequestCount(orgId, eventId, filter), count)
        .setIn(paths.supportRequestPage(orgId, eventId, filter), page)

      return nextState.mergeDeep(supportRequestMap)
    },

    [actions.updateSupportRequest.complete]: (state, { payload: { supportRequest } }) => {
      const { id, orgId, eventId } = supportRequest

      let nextState = state
      if (state.getIn(paths.systemSupportRequests())) {
        nextState = nextState.setIn(paths.systemSupportRequest(id), fromJS(supportRequest))
      }
      if (state.getIn(paths.supportRequests(orgId, eventId))) {
        nextState = nextState.setIn(paths.supportRequest(orgId, eventId, id), fromJS(supportRequest))
      }

      return nextState
    },

    [actions.saveListScroll]: (state, { payload: { path, scrollTop } }) =>
      state.setIn(paths.contextListScroll(path), scrollTop),

    [actions.loadUserConversations]: (state, { payload: { filter, conversations, page, count, platformLogs } }) => {
      const systemPaths = {
        count: paths.systemUserConversationCount(filter),
        page: paths.systemUserConversationPage(filter),
        data:
          filter === conversationFilters.live
            ? paths.systemUserConversationsLiveEvent
            : paths.systemUserConversations,
      }

      const systemUserConversationsMap = arrayToMap(conversations)

      const nextState = setPlatformLogs(state, platformLogs)
        .setIn(systemPaths.count, count)
        .setIn(systemPaths.page, page)

      const path = systemPaths.data()

      return state.getIn(path)
        ? nextState.mergeIn(path, systemUserConversationsMap)
        : nextState.setIn(path, systemUserConversationsMap)
    },

    [actions.loadUserConversationsByEvent]: (
      state,
      { payload: { orgId, eventId, conversations, page, count, platformLogs } },
    ) => {
      const eventUserConversationsMap = arrayToMap(conversations)

      const nextState = setPlatformLogs(state, platformLogs)
        .setIn(paths.userConversationCount(orgId, eventId), count)
        .setIn(paths.userConversationPage(orgId, eventId), page)

      const path = paths.userConversations(orgId, eventId)

      return state.getIn(path)
        ? nextState.mergeIn(path, eventUserConversationsMap)
        : nextState.setIn(path, eventUserConversationsMap)
    },
  },
  Map(),
)
