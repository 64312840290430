import { Components, Theme } from '@mui/material'
import { ComponentOptions } from './types'

export const MuiSwitch = ({ palette }: ComponentOptions): Components<Theme>['MuiSwitch'] => {
  return {
    defaultProps: {
      color: 'primary',
    },
    styleOverrides: {
      track: {
        backgroundColor: palette.text?.primary,
      },
    },
  }
}
