import React, { useState, useEffect } from 'react'
import { Snackbar, Alert } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useToaster } from '~hooks/useToaster'

const transitionDuration = 200

const ToastAlert = () => {
  const classes = useStyles()
  const [open, setOpen] = useState<boolean>()
  const { clear, current, hash } = useToaster()

  useEffect(() => {
    if (current) setOpen(true)
  }, [current, hash])

  const handleClose = () => {
    setTimeout(() => clear(current), transitionDuration)
    setOpen(false)
  }

  return (
    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} transitionDuration={transitionDuration}>
      <Alert
        onClose={handleClose}
        severity={current?.type || 'info'}
        elevation={4}
        variant='filled'
        className={classes.alert}
      >
        {current?.message || ''}
      </Alert>
    </Snackbar>
  )
}

const useStyles = makeStyles(() => ({
  alert: {
    minWidth: '280px',
  },
}))

export default ToastAlert
