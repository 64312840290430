import { actions } from './context/actionCreators'

export const activityStarted = action => {
  const actionString = getActionString(action)
  if (actionString) {
    return actions.activityStarted(actionString)
  }

  console.error('Bad attempt to start an activity ', action)
}

export const activityEnded = action => {
  const actionString = getActionString(action)
  if (actionString) {
    return actions.activityEnded(actionString)
  }

  console.error('Bad attempt to end an activity ', action)
}

const getActionString = action => {
  if (typeof action === 'string') {
    return action
  }

  if (typeof action === 'object' && action.complete) {
    return action.complete.toString()
  }

  return undefined
}

export const simpleAction = payload => payload

export function basicActions() {
  return {
    start: () => null,
    complete: payload => payload,
    fail: err => err,
  }
}

export function basicActionsWithMeta() {
  return {
    start: () => null,
    complete: [data => data.payload, data => data.meta],
    fail: err => err,
  }
}

export function dispatchBasicActions(action, funcMap, prepAction) {
  return async (dispatch, getState) => {
    let completeFunc
    let payloadFunc
    let failFunc
    let failPayloadFunc
    let getActivityFunc

    if (typeof prepAction === 'function') {
      prepAction(dispatch, getState)
    }

    if (typeof funcMap === 'function') {
      payloadFunc = funcMap
    } else {
      completeFunc = funcMap.complete
      payloadFunc = funcMap.payload
      failFunc = funcMap.fail
      failPayloadFunc = funcMap.failPayload
      getActivityFunc = funcMap.getActivity
    }

    const activity = getActivityFunc ? getActivityFunc() : action

    try {
      dispatch(action.start())
      // 1. notify activity started
      dispatch(activityStarted(activity))
      // 2. process activity
      const payload = await payloadFunc(dispatch, getState)
      dispatch(action.complete(payload))
      // 3a. (optional) post activity completed (e.g. navigation, subsequent actions)
      if (completeFunc) {
        completeFunc(dispatch, getState, payload)
      }
      return payload
    } catch (err) {
      // 3b (optional) process any failures
      dispatch(action.fail(err))
      if (failFunc) {
        failFunc(dispatch, err)
      }
      if (failPayloadFunc) {
        // 3c (recommended for get requests) reduce default data so we do not get an infinite select loop
        dispatch(action.complete(failPayloadFunc()))
      }
    } finally {
      // 4. notify activity ended.
      dispatch(activityEnded(activity))
    }
  }
}

export const dispatchCheckedAction = (action, payloadFunc, errorObject, activityOverride) => {
  return async (dispatch, getState) => {
    const activity = activityOverride ? activityOverride : action

    try {
      dispatch(activityStarted(activity))
      const payload = await payloadFunc(dispatch, getState)
      dispatch(action.complete(payload))
    } catch (err) {
      dispatch(action.complete(errorObject))
    } finally {
      dispatch(activityEnded(activity))
    }
  }
}

export const dispatchPublishingAction = dispatchBasicActions

export const dispatchFetchingAction =
  (action, { payload, failPayload, onError }) =>
  async (dispatch, getState) => {
    try {
      const results = await payload(dispatch, getState)
      dispatch(action(results))
    } catch (err) {
      if (onError) {
        onError(dispatch, err)
      }
      dispatch(action(failPayload()))
    }
  }

export const safeAsyncPayload = ({ payload, failPayload }) => {
  return async (dispatch, getState) => {
    try {
      await payload(dispatch, getState)
    } catch (err) {
      if (failPayload) {
        failPayload(dispatch, getState)
      }
    }
  }
}

export function basicState() {
  return {
    error: null,
    isFetching: false,
    isLoaded: false,
  }
}

export function basicCompleteReducer(state) {
  return {
    ...state,
    error: null,
    isFetching: false,
  }
}

export function basicLoadCompleteReducer(state, action) {
  return {
    ...basicCompleteReducer(state, action),
    isLoaded: true,
  }
}

export function basicFailReducer(state, action) {
  return {
    ...state,
    error: action.payload,
    isFetching: false,
  }
}

export function basicStartReducer(state) {
  return {
    ...state,
    error: null,
    isFetching: true,
  }
}

export function filterActions() {
  return {
    search: payload => payload,
    clear: () => null,
  }
}

export function filterClearReducer(state) {
  return {
    ...state,
    filterText: '',
    filter: filterState(),
  }
}

export const getFilterFunction = (payload, selectors) => {
  const regex = new RegExp(payload, 'i')
  const filterFunc = entity => {
    if (entity.custom) {
      Object.keys(entity.custom).forEach(key => {
        const customSelector = entity => {
          // need to account for not all records having custom fields
          return entity.custom && entity.custom[key] ? entity.custom[key] : ''
        }
        // only add a selector if it will return different data than a selector in the array.
        // (prevent adding dupes)
        const customValue = customSelector(entity)
        if (!selectors.some(s => s(entity) === customValue)) {
          selectors.push(customSelector)
        }
      })
    }
    return selectors.some(s => regex.test(s(entity)))
  }
  return filterFunc
}

export function filterSearchReducer(...selectors) {
  return (state, { payload }) => {
    return {
      ...state,
      filterText: payload,
      filter: getFilterFunction(payload, selectors),
    }
  }
}

export function filterState() {
  return () => true
}

export function modalActions() {
  return {
    show: payload => payload,
    hide: () => null,
  }
}

export function modalHideReducer(state) {
  return {
    ...state,
    modal: {
      data: undefined,
      visible: false,
    },
  }
}

export function modalShowReducer(state, action) {
  return {
    ...state,
    error: undefined,
    modal: {
      data: action.payload,
      visible: true,
    },
  }
}

export function modalState() {
  return {
    data: undefined,
    visible: false,
  }
}

export function uploadState() {
  return {
    visible: false,
  }
}

export function gridSortActions() {
  return {
    sort: payload => payload,
  }
}

export function gridPinReducer(state, payload) {
  let pinnedColumns = state.pinnedColumns || []
  const index = pinnedColumns.indexOf(payload.column)
  if (index !== -1) {
    pinnedColumns.splice(index, 1)
  } else {
    pinnedColumns.push(payload.column)
  }

  return {
    ...state,
    pinnedColumns,
  }
}

export function gridSortState() {
  return {
    column: '',
    direction: '',
  }
}
export function uploadProgressActions(state, payload) {
  return {
    ...state,
    percentComplete: payload,
  }
}

export function uploadProgressState() {
  return {
    percentComplete: 0,
  }
}

export const getIn = (jsObject, path, notSetValue = undefined) => {
  let current = jsObject
  try {
    path.forEach(prop => {
      current = current[prop]
    })
  } catch (err) {
    return notSetValue
  }
  return current
}
