import fetch from 'isomorphic-fetch'
import { sciensio as config } from '../../config'
import { getPayload } from './getPayload'
import { fetchOnce } from './fetchOnce'
import { processResponse } from './processResponse'
import { combineUrl } from '../../../core/util/string/combineUrl'
import { interceptor } from './interceptors'

export const get = async (url, sharedPromise, unAuthHandler) => {
  return fetchOnce(
    url,
    'get',
    () => {
      const payload = getPayload('GET')

      const promise = new Promise((resolve, reject) => {
        return fetch(combineUrl(config.apiUrl, 'api', url), payload)
          .then(response => {
            interceptor.intercept(response)
            processResponse({ resolve, reject, response, url, method: 'get', unAuthHandler })
          })
          .catch(() => {
            reject({
              status: -1,
              message: 'Could not access api',
            })
          })
      })

      return promise
    },
    sharedPromise,
  )
}
