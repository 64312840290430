import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@mui/styles'
import { Flex } from 'mui-blox'
import ClassNames from 'classnames'

const Bubble = ({ children, className, tick, ...rest }) => {
  const classes = useStyles()
  return (
    <Flex
      flexDirection='column'
      className={ClassNames(className, classes.root, {
        [classes.bubbleTick]: tick,
      })}
      {...rest}
    >
      {children}
    </Flex>
  )
}

Bubble.propTypes = {
  tick: PropTypes.bool,
}

Bubble.defaultProps = {
  tick: false,
}

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    maxWidth: '100%',
    borderRadius: 6,
    position: 'relative',
    padding: spacing(1, 1.5),
    '& button': {
      padding: spacing(0.5, 1),
      margin: spacing(0.5, 0),
      marginBottom: spacing(0.5),
      minWidth: 50,
    },
    '& p': {
      wordBreak: 'break-word',
    },
  },
  bubbleTick: {
    marginBottom: 9,
    '&:after': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      width: 0,
      height: 0,
      border: '9px solid transparent',
      borderBottom: 0,
      marginBottom: -9,
    },
  },
}))

export default Bubble
