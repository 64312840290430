import { alpha } from '@mui/material/styles'
import { yellow, amber } from '@mui/material/colors'
import grey from '../colors/grey'

const createDatasetColors = colors =>
  colors.map(c => ({
    backgroundColor: alpha(c, 0.6),
    borderColor: c,
  }))

export default {
  grey,
  background: {
    default: '#F1F1F1',
    paper: '#fff',
  },
  brandColor: '#8BC53F',
  primary: {
    lighter: '#C8F094',
    light: '#A0CF61',
    main: '#8BC53F',
    dark: '#74A334',
    contrastText: '#fff',
  },
  secondary: {
    lighter: grey[300],
    light: grey[500],
    main: '#4C4C4C',
    dark: '#2E2C2D',
    contrastText: '#EAEAEA',
  },
  tertiary: {
    lighter: '#88CED8',
    light: '#33A5B5',
    main: '#028EA3',
    dark: '#007184',
    contrastText: '#fff',
  },
  error: {
    lighter: '#FFD2D2',
    light: '#e57373',
    main: '#EC302F',
    dark: '#d32f2f',
    contrastText: '#fff',
  },
  warning: {
    lighter: '#FFD5B3',
    light: '#FFA051',
    main: '#ED6C03',
    dark: '#A54B00',
  },
  info: {
    lighter: '#85C1E2',
    light: '#289FDF',
    main: '#0388D1',
    dark: '#025581',
  },
  text: {
    primary: 'rgba(0, 0, 0, 0.87)',
    secondary: 'rgba(0, 0, 0, 0.54)',
    disabled: 'rgba(0, 0, 0, 0.38)',
    hint: 'rgba(0, 0, 0, 0.38)',
  },
  divider: grey[100],
  chat: {
    user: {
      main: '#41c6ff',
      contrastText: '#fff',
    },
    bot: {
      main: grey[100],
      contrastText: 'rgba(0, 0, 0, 0.87)',
    },
    support: {
      main: grey[500],
      contrastText: 'rgba(255, 255, 255, 0.95)',
    },
  },
  common: {
    transparent: 'rgba(0, 0, 0, 0)',
    yellow: yellow[500],
  },
  charts: {
    datasets: createDatasetColors(['#4D4D4D', '#A0CF61', '#A2C7C7', '#006666', '#458F8F', '#74A334']),
  },
  highlights: ['#EF9EA3', '#F3ECDC', '#D6E39E', '#89D2F3', '#FACE91', '#FFF59C', '#F5B285', '#94B1DB', '#C999C1'],
  status: {
    incomplete: '#DA423A',
    warn: '#FACE91',
    provisioning: '#4183C4',
    upcoming: '#4183C4',
    current: '#43a047',
    warning: amber[400],
    past: grey[300],
    decommissioned: grey[300],
  },
}
