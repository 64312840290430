import deepmerge from 'deepmerge'
import basic from './light'
import dark from './dark'
import { getContrastRatio } from '@mui/material/styles'
import { customer } from './customer'
import { osh } from './osh'
import { PaletteOptions } from '@mui/material'

interface GetPaletteReturn {
  palette: PaletteOptions
  hue: 'light' | 'dark'
}

export const getPalette = (type: string, customPalette: Partial<PaletteOptions> = {}): GetPaletteReturn => {
  const styles = { osh, basic, dark, customer }

  const background = customPalette.background?.default || styles[type].background?.default || '#fff'
  const hue = getContrastRatio(background, '#fff') > 3 ? 'dark' : 'light'

  const basePalette = hue === 'light' ? basic : dark

  const mergedPalette = deepmerge(basePalette, customPalette)

  return { palette: mergedPalette as PaletteOptions, hue }
}
