import { useContext, createContext } from 'react'

export const MessageActionContext = createContext()

export const useMessageAction = () => {
  const context = useContext(MessageActionContext)

  const onPostBack = post => {
    context && context.postBack(post)
  }
  const onLink = url => {
    context && context.linkTo(url)
  }

  return {
    onPostBack,
    onLink,
  }
}
