export const osh = {
  redirect: {
    YES: true,
    NO: false,
  },
  searchTypes: {
    providers: {
      providerName: 'providerName',
      center: 'center',
      market: 'market',
      payer: 'payer',
    },
    hospitals: {
      hospitalName: 'hospitalName',
      market: 'market',
      center: 'center',
    },
    clinics: {
      county: 'county',
      center: 'center',
      payer: 'payer',
    },
  },
  showByTypes: {
    providers: {
      provider: 'provider',
      payer: 'payer',
    },
  },
  marketExternalLinkMap: {
    Clevland: 'cleveland',
    Detroit: 'detroit',
    Chicagoland: 'greater-chicagoland',
    'Indy & Fort Wayne': 'indianapolis-and-fort-wayne',
    Joliet: 'joliet',
    'Northwest Indiana': 'northwest-indiana',
    philadelphia: 'philadelphia',
    rockford: 'rockford',
    waukegan: 'waukegan',
  },
}
