import { isValue } from '~core/validation/isValue'
import { typify } from './data/typify'

const validatePair = ([k, v]) => {
  if (!k) return false
  return typify.isArray(v) ? isValue(v.filter(isValue)) : isValue(v)
}

const formatPair = (k, v) => (!validatePair(k, v) ? '' : `${k}=${v}`)

const appendSegment = (query, [k, v], i, arr) => {
  const pair = formatPair(k, v)

  const prefix = i === 0 ? '?' : ''
  const suffix = i === arr.length - 1 ? '' : '&'

  return `${query}${prefix}${pair}${suffix}`
}

export const buildQuery = (path = '', params = {}) => {
  const url = path.replace(/(\?|\/)$/, '')

  const query = Object.entries(params)
    .filter(validatePair)
    .reduce((query, pair, i, arr) => appendSegment(query, pair, i, arr), '')

  return `${url}${query}`
}
