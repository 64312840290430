import { useCallback, useRef } from 'react'
import { MESSAGE_DIRECTIONS, MESSAGE_SOURCES, socketNamespaces, socketActions } from '~constants'
import { useSocketAction } from '../../socketv4/hooks/useSocketAction'
import { BotMessage } from '../types'

type cb = (message: BotMessage) => void

interface BotMessageActionPayload {
  messageText: string
  sent: string
}

export const useBotMessageListener = (conversationId: string, addMessage: cb): void => {
  const adder = useRef<cb>()

  adder.current = addMessage

  useSocketAction<BotMessageActionPayload>(
    { namespace: socketNamespaces.users, room: conversationId },
    socketActions.botMessage,
    useCallback(
      payload => {
        adder.current &&
          adder.current({
            message: payload.messageText,
            messageEventType: MESSAGE_SOURCES.botResponse,
            direction: MESSAGE_DIRECTIONS.outgoing,
            messageTimestamp: payload.sent,
          })
      },
      [adder],
    ),
  )
}
