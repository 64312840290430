import React from 'react'
import { Suspense, lazyRetry } from '../../../routing'

const MenuRouter = lazyRetry(() =>
  import(
    /* webpackChunkName: "exconMenu" */
    './Router'
  ),
)

const Root = props => (
  <Suspense>
    <MenuRouter {...props} />
  </Suspense>
)

export default Root
