import { useLocation } from 'react-router'
import { useRecoilState } from 'recoil'
import { contentWidth } from '../state/contentWidth'

export const useContentWidth = (defaultValue = 600) => {
  const { pathname } = useLocation()
  const [state, setState] = useRecoilState(contentWidth)

  return {
    width: state[pathname] || defaultValue,
    setWidth: value => setState(current => ({ ...current, [pathname]: value })),
  }
}
