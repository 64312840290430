import React, { FC } from 'react'
import { Suspense, lazyRetry } from '../../../routing'

const Routes = lazyRetry(
  () =>
    import(
      /* webpackChunkName: "notifications" */
      './Routes'
    ),
)

const Root: FC = props => (
  <Suspense>
    <Routes {...props} />
  </Suspense>
)

export default Root
