import { useMemo, useCallback } from 'react'
import { useMediaQuery } from '@mui/material'

/**
 * Material-UI 4.8.3
 * Without `noSsr` option, useMediaQuery match will initially return false regardless of window size
 */

export const BREAKPOINTS = {
  xs: 'xs',
  sm: 'sm',
  md: 'md',
  lg: 'lg',
  xl: 'xl',
}

const forwardKeys = ['xs', 'sm', 'md', 'lg', 'xl']

export const useBreakpoint = bp => {
  const up = useMediaQuery(theme => theme.breakpoints.up(bp), { noSsr: true })
  const down = useMediaQuery(theme => theme.breakpoints.down(bp), { noSsr: true })

  return { up, down }
}

export const useBreakpoints = () => {
  const isXs = useMediaQuery(theme => theme.breakpoints.down('sm'), { noSsr: true })
  const isSm = useMediaQuery(theme => theme.breakpoints.down('md'), { noSsr: true })
  const isMd = useMediaQuery(theme => theme.breakpoints.down('lg'), { noSsr: true })
  const isLg = useMediaQuery(theme => theme.breakpoints.down('xl'), { noSsr: true })
  const isXl = useMediaQuery(theme => theme.breakpoints.up('xl'), { noSsr: true })

  const r = useMemo(
    () => new Map().set('xs', isXs).set('sm', isSm).set('md', isMd).set('lg', isLg).set('xl', isXl),
    [isXs, isSm, isMd, isLg, isXl],
  )

  let current = undefined
  if (!current && r.get('xs')) current = 'xs'
  if (!current && r.get('sm')) current = 'sm'
  if (!current && r.get('md')) current = 'md'
  if (!current && r.get('lg')) current = 'lg'
  if (!current && r.get('xl')) current = 'xl'

  const isUp = useCallback(
    bp => {
      const keyIndex = forwardKeys.findIndex(k => k === bp)

      for (let [i, key] of forwardKeys.entries()) {
        if (i < keyIndex) {
          if (r.get(key)) return false
          else continue
        }

        if (i >= keyIndex) {
          if (r.get(key)) return true
        }

        return false
      }
    },
    [r],
  )

  const isDown = useCallback(
    bp => {
      const keyIndex = forwardKeys.findIndex(k => k === bp) /*?*/

      for (let [i, key] of forwardKeys.entries()) {
        if (i < keyIndex) {
          continue
        }

        if (i >= keyIndex) {
          if (!r.get(key)) return false
          else return true
        }

        return false
      }

      return false
    },
    [r],
  )

  return {
    current,
    isUp,
    isDown,
  }
}
