import {
  alpha,
  darken,
  buttonClasses,
  Color,
  ComponentsProps,
  ComponentsOverrides,
  SimplePaletteColorOptions,
  Theme,
} from '@mui/material'
import { OverridesStyleRules } from '@mui/material/styles/overrides'
import { ComponentOptions } from './types'

interface ButtonStyles {
  defaultProps: ComponentsProps['MuiButton']
  styleOverrides: {
    outlinedError?: OverridesStyleRules
  } & ComponentsOverrides<Theme>['MuiButton']
}

export const MuiButton = ({ hue, palette, spacing }: ComponentOptions): ButtonStyles => {
  const primary = palette.primary as SimplePaletteColorOptions
  const secondary = palette.secondary as SimplePaletteColorOptions
  const error = palette.error as SimplePaletteColorOptions
  const grey = palette.grey as Color

  return {
    defaultProps: {
      variant: 'outlined',
      color: 'secondary',
      size: 'large',
    },
    styleOverrides: {
      root: {
        textTransform: 'none',
        fontWeight: 500,
        '&>svg': {
          marginRight: spacing(1),
        },
        '&:focus, &:active': {
          boxShadow: `0 0 0 2px ${alpha(primary.light as string, 0.35)}`,
        },
      },
      outlined: {
        border: '1px solid',
        backgroundColor: 'transparent',
        color: palette.text?.secondary,
        boxShadow: 'none',
        '&:hover': {
          backgroundColor: alpha(secondary.light as string, hue === 'light' ? 0.1 : 0.3),
          borderColor: secondary.lighter,
          boxShadow: 'none',
        },
        '&:focus, &:active': {
          outlineOffset: 3,
          outlineColor: secondary.light,
        },
        '&:active': {
          outlineColor: secondary.light,
        },
        [`&.${buttonClasses.disabled}`]: {
          backgroundColor: hue === 'light' ? grey[100] : grey[800],
          borderColor: grey[400],
          color: grey[400],
        },
      },
      outlinedSecondary: {
        borderColor: alpha(secondary.light as string, 0.6),
        color: secondary.light,
        fill: secondary.light,
        '&:focus, &:active': {
          boxShadow: `0 0 0 2px ${alpha(secondary.light as string, 0.2)}`,
        },
        '&:hover': {
          color: darken(secondary.light as string, 0.1),
          borderColor: alpha(secondary.light as string, 0.7),
        },
      },
      outlinedPrimary: {
        borderColor: primary.main,
        backgroundColor: primary.main,
        color: primary.contrastText,
        '&:hover': {
          borderColor: primary.dark,
          backgroundColor: primary.dark,
        },
        [`&.${buttonClasses.disabled}`]: {
          backgroundColor: alpha((hue === 'light' ? primary.light : primary.dark) as string, 0.6),
          borderColor: alpha((hue === 'light' ? primary.light : primary.dark) as string, 0.6),
          color: alpha(primary.contrastText as string, 0.6),
        },
      },
      outlinedError: {
        borderColor: error.main,
        color: error.main,
        '&:hover': {
          backgroundColor: alpha(error.light as string, 0.34),
        },
        '&:focus, &:active': {
          boxShadow: `0 0 0 2px ${alpha(error.light as string, 0.35)}`,
        },
      },
      sizeLarge: {
        padding: spacing(0.875, 1.75),
        fontSize: '1rem',
      },
      sizeMedium: {
        padding: spacing(0.5, 1.5),
        fontSize: '1rem',
      },
      sizeSmall: {
        padding: spacing(0.375, 1.25),
        fontSize: '0.85rem',
        '&>svg': {
          marginRight: spacing(0.5),
          height: '16px',
          width: '16px',
        },
      },
    },
  }
}
