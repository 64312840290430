import { handleActions, combineActions } from 'redux-actions'
import { Map } from 'immutable'
import { actions } from './actionCreators'
import paths from './paths'

import { arrayToMap, basicCompleteReducer, basicFailReducer, basicStartReducer } from '../redux-helpers-immutable'

export default handleActions(
  {
    [combineActions(actions.loadNotifications.start)]: basicStartReducer,

    [combineActions(actions.loadNotifications.fail)]: basicFailReducer,

    [actions.loadNotifications.complete]: (state, { payload: { notifications, orgId, eventId } }) =>
      basicCompleteReducer(state).setIn(paths.notifications(orgId, eventId), arrayToMap(notifications)),
  },
  Map(),
)
