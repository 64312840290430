import { combineUrl } from '../../core/util/string/combineUrl'

function RestError(errors) {
  this.errors = errors
}

RestError.prototype = Object.create(Error.prototype)
RestError.prototype.constructor = RestError

const RestClient = function (baseUrl) {
  this.baseUrl = baseUrl
  this.token = null
}

RestClient.prototype.headers = function () {
  const self = this
  return {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${self.token}`,
  }
}

RestClient.prototype.delete = async function (url) {
  return this.request(url, 'DELETE')
}

RestClient.prototype.get = async function (url) {
  return this.request(url, 'GET')
}

RestClient.prototype.patch = async function (url, body) {
  return this.request(url, 'PATCH', body)
}

RestClient.prototype.post = async function (url, body) {
  return this.request(url, 'POST', body)
}

RestClient.prototype.request = async function (url, method, body) {
  const self = this
  const options = {
    method: method,
    headers: self.headers(),
  }

  if (body != null) {
    options.body = JSON.stringify(body)
  }
  const response = await fetch(combineUrl(self.baseUrl, url), options)

  if (response.status === 204) {
    return
  }

  if (response.status === 401) {
    const actions = require('../redux/auth/actionCreators').actions
    const dispatch = require('../redux').getDispatch()

    dispatch(actions.logout())
    window._logout()
  }

  if (response.status === 403) {
    console.log('forbidden')
    return
  }

  const json = await response.json()

  if (!response.ok) {
    const errors = json.errors || [json.message || json.name]
    throw new RestError(errors)
  }

  return json
}

export default RestClient
