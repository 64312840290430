import React, { useCallback, useState } from 'react'
import { Modal } from '@mui/material'
import { animated, useSpring } from 'react-spring'
import LogoLarge from '../../_layout/logo/LogoLarge'
import { Logo42ChatContext } from './hooks/use42ChatLogo'
import { useDebounce } from '~hooks/useDebounce'
import { useWidth } from '../../_layout/hooks/useWidth'
import { useLocation } from 'react-router'

const Logo42Chat = ({ children }) => {
  const { pathname } = useLocation()
  const [modalOpen, setModalOpen] = useState(!pathname.includes('messenger'))
  const [visible, setVisible] = useState(true)

  const open = useDebounce(visible, null, 400)

  const handleSetVisible = useCallback(
    value => {
      setVisible(value)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [open, setVisible],
  )

  return (
    <Logo42ChatContext.Provider value={{ showLogo: handleSetVisible }}>
      {children}
      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        closeAfterTransition
        BackdropProps={{ timeout: 400 }}
        aria-describedby='42Chat-loading-logo'
      >
        <Fade in={open} onExited={() => setModalOpen(false)}>
          <LogoLarge />
        </Fade>
      </Modal>
    </Logo42ChatContext.Provider>
  )
}

const Fade = React.forwardRef(function Fade({ in: open, children, onEnter, onExited, ...other }, ref) {
  const { width } = useWidth()

  const props =
    width === 'xs'
      ? {
          top: '50%',
          left: '50%',
          transform: open ? 'scale(1)' : 'scale(3)',
          marginTop: -24,
          marginLeft: -55,
          opacity: open ? 1 : 0,
        }
      : {
          // top: open ? '50%' : '0%',
          // left: open ? '50%' : '0%',
          // marginTop: open ? -24 : 0,
          // marginLeft: open ? -92 / 2 : 0,
          top: '50%',
          left: '50%',
          marginTop: -24,
          marginLeft: -92 / 2,
          transform: open ? 'scale(1)' : 'scale(3)',
          opacity: open ? 1 : 0,
        }

  const style = useSpring({
    config: {
      mass: 1,
      tension: 300,
      friction: 35,
    },
    ...props,
    position: 'absolute',
    outline: 'none',
    onRest: () => {
      if (!open && onExited) {
        onExited()
      }
    },
  })

  return (
    <animated.div ref={ref} style={style} {...other}>
      {children}
    </animated.div>
  )
})

export default Logo42Chat
