import styled, { css } from 'styled-components'

export const CloseButton = styled.div`
  height: 0;
  width: 0;
  margin: 0;
  border-radius: 50%;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: all 0.3s;
  cursor: pointer;
`

export const MainContainer = styled.div<{ isLeft?: boolean }>`
  border-radius: 5px;
  background-color: #ffff;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
  padding-top: 0;
  position: relative;
  width: auto;
  word-wrap: break-word;
  min-width: 25ch;
  max-width: 350px;
  &:hover ${CloseButton} {
    opacity: 1;
    height: 24px;
    width: 24px;
    margin-top: -16px;
    ${({ isLeft }) =>
      isLeft
        ? css`
            margin-left: -16px;
          `
        : css`
            margin-right: -16px;
          `}
  }
`

export const Avatar = styled.img<{ size: number; color: string }>`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  border-radius: 50%;
  background-color: ${({ color }) => color};
  object-fit: fill;
  border: solid 5px ${({ color }) => color};
`

export const TopContainer = styled.div<{ startColor: string; stopColor: string }>`
  display: flex;
  flex-direction: column;
  background: linear-gradient(${({ startColor }) => startColor}, ${({ stopColor }) => stopColor});
  border-radius: inherit;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  flex: 1;
  padding: 28px 28px 0 28px;
`

export const ActionButtonContainer = styled.button<{ color: string }>`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: ${({ color }) => color};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: none;
`
