import { useCallback, useMemo, useState } from 'react'
import { getTokenFromSearch } from './getTokenFromSearch'
import { getTokenFromStorage } from './getTokenFromStorage'

export const useAuthToken = (queryString, locationKey = 'auth') => {
  const getTokenFromSources = () => {
    if (queryString) {
      const token = getTokenFromSearch(queryString)
      if (token) return token
    }
    return getTokenFromStorage(locationKey)
  }

  const [authToken, setAuthToken] = useState(() => getTokenFromSources())

  const saveToken = useCallback(
    token => {
      setAuthToken(token)
      try {
        localStorage[locationKey] = JSON.stringify({ token })
      } catch (err) {
        console.error('Could not set token in localstorage', token)
      }
    },
    [setAuthToken, locationKey],
  )

  useMemo(() => {
    const token = getTokenFromSearch(queryString)
    if (token) saveToken(token)
  }, [queryString, saveToken])

  return [authToken, saveToken]
}
