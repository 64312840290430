import React from 'react'
import PropTypes from 'prop-types'
import { Flex } from 'mui-blox'
import { Conditional, useMeasureCell } from '~controls'
import { messageBubbleTypes } from '../../../constants'
import { Button, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useMessageAction } from '../messenger/hooks/useMessageAction'

const RenderBubbleContent = ({ bubble }) => {
  const classes = useStyles()
  const { onPostBack, onLink } = useMessageAction()
  const measure = useMeasureCell()

  const renderButtons = () => {
    if (bubble.buttons) {
      return (
        <Flex flexWrap='wrap' className={classes.postBacks}>
          {bubble.buttons.map(button => (
            <Button color='primary' variant='contained' key={button} onClick={() => onPostBack(button)}>
              {button}
            </Button>
          ))}
        </Flex>
      )
    }

    return null
  }

  return (
    <Conditional
      conditions={{
        empty: bubble.type === messageBubbleTypes.text && !bubble.text,
        text: bubble.type === messageBubbleTypes.text,
        link: bubble.type === messageBubbleTypes.link,
        postBacks: bubble.type === messageBubbleTypes.postBack,
        image: bubble.type === messageBubbleTypes.image,
      }}
    >
      {{
        default: <Flex>defff</Flex>,
        empty: <Flex height={4} />,
        text: <Typography color='inherit'>{bubble.text}</Typography>,
        link: (
          <Button className={classes.button} color='primary' variant='contained' onClick={() => onLink(bubble.url)}>
            {bubble.text}
          </Button>
        ),
        postBacks: renderButtons(),
        image: (
          <img src={bubble.image} className={classes.image} onLoad={measure} title='bubble-image' alt='bot-image' />
        ),
      }}
    </Conditional>
  )
}

RenderBubbleContent.propTypes = {
  bubble: PropTypes.object.isRequired,
}

const useStyles = makeStyles(({ spacing }) => ({
  button: {
    alignSelf: 'flex-start',
    // color: palette.primary.contrastText,
  },
  postBacks: {
    '& > :not(:last-child)': {
      marginRight: spacing(0.5),
    },
  },
  image: {
    height: 'auto',
    width: `calc(100% - ${spacing(2)})`,
    margin: spacing(1),
    borderRadius: spacing(1),
  },
}))

export default RenderBubbleContent
