import React from 'react'
import { CircularProgress } from '@mui/material'
import { StyleRulesCallback, withStyles } from '@mui/styles'
import ClassNames from 'classnames'
import { Flex } from 'mui-blox'

interface ActivityContainerProps {
  children: React.ReactNode
  isActive: boolean
  hideChildren?: boolean
  classes?: any
  className?: string
  progressClassName?: string
  fullWidth?: boolean
}

const ActivityContainer = ({
  children,
  isActive,
  hideChildren,
  classes,
  className = undefined,
  progressClassName,
  fullWidth,
}: ActivityContainerProps) => {
  return (
    <div className={ClassNames(classes.container, className, { [classes.fullWidth]: fullWidth })}>
      <div
        className={ClassNames(classes.mask, {
          [classes.isMasked]: isActive,
          [classes.hideChildren]: isActive && hideChildren,
          [classes.fullWidth]: fullWidth,
        })}
      >
        {children}
      </div>
      {isActive && (
        <Flex justifyContent='center' alignItems='center' className={classes.cover}>
          <CircularProgress size={20} thickness={6} className={progressClassName} />
        </Flex>
      )}
    </div>
  )
}

const styles: StyleRulesCallback<string, any, string> = () => {
  const opacityTransition = 'opacity 0.2s ease'

  return {
    container: {
      position: 'relative',
      width: 'fit-content',
      '& svg': {
        marginRight: 0,
      },
    },
    mask: {
      transition: opacityTransition,
    },
    isMasked: {
      opacity: 0.2,
      transition: opacityTransition,
    },
    hideChildren: {
      opacity: 0,
    },
    cover: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      background: 'transparent',
    },
    fullWidth: {
      flex: 1,
      width: '100%',
    },
  }
}

export default withStyles(styles)(ActivityContainer)
