import React, { useState, useEffect, forwardRef } from 'react'
import PropTypes from 'prop-types'
import AutoSizeList, { AutoSizeListProps } from './AutoSizeList'

export type BottomPullListProps = AutoSizeListProps

const BottomPullList = forwardRef<HTMLDivElement, BottomPullListProps>(({ rowCount, ...rest }) => {
  const [_scrollToIndex, setScrollToIndex] = useState(-1)

  useEffect(() => {
    setScrollToIndex(rowCount - 1)
  }, [rowCount])

  return <AutoSizeList rowCount={rowCount} scrollToIndex={_scrollToIndex} {...rest} />
})

BottomPullList.propTypes = {
  rowCount: PropTypes.number.isRequired,
  onRenderRow: PropTypes.func.isRequired,
  onSelected: PropTypes.func,
}

BottomPullList.displayName = 'BottomPullList'

export default BottomPullList
