import {
  Components,
  Theme,
  alpha,
  outlinedInputClasses,
  inputAdornmentClasses,
  inputBaseClasses,
  avatarClasses,
  selectClasses,
} from '@mui/material'
import { ComponentOptions } from './types'

export const MuiOutlinedInput = ({ spacing, palette }: ComponentOptions): Components<Theme>['MuiOutlinedInput'] => {
  return {
    defaultProps: {},
    styleOverrides: {
      root: {
        borderRadius: 4,
        padding: 0,
        [`&.${outlinedInputClasses.disabled} .${outlinedInputClasses.notchedOutline}`]: {
          borderColor: palette.text?.disabled,
        },
        [`& .${inputAdornmentClasses.sizeSmall} .${avatarClasses.root}`]: {
          height: spacing(3.75),
          width: spacing(3.75),
        },
        [`&.${inputBaseClasses.sizeSmall} .${selectClasses.icon}`]: {
          margin: 3,
          width: 22,
          height: 22,
        },
      },
      input: {
        height: 20,
        padding: spacing(1.5, 1.25),
      },
      notchedOutline: {
        borderColor: alpha(palette.text?.primary as string, 0.23),
        '&:hover': {
          borderColor: palette.text?.primary,
        },
      },
      inputSizeSmall: {
        padding: spacing('9px', 1.25),
      },
    },
  }
}
