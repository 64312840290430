export default {
  selected: (eventId, table) => ['event', String(eventId), table, 'selected'],
  selectedColumn: (eventId, table) => ['event', String(eventId), table, 'selected', 'column'],
  selectedOperator: (eventId, table) => ['event', String(eventId), table, 'selected', 'operator'],
  selectedValue: (eventId, table) => ['event', String(eventId), table, 'selected', 'value'],
  filterByKey: (eventId, table, key) => ['event', String(eventId), table, 'filters', key],
  filters: (eventId, table) => ['event', String(eventId), table, 'filters'],
  columnValue: () => ['column', 'value'],
  columnCustomValue: () => ['column', 'filterField'],
  operatorValue: () => ['operator', 'value'],
}
