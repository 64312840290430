import React, { FC, useState } from 'react'
import { useEffect } from 'react'
import { Flex } from 'mui-blox'
import { Typography, Button } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { interceptor } from '../../api/rootFetch/interceptors'
import Robot from '../../images/Robot.png'
import Logo from '../../images/42ChatNewLogo.png'

interface AuthScopeErrorHandlerProps {
  children: React.ReactNode
}

export const AuthScopeErrorHandler: FC = ({ children }: AuthScopeErrorHandlerProps) => {
  const [error, setError] = useState(false)
  const classes = useStyles()

  useEffect(() => {
    interceptor.setInterceptor((error: any) => {
      if (error.status === 403) {
        setError(true)
      }
    })
  })

  const goBack = () => {
    window.location.href = '/'
  }

  return (
    <>
      {error ? (
        <Flex flexColumn p={2} alignItems='center'>
          <Flex flex={0.1} />
          <Flex flexColumn maxWidth={600} flex={0.9} className={classes.container}>
            <div className={classes.imagesContainer}>
              <img src={Logo} className={classes.logo} />
              <img src={Robot} className={classes.image} />
            </div>
            <Typography variant='h3' align='center'>
              {"You Shouldn't Be Here! you Must Be Lost"}
            </Typography>
            <Typography variant='body1' align='center'>
              {
                'Let us help you find your way back to where you were. If you think this is a mistake, please contact us at support@42chat.com'
              }
            </Typography>
            <Flex className={classes.buttonContainer} flexWrap='wrap' marginTop={2}>
              <Button onClick={goBack}>Go Back to Home Page</Button>
            </Flex>
          </Flex>
        </Flex>
      ) : (
        children
      )}
    </>
  )
}

const useStyles = makeStyles(({ spacing, breakpoints }) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: spacing(2),
  },
  image: {
    maxHeight: 200,
  },
  logo: {
    maxHeight: 100,
  },
  imagesContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: spacing(2),
  },
  buttonContainer: {
    justifyContent: 'space-between',
    '& button': {
      marginTop: spacing(0.5),
    },
    [breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
}))
