import React from 'react'
import PropTypes from 'prop-types'
import { ExconSettingsContext } from './useExconSettingsContext'
import { useExconSettings } from './useExconSettings'
import { CircularProgress } from '@mui/material'

const ExconSettingsProvider = ({ children, exconId, settings, Fallback }) => {
  const { settings: resolvedSettings, isFetching } = useExconSettings(settings ? '42' : exconId, settings)

  return isFetching ? (
    <Fallback />
  ) : (
    <ExconSettingsContext.Provider value={resolvedSettings}>{children}</ExconSettingsContext.Provider>
  )
}

ExconSettingsProvider.propTypes = {
  exconId: PropTypes.string,
  settings: PropTypes.object,
  Fallback: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
}

ExconSettingsProvider.defaultProps = {
  exconId: undefined,
  settings: undefined,
  Fallback: CircularProgress,
}

export default ExconSettingsProvider
