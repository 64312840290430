import { ValueOf } from '~models/util'

export const STATES = {
  ak: 'ak',
  al: 'al',
  ar: 'ar',
  az: 'az',
  ca: 'ca',
  co: 'co',
  ct: 'ct',
  de: 'de',
  fl: 'fl',
  ga: 'ga',
  hi: 'hi',
  ia: 'ia',
  id: 'id',
  il: 'il',
  in: 'in',
  ks: 'ks',
  ky: 'ky',
  la: 'la',
  ma: 'ma',
  md: 'md',
  me: 'me',
  mi: 'mi',
  mn: 'mn',
  mo: 'mo',
  ms: 'ms',
  mt: 'mt',
  nc: 'nc',
  nd: 'nd',
  ne: 'ne',
  nh: 'nh',
  nj: 'nj',
  nm: 'nm',
  nv: 'nv',
  ny: 'ny',
  oh: 'oh',
  ok: 'ok',
  or: 'or',
  pa: 'pa',
  ri: 'ri',
  sc: 'sc',
  sd: 'sd',
  tn: 'tn',
  tx: 'tx',
  ut: 'ut',
  va: 'va',
  vt: 'vt',
  wa: 'wa',
  wi: 'wi',
  wv: 'wv',
  wy: 'wy',
} as const

type StateNamesType = Record<ValueOf<typeof STATES>, string>

export const STATE_NAMES_SHORT: StateNamesType = Object.values(STATES).reduce((acc, value) => {
  acc[value] = value.toUpperCase()

  return acc
}, {} as StateNamesType)

export const STATE_NAMES_FULL: StateNamesType = {
  al: 'Alabama',
  ak: 'Alaska',
  az: 'Arizona',
  ar: 'Arkansas',
  ca: 'California',
  co: 'Colorado',
  ct: 'Connecticut',
  de: 'Delaware',
  fl: 'Florida',
  ga: 'Georgia',
  hi: 'Hawaii',
  id: 'Idaho',
  il: 'Illinois',
  in: 'Indiana',
  ia: 'Iowa',
  ks: 'Kansas',
  ky: 'Kentucky',
  la: 'Louisiana',
  me: 'Maine',
  md: 'Maryland',
  ma: 'Massachusetts',
  mi: 'Michigan',
  mn: 'Minnesota',
  ms: 'Mississippi',
  mo: 'Missouri',
  mt: 'Montana',
  ne: 'Nebraska',
  nv: 'Nevada',
  nh: 'New Hampshire',
  nj: 'New Jersey',
  nm: 'New Mexico',
  ny: 'New York',
  nc: 'North Carolina',
  nd: 'North Dakota',
  oh: 'Ohio',
  ok: 'Oklahoma',
  or: 'Oregon',
  pa: 'Pennsylvania',
  ri: 'Rhode Island',
  sc: 'South Carolina',
  sd: 'South Dakota',
  tn: 'Tennessee',
  tx: 'Texas',
  ut: 'Utah',
  vt: 'Vermont',
  va: 'Virginia',
  wa: 'Washington',
  wv: 'West Virginia',
  wi: 'Wisconsin',
  wy: 'Wyoming',
}
