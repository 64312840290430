const Events = function (restClient) {
  this.restClient = restClient
}

Events.prototype.deleteEvent = function (orgId, eventId) {
  return this.restClient.delete(`/orgs/${orgId}/events/${eventId}`)
}

Events.prototype.updateEvent = function (orgId, event) {
  return this.restClient.patch(`/orgs/${orgId}/events/${event.id}`, event)
}

Events.prototype.addImage = function (orgId, eventId, imageData) {
  return this.restClient.post(`/orgs/${orgId}/events/${eventId}/addImage`, {
    imageData,
  })
}

Events.prototype.removeImage = function (orgId, eventId, imageData) {
  return this.restClient.post(`/orgs/${orgId}/events/${eventId}/removeImage`, {
    imageData,
  })
}

Events.prototype.getEvent = function (orgId, eventId) {
  return this.restClient.get(`/orgs/${orgId}/events/${eventId}`)
}

Events.prototype.updateEventLinks = function (orgId, eventId, links) {
  return this.restClient.patch(`/orgs/${orgId}/events/${eventId}/links`, {
    links,
  })
}

Events.prototype.getPhoneNumbers = function (orgId, eventId) {
  return this.restClient.get(`/orgs/${orgId}/events/${eventId}/phoneNumbers`)
}

Events.prototype.getMergeFields = function (orgId, eventId) {
  return this.restClient.get(`/orgs/${orgId}/events/${eventId}/mergeFields`)
}

export default Events
