import React from 'react'
import PropTypes from 'prop-types'

import { UnauthorizedHandlerContext } from '../../brix/connectors/auth/useUnauthorizedHandler'

const UnauthorizedHandlerProvider = ({ value, children }) => {
  return <UnauthorizedHandlerContext.Provider value={value}>{children}</UnauthorizedHandlerContext.Provider>
}

UnauthorizedHandlerProvider.propTypes = {
  value: PropTypes.func.isRequired,
}

export default UnauthorizedHandlerProvider
