import React from 'react'
import PropTypes from 'prop-types'
import { Flex } from 'mui-blox'
import { IconButton, Paper } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import CloseIcon from 'mdi-react/CloseIcon'

const PopupMessengerContainer = ({ children, onClose, showClose }) => {
  const classes = useStyles()

  const handleClose = () => {
    onClose && onClose()
  }

  return (
    <Flex flexColumn className={classes.messengerContainer} position='relative'>
      <Paper elevation={3} square={false} className={classes.paper}>
        {children}
      </Paper>
      {showClose && (
        <IconButton
          onClick={handleClose}
          className={classes.closeButton}
          size='small'
          aria-label='Close chat'
          tabIndex={-1}
        >
          <CloseIcon />
        </IconButton>
      )}
    </Flex>
  )
}

PopupMessengerContainer.propTypes = {
  onClose: PropTypes.func,
  showClose: PropTypes.bool.isRequired,
}
PopupMessengerContainer.defaultProps = {
  onClose: undefined,
}

const useStyles = makeStyles(({ palette, spacing, mixins: { alpha } }) => ({
  messengerContainer: {
    '& > div': {
      display: 'flex',
      flex: 1,
    },
  },
  paper: {
    backgroundColor: palette.background.default,
    borderRadius: spacing(0.5),
  },
  closeButton: {
    position: 'absolute',
    top: 6,
    right: 6,
    width: 32,
    height: 32,
    minHeight: 32,
    backgroundColor: alpha(palette.secondary.light, 0.5),
    color: palette.secondary.contrastText,
  },
}))

export default PopupMessengerContainer
