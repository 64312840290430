import { createActions } from 'redux-actions'
import { basicActions, gridSortActions, simpleAction } from '../redux-helpers'

const actionCreators = createActions({
  idks: {
    gridSort: gridSortActions(),
    scrollLeft: simpleAction,
    incrementPageIndex: simpleAction,
    load: basicActions(),
    resetPageIndex: simpleAction,
    save: basicActions(),
    filterableFields: simpleAction,
  },
})

export const actions = actionCreators.idks
export const branchName = 'idks'
