import React, { CSSProperties, ReactNode, useCallback, useRef, createContext, useContext, useEffect } from 'react'
import { ListOnItemsRenderedProps, VariableSizeList as List } from 'react-window'
import AutoSizer from 'react-virtualized-auto-sizer'

export interface AutoSizeListProps {
  rowCount: number
  onRenderRow: (index: number) => ReactNode
  onSelected?: (index: number) => void
  style?: CSSProperties
}

export interface ListProps {
  overscanRowCount?: number
  onSetListRef?: (ref: any) => void
  scrollToIndex?: number
  onItemsRendered?: (props: ListOnItemsRenderedProps) => void
}

export interface RowProps extends Pick<AutoSizeListProps, 'style' | 'onSelected' | 'onRenderRow'> {
  index: number
  width: number
}

export const DynamicListContext = createContext<Partial<{ setSize: (index: number, size: number) => void }>>({})

const Row: React.FC<RowProps> = ({ index, width, style, onRenderRow, onSelected }) => {
  const { setSize } = useContext(DynamicListContext)
  const rowRoot = useRef<null | HTMLDivElement>(null)

  useEffect(() => {
    if (rowRoot.current) {
      setSize && setSize(index, rowRoot.current.getBoundingClientRect().height)
    }
  }, [index, setSize, width])

  return (
    <div style={style} onClick={onSelected ? () => onSelected(index) : undefined}>
      <div ref={rowRoot}>{onRenderRow(index)}</div>
    </div>
  )
}

const AutoSizeList: React.FC<AutoSizeListProps & ListProps> = ({
  onRenderRow,
  rowCount,
  onSelected,
  scrollToIndex,
  style,
  onItemsRendered,
  onSetListRef,
  overscanRowCount,
}) => {
  const listRef = useRef<List | null>(null)

  const sizeMap = React.useRef<{ [key: string]: number }>({})

  const setSize = useCallback((index: number, size: number) => {
    // Performance: Only update the sizeMap and reset cache if an actual value changed
    if (sizeMap.current[index] !== size) {
      sizeMap.current = { ...sizeMap.current, [index]: size }
      if (listRef.current) {
        // Clear cached data and rerender
        listRef.current.resetAfterIndex(0)
      }
    }
  }, [])

  const getSize = useCallback(index => {
    return sizeMap.current[index] || 100
  }, [])

  // Increases accuracy by calculating an average row height
  // Fixes the scrollbar behaviour described here: https://github.com/bvaughn/react-window/issues/408
  const calcEstimatedSize = React.useCallback(() => {
    const keys = Object.keys(sizeMap.current)
    const estimatedHeight = keys.reduce((p, i) => p + sizeMap.current[i], 0)

    return estimatedHeight / keys.length
  }, [])

  useEffect(() => {
    if ((scrollToIndex && scrollToIndex >= 0) || scrollToIndex === 0) {
      listRef.current?.scrollToItem(scrollToIndex, 'start')
    }
  }, [scrollToIndex])

  return (
    <DynamicListContext.Provider value={{ setSize }}>
      <AutoSizer>
        {({ width, height }) => (
          <List
            onItemsRendered={onItemsRendered}
            style={style}
            ref={variableSizeList => {
              if (typeof onSetListRef === 'function') {
                onSetListRef(variableSizeList)
              }
              listRef.current = variableSizeList
            }}
            itemCount={rowCount}
            width={width}
            itemSize={getSize}
            height={height}
            overscanCount={overscanRowCount || 4}
            estimatedItemSize={calcEstimatedSize()}
          >
            {props => (
              <Row
                {...props}
                style={props.style as CSSProperties}
                width={width}
                onRenderRow={onRenderRow}
                onSelected={onSelected}
              />
            )}
          </List>
        )}
      </AutoSizer>
    </DynamicListContext.Provider>
  )
}

export default AutoSizeList
