import { combineReducers } from 'redux'

import { reducers as providerReducer } from './providers/reducers'
import { reducers as plansReducer } from './plans/reducers'
import { reducers as hospitalReducer } from './hospital/reducers'

export const oshReducers = combineReducers({
  providers: providerReducer,
  plans: plansReducer,
  hospitals: hospitalReducer,
})
