import React from 'react'
import { Tooltip } from '@mui/material'

const withTooltip = (tooltip, disabled, TooltipProps) => Component => {
  return !tooltip || disabled ? (
    Component
  ) : (
    <Tooltip title={tooltip} enterDelay={200} {...TooltipProps}>
      {Component}
    </Tooltip>
  )
}

export default withTooltip
