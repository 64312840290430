import { titleCaseMinors } from './titleCaseMinors'
import { escapeRegExp } from './escapeRegExp'

const escaped = titleCaseMinors.map(escapeRegExp)
const minorMatcher = new RegExp('[^^]\\b(' + escaped.join('|') + ')\\b', 'ig')
const punctuationMatcher = /:\s*(\w)/g

export const titleCase = (string = '', options = {}) => {
  const { excludeMinors = true } = options

  let result = string.replace(/(^|\s)(\w)/g, (matches, previous, letter) => {
    return previous + letter.toUpperCase()
  })

  if (!excludeMinors) {
    result = result.replace(minorMatcher, minor => {
      return minor.toLowerCase()
    })
  }

  return result.replace(punctuationMatcher, letter => {
    return letter.toUpperCase()
  })
}
