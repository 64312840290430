import { Map } from 'immutable'
import { getOrgId, getEventId } from './routes'
import { formatSupportUsername } from '../../../../core/util/dataHelpers'
import paths from '../paths'

export const isForEvent = state => {
  const orgId = getOrgId(state)
  const eventId = getEventId(state)

  return Boolean(orgId && eventId)
}

export const filterUnresolved = list =>
  list.filter(x => {
    return !x.get('isResolved', false)
  })

export const filterEscalated = list =>
  list.filter(x => {
    return x.get('isEscalated', false)
  })

export const filterMarkedForRetro = list =>
  list.filter(x => {
    return x.get('postEventRetro', false)
  })

export const filterUnhandled = list => list.filter(x => !Boolean(x.get('validity')))

export const sortDirections = {
  asc: 'ASC',
  desc: 'DESC',
}

export const sortByTimestamp = (list, direction) =>
  list.sort((a, b) => {
    const date1 = a.get('messageTimestamp')
    const date2 = b.get('messageTimestamp')
    if (date1 < date2) return direction === sortDirections.asc ? -1 : 1
    if (date1 > date2) return direction === sortDirections.asc ? 1 : -1
    return 0
  })

export const getUserNameOrDefault = (state, datum) => {
  const userName = datum.get('userName') || datum.getIn(['platformLogDoc', 'smoochUserName'])
  if (userName) {
    return formatSupportUsername(userName)
  }

  const orgId = datum.get('orgId')
  const eventId = datum.get('eventId')
  const smoochId = datum.get('smoochId')
  const platform = state.support.getIn(paths.platformLog(orgId, eventId, smoochId), Map())

  return formatSupportUsername(platform.get('smoochUserName'))
}
