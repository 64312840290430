import { Map } from 'immutable'
import { sciensio as config } from '../../config'
import paths from './paths'

export const modals = {
  CUSTOMER_PARTICIPANT_EDIT: 'CUSTOMER_PARTICIPANT_EDIT',
  IMPERSONATION: 'IMPERSONATION',
  PARTICIPANT_CONVERSATION: 'PARTICIPANT_CONVERSATION',
  USER_MANAGEMENT: 'USER_MANAGEMENT',
}

const getParamFromCurrentLocation = param => {
  const regexp = new RegExp(`(/${param}/)(.[^/]*)`)
  const { pathname } = window.location

  const matches = pathname.match(regexp)
  return matches && matches.length > 2 ? matches[2] : undefined
}

export const selectOrgId = () => getParamFromCurrentLocation('orgs')

export const selectEventId = () => getParamFromCurrentLocation('events')

export const selectNotification = state => state.context.getIn(paths.notification(), Map())

export const selectMediaUploadModal = state => state.context.getIn(paths.mediaUploadModal(), Map())

export const selectMediaUploadVisible = state => state.context.getIn(paths.mediaUploadModalPaths.visible(), false)

export const selectMediaUploadMeta = state => state.context.getIn(paths.mediaUploadModalPaths.meta(), Map())

export const selectModal = state => state.context.getIn(paths.modal(), Map())

export const selectModalVisible = (state, modalType) =>
  state.context.getIn(paths.modalPaths.modalType()) === modalType

export const selectModalMeta = (state, modalType) => {
  return state.context.getIn(paths.modalPaths.modalType()) === modalType
    ? state.context.getIn(paths.modalPaths.meta())
    : Map()
}

export const selectCardLocationRect = state => state.context.getIn(paths.cardLocation(), Map())

export const selectImageUrl = (orgId, urlSafeEventId, fileName) => {
  if (!orgId) {
    return `${config.cloudfrontUrl}/${config.intakeStagingArea}/${fileName}`
  }
  return urlSafeEventId
    ? `${config.cloudfrontUrl}/${orgId}/${urlSafeEventId}/${fileName}`
    : `${config.cloudfrontUrl}/${orgId}/${fileName}`
}

export const selectActivityStatus = (state, activity) => {
  let activityString = ''

  if (typeof activity === 'string') {
    activityString = activity
  }
  if (activity && activity.complete) {
    activityString = activity.complete.toString()
  }

  return state.context.getIn(paths.activityStatus(activityString), false)
}
