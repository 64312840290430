export const userConversation = {
  live: 'live',
  all: 'all',
}

export const idks = {
  all: 'all',
  unhandled: 'unhandled',
  unresolved: 'unresolved',
  escalated: 'escalated',
  markedForRetro: 'markedForRetro',
}

export const support = {
  all: 'all',
  unresolved: 'unresolved',
}
