import { Components, Theme, inputLabelClasses } from '@mui/material'

export const MuiInputLabel = (): Components<Theme>['MuiInputLabel'] => {
  return {
    defaultProps: { shrink: true },
    styleOverrides: {
      root: {
        fontSize: 14,
        [`&.${inputLabelClasses.root}[data-shrink=true]`]: {
          transform: 'translate(4px, -25px)',
        },
      },
    },
  }
}
