import React, { useContext, createContext, PropsWithChildren } from 'react'
import { GlobalStyles, ThemeProvider as MuiThemeProvider } from '@mui/material'
import { Flex } from 'mui-blox'
import { eventSettingsKeys, BRANDING_KEYS } from '~constants'
import { LoadingIndicatorBar } from '~controls'
import { getGlobalStyles } from '~style'
import { typify } from '~core/util/data/typify'
import { useGetEventTheme, BrandingValues } from './useGetEventTheme'

interface BrandingContext {
  branding: BrandingValues
  refresh: () => Promise<void>
  eventId: string
}

export const BrandingContext = createContext({
  BRANDING_KEYS,
  branding: {} as BrandingValues,
  refresh: () => {},
  eventId: '',
})

export const useEventBranding = () => {
  return useContext(BrandingContext)
}

type FallbackFunction = () => React.ReactElement | null

interface BrandingProviderProps {
  eventId?: string
  disabled?: boolean
  themeName?: string
  settingKey?: string
  disableGlobal?: boolean
  fallback?: React.ReactElement | FallbackFunction
  brandingValues?: BrandingValues
}

export const BrandingProvider = ({
  children,
  eventId = '',
  disabled = false,
  disableGlobal = false,
  themeName = undefined,
  settingKey = eventSettingsKeys.branding,
  fallback,
  brandingValues,
}: PropsWithChildren<BrandingProviderProps>) => {
  const { isGetting, theme, branding, refresh } = useGetEventTheme({
    eventId,
    disabled,
    themeName,
    settingKey,
    initialValues: brandingValues,
  })

  const Fallback = () => {
    if (fallback && typify.isFunction(fallback)) return (fallback as FallbackFunction)()
    if (React.isValidElement(fallback)) return fallback

    return (
      <Flex flexColumn>
        <LoadingIndicatorBar isLoading={true} />
      </Flex>
    )
  }

  return isGetting ? (
    <Fallback />
  ) : (
    <BrandingContext.Provider value={{ BRANDING_KEYS, branding, refresh, eventId }}>
      <MuiThemeProvider theme={theme}>
        {!disableGlobal && <GlobalStyles styles={getGlobalStyles(theme)} />}
        {children}
      </MuiThemeProvider>
    </BrandingContext.Provider>
  )
}
