import React from 'react'
import { Suspense, lazyRetry } from '../../../routing'

const HealthScreeningRoutes = lazyRetry(() =>
  import(
    /* webpackChunkName: "healthScreening" */
    './Routes'
  ),
)

const HealthScreeningRoot = props => (
  <Suspense>
    <HealthScreeningRoutes {...props} />
  </Suspense>
)

export default HealthScreeningRoot
