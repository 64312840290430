import pkg from '../../package.json'

export const auth0 = {
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID || 'tRZ2BDCrRpvG059GG5ZJf80MfCe1hgCp',
  domain: process.env.REACT_APP_AUTH0_DOMAIN || 'testlogin.42chat.com',
  audience: process.env.REACT_APP_AUTH0_AUDIENCE || 'https://sciensio-test.auth0.com/api/v2/',
}

export const sciensio = {
  apiUrl: process.env.REACT_APP_SCIENSIO_API_URL || 'http://localhost:8080',
  socketUrl: process.env.REACT_APP_SCIENSIO_SOCKET_URL || 'http://127.0.0.1',
  socketPort: process.env.REACT_APP_SCIENSIO_SOCKET_PORT,
  anonymousKey: process.env.REACT_APP_SCIENSIO_ANONYMOUS_KEY,
  cloudfrontUrl: process.env.REACT_APP_CLOUDFRONT_BASEURL || 'https://images.42chat.com',
  intakeStagingArea: process.env.REACT_APP_INTAKE_STAGING_AREA || 'intakeStaging',
  previewUrl: process.env.REACT_APP_PREVIEW_URL || 'https://bot-test.42chat.com/preview/',
  webMessengerUrl: process.env.REACT_APP_MESSENGER_URL || 'https://webmessenger-test.42chat.com/',
  paymentPortalRedirect:
    process.env.REACT_APP_PAYMENT_PORTAL_REDIRECT || 'https://sciensio.isecuresites.com/invoice/payment',
  flatfileIoLicenseKey: process.env.REACT_APP_FLATFILE_IO_LICENSE_KEY,
  buildVersion: pkg.version,
}

export const facebook = {
  appId: process.env.REACT_APP_FACEBOOK_BOT_APP_ID,
}

export const stripe = {
  apiKey: process.env.REACT_APP_STRIPE_KEY,
}

export const firebase = {
  excon_place: process.env.REACT_APP_EXCON_PLACE_HOST || 'http://localhost:3210',
  excon_place_local: 'http://localhost:3210',
}

export const ddog = {
  admin: {
    clientToken: process.env.REACT_APP_DD_ADMIN_CLIENT_TOKEN || 'pubd3c97e45ed6e6b854f5ea9b3f3ff5f23',
    applicationId: process.env.REACT_APP_DD_ADMIN_APPLICATION_ID || '3332a7b6-3678-444a-80c9-b9d4e033b92c',
  },
  excons: {
    clientToken: process.env.REACT_APP_DD_EXCON_CLIENT_TOKEN || 'pub57cb643856b9b5853550ad26a2c54267',
    applicationId: process.env.REACT_APP_DD_EXCON_APPLICATION_ID || '9a3f3dc2-8324-4a5f-87b7-f67fc171c5db',
  },
}
