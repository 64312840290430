export const libraryTypes = {
  response: 'response',
  notification: 'notification',
  trainingPhrase: 'trainingPhrase',
  synonymGroup: 'synonymGroup',
  question: 'question',
}

export const BUBBLE_TYPES = {
  text: 'text',
  conversationExtension: 'conversationExtension',
  image: 'image',
}

export const MATCH_TYPES = {
  answeredQuestion: 'answeredQuestion',
  authentication: 'authentication',
  botSetting: 'botSetting',
  channel: 'channel',
  participantField: 'participantField',
  previousIntent: 'previousIntent',
  question: 'question',
  timespan: 'timespan',
  silent: 'silent',
  excludeRecipients: 'excludeRecipients',
  missingAnswer: 'missingAnswer',
}

export const MATCH_TYPES_WEIGHTS = {
  [MATCH_TYPES.answeredQuestion]: 0.9,
  [MATCH_TYPES.authentication]: 1,
  [MATCH_TYPES.botSetting]: 1,
  [MATCH_TYPES.channel]: 1,
  [MATCH_TYPES.participantField]: 1,
  [MATCH_TYPES.previousIntent]: 1,
  [MATCH_TYPES.question]: 1,
  [MATCH_TYPES.timespan]: 2,
  [MATCH_TYPES.silent]: -100,
  [MATCH_TYPES.excludeRecipients]: 1,
  [MATCH_TYPES.missingAnswer]: 0.8,
}

export const MATCH_TYPE_LABELS = {
  [MATCH_TYPES.answeredQuestion]: 'Answered Question',
  [MATCH_TYPES.authentication]: 'Authentication',
  [MATCH_TYPES.botSetting]: 'Bot Setting',
  [MATCH_TYPES.channel]: 'Participant Channel',
  [MATCH_TYPES.participantField]: 'Participant Field',
  [MATCH_TYPES.previousIntent]: 'Previous Intent',
  [MATCH_TYPES.silent]: 'Silent',
  [MATCH_TYPES.question]: 'Question',
  [MATCH_TYPES.timespan]: 'Time Limited',
  [MATCH_TYPES.excludeRecipients]: 'Exclude Recipients',
  [MATCH_TYPES.missingAnswer]: 'Missing Answer',
}

export const botSettingMatches = {
  liveChat: {
    key: 'liveChat',
    label: 'Live chat',
    values: {
      on: {
        value: true,
        label: 'On',
      },
      off: {
        value: false,
        label: 'Off',
      },
    },
  },
}

export const responseParticipantFieldMatchOperations = {
  equals: 'equals',
  notEquals: 'notEquals',
  hasValue: 'hasValue',
  noValue: 'noValue',
  includesAll: 'includesAll',
}

export const responseParticipantAuthenticationTypes = {
  participantAuth: 'participantAuth',
  anonymousAccess: 'anonymousAccess',
}
