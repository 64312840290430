import { useCallback, useMemo } from 'react'
import { useParticipantAuthToken } from '../auth/useParticipantAuthToken'
import { useAuthToken } from '../auth/useAuthToken'

type methods = 'GET' | 'POST' | 'PATCH' | 'DELETE'

export type Body = Record<string, unknown> | string | number | unknown[]

export const usePayload = (method: methods) => {
  const [bearer] = useAuthToken()
  const [participantBearer] = useParticipantAuthToken()

  const payload = useMemo(
    () => ({
      credentials: 'include',
      method,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${bearer}`,
        ParticipantAuth: `Bearer ${participantBearer}`,
      },
    }),
    [bearer, participantBearer, method],
  )

  return useCallback(
    (body?: Body) =>
      body
        ? {
            ...payload,
            body: JSON.stringify(body),
          }
        : payload,
    [payload],
  )
}
