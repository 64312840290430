export const InlineEditTypes = {
  text: 'text',
  enum: 'enum',
  bool: 'bool',
  select: 'select',
}

export const FilterTypes = {
  text: 'text',
  bool: 'bool',
  number: 'number',
  timestamp: 'timestamp',
  enum: 'enum',
}

export const FilterOperators = {
  equal: '=',
  notEqual: '!=',
  greaterThan: '>',
  greaterThanOrEqual: '>=',
  lessThan: '<',
  lessThanOrEqual: '<=',
  contains: 'contains',
  notContains: 'not contains',
}

export const ApiRequestRejection = {
  notFound: 'Not Found',
}

export const DATETIME = {
  date: 'D',
  dateHyphenated: 'y-LL-dd',
  dateLong: 'DDD',
  dateTime: 'D h:mm a ZZZZ',
  day: 'ccc, LLL d',
  time: 'h:mm a',
  shortDateTime: 'LLL d, h:mm a',
  shortDateTimeNoMeridiem: 'LLL d, h:mm',
  monthYear: 'LLL yyyy',
  monthDay: 'LLL d',
  weekDayTime: 'ccc h:mm',
}
