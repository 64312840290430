import pathToRegexp from 'path-to-regexp'
import { routes } from './routes'
import { useHistory } from '../../react/routing/useHistory'
import { parseQuery } from './parseQuery'

export const pathFromParams = (path, params) => {
  let newPath = path
  Object.keys(params).forEach(key => {
    newPath = newPath.replace(`:${key}`, params[key])
  })
  return newPath
}

const extractRouteParams = path => {
  const tokens = pathToRegexp.parse(path)
  const routeParams = []
  tokens.forEach(token => {
    if (typeof token === 'object') {
      routeParams.push(token)
    }
  })

  return routeParams
}

const generateRouteFunc = (routeName, path) => {
  const routeParams = extractRouteParams(path)
  const toPath = pathToRegexp.compile(path)

  return (...params) => {
    if (routeParams.length < params.length) {
      let expected = routeParams.map(rp => rp.name).join(', ')
      throw Error(`Unexpected number of route parameters for '${routeName}'. Expected: ${expected}`)
    }
    const routeValues = {}
    routeParams.forEach(({ name, optional }, index) => {
      const value = params[index]
      if (!optional && !value) {
        throw Error(`Missing required route parameters for '${routeName}'.`)
      }
      routeValues[name] = value
    })

    return toPath(routeValues)
  }
}

export const searchTo = (route, term) => `${route}?term=${encodeURIComponent(term)}`

const routeNames = Object.keys(routes)
export const pathTo = {}
routeNames.forEach(name => {
  pathTo[name] = generateRouteFunc(name, routes[name])
})

const pushHistory = path => {
  const { push } = useHistory()
  push(path)
}

export const navigator = {
  //   go: (path) => push(path),

  //   dashboard: (orgId, eventId) => push(pathTo.dashboard(orgId, eventId)),
  //   event: (orgId, eventId) => push(pathTo.event(orgId, eventId)),
  //   createEvent: (orgId) => push(pathTo.eventCreate(orgId)),
  //   eventImages: (orgId, eventId) => push(pathTo.eventImages(orgId, eventId)),
  //   eventLinks: (orgId, eventId) => push(pathTo.eventLinks(orgId, eventId)),
  events: orgId => pushHistory(pathTo.botList(orgId)),
  login: () => pushHistory(pathTo.login()),
  //   notifications: (orgId, eventId) => push(pathTo.notifications(orgId, eventId)),
  //   participants: (orgId, eventId) => push(pathTo.participants(orgId, eventId)),
  //   profile: () => push(pathTo.profile()),
  //   // org: (orgId) => push(pathTo.org(orgId)),
  //   orgs: () => push(pathTo.orgs()),
  //   responses: (orgId, eventId) => push(pathTo.responses(orgId, eventId)),
  //   response: (orgId, eventId, intentId) => push(pathTo.response(orgId, eventId, intentId)),
  //   users: (orgId) => push(pathTo.users(orgId)),
  //   botTesting: (orgId, eventId) => push(pathTo.botTesting(orgId, eventId)),
  //   idks: (orgId, eventId) => push(pathTo.idks(orgId, eventId)),
  //   falsePositives: (orgId, eventId) => push(pathTo.falsePositives(orgId, eventId)),
  //   comparisons: (orgId, eventId) => push(pathTo.comparisons(orgId, eventId)),
  comparison: (orgId, eventId, comparisonId) => pushHistory(pathTo.comparison(orgId, eventId, comparisonId)),
  //   templateSurvey: orgId => push(pathTo.templateSurvey(orgId)),
  //   training: (orgId, eventId) => push(pathTo.training(orgId, eventId)),
  //   trainingResults: (orgId, eventId, trainingId) => push(pathTo.trainingResults(orgId, eventId, trainingId)),
}

export const completeParams = (route, ...params) => {
  const regex = /(:[a-zA-Z]*)/g
  let i = 0
  return route.replace(regex, () => {
    return params[i++]
  })
}

export const getFirstSearchTerm = search => {
  const terms = Object.values(parseQuery(search))
  return terms.length > 0 ? terms[0] : ''
}

export const fillParams = (route, ...params) => {
  const regex = /(:[a-zA-Z]*)/g
  let i = 0
  return route.replace(regex, () => {
    return params[i++]
  })
}
