import React from 'react'
import PropTypes from 'prop-types'
import ClassNames from 'classnames'
import { Flex } from 'mui-blox'
import { makeStyles } from '@mui/styles'
import ContentFrame from './ContentFrame'
import LeftBar from './LeftBar'
import FramedTitle from './FramedTitle'
import HeaderFrame from './HeaderFrame'
import { useAccount } from '../../account/hooks/useAccount'
import { useSetPageTitle } from '../hooks/useSetPageTitle'

const FramedPage = ({
  className = undefined,
  children,
  sideBarContent = null,
  disableAccount = false,
  disableHeader = false,
  disableLeftBar = false,
}) => {
  const classes = useStyles()

  const { renderButton } = useAccount()
  const { tools } = useSetPageTitle()

  const hasTools = React.Children.count(tools) > 0 || React.Children.count(children) > 0

  return (
    <Flex flexColumn className={ClassNames(className)}>
      {!disableHeader && (
        <HeaderFrame>
          <FramedTitle />
          <Flex alignItems='baseline'>
            {hasTools && <Flex>{tools}</Flex>}
            {!disableAccount && renderButton({ className: classes.button })}
          </Flex>
        </HeaderFrame>
      )}
      <ContentFrame flexColumn>{children}</ContentFrame>
      {!disableLeftBar && <LeftBar>{sideBarContent}</LeftBar>}
    </Flex>
  )
}

FramedPage.propTypes = {
  sideBarContent: PropTypes.element,
  disableHeader: PropTypes.bool,
  disableAccount: PropTypes.bool,
  disableLeftBar: PropTypes.bool,
}

const useStyles = makeStyles(({ breakpoints, spacing, palette }) => ({
  button: {
    color: palette.text.secondary,
    marginRight: spacing(1),
    marginBottom: spacing(1),
    [breakpoints.down('sm')]: {
      color: palette.common.white,
      marginBottom: 0,
    },
  },
}))

export default FramedPage
