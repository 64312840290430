import { useCallback, useState, useRef, useEffect } from 'react'
import { useApiMessageToast } from '../common'
import { FetchOptions } from './types'

export const useFetchHandler = <TResponse>(options: FetchOptions = {}) => {
  const cancelRef = useRef(false)

  useEffect(() => {
    return () => {
      cancelRef.current = true
    }
  }, [])

  const { initialFetching = false, onError } = options
  const { pushError } = useApiMessageToast()

  const [isFetching, setIsFetching] = useState(initialFetching)

  const doneFetching = useCallback(() => {
    setTimeout(() => {
      !cancelRef.current && setIsFetching(false)
    })
  }, [setIsFetching])

  const fetch = useCallback(
    async (fetcher: () => Promise<TResponse>) => {
      setIsFetching(true)
      let result

      try {
        result = await fetcher()
      } catch (err) {
        if (onError) {
          onError(err, pushError)
        } else {
          pushError(err.message, err.status)
        }
        throw err
      } finally {
        doneFetching()
      }

      return result
    },
    [onError, doneFetching, pushError],
  )

  return { fetch, isFetching }
}
