import React, { FC } from 'react'
import { Theme } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { ValueOf } from '~models/util'
import Bubble from './Bubble'
import { useEventBranding } from '~brix/connectors/context/useEventBranding'
import { BrandingValues } from '~brix/connectors/context/useGetEventTheme'

export const TICK_PLACEMENT = {
  left: 'left',
  right: 'right',
}

type TickType = ValueOf<typeof TICK_PLACEMENT> | null | undefined
type ColorType = 'primary' | 'secondary' | 'tertiary' | 'branding'
interface ComponentProps {
  tick?: TickType
  color?: ColorType
  pending?: boolean
  className?: string
  children: React.ReactNode
}

const FlexBubble: FC<ComponentProps> = ({
  tick,
  color = 'primary',
  pending = false,
  children,
  className,
  ...other
}) => {
  const { branding } = useEventBranding()

  const classes = useStyles({ tick, color, pending, branding })

  return (
    <Bubble tick={!!tick} className={classNames(classes.root, className)} {...other}>
      {children}
    </Bubble>
  )
}

const useStyles = makeStyles((theme: Theme) => {
  const {
    palette,
    mixins: { emphasize, alpha },
  } = theme

  return createStyles<string, { tick: TickType; color: ColorType; pending: boolean; branding: BrandingValues }>({
    root: ({ tick, color, pending, branding }) => {
      let styles = {}

      if (tick === TICK_PLACEMENT.left) {
        styles['borderBottomLeftRadius'] = '0px'
        styles['&:after'] = {
          left: 0,
          marginLeft: 0,
          borderLeft: 0,
        }
      } else if (tick === TICK_PLACEMENT.right) {
        styles['borderBottomRightRadius'] = '0px'
        styles['&:after'] = {
          right: 0,
          marginRight: 0,
          borderRight: 0,
        }
      }

      let backgroundColor
      let textColor

      if (color === 'primary') {
        backgroundColor = palette.chat.user.main
        textColor = palette.chat.user.contrastText
      } else if (color === 'secondary') {
        backgroundColor = palette.background.paper
        textColor = palette.getContrastText(palette.background.paper)
      } else if (color === 'tertiary') {
        backgroundColor = palette.primary.dark
        textColor = palette.primary.contrastText
      } else if (color === 'branding') {
        backgroundColor = branding.tertiary || emphasize(palette.background.paper, 0.1)
        textColor = palette.getContrastText(backgroundColor)
      } else {
        backgroundColor = emphasize(palette.background.paper, 0.1)
        textColor = palette.getContrastText(palette.background.paper)
      }

      if (pending) {
        backgroundColor = alpha(backgroundColor, 0.35)
        textColor = alpha(textColor, 0.8)
      }

      styles = {
        ...styles,
        backgroundColor,
        color: textColor,
      }

      if (styles['&:after']) {
        styles['&:after']['borderTopColor'] = backgroundColor
      }

      return styles
    },
  })
})

export default FlexBubble
