import { Map, fromJS } from 'immutable'
import { handleActions } from 'redux-actions'
import { actions } from './actions'
import paths from './paths'
import { selectFilterKey } from './selectors'

export default handleActions(
  {
    [actions.selectColumn]: (state, { payload: { eventId, table, column } }) =>
      state.setIn(paths.selectedColumn(eventId, table), column),

    [actions.clearColumn]: (state, { payload: { eventId, table } }) =>
      state.setIn(paths.selected(eventId, table), Map()),

    [actions.clearOperator]: (state, { payload: { eventId, table } }) =>
      state.setIn(paths.selectedOperator(eventId, table), Map()).deleteIn(paths.selectedValue(eventId, table)),

    [actions.selectOperator]: (state, { payload: { eventId, table, operator } }) =>
      state.setIn(paths.selectedOperator(eventId, table), operator),

    [actions.addFilter]: (state, { payload: { eventId, table, column, operator, value } }) => {
      const key = selectFilterKey(column, operator, value)
      return state.setIn(paths.selected(eventId, table), Map()).setIn(
        paths.filterByKey(eventId, table, key),
        Map({
          key,
          column,
          operator,
          value,
        }),
      )
    },

    [actions.removeFilter]: (state, { payload: { eventId, table, key } }) =>
      state.deleteIn(paths.filterByKey(eventId, table, key)),

    [actions.resetFilter]: (state, { payload: { eventId, table } }) => state.deleteIn(paths.filters(eventId, table)),

    [actions.editFilter]: (state, { payload: { eventId, table, key } }) => {
      const path = paths.filterByKey(eventId, table, key)
      const filter = state.getIn(path)
      return state
        .deleteIn(path)
        .setIn(paths.selectedColumn(eventId, table), filter.get('column'))
        .setIn(paths.selectedOperator(eventId, table), filter.get('operator'))
        .setIn(paths.selectedValue(eventId, table), filter.get('value'))
    },

    [actions.selectValue]: (state, { payload: { eventId, table, value } }) => {
      const path = paths.selectedValue(eventId, table)
      return state.setIn(path, fromJS(value))
    },
  },
  Map(),
)
