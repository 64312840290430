export const FORM_TYPES = {
  quote: 'quote',
}

export const FORM_BLOCK_TYPES = {
  autocomplete: 'autocomplete',
  check: 'check',
  date: 'date',
  fieldSet: 'fieldSet',
  secure: 'secure',
  select: 'select',
  switch: 'switch',
  text: 'text',
} as const

export const FORM_LAYOUT_TYPES = {
  stack: 'stack',
  grid: 'grid',
} as const

export const FORM_ELEMENT_TYPES = {
  field: 'field',
  header: 'header',
} as const

export const FORM_COMPLICATION_TYPES = {
  infoModal: 'infoModal',
  tag: 'tag',
} as const
