import React from 'react'
import { useQueryParams } from '../routing'
import { StyledEngineProvider, Typography, Box } from '@mui/material'
import { ThemeProvider } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { getTheme } from '../../style'
import { MessengerBrander } from './decorators/MessengerBrander'
import { useMessengerHostListener } from './hooks/useMessengerHostListener'

import '../../index.css'

const GlobalStyles = ({ children }) => {
  useStyles()
  return children
}

const MessengerRoot = () => {
  useStyles()

  const { eventId, variant, zIndex } = useQueryParams()

  useMessengerHostListener()
  const theme = getTheme()

  if (!eventId) {
    return (
      <Box p={2}>
        <Typography>Event ID is required!</Typography>
      </Box>
    )
  }

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <GlobalStyles>
          <MessengerBrander eventId={eventId} variant={variant} zIndex={zIndex} />
        </GlobalStyles>
      </ThemeProvider>
    </StyledEngineProvider>
  )
}

const useStyles = makeStyles(() => ({
  '@global': {
    html: {
      height: '100%',
    },
    'body, #root': {
      height: '100%',
      margin: 0,
    },
    '#root': {
      display: 'flex',
      flexDirection: 'column',
    },
  },
}))

export default MessengerRoot
