import React, { ForwardedRef, useEffect, useState } from 'react'
import { AvatarVariantA, AvatarVariantB, AvatarVariantC } from '.'
import { AvatarProps } from './types'
import { SemanticIcon, iconUsages } from '~controls'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import { Fab } from '@mui/material'
import ClassNames from 'classnames'

interface SelectedAvatarProps extends AvatarProps {
  avatarStyle: string
}

export const SelectedAvatar: React.FC<SelectedAvatarProps> = React.forwardRef(
  ({ avatarStyle, ...props }, ref: ForwardedRef<HTMLDivElement> | undefined) => {
    const classes = useStyles({ buttonSize: props.buttonSize })

    const { isMessengerOpen, onClick, id } = props
    const [mounted, setIsMounted] = useState(false)

    useEffect(() => {
      setIsMounted(true)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const renderContent = () => {
      if (isMessengerOpen) {
        return (
          <Fab
            color='primary'
            onClick={onClick}
            className={ClassNames(classes.fab)}
            size='large'
            id={id}
            aria-label='Close conversation'
          >
            <SemanticIcon usage={iconUsages.close} className={classes.rotateIcon} />
          </Fab>
        )
      }

      if (avatarStyle === 'avatarA') {
        return <AvatarVariantA {...props} className={mounted ? classes.fadeInIcon : ''} />
      }

      if (avatarStyle === 'avatarB') {
        return <AvatarVariantB {...props} className={mounted ? classes.fadeInIcon : ''} />
      }

      if (avatarStyle === 'avatarC') {
        return <AvatarVariantC {...props} className={mounted ? classes.fadeInIcon : ''} />
      }

      return <AvatarVariantB {...props} className={mounted ? classes.fadeInIcon : ''} />
    }

    return <div ref={ref}>{renderContent()}</div>
  },
)

export default SelectedAvatar

const useStyles = makeStyles<{ buttonSize: number }>(() =>
  createStyles<string, { buttonSize: number }>({
    fab: {
      boxShadow: 'none',
      flexShrink: 0,
      height: props => props.buttonSize,
      width: props => props.buttonSize,
    },
    fadeInIcon: {
      animation: '$fade 0.25s ease-out',
    },
    rotateIcon: {
      animation: '$spin 0.25s ease-out',
    },
    '@keyframes spin': {
      '0%': {
        transform: 'rotate(45deg)',
      },
      '100%': {
        transform: 'rotate(0deg)',
      },
    },
    '@keyframes fade': {
      '0%': {
        opacity: 0,
      },
      '100%': {
        opacity: 1,
      },
    },
  }),
)
